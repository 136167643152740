import Checkbox from 'components/checkbox';
import FocusInput from 'components/focus-input';
import Button from 'components/NButton';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import swal from 'sweetalert';
import { onboardingSequency } from 'util/index';
import * as yup from 'yup';
import { IInviteSlideProps } from '../../../../interfaces/IInvitation';

const PreAddedSocialLinks = [
  { type: 'instagram', url: '' },
  {
    type: 'tiktok',
    url: '',
  },
  {
    type: 'twitter',
    url: '',
  },
  {
    type: 'youtube',
    url: '',
  },
  {
    type: 'facebook',
    url: '',
  },
];

const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required('Enter your full name')
    .matches(/^(\D+\s+\D+)(\s*\D*)*$/, 'Enter your full name'),
  email: yup
    .string()
    .email('Enter valid email address')
    .required('Enter valid email address'),
  password: yup
    .string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must be the combination of upercase, lowercase, number, and special character',
    )
    .max(50)
    .min(8)
    .required('Password is required'),
  sign: yup
    .bool()
    .oneOf([true], 'Accept Terms & Conditions is required')
    .required(),
});

const Slide8: React.FC<IInviteSlideProps & { gotoSignUp: Function }> = ({
  sliderNumber,
  next,
  className,
  user,
}) => {
  const history = useHistory();
  const { setUser, SignUp, loggedIn } = useAuth();
  const {
    values,
    handleChange,
    handleBlur,
    resetForm,
    isValid,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    setValues,
    // tslint:disable-next-line: react-hooks-nesting
  } = useFormik({
    validationSchema,
    initialValues: {
      fullName: '',
      email: '',
      password: '',
      sign: true,
      refId: undefined,
      isInvitedUser: false,
      allowSelling: false,
      isAffiliate: false,
    },
    onSubmit: async (formData: any) => {
      if (loggedIn) {
        swal({
          title: 'Already logged in',
          icon: 'success',
        }).then((data) => {
          // data && history.push('/my-profile');
        });

        return;
      }
      const name = formData.fullName.replace(/\s{2,}/g, ' ').trim();
      const firstName = name.split(' ').slice(0, -1).join(' ');
      const lastName = name.split(' ').slice(-1).join(' ');

      await SignUp({
        ...formData,
        firstName,
        lastName,
        isSuperAffiliate: false,
        refId: user?._id,
        isInvitedUser: true,
        allowSelling: true,
        isAffiliate: true,
        socialMediaLinks: PreAddedSocialLinks,
        isActiveProfile: true,
        frontendURL: `${window.location.protocol}//${window.location.host}/verify-email`,
        timeOffset: dayjs.tz.guess(),
      })
        .then((res: any) => {
          history.push(onboardingSequency[0]);
        })
        .catch((e: Error) => {
          if (e?.message.toLocaleLowerCase() === 'email already exists')
            swal({
              title: 'You already have an account!',
              text: 'Please login below or use a different email address for registration',
              icon: 'error',
              buttons: ['Cancel', 'Login'],
            });
        });
    },
  });

  const beforeSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    if (Object.keys(errors).length === 1 && errors.sign) {
      toast.info(
        'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
      );
    }
    handleSubmit(e);
  };
  return (
    <div className={className}>
      <div className="profile--info mb-15">
        <p>
          Simply fill out the form below to start growing your social revenue
          today!
        </p>
        <form
          action="post"
          onSubmit={(e) => {
            beforeSubmit(e);
          }}
          className="form"
        >
          <div className="field">
            <FocusInput
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.fullName}
              touched={touched.fullName}
              value={values.fullName}
              label="Full Name"
              name="fullName"
              validations={[{ noMultipeSpace: true }, { type: 'alpha' }]}
            />
          </div>
          <div className="field">
            <FocusInput
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email}
              touched={touched.email}
              value={values.email}
              label="Email"
              name="email"
              validations={[{ noSpace: true }]}
            />
          </div>
          <div className="field">
            <FocusInput
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password}
              touched={touched.password}
              value={values.password}
              label="Password"
              type="password"
              limit={50}
              name="password"
            />
          </div>
          <div className="check-holder">
            <Checkbox
              name="sign"
              defaultChecked={values.sign}
              onChange={handleChange}
              onBlur={handleBlur}
              label={
                <span
                  style={{
                    position: 'relative',
                    zIndex: 10,
                  }}
                >
                  By creating and account you are agreeing to our{' '}
                  <Link to="/terms" target="_blank">
                    Terms and Conditions
                  </Link>{' '}
                  and{' '}
                  <Link to="/policy" target="_blank">
                    Privacy Policy
                  </Link>
                </span>
              }
            />
          </div>
          <Button
            block
            type="primary"
            htmlType="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            SIGN UP
          </Button>
        </form>
      </div>
      <div className="video-holder">
        <video
          style={{ width: '100%' }}
          poster="/assets/images/inner-slide8.jpg"
          autoPlay
          muted
          loop
          playsInline
          preload="none"
        >
          <source
            src="https://d1i1apkb1v314l.cloudfront.net/vid-pops/inner-circle/08_background.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default styled(Slide8)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  color: #fff;

  .video-holder {
    position: absolute;
    left: -14px;
    right: -14px;
    top: 0;
    bottom: 0;
  }

  .profile--info {
    position: relative;
    z-index: 3;
    font-size: 16px;
    line-height: 1.2;
  }

  .btns-holder {
    position: relative;
    color: #fff;
  }

  p {
    margin: 0 0 25px;
    font-size: 20px;
    line-height: 1.23;
  }

  .button {
    min-width: inherit;

    &.button-primary {
      padding: 15px;
      min-width: 140px;
    }

    + .button {
      margin: 12px 0 0;
    }
  }

  .button-link {
    color: #14d7f0;

    &:hover {
      color: #ff5400;
    }
  }

  .checkbox {
    margin: 0 0 15px;

    label {
      padding: 0 0 0 35px;
      text-align: left;
      position: relative;
      color: #fff;
    }

    .custom-input-holder {
      position: absolute;
      left: 0;
      top: 0;
    }

    .custom-input {
      width: 24px;
      height: 24px;
      border-radius: 0;
      font-size: 10px;
      margin-top: 0;
      border: none !important;

      &:after {
        border: none !important;
      }
    }

    .label-text {
      color: #fff;
      font-size: 10px;
      line-height: 1.2;
      display: block;
    }
  }

  .check-holder {
    padding-top: 10px;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .text-input {
    &.mb-20 {
      margin-bottom: 10px !important;
    }

    &.input-active {
      label {
        font-size: 9px;
        top: 6px;
      }
    }

    label {
      top: 12px;
      font-size: 15px;
      line-height: 1.3;
    }
  }

  .field {
    position: relative;
    overflow: hidden;
  }

  .form-control {
    height: 40px;
    padding: 15px 17px 5px;
    border: none !important;
    font-size: 15px;
  }

  .is-invalid {
    font-size: 12px !important;
    margin: 5px 0;
  }
`;

export const walletHistory = {
  item: {
    _id: '605ba3a6d3a6213113d999b0',
    userID: '605500a349057f0f3f51623e',
    walletEvents: [
      {
        event: {
          eventType: 'Sale',
          eventPrice: 21.78,
          eventDate: '2021-04-26T06:58:14.000Z',
          eventDescription: 'Order: 3236-457240-6484',
          associatedID: '3236-457240-6484',
          orderId: '60870d3796b58841acf51508',
          transferStatus: 'pending',
        },
        _id: '60870d4696b58841acf51512',
      },
      {
        event: {
          eventType: 'Sale',
          eventPrice: 21.78,
          eventDate: '2021-04-26T06:57:49.000Z',
          eventDescription: 'Order: 3236-457240-6484',
          associatedID: '3236-457240-6484',
          orderId: '60870d1c96b58841acf514fd',
          transferStatus: 'pending',
        },
        _id: '60870d2d96b58841acf51507',
      },
      {
        event: {
          eventType: 'Sale',
          eventPrice: 21.78,
          eventDate: '2021-04-26T06:57:18.000Z',
          eventDescription: 'Order: 3236-457240-6484',
          associatedID: '3236-457240-6484',
          orderId: '60870d0096b58841acf514f2',
          transferStatus: 'pending',
        },
        _id: '60870d0e96b58841acf514fc',
      },
      {
        event: {
          eventType: 'Sale',
          eventPrice: 21.78,
          eventDate: '2021-04-26T06:56:56.000Z',
          eventDescription: 'Order: 3236-457240-6484',
          associatedID: '3236-457240-6484',
          orderId: '60870ce996b58841acf514e7',
          transferStatus: 'pending',
        },
        _id: '60870cf896b58841acf514f1',
      },
      {
        event: {
          eventType: 'Sale',
          eventPrice: 21.78,
          eventDate: '2021-04-26T06:56:29.000Z',
          eventDescription: 'Order: 3236-457240-6484',
          associatedID: '3236-457240-6484',
          orderId: '60870cce96b58841acf514dc',
          transferStatus: 'pending',
        },
        _id: '60870cdd96b58841acf514e6',
      },
    ],
    createdAt: '2021-03-24T20:40:06.461Z',
    updatedAt: '2021-04-26T18:58:14.250Z',
    __v: 0,
  },
  total: 52,
  withdrawalAvailable: 0,
  withdrawalPending: '473.7452',
};

export const Orders = {
  items: [
    {
      priceVariation: {
        title: "This variation doesn't have any attachment question required",
        price: 88,
        description: 'Buy me',
        type: 'facebook',
      },
      orderStatus: 'Canceled',
      orderNumber: '3231-917429-7478',
      popType: 'advertise',
      isDeleted: false,
      videoLink: null,
      videoName: null,
      dueDate: '2021-04-09T08:43:59.000Z',
      paymaQuestion: null,
      paymaAnswer: null,
      popLiveEventStartDateTime: null,
      videoEncodeComplete: 'incomplete',
      paymentIntentId: 'pi_1IczJsKJHvJLirylrWolPEWm',
      processingStatus: 'Canceled',
      expireEmailDelivered: false,
      orderPlatformFee: 2.85,
      rate: null,
      review: null,
      qualifiedAffiliateId: null,
      qualifiedSuperAffiliateId: null,
      disputeStartedAt: null,
      disputeCompletedAt: null,
      _id: '606b768f9870eb380910fde8',
      questions: [
        {
          responseValue: 'asdfiajsfdl ',
          responseOptions: [],
          attachements: [],
          _id: '606a462247f57a069277df74',
          title: 'Only One Question At the moment',
          responseType: 'text',
        },
      ],
      popId: {
        duration: 15,
        isActive: true,
        popType: 'advertise',
        isComplete: false,
        isDeleted: false,
        isUserDefault: false,
        popThumbnail: null,
        _id: '60679ec9a5f54957061719f8',
        title: 'Insta ADd',
        price: 43,
        description:
          "Going from first to last slide, or from last to first slide, on infinite mode doesn't animate",
        additionalArt: [],
        questions: [],
        digitalDownloads: [],
        priceVariations: [
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '606a462247f57a069277df74',
                title: 'Only One Question At the moment',
                responseType: 'text',
              },
            ],
            _id: '606a462247f57a069277df73',
            title:
              "This variation doesn't have any attachment question required",
            price: 88,
            description: 'Buy me',
            isActive: true,
            type: 'facebook',
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'What do you think about me?',
                responseType: 'text',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb3499f',
                title: 'Show me your picture.',
                responseType: 'file',
              },
              {
                isActive: true,
                responseOptions: [
                  {
                    _id: '606dd2169ee89312fdc3331b',
                    key: '0',
                    text: 'captian marvel',
                    value: false,
                  },
                  {
                    _id: '606dd2169ee89312fdc3331c',
                    key: '1',
                    text: ' captain america',
                    value: false,
                  },
                  {
                    _id: '606dd2169ee89312fdc3331d',
                    key: '2',
                    text: 'avenger',
                    value: false,
                  },
                ],
                _id: '6068a98c1701de739bb349a0',
                title: 'Movies you like?',
                text: 'captain america, captian marvel',
                responseType: 'selectList',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'Advertise 24 hours',
            description:
              'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document',
            isActive: true,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'Be Prove of your name and tell us.',
                responseType: 'text',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'asdfa',
            description: '444',
            isActive: true,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'What do you think about me?',
                responseType: 'text',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb3499f',
                title: 'Show me your picture.',
                responseType: 'file',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb349a0',
                title: 'Movies you like?',
                text: 'captain america, captian marvel',
                responseType: 'selectList',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'Advertise 24 hours',
            description:
              'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document',
            isActive: false,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'Be Prove of your name and tell us.',
                responseType: 'text',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'asdfa',
            description: '444',
            isActive: false,
          },
          {
            questions: [],
            _id: '606a482447f57a069277df8f',
            title: 'No Questions At the moment',
            price: 999,
            description: '88989',
            isActive: true,
            type: 'twitter',
          },
        ],
        popName: 'instaadd5630',
        sortOrder: 11,
        owner: '6053c8e2ef711660d04c12f8',
        altOptions: [],
        createdAt: '2021-04-02T22:46:33.032Z',
        updatedAt: '2021-04-24T15:38:02.301Z',
        __v: 0,
      },
      buyer: {
        socialLinks: [],
        tagLine: null,
        userSetupStatus: 0,
        verifiedPath: false,
        isDeleted: false,
        isInfluencer: false,
        isCreative: false,
        isEducator: false,
        isActiveProfile: false,
        isEmailVerified: true,
        isActiveSeller: false,
        skipOnBoarding: false,
        sellerComissionRate: 90,
        signupSource: null,
        sellerPlatformFee: 2.9,
        sellerProcessingFee: 10,
        servicesTitleEnabled: true,
        servicesTitleValue: 'My Services',
        linksTitleEnabled: true,
        linksTitleValue: 'My Links',
        sundayPopLiveActive: false,
        mondayPopLiveActive: false,
        tuesdayPopLiveActive: false,
        wednesdayPopLiveActive: false,
        thursdayPopLiveActive: false,
        fridayPopLiveActive: false,
        saturdayPopLiveActive: false,
        stripeSellerAccountId: '',
        isGuest: false,
        shoutoutHoursToDeliver: 48,
        fanmailHoursToDeliver: 48,
        popLiveOrderBuffer: 48,
        betaStatus: 0,
        invitationActive: false,
        inviationStatus: 'fresh',
        userStatus: null,
        isAffiliate: false,
        isSuperAffiliate: false,
        allowPopLive: false,
        referralId: null,
        userThemeId: null,
        allowSelling: false,
        isInvitedUser: false,
        library: [],
        enableSystemThemeAccess: false,
        _id: '606b77039870eb380910fdea',
        email: 'thsiioasjdfk@asdjfc.om',
        firstName: 'askdfjasdfj',
        lastName: 'skldf',
        username: 'asiodfjajkd3c',
        invitationHash: '5Pf3DX',
        themeColor: {
          isActive: true,
          _id: '5fa960bd19db9f677650d40d',
          colorSetName: 'Pop Default',
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          primaryColor: '#e51075',
          secondaryColor: '#02b0f0',
        },
        links: [
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '606b77039870eb380910fdeb',
            url: null,
            title: 'My Services',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 1,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '606b77039870eb380910fdec',
            url: null,
            title: 'My Links',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 100,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '606b77039870eb380910fded',
            url: null,
            title: 'About Me',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 200,
          },
          {
            popLinksId: '606b77039870eb380910fdee',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '606b77049870eb380910fdf1',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 2,
          },
          {
            popLinksId: '606b77039870eb380910fdef',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '606b77049870eb380910fdf2',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 3,
          },
          {
            popLinksId: '606b77049870eb380910fdf0',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '606b77049870eb380910fdf3',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 4,
          },
        ],
        sundayPopLiveSlots: [],
        mondayPopLiveSlots: [],
        tuesdayPopLiveSlots: [],
        wednesdayPopLiveSlots: [],
        thursdayPopLiveSlots: [],
        fridayPopLiveSlots: [],
        saturdayPopLiveSlots: [],
        createdAt: '2021-04-05T20:45:55.978Z',
        updatedAt: '2021-04-05T20:47:34.203Z',
        __v: 0,
      },
      seller: {
        socialLinks: [],
        tagLine: 'my tag line',
        userSetupStatus: 10,
        verifiedPath: false,
        isDeleted: false,
        isInfluencer: false,
        isCreative: false,
        isEducator: false,
        isActiveProfile: true,
        isEmailVerified: false,
        isActiveSeller: true,
        skipOnBoarding: false,
        sellerComissionRate: 90,
        signupSource: null,
        sellerPlatformFee: 2.9,
        sellerProcessingFee: 10,
        servicesTitleEnabled: true,
        servicesTitleValue: 'My Services',
        linksTitleEnabled: true,
        linksTitleValue: 'My Links',
        sundayPopLiveActive: false,
        mondayPopLiveActive: false,
        tuesdayPopLiveActive: false,
        wednesdayPopLiveActive: false,
        thursdayPopLiveActive: false,
        fridayPopLiveActive: false,
        saturdayPopLiveActive: false,
        stripeSellerAccountId: '',
        isGuest: false,
        shoutoutHoursToDeliver: 48,
        fanmailHoursToDeliver: 48,
        popLiveOrderBuffer: 48,
        betaStatus: 0,
        invitationActive: false,
        inviationStatus: 'fresh',
        userStatus: 'invitationReviewPending',
        isAffiliate: false,
        isSuperAffiliate: false,
        allowPopLive: false,
        referralId: null,
        userThemeId: '607605ee6194a106312e5f5d',
        allowSelling: false,
        isInvitedUser: false,
        library: [
          '6074b53cd7fee065a0cde25a',
          '6074b643d7fee065a0cde25b',
          '6074b683d7fee065a0cde25c',
          '6074b758d7fee065a0cde25d',
          '6074b856d7fee065a0cde25e',
          '6075db0d20c89a7dc3a7d0eb',
          '6075dbe320c89a7dc3a7d0ec',
          '6079b6a3f623916d1096bc86',
          '6079bae7f623916d1096bc87',
          '6079bdb8f623916d1096bc88',
        ],
        enableSystemThemeAccess: true,
        _id: '6053c8e2ef711660d04c12f8',
        firstName: 'askdfjaks',
        lastName: 'asdfklj',
        email: 'myprofile@test.com',
        username: 'askdfjaksasdfkl',
        themeColor: {
          isActive: true,
          _id: '5fa960bd19db9f677650d40f',
          colorSetName: 'Sedona',
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          primaryColor: '#f7a278',
          secondaryColor: '#a13d63',
        },
        links: [
          {
            popLinksId: '6053c8e2ef711660d04c1305',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6053c8e2ef711660d04c1308',
            sortOrder: 1,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6053c8e2ef711660d04c12f9',
            sortOrder: 4,
            url: null,
            title: 'My Services',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c12fa',
            sortOrder: 5,
            url: null,
            title: 'My Links',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c7a63ad20a1e8ceb2da7',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6058c7a63ad20a1e8ceb2daa',
            sortOrder: 3,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6053c8e2ef711660d04c1304',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c1307',
            sortOrder: 5,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c55a3ad20a1e8ceb2d97',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6058c55a3ad20a1e8ceb2d98',
            sortOrder: 3,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cca54650194669572cf3c',
            sortOrder: 39,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc9a1650194669572cf3a',
            sortOrder: 38,
          },
          {
            popLinksId: '6058c6803ad20a1e8ceb2da3',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c6803ad20a1e8ceb2da4',
            sortOrder: 10,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c6063ad20a1e8ceb2d9d',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c6063ad20a1e8ceb2d9e',
            sortOrder: 1,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc980650194669572cf39',
            sortOrder: 40,
          },
          {
            popLinksId: '6053c8e2ef711660d04c1306',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c1309',
            sortOrder: 6,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c7493ad20a1e8ceb2da5',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c7493ad20a1e8ceb2da6',
            sortOrder: 19,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605ccca0650194669572cf3d',
            sortOrder: 36,
          },
          {
            popLinksId: '6058c5ba3ad20a1e8ceb2d9b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c5ba3ad20a1e8ceb2d9c',
            sortOrder: 7,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605a016bf9c7c21f36a861b6',
            sortOrder: 11,
            url: 'fabco.com',
            title: '55555sdafadsfdsafdsfa',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fabco.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a01d7f9c7c21f36a861b7',
            sortOrder: 13,
            url: 'asdfasfdfas.com',
            title: '222222222222222222',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfasfdfas.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0346f9c7c21f36a861b8',
            sortOrder: 17,
            url: 'aklsdfjk.com',
            title: 'new links',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aklsdfjk.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc9e8650194669572cf3b',
            sortOrder: 37,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a03e8f9c7c21f36a861ba',
            sortOrder: 12,
            url: 'kasjfdlkasdf.com',
            title: 'newlkasjdflk',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=kasjfdlkasdf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0418f9c7c21f36a861bb',
            sortOrder: 25,
            url: 'fb.com',
            title: ' ewww',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fb.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '605a0498f9c7c21f36a861bc',
            sortOrder: 6,
            url: 'lsakdjf.com',
            title: 'asdfc',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=lsakdjf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a04dcf9c7c21f36a861bd',
            sortOrder: 15,
            url: 'aslkdfj.com',
            title: 'asdfljasdflj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aslkdfj.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a06daf9c7c21f36a861c3',
            sortOrder: 9,
            url: 'laksdfj.com',
            title: 'updating you',
            imageURL:
              'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/ca7361d7-b93a-4eb0-868f-b4497c53acb5.jpg',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0513f9c7c21f36a861be',
            sortOrder: 16,
            url: 'asdfafl.com',
            title: 'asdfa',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfafl.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a067ff9c7c21f36a861c2',
            sortOrder: 14,
            url: 'aksjdf.com',
            title: 'last try',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aksjdf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '605a0547f9c7c21f36a861bf',
            sortOrder: 2,
            url: 'asdfjla.com',
            title: 'asdfa laskdfj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfjla.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a066ff9c7c21f36a861c1',
            sortOrder: 27,
            url: 'sakdfj.co',
            title: 'kjiafaskdjfh',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=sakdfj.co',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a16c5f9c7c21f36a861ca',
            sortOrder: 24,
            url: 'test.run',
            title: 'test.rung',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=test.run',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a05c0f9c7c21f36a861c0',
            sortOrder: 28,
            url: 'klasdjf.com',
            title: 'asdfkljalkfj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=klasdjf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a18bcf9c7c21f36a861cb',
            sortOrder: 34,
            url: 'klajef.com',
            title: 'new this',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=klajef.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605ccd19650194669572cf3f',
            sortOrder: 41,
          },
          {
            popLinksId: '605a3f56b8f2c82578929421',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3f56b8f2c82578929424',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 18,
          },
          {
            popLinksId: '605a3f80b8f2c82578929425',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3f81b8f2c82578929428',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 23,
          },
          {
            popLinksId: '605a3facb8f2c82578929429',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3facb8f2c8257892942a',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 35,
          },
          {
            popLinksId: '605a45ddb8f2c8257892942b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a45ddb8f2c8257892942d',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 30,
          },
          {
            popLinksId: '605a5c4b9942a02b754443b1',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a5c4b9942a02b754443b3',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 37,
          },
          {
            popLinksId: '605aa6d7d3a6213113d99957',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605aa6d7d3a6213113d99958',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 26,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605bae5ed3a6213113d99a2c',
            sortOrder: 4,
            title: 'askdfjaks',
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '605bb3d3d3a6213113d99a31',
            title: 'section titile new new ',
            sortOrder: 3,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc276650194669572cf2a',
            sortOrder: 33,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc2f5650194669572cf2b',
            sortOrder: 32,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc30f650194669572cf2c',
            url: 'fb.com',
            title: 'hello me',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fb.com',
            sortOrder: 35,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc3bf650194669572cf2e',
            sortOrder: 29,
            url: 'kasfdj.com',
            title: 'test',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=kasfdj.com',
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc46c650194669572cf30',
            title: 'song askdfj',
            url: 'https://www.youtube.com/watch?v=3-JbvmuCNcc',
            sortOrder: 1,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605e278db533aa6fffff6bd1',
            title: 'asdfasf',
            url: 'https://www.youtube.com/watch?v=MhODHoaats0&t=383s',
            sortOrder: 46,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: true,
            isDeleted: true,
            isActive: false,
            _id: '605e296e2608fc14d34330c5',
            title: 'I love you so bad',
            url: 'https://www.youtube.com/watch?v=FixCjwGs2JE',
            sortOrder: 2,
          },
          {
            popLinksId: '6064f07456f01b7cd0f2fada',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f07556f01b7cd0f2fadf',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 7,
          },
          {
            popLinksId: '6064f19256f01b7cd0f2fae8',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f19256f01b7cd0f2faea',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 5,
          },
          {
            popLinksId: '6064f1cd56f01b7cd0f2faeb',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f1cd56f01b7cd0f2faec',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 11,
          },
          {
            popLinksId: '6065e17356f01b7cd0f2faed',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '6065e17356f01b7cd0f2faf0',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 8,
          },
          {
            popLinksId: '6065e62f56f01b7cd0f2faf1',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6065e62f56f01b7cd0f2faf2',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 52,
          },
          {
            popLinksId: '60679c0da5f54957061719e5',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '60679c0da5f54957061719e8',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 53,
          },
          {
            popLinksId: '60679dbfa5f54957061719f4',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '60679dbfa5f54957061719f7',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 54,
          },
          {
            popLinksId: '60679ec9a5f54957061719f8',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '60679ec9a5f54957061719fb',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 10,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: true,
            isDeleted: false,
            isActive: false,
            _id: '60787ec29ccfbd2400757c1a',
            title: 'helo',
            url: 'https://www.youtube.com/watch?v=3_ftZdc-sYI',
            sortOrder: 9,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '607ca8103b86101264674806',
            title: 'my tag line',
            sortOrder: 12,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '607ca8bf3b86101264674807',
            title: 'my tag line',
            sortOrder: 1,
          },
        ],
        sundayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fc',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        mondayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fd',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        tuesdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fe',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        wednesdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12ff',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        thursdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1300',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        fridayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1301',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        saturdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1302',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        invitationHash: '2YlDsj',
        createdAt: '2021-03-18T21:40:50.279Z',
        updatedAt: '2021-04-27T20:57:39.347Z',
        __v: 0,
        timeOffset: 'Asia/Karachi',
        description: "please i'm awais",
        tags: 'yes me,dddsaf aksjdflji,kasdjf,asdfl',
        profileImage:
          'https://selfiepop-bucket.s3.amazonaws.com/users/profile-image/5c945ed3-0eb7-4ae1-b2ad-355ac49df5b5.png',
        address1: '',
        address2: '',
        city: '',
        country: '',
        phone: '',
        state: '',
        zip: null,
      },
      price: 88,
      dateOrderStarted: '2021-04-05T20:46:51.000Z',
      title: 'Insta ADd',
      description:
        "Going from first to last slide, or from last to first slide, on infinite mode doesn't animate",
      popLiveAttendees: [],
      createdAt: '2021-04-05T20:43:59.357Z',
      updatedAt: '2021-04-24T06:28:30.781Z',
      __v: 0,
      attachments: [],
    },
    {
      priceVariation: {
        title: "This variation doesn't have any attachment question required",
        price: 88,
        description: 'Buy me',
        type: 'facebook',
      },
      orderStatus: 'Canceled',
      orderNumber: '3231-917429-7478',
      popType: 'advertise',
      isDeleted: false,
      videoLink: null,
      videoName: null,
      dueDate: '2021-04-09T06:20:50.000Z',
      paymaQuestion: null,
      paymaAnswer: null,
      popLiveEventStartDateTime: null,
      videoEncodeComplete: 'incomplete',
      paymentIntentId: 'pi_1IcxDIKJHvJLirylko4cKMKI',
      processingStatus: 'Canceled',
      expireEmailDelivered: false,
      orderPlatformFee: 2.85,
      rate: null,
      review: null,
      qualifiedAffiliateId: null,
      qualifiedSuperAffiliateId: null,
      disputeStartedAt: null,
      disputeCompletedAt: null,
      _id: '606b55029870eb380910fd68',
      questions: [
        {
          responseValue: 'dumb',
          responseOptions: [],
          attachements: [],
          _id: '606a462247f57a069277df74',
          title: 'Only One Question At the moment',
          responseType: 'text',
        },
      ],
      popId: {
        duration: 15,
        isActive: true,
        popType: 'advertise',
        isComplete: false,
        isDeleted: false,
        isUserDefault: false,
        popThumbnail: null,
        _id: '60679ec9a5f54957061719f8',
        title: 'Insta ADd',
        price: 43,
        description:
          "Going from first to last slide, or from last to first slide, on infinite mode doesn't animate",
        additionalArt: [],
        questions: [],
        digitalDownloads: [],
        priceVariations: [
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '606a462247f57a069277df74',
                title: 'Only One Question At the moment',
                responseType: 'text',
              },
            ],
            _id: '606a462247f57a069277df73',
            title:
              "This variation doesn't have any attachment question required",
            price: 88,
            description: 'Buy me',
            isActive: true,
            type: 'facebook',
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'What do you think about me?',
                responseType: 'text',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb3499f',
                title: 'Show me your picture.',
                responseType: 'file',
              },
              {
                isActive: true,
                responseOptions: [
                  {
                    _id: '606dd2169ee89312fdc3331b',
                    key: '0',
                    text: 'captian marvel',
                    value: false,
                  },
                  {
                    _id: '606dd2169ee89312fdc3331c',
                    key: '1',
                    text: ' captain america',
                    value: false,
                  },
                  {
                    _id: '606dd2169ee89312fdc3331d',
                    key: '2',
                    text: 'avenger',
                    value: false,
                  },
                ],
                _id: '6068a98c1701de739bb349a0',
                title: 'Movies you like?',
                text: 'captain america, captian marvel',
                responseType: 'selectList',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'Advertise 24 hours',
            description:
              'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document',
            isActive: true,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'Be Prove of your name and tell us.',
                responseType: 'text',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'asdfa',
            description: '444',
            isActive: true,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'What do you think about me?',
                responseType: 'text',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb3499f',
                title: 'Show me your picture.',
                responseType: 'file',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb349a0',
                title: 'Movies you like?',
                text: 'captain america, captian marvel',
                responseType: 'selectList',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'Advertise 24 hours',
            description:
              'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document',
            isActive: false,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'Be Prove of your name and tell us.',
                responseType: 'text',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'asdfa',
            description: '444',
            isActive: false,
          },
          {
            questions: [],
            _id: '606a482447f57a069277df8f',
            title: 'No Questions At the moment',
            price: 999,
            description: '88989',
            isActive: true,
            type: 'twitter',
          },
        ],
        popName: 'instaadd5630',
        sortOrder: 11,
        owner: '6053c8e2ef711660d04c12f8',
        altOptions: [],
        createdAt: '2021-04-02T22:46:33.032Z',
        updatedAt: '2021-04-24T15:38:02.301Z',
        __v: 0,
      },
      buyer: {
        socialLinks: [],
        tagLine: null,
        userSetupStatus: 0,
        verifiedPath: false,
        isDeleted: false,
        isInfluencer: false,
        isCreative: false,
        isEducator: false,
        isActiveProfile: false,
        isEmailVerified: true,
        isActiveSeller: false,
        skipOnBoarding: false,
        sellerComissionRate: 90,
        signupSource: null,
        sellerPlatformFee: 2.9,
        sellerProcessingFee: 10,
        servicesTitleEnabled: true,
        servicesTitleValue: 'My Services',
        linksTitleEnabled: true,
        linksTitleValue: 'My Links',
        sundayPopLiveActive: false,
        mondayPopLiveActive: false,
        tuesdayPopLiveActive: false,
        wednesdayPopLiveActive: false,
        thursdayPopLiveActive: false,
        fridayPopLiveActive: false,
        saturdayPopLiveActive: false,
        stripeSellerAccountId: '',
        isGuest: true,
        shoutoutHoursToDeliver: 48,
        fanmailHoursToDeliver: 48,
        popLiveOrderBuffer: 48,
        betaStatus: 0,
        invitationActive: false,
        inviationStatus: 'fresh',
        userStatus: null,
        isAffiliate: false,
        isSuperAffiliate: false,
        allowPopLive: false,
        referralId: null,
        userThemeId: null,
        allowSelling: false,
        isInvitedUser: false,
        library: [],
        enableSystemThemeAccess: false,
        _id: '606b57ca9870eb380910fd81',
        email: 'askdfj531@user.guest',
        firstName: 'askdfj',
        lastName: 'a',
        username: 'askdfj531',
        invitationHash: 'FUP169',
        themeColor: {
          isActive: true,
          _id: '5fa960bd19db9f677650d40d',
          colorSetName: 'Pop Default',
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          primaryColor: '#e51075',
          secondaryColor: '#02b0f0',
        },
        links: [
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '606b57ca9870eb380910fd82',
            url: null,
            title: 'My Services',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 1,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '606b57ca9870eb380910fd83',
            url: null,
            title: 'My Links',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 100,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '606b57ca9870eb380910fd84',
            url: null,
            title: 'About Me',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 200,
          },
          {
            popLinksId: '606b57ca9870eb380910fd85',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '606b57ca9870eb380910fd88',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 2,
          },
          {
            popLinksId: '606b57ca9870eb380910fd86',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '606b57ca9870eb380910fd89',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 3,
          },
          {
            popLinksId: '606b57ca9870eb380910fd87',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '606b57ca9870eb380910fd8a',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 4,
          },
        ],
        sundayPopLiveSlots: [],
        mondayPopLiveSlots: [],
        tuesdayPopLiveSlots: [],
        wednesdayPopLiveSlots: [],
        thursdayPopLiveSlots: [],
        fridayPopLiveSlots: [],
        saturdayPopLiveSlots: [],
        createdAt: '2021-04-05T18:32:42.263Z',
        updatedAt: '2021-04-05T18:33:06.568Z',
        __v: 0,
      },
      seller: {
        socialLinks: [],
        tagLine: 'my tag line',
        userSetupStatus: 10,
        verifiedPath: false,
        isDeleted: false,
        isInfluencer: false,
        isCreative: false,
        isEducator: false,
        isActiveProfile: true,
        isEmailVerified: false,
        isActiveSeller: true,
        skipOnBoarding: false,
        sellerComissionRate: 90,
        signupSource: null,
        sellerPlatformFee: 2.9,
        sellerProcessingFee: 10,
        servicesTitleEnabled: true,
        servicesTitleValue: 'My Services',
        linksTitleEnabled: true,
        linksTitleValue: 'My Links',
        sundayPopLiveActive: false,
        mondayPopLiveActive: false,
        tuesdayPopLiveActive: false,
        wednesdayPopLiveActive: false,
        thursdayPopLiveActive: false,
        fridayPopLiveActive: false,
        saturdayPopLiveActive: false,
        stripeSellerAccountId: '',
        isGuest: false,
        shoutoutHoursToDeliver: 48,
        fanmailHoursToDeliver: 48,
        popLiveOrderBuffer: 48,
        betaStatus: 0,
        invitationActive: false,
        inviationStatus: 'fresh',
        userStatus: 'invitationReviewPending',
        isAffiliate: false,
        isSuperAffiliate: false,
        allowPopLive: false,
        referralId: null,
        userThemeId: '607605ee6194a106312e5f5d',
        allowSelling: false,
        isInvitedUser: false,
        library: [
          '6074b53cd7fee065a0cde25a',
          '6074b643d7fee065a0cde25b',
          '6074b683d7fee065a0cde25c',
          '6074b758d7fee065a0cde25d',
          '6074b856d7fee065a0cde25e',
          '6075db0d20c89a7dc3a7d0eb',
          '6075dbe320c89a7dc3a7d0ec',
          '6079b6a3f623916d1096bc86',
          '6079bae7f623916d1096bc87',
          '6079bdb8f623916d1096bc88',
        ],
        enableSystemThemeAccess: true,
        _id: '6053c8e2ef711660d04c12f8',
        firstName: 'askdfjaks',
        lastName: 'asdfklj',
        email: 'myprofile@test.com',
        username: 'askdfjaksasdfkl',
        themeColor: {
          isActive: true,
          _id: '5fa960bd19db9f677650d40f',
          colorSetName: 'Sedona',
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          primaryColor: '#f7a278',
          secondaryColor: '#a13d63',
        },
        links: [
          {
            popLinksId: '6053c8e2ef711660d04c1305',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6053c8e2ef711660d04c1308',
            sortOrder: 1,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6053c8e2ef711660d04c12f9',
            sortOrder: 4,
            url: null,
            title: 'My Services',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c12fa',
            sortOrder: 5,
            url: null,
            title: 'My Links',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c7a63ad20a1e8ceb2da7',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6058c7a63ad20a1e8ceb2daa',
            sortOrder: 3,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6053c8e2ef711660d04c1304',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c1307',
            sortOrder: 5,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c55a3ad20a1e8ceb2d97',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6058c55a3ad20a1e8ceb2d98',
            sortOrder: 3,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cca54650194669572cf3c',
            sortOrder: 39,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc9a1650194669572cf3a',
            sortOrder: 38,
          },
          {
            popLinksId: '6058c6803ad20a1e8ceb2da3',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c6803ad20a1e8ceb2da4',
            sortOrder: 10,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c6063ad20a1e8ceb2d9d',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c6063ad20a1e8ceb2d9e',
            sortOrder: 1,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc980650194669572cf39',
            sortOrder: 40,
          },
          {
            popLinksId: '6053c8e2ef711660d04c1306',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c1309',
            sortOrder: 6,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c7493ad20a1e8ceb2da5',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c7493ad20a1e8ceb2da6',
            sortOrder: 19,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605ccca0650194669572cf3d',
            sortOrder: 36,
          },
          {
            popLinksId: '6058c5ba3ad20a1e8ceb2d9b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c5ba3ad20a1e8ceb2d9c',
            sortOrder: 7,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605a016bf9c7c21f36a861b6',
            sortOrder: 11,
            url: 'fabco.com',
            title: '55555sdafadsfdsafdsfa',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fabco.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a01d7f9c7c21f36a861b7',
            sortOrder: 13,
            url: 'asdfasfdfas.com',
            title: '222222222222222222',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfasfdfas.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0346f9c7c21f36a861b8',
            sortOrder: 17,
            url: 'aklsdfjk.com',
            title: 'new links',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aklsdfjk.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc9e8650194669572cf3b',
            sortOrder: 37,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a03e8f9c7c21f36a861ba',
            sortOrder: 12,
            url: 'kasjfdlkasdf.com',
            title: 'newlkasjdflk',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=kasjfdlkasdf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0418f9c7c21f36a861bb',
            sortOrder: 25,
            url: 'fb.com',
            title: ' ewww',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fb.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '605a0498f9c7c21f36a861bc',
            sortOrder: 6,
            url: 'lsakdjf.com',
            title: 'asdfc',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=lsakdjf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a04dcf9c7c21f36a861bd',
            sortOrder: 15,
            url: 'aslkdfj.com',
            title: 'asdfljasdflj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aslkdfj.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a06daf9c7c21f36a861c3',
            sortOrder: 9,
            url: 'laksdfj.com',
            title: 'updating you',
            imageURL:
              'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/ca7361d7-b93a-4eb0-868f-b4497c53acb5.jpg',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0513f9c7c21f36a861be',
            sortOrder: 16,
            url: 'asdfafl.com',
            title: 'asdfa',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfafl.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a067ff9c7c21f36a861c2',
            sortOrder: 14,
            url: 'aksjdf.com',
            title: 'last try',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aksjdf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '605a0547f9c7c21f36a861bf',
            sortOrder: 2,
            url: 'asdfjla.com',
            title: 'asdfa laskdfj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfjla.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a066ff9c7c21f36a861c1',
            sortOrder: 27,
            url: 'sakdfj.co',
            title: 'kjiafaskdjfh',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=sakdfj.co',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a16c5f9c7c21f36a861ca',
            sortOrder: 24,
            url: 'test.run',
            title: 'test.rung',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=test.run',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a05c0f9c7c21f36a861c0',
            sortOrder: 28,
            url: 'klasdjf.com',
            title: 'asdfkljalkfj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=klasdjf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a18bcf9c7c21f36a861cb',
            sortOrder: 34,
            url: 'klajef.com',
            title: 'new this',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=klajef.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605ccd19650194669572cf3f',
            sortOrder: 41,
          },
          {
            popLinksId: '605a3f56b8f2c82578929421',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3f56b8f2c82578929424',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 18,
          },
          {
            popLinksId: '605a3f80b8f2c82578929425',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3f81b8f2c82578929428',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 23,
          },
          {
            popLinksId: '605a3facb8f2c82578929429',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3facb8f2c8257892942a',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 35,
          },
          {
            popLinksId: '605a45ddb8f2c8257892942b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a45ddb8f2c8257892942d',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 30,
          },
          {
            popLinksId: '605a5c4b9942a02b754443b1',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a5c4b9942a02b754443b3',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 37,
          },
          {
            popLinksId: '605aa6d7d3a6213113d99957',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605aa6d7d3a6213113d99958',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 26,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605bae5ed3a6213113d99a2c',
            sortOrder: 4,
            title: 'askdfjaks',
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '605bb3d3d3a6213113d99a31',
            title: 'section titile new new ',
            sortOrder: 3,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc276650194669572cf2a',
            sortOrder: 33,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc2f5650194669572cf2b',
            sortOrder: 32,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc30f650194669572cf2c',
            url: 'fb.com',
            title: 'hello me',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fb.com',
            sortOrder: 35,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc3bf650194669572cf2e',
            sortOrder: 29,
            url: 'kasfdj.com',
            title: 'test',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=kasfdj.com',
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc46c650194669572cf30',
            title: 'song askdfj',
            url: 'https://www.youtube.com/watch?v=3-JbvmuCNcc',
            sortOrder: 1,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605e278db533aa6fffff6bd1',
            title: 'asdfasf',
            url: 'https://www.youtube.com/watch?v=MhODHoaats0&t=383s',
            sortOrder: 46,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: true,
            isDeleted: true,
            isActive: false,
            _id: '605e296e2608fc14d34330c5',
            title: 'I love you so bad',
            url: 'https://www.youtube.com/watch?v=FixCjwGs2JE',
            sortOrder: 2,
          },
          {
            popLinksId: '6064f07456f01b7cd0f2fada',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f07556f01b7cd0f2fadf',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 7,
          },
          {
            popLinksId: '6064f19256f01b7cd0f2fae8',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f19256f01b7cd0f2faea',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 5,
          },
          {
            popLinksId: '6064f1cd56f01b7cd0f2faeb',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f1cd56f01b7cd0f2faec',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 11,
          },
          {
            popLinksId: '6065e17356f01b7cd0f2faed',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '6065e17356f01b7cd0f2faf0',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 8,
          },
          {
            popLinksId: '6065e62f56f01b7cd0f2faf1',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6065e62f56f01b7cd0f2faf2',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 52,
          },
          {
            popLinksId: '60679c0da5f54957061719e5',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '60679c0da5f54957061719e8',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 53,
          },
          {
            popLinksId: '60679dbfa5f54957061719f4',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '60679dbfa5f54957061719f7',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 54,
          },
          {
            popLinksId: '60679ec9a5f54957061719f8',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '60679ec9a5f54957061719fb',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 10,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: true,
            isDeleted: false,
            isActive: false,
            _id: '60787ec29ccfbd2400757c1a',
            title: 'helo',
            url: 'https://www.youtube.com/watch?v=3_ftZdc-sYI',
            sortOrder: 9,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '607ca8103b86101264674806',
            title: 'my tag line',
            sortOrder: 12,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '607ca8bf3b86101264674807',
            title: 'my tag line',
            sortOrder: 1,
          },
        ],
        sundayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fc',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        mondayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fd',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        tuesdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fe',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        wednesdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12ff',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        thursdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1300',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        fridayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1301',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        saturdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1302',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        invitationHash: '2YlDsj',
        createdAt: '2021-03-18T21:40:50.279Z',
        updatedAt: '2021-04-27T20:57:39.347Z',
        __v: 0,
        timeOffset: 'Asia/Karachi',
        description: "please i'm awais",
        tags: 'yes me,dddsaf aksjdflji,kasdjf,asdfl',
        profileImage:
          'https://selfiepop-bucket.s3.amazonaws.com/users/profile-image/5c945ed3-0eb7-4ae1-b2ad-355ac49df5b5.png',
        address1: '',
        address2: '',
        city: '',
        country: '',
        phone: '',
        state: '',
        zip: null,
      },
      price: 88,
      dateOrderStarted: '2021-04-05T18:31:55.000Z',
      title: 'Insta ADd',
      description:
        "Going from first to last slide, or from last to first slide, on infinite mode doesn't animate",
      popLiveAttendees: [],
      createdAt: '2021-04-05T18:20:50.881Z',
      updatedAt: '2021-04-24T06:28:33.913Z',
      __v: 0,
      attachments: [],
    },
    {
      priceVariation: {
        title: "This variation doesn't have any attachment question required",
        price: 88,
        description: 'Buy me',
        type: 'facebook',
      },
      orderStatus: 'Canceled',
      orderNumber: '3231-917429-7478',
      popType: 'advertise',
      isDeleted: false,
      videoLink: null,
      videoName: null,
      dueDate: '2021-04-08T11:24:24.000Z',
      paymaQuestion: null,
      paymaAnswer: null,
      popLiveEventStartDateTime: null,
      videoEncodeComplete: 'incomplete',
      paymentIntentId: 'pi_1IcfK4KJHvJLiryldUalI2jC',
      processingStatus: 'Canceled',
      expireEmailDelivered: false,
      orderPlatformFee: 2.85,
      rate: null,
      review: null,
      qualifiedAffiliateId: null,
      qualifiedSuperAffiliateId: null,
      disputeStartedAt: null,
      disputeCompletedAt: null,
      _id: '606a4aa89870eb380910fd24',
      questions: [
        {
          responseValue: 'helloeee',
          responseOptions: [],
          attachements: [],
          _id: '606a462247f57a069277df74',
          title: 'Only One Question At the moment',
          responseType: 'text',
        },
      ],
      popId: {
        duration: 15,
        isActive: true,
        popType: 'advertise',
        isComplete: false,
        isDeleted: false,
        isUserDefault: false,
        popThumbnail: null,
        _id: '60679ec9a5f54957061719f8',
        title: 'Insta ADd',
        price: 43,
        description:
          "Going from first to last slide, or from last to first slide, on infinite mode doesn't animate",
        additionalArt: [],
        questions: [],
        digitalDownloads: [],
        priceVariations: [
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '606a462247f57a069277df74',
                title: 'Only One Question At the moment',
                responseType: 'text',
              },
            ],
            _id: '606a462247f57a069277df73',
            title:
              "This variation doesn't have any attachment question required",
            price: 88,
            description: 'Buy me',
            isActive: true,
            type: 'facebook',
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'What do you think about me?',
                responseType: 'text',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb3499f',
                title: 'Show me your picture.',
                responseType: 'file',
              },
              {
                isActive: true,
                responseOptions: [
                  {
                    _id: '606dd2169ee89312fdc3331b',
                    key: '0',
                    text: 'captian marvel',
                    value: false,
                  },
                  {
                    _id: '606dd2169ee89312fdc3331c',
                    key: '1',
                    text: ' captain america',
                    value: false,
                  },
                  {
                    _id: '606dd2169ee89312fdc3331d',
                    key: '2',
                    text: 'avenger',
                    value: false,
                  },
                ],
                _id: '6068a98c1701de739bb349a0',
                title: 'Movies you like?',
                text: 'captain america, captian marvel',
                responseType: 'selectList',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'Advertise 24 hours',
            description:
              'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document',
            isActive: true,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'Be Prove of your name and tell us.',
                responseType: 'text',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'asdfa',
            description: '444',
            isActive: true,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'What do you think about me?',
                responseType: 'text',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb3499f',
                title: 'Show me your picture.',
                responseType: 'file',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb349a0',
                title: 'Movies you like?',
                text: 'captain america, captian marvel',
                responseType: 'selectList',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'Advertise 24 hours',
            description:
              'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document',
            isActive: false,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'Be Prove of your name and tell us.',
                responseType: 'text',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'asdfa',
            description: '444',
            isActive: false,
          },
          {
            questions: [],
            _id: '606a482447f57a069277df8f',
            title: 'No Questions At the moment',
            price: 999,
            description: '88989',
            isActive: true,
            type: 'twitter',
          },
        ],
        popName: 'instaadd5630',
        sortOrder: 11,
        owner: '6053c8e2ef711660d04c12f8',
        altOptions: [],
        createdAt: '2021-04-02T22:46:33.032Z',
        updatedAt: '2021-04-24T15:38:02.301Z',
        __v: 0,
      },
      buyer: {
        socialLinks: [],
        tagLine: null,
        userSetupStatus: 0,
        verifiedPath: false,
        isDeleted: false,
        isInfluencer: false,
        isCreative: false,
        isEducator: false,
        isActiveProfile: false,
        isEmailVerified: true,
        isActiveSeller: false,
        skipOnBoarding: true,
        sellerComissionRate: 90,
        signupSource: null,
        sellerPlatformFee: 2.9,
        sellerProcessingFee: 10,
        servicesTitleEnabled: true,
        servicesTitleValue: 'My Services',
        linksTitleEnabled: true,
        linksTitleValue: 'My Links',
        sundayPopLiveActive: false,
        mondayPopLiveActive: false,
        tuesdayPopLiveActive: false,
        wednesdayPopLiveActive: false,
        thursdayPopLiveActive: false,
        fridayPopLiveActive: false,
        saturdayPopLiveActive: false,
        stripeSellerAccountId: '',
        isGuest: false,
        shoutoutHoursToDeliver: 48,
        fanmailHoursToDeliver: 48,
        popLiveOrderBuffer: 48,
        betaStatus: 0,
        invitationActive: false,
        inviationStatus: 'fresh',
        userStatus: null,
        isAffiliate: false,
        isSuperAffiliate: false,
        allowPopLive: false,
        referralId: null,
        userThemeId: null,
        allowSelling: true,
        isInvitedUser: false,
        library: [],
        enableSystemThemeAccess: false,
        _id: '606a4ad59870eb380910fd27',
        email: 'sdfadfde2@user.guest',
        firstName: 'sdfadf',
        lastName: 'asdaf',
        username: 'sdfadfde2',
        invitationHash: 'iHEDSo',
        themeColor: {
          isActive: true,
          _id: '5fa960bd19db9f677650d40d',
          colorSetName: 'Pop Default',
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          primaryColor: '#e51075',
          secondaryColor: '#02b0f0',
        },
        links: [
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '606a4ad59870eb380910fd28',
            url: null,
            title: 'My Services',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 1,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '606a4ad59870eb380910fd29',
            url: null,
            title: 'My Links',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 100,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '606a4ad59870eb380910fd2a',
            url: null,
            title: 'About Me',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 200,
          },
          {
            popLinksId: '606a4ad59870eb380910fd2b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '606a4ad59870eb380910fd2e',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 2,
          },
          {
            popLinksId: '606a4ad59870eb380910fd2c',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '606a4ad59870eb380910fd2f',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 3,
          },
          {
            popLinksId: '606a4ad59870eb380910fd2d',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '606a4ad59870eb380910fd30',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 4,
          },
        ],
        sundayPopLiveSlots: [],
        mondayPopLiveSlots: [],
        tuesdayPopLiveSlots: [],
        wednesdayPopLiveSlots: [],
        thursdayPopLiveSlots: [],
        fridayPopLiveSlots: [],
        saturdayPopLiveSlots: [],
        createdAt: '2021-04-04T23:25:09.108Z',
        updatedAt: '2021-04-04T23:28:36.067Z',
        __v: 0,
        timeOffset: 'America/Los_Angeles',
      },
      seller: {
        socialLinks: [],
        tagLine: 'my tag line',
        userSetupStatus: 10,
        verifiedPath: false,
        isDeleted: false,
        isInfluencer: false,
        isCreative: false,
        isEducator: false,
        isActiveProfile: true,
        isEmailVerified: false,
        isActiveSeller: true,
        skipOnBoarding: false,
        sellerComissionRate: 90,
        signupSource: null,
        sellerPlatformFee: 2.9,
        sellerProcessingFee: 10,
        servicesTitleEnabled: true,
        servicesTitleValue: 'My Services',
        linksTitleEnabled: true,
        linksTitleValue: 'My Links',
        sundayPopLiveActive: false,
        mondayPopLiveActive: false,
        tuesdayPopLiveActive: false,
        wednesdayPopLiveActive: false,
        thursdayPopLiveActive: false,
        fridayPopLiveActive: false,
        saturdayPopLiveActive: false,
        stripeSellerAccountId: '',
        isGuest: false,
        shoutoutHoursToDeliver: 48,
        fanmailHoursToDeliver: 48,
        popLiveOrderBuffer: 48,
        betaStatus: 0,
        invitationActive: false,
        inviationStatus: 'fresh',
        userStatus: 'invitationReviewPending',
        isAffiliate: false,
        isSuperAffiliate: false,
        allowPopLive: false,
        referralId: null,
        userThemeId: '607605ee6194a106312e5f5d',
        allowSelling: false,
        isInvitedUser: false,
        library: [
          '6074b53cd7fee065a0cde25a',
          '6074b643d7fee065a0cde25b',
          '6074b683d7fee065a0cde25c',
          '6074b758d7fee065a0cde25d',
          '6074b856d7fee065a0cde25e',
          '6075db0d20c89a7dc3a7d0eb',
          '6075dbe320c89a7dc3a7d0ec',
          '6079b6a3f623916d1096bc86',
          '6079bae7f623916d1096bc87',
          '6079bdb8f623916d1096bc88',
        ],
        enableSystemThemeAccess: true,
        _id: '6053c8e2ef711660d04c12f8',
        firstName: 'askdfjaks',
        lastName: 'asdfklj',
        email: 'myprofile@test.com',
        username: 'askdfjaksasdfkl',
        themeColor: {
          isActive: true,
          _id: '5fa960bd19db9f677650d40f',
          colorSetName: 'Sedona',
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          primaryColor: '#f7a278',
          secondaryColor: '#a13d63',
        },
        links: [
          {
            popLinksId: '6053c8e2ef711660d04c1305',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6053c8e2ef711660d04c1308',
            sortOrder: 1,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6053c8e2ef711660d04c12f9',
            sortOrder: 4,
            url: null,
            title: 'My Services',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c12fa',
            sortOrder: 5,
            url: null,
            title: 'My Links',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c7a63ad20a1e8ceb2da7',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6058c7a63ad20a1e8ceb2daa',
            sortOrder: 3,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6053c8e2ef711660d04c1304',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c1307',
            sortOrder: 5,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c55a3ad20a1e8ceb2d97',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6058c55a3ad20a1e8ceb2d98',
            sortOrder: 3,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cca54650194669572cf3c',
            sortOrder: 39,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc9a1650194669572cf3a',
            sortOrder: 38,
          },
          {
            popLinksId: '6058c6803ad20a1e8ceb2da3',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c6803ad20a1e8ceb2da4',
            sortOrder: 10,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c6063ad20a1e8ceb2d9d',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c6063ad20a1e8ceb2d9e',
            sortOrder: 1,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc980650194669572cf39',
            sortOrder: 40,
          },
          {
            popLinksId: '6053c8e2ef711660d04c1306',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c1309',
            sortOrder: 6,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c7493ad20a1e8ceb2da5',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c7493ad20a1e8ceb2da6',
            sortOrder: 19,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605ccca0650194669572cf3d',
            sortOrder: 36,
          },
          {
            popLinksId: '6058c5ba3ad20a1e8ceb2d9b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c5ba3ad20a1e8ceb2d9c',
            sortOrder: 7,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605a016bf9c7c21f36a861b6',
            sortOrder: 11,
            url: 'fabco.com',
            title: '55555sdafadsfdsafdsfa',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fabco.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a01d7f9c7c21f36a861b7',
            sortOrder: 13,
            url: 'asdfasfdfas.com',
            title: '222222222222222222',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfasfdfas.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0346f9c7c21f36a861b8',
            sortOrder: 17,
            url: 'aklsdfjk.com',
            title: 'new links',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aklsdfjk.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc9e8650194669572cf3b',
            sortOrder: 37,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a03e8f9c7c21f36a861ba',
            sortOrder: 12,
            url: 'kasjfdlkasdf.com',
            title: 'newlkasjdflk',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=kasjfdlkasdf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0418f9c7c21f36a861bb',
            sortOrder: 25,
            url: 'fb.com',
            title: ' ewww',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fb.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '605a0498f9c7c21f36a861bc',
            sortOrder: 6,
            url: 'lsakdjf.com',
            title: 'asdfc',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=lsakdjf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a04dcf9c7c21f36a861bd',
            sortOrder: 15,
            url: 'aslkdfj.com',
            title: 'asdfljasdflj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aslkdfj.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a06daf9c7c21f36a861c3',
            sortOrder: 9,
            url: 'laksdfj.com',
            title: 'updating you',
            imageURL:
              'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/ca7361d7-b93a-4eb0-868f-b4497c53acb5.jpg',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0513f9c7c21f36a861be',
            sortOrder: 16,
            url: 'asdfafl.com',
            title: 'asdfa',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfafl.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a067ff9c7c21f36a861c2',
            sortOrder: 14,
            url: 'aksjdf.com',
            title: 'last try',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aksjdf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '605a0547f9c7c21f36a861bf',
            sortOrder: 2,
            url: 'asdfjla.com',
            title: 'asdfa laskdfj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfjla.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a066ff9c7c21f36a861c1',
            sortOrder: 27,
            url: 'sakdfj.co',
            title: 'kjiafaskdjfh',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=sakdfj.co',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a16c5f9c7c21f36a861ca',
            sortOrder: 24,
            url: 'test.run',
            title: 'test.rung',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=test.run',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a05c0f9c7c21f36a861c0',
            sortOrder: 28,
            url: 'klasdjf.com',
            title: 'asdfkljalkfj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=klasdjf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a18bcf9c7c21f36a861cb',
            sortOrder: 34,
            url: 'klajef.com',
            title: 'new this',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=klajef.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605ccd19650194669572cf3f',
            sortOrder: 41,
          },
          {
            popLinksId: '605a3f56b8f2c82578929421',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3f56b8f2c82578929424',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 18,
          },
          {
            popLinksId: '605a3f80b8f2c82578929425',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3f81b8f2c82578929428',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 23,
          },
          {
            popLinksId: '605a3facb8f2c82578929429',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3facb8f2c8257892942a',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 35,
          },
          {
            popLinksId: '605a45ddb8f2c8257892942b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a45ddb8f2c8257892942d',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 30,
          },
          {
            popLinksId: '605a5c4b9942a02b754443b1',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a5c4b9942a02b754443b3',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 37,
          },
          {
            popLinksId: '605aa6d7d3a6213113d99957',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605aa6d7d3a6213113d99958',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 26,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605bae5ed3a6213113d99a2c',
            sortOrder: 4,
            title: 'askdfjaks',
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '605bb3d3d3a6213113d99a31',
            title: 'section titile new new ',
            sortOrder: 3,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc276650194669572cf2a',
            sortOrder: 33,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc2f5650194669572cf2b',
            sortOrder: 32,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc30f650194669572cf2c',
            url: 'fb.com',
            title: 'hello me',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fb.com',
            sortOrder: 35,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc3bf650194669572cf2e',
            sortOrder: 29,
            url: 'kasfdj.com',
            title: 'test',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=kasfdj.com',
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc46c650194669572cf30',
            title: 'song askdfj',
            url: 'https://www.youtube.com/watch?v=3-JbvmuCNcc',
            sortOrder: 1,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605e278db533aa6fffff6bd1',
            title: 'asdfasf',
            url: 'https://www.youtube.com/watch?v=MhODHoaats0&t=383s',
            sortOrder: 46,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: true,
            isDeleted: true,
            isActive: false,
            _id: '605e296e2608fc14d34330c5',
            title: 'I love you so bad',
            url: 'https://www.youtube.com/watch?v=FixCjwGs2JE',
            sortOrder: 2,
          },
          {
            popLinksId: '6064f07456f01b7cd0f2fada',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f07556f01b7cd0f2fadf',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 7,
          },
          {
            popLinksId: '6064f19256f01b7cd0f2fae8',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f19256f01b7cd0f2faea',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 5,
          },
          {
            popLinksId: '6064f1cd56f01b7cd0f2faeb',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f1cd56f01b7cd0f2faec',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 11,
          },
          {
            popLinksId: '6065e17356f01b7cd0f2faed',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '6065e17356f01b7cd0f2faf0',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 8,
          },
          {
            popLinksId: '6065e62f56f01b7cd0f2faf1',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6065e62f56f01b7cd0f2faf2',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 52,
          },
          {
            popLinksId: '60679c0da5f54957061719e5',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '60679c0da5f54957061719e8',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 53,
          },
          {
            popLinksId: '60679dbfa5f54957061719f4',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '60679dbfa5f54957061719f7',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 54,
          },
          {
            popLinksId: '60679ec9a5f54957061719f8',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '60679ec9a5f54957061719fb',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 10,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: true,
            isDeleted: false,
            isActive: false,
            _id: '60787ec29ccfbd2400757c1a',
            title: 'helo',
            url: 'https://www.youtube.com/watch?v=3_ftZdc-sYI',
            sortOrder: 9,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '607ca8103b86101264674806',
            title: 'my tag line',
            sortOrder: 12,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '607ca8bf3b86101264674807',
            title: 'my tag line',
            sortOrder: 1,
          },
        ],
        sundayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fc',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        mondayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fd',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        tuesdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fe',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        wednesdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12ff',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        thursdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1300',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        fridayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1301',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        saturdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1302',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        invitationHash: '2YlDsj',
        createdAt: '2021-03-18T21:40:50.279Z',
        updatedAt: '2021-04-27T20:57:39.347Z',
        __v: 0,
        timeOffset: 'Asia/Karachi',
        description: "please i'm awais",
        tags: 'yes me,dddsaf aksjdflji,kasdjf,asdfl',
        profileImage:
          'https://selfiepop-bucket.s3.amazonaws.com/users/profile-image/5c945ed3-0eb7-4ae1-b2ad-355ac49df5b5.png',
        address1: '',
        address2: '',
        city: '',
        country: '',
        phone: '',
        state: '',
        zip: null,
      },
      price: 88,
      dateOrderStarted: '2021-04-04T23:25:43.000Z',
      title: 'Insta ADd',
      description:
        "Going from first to last slide, or from last to first slide, on infinite mode doesn't animate",
      popLiveAttendees: [],
      createdAt: '2021-04-04T23:24:24.144Z',
      updatedAt: '2021-04-24T06:28:33.586Z',
      __v: 0,
      attachments: [],
    },
    {
      priceVariation: {
        type: 'instagram',
        price: 44,
        title: 'Advertise 24 hours',
        description:
          'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document',
      },
      orderStatus: 'Canceled',
      orderNumber: '3231-063469-9811',
      popType: 'advertise',
      isDeleted: false,
      videoLink: null,
      videoName: null,
      dueDate: '2021-04-08T08:04:17.000Z',
      paymaQuestion: null,
      paymaAnswer: null,
      popLiveEventStartDateTime: null,
      videoEncodeComplete: 'incomplete',
      paymentIntentId: 'pi_1Icew0KJHvJLirylSghp0Rhu',
      processingStatus: 'Canceled',
      expireEmailDelivered: false,
      orderPlatformFee: 1.58,
      rate: null,
      review: null,
      qualifiedAffiliateId: null,
      qualifiedSuperAffiliateId: null,
      disputeStartedAt: null,
      disputeCompletedAt: null,
      _id: '606a1bc147f57a069277df2f',
      popId: {
        duration: 15,
        isActive: true,
        popType: 'advertise',
        isComplete: false,
        isDeleted: false,
        isUserDefault: false,
        popThumbnail: null,
        _id: '60679ec9a5f54957061719f8',
        title: 'Insta ADd',
        price: 43,
        description:
          "Going from first to last slide, or from last to first slide, on infinite mode doesn't animate",
        additionalArt: [],
        questions: [],
        digitalDownloads: [],
        priceVariations: [
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '606a462247f57a069277df74',
                title: 'Only One Question At the moment',
                responseType: 'text',
              },
            ],
            _id: '606a462247f57a069277df73',
            title:
              "This variation doesn't have any attachment question required",
            price: 88,
            description: 'Buy me',
            isActive: true,
            type: 'facebook',
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'What do you think about me?',
                responseType: 'text',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb3499f',
                title: 'Show me your picture.',
                responseType: 'file',
              },
              {
                isActive: true,
                responseOptions: [
                  {
                    _id: '606dd2169ee89312fdc3331b',
                    key: '0',
                    text: 'captian marvel',
                    value: false,
                  },
                  {
                    _id: '606dd2169ee89312fdc3331c',
                    key: '1',
                    text: ' captain america',
                    value: false,
                  },
                  {
                    _id: '606dd2169ee89312fdc3331d',
                    key: '2',
                    text: 'avenger',
                    value: false,
                  },
                ],
                _id: '6068a98c1701de739bb349a0',
                title: 'Movies you like?',
                text: 'captain america, captian marvel',
                responseType: 'selectList',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'Advertise 24 hours',
            description:
              'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document',
            isActive: true,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'Be Prove of your name and tell us.',
                responseType: 'text',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'asdfa',
            description: '444',
            isActive: true,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'What do you think about me?',
                responseType: 'text',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb3499f',
                title: 'Show me your picture.',
                responseType: 'file',
              },
              {
                isActive: true,
                responseOptions: [],
                _id: '6068a98c1701de739bb349a0',
                title: 'Movies you like?',
                text: 'captain america, captian marvel',
                responseType: 'selectList',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'Advertise 24 hours',
            description:
              'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document',
            isActive: false,
          },
          {
            questions: [
              {
                isActive: true,
                responseOptions: [],
                _id: '60679ec9a5f54957061719fa',
                title: 'Be Prove of your name and tell us.',
                responseType: 'text',
              },
            ],
            _id: '60679ec9a5f54957061719f9',
            type: 'instagram',
            price: 44,
            title: 'asdfa',
            description: '444',
            isActive: false,
          },
          {
            questions: [],
            _id: '606a482447f57a069277df8f',
            title: 'No Questions At the moment',
            price: 999,
            description: '88989',
            isActive: true,
            type: 'twitter',
          },
        ],
        popName: 'instaadd5630',
        sortOrder: 11,
        owner: '6053c8e2ef711660d04c12f8',
        altOptions: [],
        createdAt: '2021-04-02T22:46:33.032Z',
        updatedAt: '2021-04-24T15:38:02.301Z',
        __v: 0,
      },
      buyer: {
        socialLinks: [],
        tagLine: 'my tag line',
        userSetupStatus: 10,
        verifiedPath: false,
        isDeleted: false,
        isInfluencer: false,
        isCreative: false,
        isEducator: false,
        isActiveProfile: true,
        isEmailVerified: false,
        isActiveSeller: true,
        skipOnBoarding: false,
        sellerComissionRate: 90,
        signupSource: null,
        sellerPlatformFee: 2.9,
        sellerProcessingFee: 10,
        servicesTitleEnabled: true,
        servicesTitleValue: 'My Services',
        linksTitleEnabled: true,
        linksTitleValue: 'My Links',
        sundayPopLiveActive: false,
        mondayPopLiveActive: false,
        tuesdayPopLiveActive: false,
        wednesdayPopLiveActive: false,
        thursdayPopLiveActive: false,
        fridayPopLiveActive: false,
        saturdayPopLiveActive: false,
        stripeSellerAccountId: '',
        isGuest: false,
        shoutoutHoursToDeliver: 48,
        fanmailHoursToDeliver: 48,
        popLiveOrderBuffer: 48,
        betaStatus: 0,
        invitationActive: false,
        inviationStatus: 'fresh',
        userStatus: 'invitationReviewPending',
        isAffiliate: false,
        isSuperAffiliate: false,
        allowPopLive: false,
        referralId: null,
        userThemeId: '607605ee6194a106312e5f5d',
        allowSelling: false,
        isInvitedUser: false,
        library: [
          '6074b53cd7fee065a0cde25a',
          '6074b643d7fee065a0cde25b',
          '6074b683d7fee065a0cde25c',
          '6074b758d7fee065a0cde25d',
          '6074b856d7fee065a0cde25e',
          '6075db0d20c89a7dc3a7d0eb',
          '6075dbe320c89a7dc3a7d0ec',
          '6079b6a3f623916d1096bc86',
          '6079bae7f623916d1096bc87',
          '6079bdb8f623916d1096bc88',
        ],
        enableSystemThemeAccess: true,
        _id: '6053c8e2ef711660d04c12f8',
        firstName: 'askdfjaks',
        lastName: 'asdfklj',
        email: 'myprofile@test.com',
        username: 'askdfjaksasdfkl',
        themeColor: {
          isActive: true,
          _id: '5fa960bd19db9f677650d40f',
          colorSetName: 'Sedona',
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          primaryColor: '#f7a278',
          secondaryColor: '#a13d63',
        },
        links: [
          {
            popLinksId: '6053c8e2ef711660d04c1305',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6053c8e2ef711660d04c1308',
            sortOrder: 1,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6053c8e2ef711660d04c12f9',
            sortOrder: 4,
            url: null,
            title: 'My Services',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c12fa',
            sortOrder: 5,
            url: null,
            title: 'My Links',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c7a63ad20a1e8ceb2da7',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6058c7a63ad20a1e8ceb2daa',
            sortOrder: 3,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6053c8e2ef711660d04c1304',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c1307',
            sortOrder: 5,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c55a3ad20a1e8ceb2d97',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6058c55a3ad20a1e8ceb2d98',
            sortOrder: 3,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cca54650194669572cf3c',
            sortOrder: 39,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc9a1650194669572cf3a',
            sortOrder: 38,
          },
          {
            popLinksId: '6058c6803ad20a1e8ceb2da3',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c6803ad20a1e8ceb2da4',
            sortOrder: 10,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c6063ad20a1e8ceb2d9d',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c6063ad20a1e8ceb2d9e',
            sortOrder: 1,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc980650194669572cf39',
            sortOrder: 40,
          },
          {
            popLinksId: '6053c8e2ef711660d04c1306',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c1309',
            sortOrder: 6,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c7493ad20a1e8ceb2da5',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c7493ad20a1e8ceb2da6',
            sortOrder: 19,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605ccca0650194669572cf3d',
            sortOrder: 36,
          },
          {
            popLinksId: '6058c5ba3ad20a1e8ceb2d9b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c5ba3ad20a1e8ceb2d9c',
            sortOrder: 7,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605a016bf9c7c21f36a861b6',
            sortOrder: 11,
            url: 'fabco.com',
            title: '55555sdafadsfdsafdsfa',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fabco.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a01d7f9c7c21f36a861b7',
            sortOrder: 13,
            url: 'asdfasfdfas.com',
            title: '222222222222222222',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfasfdfas.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0346f9c7c21f36a861b8',
            sortOrder: 17,
            url: 'aklsdfjk.com',
            title: 'new links',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aklsdfjk.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc9e8650194669572cf3b',
            sortOrder: 37,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a03e8f9c7c21f36a861ba',
            sortOrder: 12,
            url: 'kasjfdlkasdf.com',
            title: 'newlkasjdflk',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=kasjfdlkasdf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0418f9c7c21f36a861bb',
            sortOrder: 25,
            url: 'fb.com',
            title: ' ewww',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fb.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '605a0498f9c7c21f36a861bc',
            sortOrder: 6,
            url: 'lsakdjf.com',
            title: 'asdfc',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=lsakdjf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a04dcf9c7c21f36a861bd',
            sortOrder: 15,
            url: 'aslkdfj.com',
            title: 'asdfljasdflj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aslkdfj.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a06daf9c7c21f36a861c3',
            sortOrder: 9,
            url: 'laksdfj.com',
            title: 'updating you',
            imageURL:
              'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/ca7361d7-b93a-4eb0-868f-b4497c53acb5.jpg',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0513f9c7c21f36a861be',
            sortOrder: 16,
            url: 'asdfafl.com',
            title: 'asdfa',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfafl.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a067ff9c7c21f36a861c2',
            sortOrder: 14,
            url: 'aksjdf.com',
            title: 'last try',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aksjdf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '605a0547f9c7c21f36a861bf',
            sortOrder: 2,
            url: 'asdfjla.com',
            title: 'asdfa laskdfj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfjla.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a066ff9c7c21f36a861c1',
            sortOrder: 27,
            url: 'sakdfj.co',
            title: 'kjiafaskdjfh',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=sakdfj.co',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a16c5f9c7c21f36a861ca',
            sortOrder: 24,
            url: 'test.run',
            title: 'test.rung',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=test.run',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a05c0f9c7c21f36a861c0',
            sortOrder: 28,
            url: 'klasdjf.com',
            title: 'asdfkljalkfj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=klasdjf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a18bcf9c7c21f36a861cb',
            sortOrder: 34,
            url: 'klajef.com',
            title: 'new this',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=klajef.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605ccd19650194669572cf3f',
            sortOrder: 41,
          },
          {
            popLinksId: '605a3f56b8f2c82578929421',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3f56b8f2c82578929424',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 18,
          },
          {
            popLinksId: '605a3f80b8f2c82578929425',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3f81b8f2c82578929428',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 23,
          },
          {
            popLinksId: '605a3facb8f2c82578929429',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3facb8f2c8257892942a',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 35,
          },
          {
            popLinksId: '605a45ddb8f2c8257892942b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a45ddb8f2c8257892942d',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 30,
          },
          {
            popLinksId: '605a5c4b9942a02b754443b1',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a5c4b9942a02b754443b3',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 37,
          },
          {
            popLinksId: '605aa6d7d3a6213113d99957',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605aa6d7d3a6213113d99958',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 26,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605bae5ed3a6213113d99a2c',
            sortOrder: 4,
            title: 'askdfjaks',
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '605bb3d3d3a6213113d99a31',
            title: 'section titile new new ',
            sortOrder: 3,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc276650194669572cf2a',
            sortOrder: 33,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc2f5650194669572cf2b',
            sortOrder: 32,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc30f650194669572cf2c',
            url: 'fb.com',
            title: 'hello me',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fb.com',
            sortOrder: 35,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc3bf650194669572cf2e',
            sortOrder: 29,
            url: 'kasfdj.com',
            title: 'test',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=kasfdj.com',
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc46c650194669572cf30',
            title: 'song askdfj',
            url: 'https://www.youtube.com/watch?v=3-JbvmuCNcc',
            sortOrder: 1,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605e278db533aa6fffff6bd1',
            title: 'asdfasf',
            url: 'https://www.youtube.com/watch?v=MhODHoaats0&t=383s',
            sortOrder: 46,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: true,
            isDeleted: true,
            isActive: false,
            _id: '605e296e2608fc14d34330c5',
            title: 'I love you so bad',
            url: 'https://www.youtube.com/watch?v=FixCjwGs2JE',
            sortOrder: 2,
          },
          {
            popLinksId: '6064f07456f01b7cd0f2fada',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f07556f01b7cd0f2fadf',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 7,
          },
          {
            popLinksId: '6064f19256f01b7cd0f2fae8',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f19256f01b7cd0f2faea',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 5,
          },
          {
            popLinksId: '6064f1cd56f01b7cd0f2faeb',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f1cd56f01b7cd0f2faec',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 11,
          },
          {
            popLinksId: '6065e17356f01b7cd0f2faed',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '6065e17356f01b7cd0f2faf0',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 8,
          },
          {
            popLinksId: '6065e62f56f01b7cd0f2faf1',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6065e62f56f01b7cd0f2faf2',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 52,
          },
          {
            popLinksId: '60679c0da5f54957061719e5',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '60679c0da5f54957061719e8',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 53,
          },
          {
            popLinksId: '60679dbfa5f54957061719f4',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '60679dbfa5f54957061719f7',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 54,
          },
          {
            popLinksId: '60679ec9a5f54957061719f8',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '60679ec9a5f54957061719fb',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 10,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: true,
            isDeleted: false,
            isActive: false,
            _id: '60787ec29ccfbd2400757c1a',
            title: 'helo',
            url: 'https://www.youtube.com/watch?v=3_ftZdc-sYI',
            sortOrder: 9,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '607ca8103b86101264674806',
            title: 'my tag line',
            sortOrder: 12,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '607ca8bf3b86101264674807',
            title: 'my tag line',
            sortOrder: 1,
          },
        ],
        sundayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fc',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        mondayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fd',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        tuesdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fe',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        wednesdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12ff',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        thursdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1300',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        fridayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1301',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        saturdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1302',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        invitationHash: '2YlDsj',
        createdAt: '2021-03-18T21:40:50.279Z',
        updatedAt: '2021-04-27T20:57:39.347Z',
        __v: 0,
        timeOffset: 'Asia/Karachi',
        description: "please i'm awais",
        tags: 'yes me,dddsaf aksjdflji,kasdjf,asdfl',
        profileImage:
          'https://selfiepop-bucket.s3.amazonaws.com/users/profile-image/5c945ed3-0eb7-4ae1-b2ad-355ac49df5b5.png',
        address1: '',
        address2: '',
        city: '',
        country: '',
        phone: '',
        state: '',
        zip: null,
      },
      seller: {
        socialLinks: [],
        tagLine: 'my tag line',
        userSetupStatus: 10,
        verifiedPath: false,
        isDeleted: false,
        isInfluencer: false,
        isCreative: false,
        isEducator: false,
        isActiveProfile: true,
        isEmailVerified: false,
        isActiveSeller: true,
        skipOnBoarding: false,
        sellerComissionRate: 90,
        signupSource: null,
        sellerPlatformFee: 2.9,
        sellerProcessingFee: 10,
        servicesTitleEnabled: true,
        servicesTitleValue: 'My Services',
        linksTitleEnabled: true,
        linksTitleValue: 'My Links',
        sundayPopLiveActive: false,
        mondayPopLiveActive: false,
        tuesdayPopLiveActive: false,
        wednesdayPopLiveActive: false,
        thursdayPopLiveActive: false,
        fridayPopLiveActive: false,
        saturdayPopLiveActive: false,
        stripeSellerAccountId: '',
        isGuest: false,
        shoutoutHoursToDeliver: 48,
        fanmailHoursToDeliver: 48,
        popLiveOrderBuffer: 48,
        betaStatus: 0,
        invitationActive: false,
        inviationStatus: 'fresh',
        userStatus: 'invitationReviewPending',
        isAffiliate: false,
        isSuperAffiliate: false,
        allowPopLive: false,
        referralId: null,
        userThemeId: '607605ee6194a106312e5f5d',
        allowSelling: false,
        isInvitedUser: false,
        library: [
          '6074b53cd7fee065a0cde25a',
          '6074b643d7fee065a0cde25b',
          '6074b683d7fee065a0cde25c',
          '6074b758d7fee065a0cde25d',
          '6074b856d7fee065a0cde25e',
          '6075db0d20c89a7dc3a7d0eb',
          '6075dbe320c89a7dc3a7d0ec',
          '6079b6a3f623916d1096bc86',
          '6079bae7f623916d1096bc87',
          '6079bdb8f623916d1096bc88',
        ],
        enableSystemThemeAccess: true,
        _id: '6053c8e2ef711660d04c12f8',
        firstName: 'askdfjaks',
        lastName: 'asdfklj',
        email: 'myprofile@test.com',
        username: 'askdfjaksasdfkl',
        themeColor: {
          isActive: true,
          _id: '5fa960bd19db9f677650d40f',
          colorSetName: 'Sedona',
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          primaryColor: '#f7a278',
          secondaryColor: '#a13d63',
        },
        links: [
          {
            popLinksId: '6053c8e2ef711660d04c1305',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6053c8e2ef711660d04c1308',
            sortOrder: 1,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6053c8e2ef711660d04c12f9',
            sortOrder: 4,
            url: null,
            title: 'My Services',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c12fa',
            sortOrder: 5,
            url: null,
            title: 'My Links',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c7a63ad20a1e8ceb2da7',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6058c7a63ad20a1e8ceb2daa',
            sortOrder: 3,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6053c8e2ef711660d04c1304',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c1307',
            sortOrder: 5,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c55a3ad20a1e8ceb2d97',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6058c55a3ad20a1e8ceb2d98',
            sortOrder: 3,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cca54650194669572cf3c',
            sortOrder: 39,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc9a1650194669572cf3a',
            sortOrder: 38,
          },
          {
            popLinksId: '6058c6803ad20a1e8ceb2da3',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c6803ad20a1e8ceb2da4',
            sortOrder: 10,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c6063ad20a1e8ceb2d9d',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c6063ad20a1e8ceb2d9e',
            sortOrder: 1,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc980650194669572cf39',
            sortOrder: 40,
          },
          {
            popLinksId: '6053c8e2ef711660d04c1306',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '6053c8e2ef711660d04c1309',
            sortOrder: 6,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: '6058c7493ad20a1e8ceb2da5',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c7493ad20a1e8ceb2da6',
            sortOrder: 19,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605ccca0650194669572cf3d',
            sortOrder: 36,
          },
          {
            popLinksId: '6058c5ba3ad20a1e8ceb2d9b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6058c5ba3ad20a1e8ceb2d9c',
            sortOrder: 7,
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605a016bf9c7c21f36a861b6',
            sortOrder: 11,
            url: 'fabco.com',
            title: '55555sdafadsfdsafdsfa',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fabco.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a01d7f9c7c21f36a861b7',
            sortOrder: 13,
            url: 'asdfasfdfas.com',
            title: '222222222222222222',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfasfdfas.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0346f9c7c21f36a861b8',
            sortOrder: 17,
            url: 'aklsdfjk.com',
            title: 'new links',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aklsdfjk.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc9e8650194669572cf3b',
            sortOrder: 37,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a03e8f9c7c21f36a861ba',
            sortOrder: 12,
            url: 'kasjfdlkasdf.com',
            title: 'newlkasjdflk',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=kasjfdlkasdf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0418f9c7c21f36a861bb',
            sortOrder: 25,
            url: 'fb.com',
            title: ' ewww',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fb.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '605a0498f9c7c21f36a861bc',
            sortOrder: 6,
            url: 'lsakdjf.com',
            title: 'asdfc',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=lsakdjf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a04dcf9c7c21f36a861bd',
            sortOrder: 15,
            url: 'aslkdfj.com',
            title: 'asdfljasdflj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aslkdfj.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a06daf9c7c21f36a861c3',
            sortOrder: 9,
            url: 'laksdfj.com',
            title: 'updating you',
            imageURL:
              'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/ca7361d7-b93a-4eb0-868f-b4497c53acb5.jpg',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a0513f9c7c21f36a861be',
            sortOrder: 16,
            url: 'asdfafl.com',
            title: 'asdfa',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfafl.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a067ff9c7c21f36a861c2',
            sortOrder: 14,
            url: 'aksjdf.com',
            title: 'last try',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=aksjdf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '605a0547f9c7c21f36a861bf',
            sortOrder: 2,
            url: 'asdfjla.com',
            title: 'asdfa laskdfj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=asdfjla.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a066ff9c7c21f36a861c1',
            sortOrder: 27,
            url: 'sakdfj.co',
            title: 'kjiafaskdjfh',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=sakdfj.co',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a16c5f9c7c21f36a861ca',
            sortOrder: 24,
            url: 'test.run',
            title: 'test.rung',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=test.run',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a05c0f9c7c21f36a861c0',
            sortOrder: 28,
            url: 'klasdjf.com',
            title: 'asdfkljalkfj',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=klasdjf.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a18bcf9c7c21f36a861cb',
            sortOrder: 34,
            url: 'klajef.com',
            title: 'new this',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=klajef.com',
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605ccd19650194669572cf3f',
            sortOrder: 41,
          },
          {
            popLinksId: '605a3f56b8f2c82578929421',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3f56b8f2c82578929424',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 18,
          },
          {
            popLinksId: '605a3f80b8f2c82578929425',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3f81b8f2c82578929428',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 23,
          },
          {
            popLinksId: '605a3facb8f2c82578929429',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a3facb8f2c8257892942a',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 35,
          },
          {
            popLinksId: '605a45ddb8f2c8257892942b',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a45ddb8f2c8257892942d',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 30,
          },
          {
            popLinksId: '605a5c4b9942a02b754443b1',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605a5c4b9942a02b754443b3',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 37,
          },
          {
            popLinksId: '605aa6d7d3a6213113d99957',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605aa6d7d3a6213113d99958',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 26,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605bae5ed3a6213113d99a2c',
            sortOrder: 4,
            title: 'askdfjaks',
          },
          {
            popLinksId: null,
            linkType: 'sectionTitle',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '605bb3d3d3a6213113d99a31',
            title: 'section titile new new ',
            sortOrder: 3,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc276650194669572cf2a',
            sortOrder: 33,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc2f5650194669572cf2b',
            sortOrder: 32,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc30f650194669572cf2c',
            url: 'fb.com',
            title: 'hello me',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=fb.com',
            sortOrder: 35,
          },
          {
            popLinksId: null,
            linkType: 'simpleLink',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605cc3bf650194669572cf2e',
            sortOrder: 29,
            url: 'kasfdj.com',
            title: 'test',
            imageURL:
              'https://www.google.com/s2/favicons?sz=64&domain_url=kasfdj.com',
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: false,
            isDeleted: true,
            isActive: false,
            _id: '605cc46c650194669572cf30',
            title: 'song askdfj',
            url: 'https://www.youtube.com/watch?v=3-JbvmuCNcc',
            sortOrder: 1,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '605e278db533aa6fffff6bd1',
            title: 'asdfasf',
            url: 'https://www.youtube.com/watch?v=MhODHoaats0&t=383s',
            sortOrder: 46,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: true,
            isDeleted: true,
            isActive: false,
            _id: '605e296e2608fc14d34330c5',
            title: 'I love you so bad',
            url: 'https://www.youtube.com/watch?v=FixCjwGs2JE',
            sortOrder: 2,
          },
          {
            popLinksId: '6064f07456f01b7cd0f2fada',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f07556f01b7cd0f2fadf',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 7,
          },
          {
            popLinksId: '6064f19256f01b7cd0f2fae8',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f19256f01b7cd0f2faea',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 5,
          },
          {
            popLinksId: '6064f1cd56f01b7cd0f2faeb',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '6064f1cd56f01b7cd0f2faec',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 11,
          },
          {
            popLinksId: '6065e17356f01b7cd0f2faed',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: false,
            _id: '6065e17356f01b7cd0f2faf0',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 8,
          },
          {
            popLinksId: '6065e62f56f01b7cd0f2faf1',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '6065e62f56f01b7cd0f2faf2',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 52,
          },
          {
            popLinksId: '60679c0da5f54957061719e5',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '60679c0da5f54957061719e8',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 53,
          },
          {
            popLinksId: '60679dbfa5f54957061719f4',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: true,
            isActive: true,
            _id: '60679dbfa5f54957061719f7',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 54,
          },
          {
            popLinksId: '60679ec9a5f54957061719f8',
            linkType: 'service',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '60679ec9a5f54957061719fb',
            url: null,
            title: '',
            imageURL: null,
            associatedRecordID: null,
            sortOrder: 10,
          },
          {
            popLinksId: null,
            linkType: 'youtubeLink',
            autoPlay: true,
            mute: true,
            loop: true,
            isDeleted: false,
            isActive: false,
            _id: '60787ec29ccfbd2400757c1a',
            title: 'helo',
            url: 'https://www.youtube.com/watch?v=3_ftZdc-sYI',
            sortOrder: 9,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '607ca8103b86101264674806',
            title: 'my tag line',
            sortOrder: 12,
          },
          {
            popLinksId: null,
            linkType: 'biography',
            autoPlay: false,
            mute: false,
            loop: false,
            isDeleted: false,
            isActive: true,
            _id: '607ca8bf3b86101264674807',
            title: 'my tag line',
            sortOrder: 1,
          },
        ],
        sundayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fc',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        mondayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fd',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        tuesdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12fe',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        wednesdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c12ff',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        thursdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1300',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        fridayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1301',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        saturdayPopLiveSlots: [
          {
            _id: '6053c8e2ef711660d04c1302',
            startTime: '09:00 AM',
            endTime: '05:00 PM',
          },
        ],
        invitationHash: '2YlDsj',
        createdAt: '2021-03-18T21:40:50.279Z',
        updatedAt: '2021-04-27T20:57:39.347Z',
        __v: 0,
        timeOffset: 'Asia/Karachi',
        description: "please i'm awais",
        tags: 'yes me,dddsaf aksjdflji,kasdjf,asdfl',
        profileImage:
          'https://selfiepop-bucket.s3.amazonaws.com/users/profile-image/5c945ed3-0eb7-4ae1-b2ad-355ac49df5b5.png',
        address1: '',
        address2: '',
        city: '',
        country: '',
        phone: '',
        state: '',
        zip: null,
      },
      price: 44,
      questions: [
        {
          responseValue: 'nothing',
          responseOptions: [],
          attachements: [],
          _id: '60679ec9a5f54957061719fa',
          title: 'What do you think about me?',
          responseType: 'text',
        },
        {
          responseValue: '',
          responseOptions: [],
          attachements: [],
          _id: '6068a98c1701de739bb3499f',
          title: 'Show me your picture.',
          responseType: 'file',
        },
        {
          responseValue: 'captain america,captian marvel,',
          responseOptions: [],
          attachements: [],
          _id: '6068a98c1701de739bb349a0',
          title: 'Movies you like?',
          responseType: 'selectList',
          text: 'captain america, captian marvel',
        },
      ],
      dateOrderStarted: '2021-04-04T23:00:53.000Z',
      title: 'Insta ADd',
      description:
        "Going from first to last slide, or from last to first slide, on infinite mode doesn't animate",
      popLiveAttendees: [],
      createdAt: '2021-04-04T20:04:17.129Z',
      updatedAt: '2021-04-24T06:28:33.311Z',
      __v: 0,
      attachments: [],
    },
  ],
  totalCount: 9,
};

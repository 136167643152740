import { getSocialAccounts } from 'api/social-accounts';
import classNames from 'classnames';
import { ServiceType } from 'enums';
import useAuth from 'hooks/useAuth';
import useDispatch from 'hooks/useDispatch';
import useQuery from 'hooks/useQuery';
import useSelector from 'hooks/useSelector';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { SET_SOCIAL_LINK } from 'reducer/appStates';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import styled from 'styled-components';
import { IUserLink } from 'types';
import IPop from 'types/Pop.type';
import { create } from '../../api/Pop';
import { pushIntoUser, update } from '../../api/User';
import EditableItem from './components/EditableItem';
interface Props {
  className?: string;
}

function PopEditor({ className }: Props): ReactElement<any> {
  const { user, setUser } = useAuth();
  const [LinkType, setLinkType] = useState<IUserLink['linkType']>('simpleLink');
  const [value, setValues] = useState<Partial<IUserLink | IPop>>();
  const dispatch = useDispatch();
  const socialLinks = useSelector((state) => state?.appStates?.socialLinks);
  const history = useHistory();

  const options = useQuery();
  const ref = useRef<HTMLDivElement>(null);

  const UpdateUser = useCallback(
    async (value) => {
      delete value._id;
      const userData = await update(value);
      await pushIntoUser(user._id, {
        name: 'links',
        value: {
          title: value.tagLine,
          isActive: value.isActive,
          linkType: 'biography',
        },
      }).then(async (data: any) => {
        setUser((u: any) => ({
          ...u,
          ...userData?.data,
          links: [...u.links, data.links[data.links.length - 1]],
        }));
      });
    },
    [setUser, user._id],
  );

  const updateSocialLinks = useCallback(
    async (value) => {
      delete value._id;
      const userData = await update({
        socialMediaLinks: value.socialMediaLinks,
      });
      await pushIntoUser(user._id, {
        name: 'links',
        value: {
          title: 'Social Links',
          isActive: value.isActive,
          linkType: 'socialLinks',
        },
      }).then(async (data: any) => {
        setUser((u: any) => ({
          ...u,
          ...userData?.data,
          links: [...u.links, data.links[data.links.length - 1]],
        }));
      });
    },
    [setUser, user._id],
  );

  useEffect(() => {
    setTimeout(() => {
      if (ref.current)
        scrollIntoView(ref.current, {
          behavior: 'smooth',
          block: 'start',
        });
    }, 700);
  }, []);

  const addNewPop = useCallback(
    async (values) => {
      try {
        const response = await create(values);
        if (values.type === ServiceType.POPLIVE) {
          const timeZone = values.timeZone;
          if (timeZone !== user.timeOffset) {
            await update({ timeOffset: timeZone });
          }
        }
        const newArray = [...user.links, response.pop];
        setUser({ ...user, links: newArray, timeOffset: values.timeZone });
      } catch (e) {}
    },
    [setUser, user],
  );

  const addNewLink = useCallback(
    async (value: any) => {
      if (!value) return;
      await pushIntoUser(user._id, value).then(async (data: any) => {
        setUser({
          ...user,
          links: data.links,
        });
      });
    },
    [setUser, user],
  );

  useEffect(() => {
    if (options.type === 'service') {
      setValues({
        popType: options.subType as string,
        isActive: true,
        timeZone: user.timeOffset,
      });
      setLinkType(options.type);
    } else if (options.type === 'socialLinks') {
      setLinkType(options.type);
    } else {
      setLinkType(options.subType as IUserLink['linkType']);
    }

    if (options?.subType === ServiceType.ADVERTISE) {
      getSocialAccounts().then((res) => {
        dispatch({ type: SET_SOCIAL_LINK, payload: res?.socialMedia || [] });
      });
    }
  }, [options]);

  const handleSubmit = async (values: any) => {
    if (LinkType === 'service') {
      addNewPop && (await addNewPop(values));
      return;
    }
    if (LinkType === 'biography') {
      UpdateUser && (await UpdateUser(values));
      return;
    }
    if (LinkType === 'socialLinks') {
      await updateSocialLinks?.(values);
      return;
    }
    addNewLink && (await addNewLink(values));
    handleCancel();
  };

  const handleCancel = () => {
    history.push(`/my-profile/${LinkType === 'service' ? 'services' : ''}`);
  };

  return (
    <div ref={ref} className={classNames('create__pop', className)}>
      {LinkType && (
        <EditableItem
          type={LinkType}
          value={['biography', 'socialLinks'].includes(LinkType) ? user : value}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          socialLinks={socialLinks}
          title={`Add New ${
            LinkType === 'service' ? options.subtype : LinkType
          }`}
          options={{ delete: false, status: true, close: false }}
        />
      )}
    </div>
  );
}

export default styled(PopEditor)``;

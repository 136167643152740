import { EypeClose, EypeOpen } from 'assets/svgs';
import { useFormik } from 'formik';
import useQuery from 'hooks/useQuery';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getUserSetupUri } from 'util/index';
import * as yup from 'yup';
import FocusInput from '../components/focus-input';
import { default as NButton } from '../components/NButton';
import useAuth from '../hooks/useAuth';

const validationSchema = yup.object().shape({
  email: yup.string().required('Email or username is required!'),
  password: yup.string().required('Enter your password'),
});
export default function Login(props: any) {
  const { Login } = useAuth();
  const history = useHistory();
  const { redirect } = useQuery();
  const { loggedIn } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const [serverError, setserverError] = useState<string | null>(null);

  const {
    values,
    handleChange,
    handleBlur,
    resetForm,
    isValid,
    isSubmitting,
    handleSubmit,
    errors,
    touched,
    // tslint:disable-next-line: react-hooks-nesting
  } = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    // validateOnBlur:true,
    onSubmit: async (formData: any) => {
      await Login(formData)
        .then((user: any) => {
          let url = '/my-profile';
          if (user.userSetupStatus < 10) {
            url = getUserSetupUri(user.userSetupStatus);
          } else {
            if (redirect) {
              const uri = new URL(redirect as string);
              url = uri.pathname + uri.search;
            }
          }
          history.push(url);
        })
        .catch((e: Error) => {
          if (e.message === 'Your account is deactivated') {
            setserverError('The account does not exists');
          } else setserverError(e.message);
        });
    },
  });

  useEffect(() => {
    loggedIn && history.push('/my-profile');
  }, []);

  return (
    <div className="landing-page-area">
      <div className="container justify-content-center">
        {/* <MobileSlider /> */}
        <div className="form-signup-area">
          <strong className="logo">
            <Link to="/">
              <img src="assets/images/beta-logo.svg" alt="selfiepop" />
            </Link>
          </strong>
          {serverError && (
            <div className="alert alert-danger">{serverError}</div>
          )}
          <form action="post" className="form" onSubmit={handleSubmit}>
            <div className="field">
              <FocusInput
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
                label="Email or Username *"
                inputClasses="mb-25"
                name="email"
                value={values.email}
                validations={[{ noSpace: true }]}
              />
            </div>
            <div className="field">
              <FocusInput
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                touched={touched.password}
                label="Password *"
                limit={50}
                type={showPassword ? 'text' : 'password'}
                inputClasses="mb-25 icon-right"
                name="password"
                value={values.password}
                hasIcon={true}
                icon={
                  <span
                    onClick={() => {
                      values.password
                        ? setShowPassword(!showPassword)
                        : setShowPassword(false);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {showPassword ? <EypeClose /> : <EypeOpen />}
                  </span>
                }
              />
            </div>

            <div className="links-area">
              <NButton
                htmlType="submit"
                className="mb-20"
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
                type="primary"
                size="x-large"
              >
                Sign In
              </NButton>
              <strong className="info-text text-primary">
                Forget your password?
              </strong>
              <Link to="/recover-password" className="link">
                Reset Password
              </Link>

              <div className="mt-20">
                <strong className="caption">
                  <Link to="/signup" className="text-primary">
                    Click here
                  </Link>{' '}
                  to apply for beta
                </strong>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

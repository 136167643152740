import {
  createTheme,
  createThemeCategory,
  deleteTheme,
  getThemeCategories,
} from 'api/theme';
import { ArrowBack, ImageEdit, PlusFilled, SortIcon } from 'assets/svgs';
import FocusInput from 'components/focus-input';
import Model from 'components/modal';
import { AppAlert } from 'components/Model';
import Button from 'components/NButton';
import SimpleCard from 'components/SPCards/SimpleCard';
import useAuth from 'hooks/useAuth';
import useControlledState from 'hooks/useControlledState';
import useDispatch from 'hooks/useDispatch';
import useOpenClose from 'hooks/useOpenClose';
import useSelector from 'hooks/useSelector';
import ThemeCard from 'pages/my-profile/components/Editor/ThemeCard';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  SET_CATEGORIES,
  SET_CURRENT_THEME,
  SET_SYSTEM_THEMES,
  SET_THEMES,
} from 'reducer/theme';
import styled from 'styled-components';
import swal from 'sweetalert';
import ITheme from 'types/Theme';
import { getThemes, startThemePolling, stopThemePolling } from 'util/index';
import CurdModel from './components/CurdModel';
import { SortThemeList } from './ThemeDragable';

const ThemeLibraryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .theme-library-content__p {
    @media (max-width: 1199px) {
      width: 40%;
    }

    @media (max-width: 800px) {
      width: 34%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .btns-actions {
    margin: 0;
    padding: 0 10px 0 0;

    @media (max-width: 767px) {
      width: 100%;
      justify-content: center;
    }

    .button {
      &.button-sm {
        @media (max-width: 767px) {
          min-width: inherit;
        }
      }

      + .button {
        @media (max-width: 767px) {
          margin-left: 8px;
        }
      }

      svg {
        @media (max-width: 370px) {
          display: block;
          margin: 0 auto 5px;
        }
      }
    }
  }
`;
const getThumbnail = (theme?: ITheme) => {
  if (theme?.previewThumbnailPath) {
    return theme.previewThumbnailPath;
  }

  return '/assets/images/theme-preview-default.png';
};

const ThemeLibrary: React.FC<{
  className?: string;
  isOnboarding?: boolean;
  onCardClick?(theme: ITheme): void;
}> = ({ className, isOnboarding = false, onCardClick }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState('');

  const {
    systemThemes = [],
    allthemes = [],
    current,
    categories = [],
  }: {
    allthemes: ITheme[];
    systemThemes: ITheme[];
    current: ITheme;
    categories: [];
  } = useSelector((state) => state.theme);
  const { user, setUser } = useAuth();

  const [defaultThemes] = useControlledState([], {
    value: systemThemes || [],
  });

  const [isApplying, setIsApplying] = useState<string>('');
  const [isSortingOpen, onSortingOpen, onSortingClose] = useOpenClose(false);

  useEffect(() => {
    if (!isOnboarding) {
      startThemePolling(getThemesCb, true);
    } else {
      getThemes(getThemesCb);
    }

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 0);

    return () => {
      stopThemePolling();
    };
  }, []);

  useEffect(() => {
    async function categeries() {
      try {
        const response = await getThemeCategories();
        if (response.success) {
          const { items } = response;
          dispatch({ type: SET_CATEGORIES, payload: items });
        }
      } catch (error) {}
    }
    categeries();
    return () => {
      if (!isOnboarding) {
        dispatch({ type: SET_CURRENT_THEME, payload: undefined });
      }
    };
  }, []);

  const getThemesCb = (userThemes: ITheme[], systemThemes: ITheme[]) => {
    dispatch({
      type: SET_SYSTEM_THEMES,
      payload: [...systemThemes],
    });
  };

  const applySystemTheme = async (theme: ITheme) => {
    setIsApplying(`${theme._id}`);
    await createTheme({
      ...theme,
      isSystemTheme: false,
      isPublished: false,
      isDefault: false,
      isActive: true,
      baseURL: `${window.location.host}/${user.username}`,
      categoryId: (theme.categoryId as any)?._id,
    })
      .then((res) => {
        dispatch({
          type: SET_THEMES,
          payload: [...allthemes, res?.theme],
        });
        setUser({ ...user, userThemeId: res.theme });
        swal(`${theme?.name}`, `Applied Successfully`, 'success').then(() =>
          history.push('/my-profile'),
        );
      })
      .catch((e) => {
        swal('Error', `Could not apply ${theme.name}`, 'error');
        console.log(e);
      });

    setIsApplying('');
  };

  const handleDelete = async (theme: ITheme) => {
    AppAlert({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this!',
      buttons: ['', { text: 'Delete', closeModal: false }],
      onConfirm: async () => {
        if (!theme?._id) return;
        await deleteTheme(theme._id).then((res: ITheme) => {
          if (theme.isSystemTheme) {
            const newThemes = systemThemes.filter((t) => t._id !== theme._id);
            dispatch({
              type: SET_SYSTEM_THEMES,
              payload: newThemes,
            });
          }
          //  @ts-ignore-block
          swal?.close();
        });
      },
    });
  };
  const saveCategory = async () => {
    try {
      setIsOpen(false);
      setCategory('');
      await createThemeCategory(category);
    } catch (error) {}
  };

  return (
    <div className={className}>
      {!isOnboarding && (
        <div className="theme-library-header">
          <Link
            to="/my-profile/themes-listing"
            className="btn-back"
            type="text"
          >
            <span className="img">
              <ArrowBack />
            </span>
            Back
          </Link>
          <h5>Theme Library</h5>
        </div>
      )}
      <div className="theme-library-content">
        <ThemeLibraryHeader>
          {!isOnboarding && (
            <p className="theme-library-content__p">
              Click the theme thumbnail to preview. Apply the theme by clicking
              the <span className="btn-text">Apply</span> button.
            </p>
          )}
          {user.enableSystemThemeAccess === true && !isOnboarding && (
            <div className="btns-actions">
              <Button
                type="primary"
                className="mb-10"
                size="small"
                onClick={onSortingOpen}
                icon={<SortIcon />}
              >
                Categories
              </Button>
              <Button
                type="primary"
                className="mb-10"
                size="small"
                icon={<PlusFilled />}
                id="add-new-pop"
                onClick={() => setIsOpen(true)}
              >
                Add Category
              </Button>
            </div>
          )}
        </ThemeLibraryHeader>
        <SimpleCard showHeader={false} classes={{ card: '' }}>
          <div className="themes-listing-cards row">
            {user?.enableSystemThemeAccess && !isOnboarding && (
              <div className="themes-listing-card col-holder">
                <ThemeCard
                  create
                  cardText="Create Your Own Theme!"
                  cardIcon={<ImageEdit />}
                  title="Custom Theme"
                  footerIcons={{ edit: false, add: false, delete: false }}
                  onClick={() => {
                    history.push(
                      '/my-profile/theme-editor?systemThemeCreate=true',
                    );
                  }}
                />
              </div>
            )}
            {isOnboarding ? (
              <SortThemeList
                categories={[...categories].sort(
                  (a: any, b: any) => a.onBoardSortOrder - b.onBoardSortOrder,
                )}
                items={[...defaultThemes].sort(
                  (a: any, b: any) => a.onBoardSortOrder - b.onBoardSortOrder,
                )}
                onCardClick={onCardClick}
                handleDelete={handleDelete}
                applySystemTheme={applySystemTheme}
                current={current}
                getThumbnail={getThumbnail}
                isApplying={isApplying}
                isOnboarding={isOnboarding}
              />
            ) : (
              <SortThemeList
                categories={[...categories].sort(
                  (a: any, b: any) => a.sortOrder - b.sortOrder,
                )}
                items={[...defaultThemes].sort(
                  (a: any, b: any) => a.sortOrder - b.sortOrder,
                )}
                onCardClick={onCardClick}
                handleDelete={handleDelete}
                applySystemTheme={applySystemTheme}
                current={current}
                getThumbnail={getThumbnail}
                isApplying={isApplying}
                isOnboarding={isOnboarding}
              />
            )}
          </div>
        </SimpleCard>
      </div>
      <Model
        isOpen={isOpen}
        onClose={() => {
          setCategory('');
          setIsOpen(false);
        }}
        onOk={saveCategory}
      >
        <FocusInput
          materialDesign
          label="Category"
          name="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </Model>
      <CurdModel
        isOpen={isSortingOpen}
        onClose={onSortingClose}
        categories={categories}
        themes={defaultThemes}
      />
    </div>
  );
};

export default styled(ThemeLibrary)`
  .categoryTitle {
    width: 100%;
    display: block;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin: 0 0 30px;
    padding: 0 10px;

    @media (max-width: 767px) {
      margin: 0 0 15px;
      padding: 0 5px;
    }
  }
  .card-item-holder {
    width: 14.28%;
    padding: 0 10px;
    margin: 0 0 20px;

    @media (max-width: 2999px) {
      width: 16.66%;
    }

    @media (max-width: 1499px) {
      width: 20%;
    }

    @media (max-width: 1199px) {
      width: 25%;
    }

    @media (max-width: 1023px) {
      width: 33.333%;
    }

    @media (max-width: 767px) {
      width: 50%;
      padding: 0 5px;
      margin: 0 0 10px;
    }

    @media (max-width: 370px) {
      width: 100%;
    }

    .card-rendering {
      > img {
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: auto;
      }

      .card-rendering-text {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .theme-card {
      height: auto;
      min-height: inherit;
      border-color: #e9e9eb;

      &:hover {
        border-color: #9fa8da;
      }

      &:before {
        display: none;
      }

      .theme-card-image {
        max-height: inherit;
        border: none;
        border-radius: 0;
      }
    }
  }
  .theme-library-header {
    background-color: #eeeff1;
    padding: 15px 80px;
    position: relative;

    h5 {
      text-align: center;
      margin: 0;
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
    }

    .btn-back {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);
      color: #3b719e;

      .img {
        width: 18px;
        height: 18px;
        display: inline-block;
        vertical-align: top;
        margin: 2px 6px 0 0;
      }

      svg {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }
  }

  .card {
    background: none;
    border: none;
  }

  ${(props) => {
    if (!props.isOnboarding) {
      return `
        .theme-card {
          height: 371px;
      
          @media (max-width: 767px) {
            height: 257px;
          }
      
          .theme-card-image {
            max-height: inherit;
            border: 1px solid #e9e9eb;
            border-radius: 6px;
            overflow: hidden;
      
            img {
              width: 100%;
              height: 100%;
              object-position: top;
            }
          }
        }
      `;
    }
    return `
    .themes-listing-card {
      padding: 0 10px;
      margin: 0 0 20px;
  
      @media (max-width: 1023px) {
        width: 249px;
        padding: 0 10px;
        margin: 0 0 20px;
        flex: inherit;
        max-width: inherit;
      }
  
      @media (max-width: 767px) {
        width: 174px;
        padding: 0 5px;
      }
      .theme-card {
        min-height: inherit;
        height: 267px;

        @media (max-width: 1023px) {
          height: 371px;
        }

        @media (max-width: 767px) {
          height: 257px;
        }

        &.create {
          height: 273px;
          min-height: inherit;

          @media (max-width: 1023px) {
            height: 376px;
          }

          @media (max-width: 767px) {
            height: 262px;
          }

          .theme-card-inner {
            &:before {
              display: none;
            }
          }

          h6 {
            font-size: 16px;
            line-height: 20px;
            color: #9fa8da;
          }
        }

        .theme-card-image {
          max-height: inherit;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      }
    `;
  }}
  .category_section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .themes-listing-cards {
    margin: 0 -10px;

    @media (max-width: 767px) {
      margin: 0 -5px;
    }
  }

  .theme-library-content {
    ${({ isOnboarding }) => {
      if (isOnboarding) {
        return `
          background: #fff;
        `;
      }
      return `
        padding: 30px 35px;
        background: #f5f6f8;

        @media (max-width: 1199px) {
          padding: 20px;
        }
    
        @media (max-width: 767px) {
          padding: 15px 10px;
        }
    
        @media (max-width: 480px) {
          // padding: 15px 0;
        }
      `;
    }}

    &__p {
      margin: 0 0 24px;
      color: #655f5f;
      font-size: 15px;
      line-height: 18px;

      @media (max-width: 1199px) {
        margin: 0 0 20px;
      }

      .btn-text {
        min-width: 60px;
        border: 1px solid #e5e5e5;
        display: inline-block;
        vertical-align: middle;
        background: #fff;
        text-align: center;
        padding: 2px 5px;
      }
    }
  }

  .col-holder {
    width: 249px;
    padding: 0 10px;
    margin: 0 0 20px;

    @media (max-width: 767px) {
      width: 174px;
      padding: 0 5px;
    }
  }

  .theme-editor-actions {
    max-width: 570px;
    margin: 0 auto;
  }
`;

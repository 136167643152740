import UploadandEditor from 'components/UploadandEditor';
import React, { useState } from 'react';
import { removeLibraryImage } from '../../../../api/theme';
import { AddImage, Cancel, Chromatic } from '../../../../assets/svgs';
import NButton from '../../../../components/NButton';
import SimpleCard from '../../../../components/SPCards/SimpleCard';
import ThemeCard from './ThemeCard';

interface Props {
  value?: any;
  onChange?: Function;
  name?: string;
  onClose?: any;
  images?: { id: string; url: string }[];
  onUserImageDelete?: Function;
  onUserImageUpload?: Function;
}

type BGEditorSelectedView = 'your-images' | 'image-library';

const BackgroundImageSelector = ({
  value: initialValue,
  onChange,
  name,
  onClose,
  images = [],
  onUserImageDelete,
  onUserImageUpload,
}: Props) => {
  const [selectedView, setSelectedView] = useState<BGEditorSelectedView>(
    'your-images',
  );
  const [imageLibrary, setImageLibrary] = useState([]);

  const onSelectHandler = (url: string) => {
    onChange && onChange(name, url);
    onClose && onClose();
  };

  return (
    <div>
      <SimpleCard
        header={
          <div className="rc-card-title">
            <div className="theme-bg-header-icon">
              <Chromatic />
            </div>
            Background Image
          </div>
        }
        classes={{ header: 'theme-bg-select-header' }}
        extra={
          <div onClick={onClose}>
            <Cancel />
          </div>
        }
      >
        <div className="theme-bg-select-container theme-editor-actions">
          <div className="action">
            <NButton
              type={selectedView === 'your-images' ? 'primary' : undefined}
              onClick={() => {
                setSelectedView('your-images');
              }}
              block
            >
              Your Images
            </NButton>
            <NButton
              type={selectedView === 'image-library' ? 'primary' : undefined}
              onClick={() => {
                setSelectedView('image-library');
              }}
              block
            >
              Image Library
            </NButton>
          </div>
          <div className="theme-bg-cards row mx-n10">
            {selectedView === 'your-images' && (
              <div className="col-sm-4 px-10 mb-20">
                <UploadandEditor
                  accept="image/*"
                  url="/image/library/upload"
                  cropper={false}
                  onSuccess={({ imageURL }: any) => {
                    onSelectHandler(imageURL);
                    onUserImageUpload?.();
                  }}
                  validation={{
                    minWidth: 100,
                    minHeight: 100,
                    maxSize: 1024 * 1024 * 2,
                  }}
                >
                  <ThemeCard
                    create
                    cardText="Upload an Image"
                    cardIcon={<AddImage />}
                    showFooter={false}
                  />
                </UploadandEditor>
              </div>
            )}
            {(selectedView === 'your-images' ? images : imageLibrary).map(
              (image) => (
                <div className="col-sm-4 px-10 mb-20">
                  <ThemeCard
                    image={image.url}
                    showFooter={false}
                    showRemoveIcon={selectedView === 'your-images'}
                    onClick={() => onSelectHandler(image.url)}
                    onDeleteClick={() => {
                      removeLibraryImage(image.id)
                        .then(() => {
                          onUserImageDelete?.(image.id);
                        })
                        .catch(console.log);
                    }}
                  />
                </div>
              ),
            )}
          </div>
        </div>
      </SimpleCard>
    </div>
  );
};

export default BackgroundImageSelector;

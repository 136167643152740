import { orderCreate } from 'api/Order';
import { updateGuestUser } from 'api/User';
import { createOrderWithGuestUser } from 'api/Utils';
import InformationWidget from 'components/InformationWidget';
import Button, { ButtonSkeleton } from 'components/NButton';
import Select from 'components/Select';
import Slider from 'components/slider';
import { ServiceType } from 'enums';
import useAuth from 'hooks/useAuth';
import useDispatch from 'hooks/useDispatch';
import useKeyPressListener from 'hooks/useKeyPressListener';
import { stringify } from 'querystring';
import React, { ReactElement, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useParams } from 'react-router';
import {
  RESET_PURCHASE_STATES,
  SET_GUEST_USER,
  SET_ORDER,
} from 'reducer/purchaseStates';
import styled from 'styled-components';
import { arrayFilter } from 'util/index';
import PaymaForm from '../../../purchase/payma-form';
import PopLiveForm from '../../../purchase/poplive-form';
import ShoutoutForm from '../../../purchase/shoutout-form';
import InfoCard from './Infocard';
interface IServiceProps {
  pop: any;
  ownProfile: boolean;
  onStopPOPupOpen: Function;
  infoCard?: any;
  icon?: ReactElement;
  buttonTitle?: string;
  className?: string;
  skeleton?: boolean;
}

const Generic: React.FC<IServiceProps> = (props) => {
  const {
    pop,
    infoCard,
    buttonTitle,
    ownProfile,
    onStopPOPupOpen,
    icon,
    className,
    skeleton,
  } = props;

  const {
    _id,
    title,
    price,
    description,
    priceVariations = [],
    questions = [],
    additionalArt = [],
    owner,
    popType,
  } = pop || {};

  const { user: authUser, isAuthenticated, loggedIn } = useAuth();
  const history = useHistory();
  const { username } = useParams<{ username: string }>();
  const dispatch = useDispatch();
  const [isloader, setLoader] = useState(false);
  const [variations, setVariations] = useState([]);
  const [selectedV, setSelectedV] = useState<any>({});

  useEffect(() => {
    if (_id) {
      const vs: any = [
        {
          _id,
          title,
          price,
          description,
          product: true,
          isActive: true,
        },
        ...priceVariations,
      ]
        .filter(({ isActive }) => isActive)
        .map((v: any) => {
          return { label: `${v.title}- $${v.price}`, value: v };
        });
      setVariations(vs);
      setSelectedV(vs[0]);
    }
  }, [_id, description, price, priceVariations, title]);

  const handleCreateOrder = async (values: any) => {
    if (ownProfile && onStopPOPupOpen) {
      onStopPOPupOpen();
      return;
    }
    setLoader(true);
    const selected = selectedV.value;
    dispatch({ type: RESET_PURCHASE_STATES });

    const sQuestions = arrayFilter(questions, { isActive: true }).concat(
      arrayFilter(selected?.questios || [], { isActive: true }),
    );

    let requestData: any = {
      questions: sQuestions,
      popId: _id,
      vpopId: selected._id,
      buyer: loggedIn && authUser?._id ? authUser?._id : null,
    };

    if (popType === ServiceType.PAYMA) {
      requestData.paymaQuestion = values.paymaQuestion;
    }

    if (popType === ServiceType.SHOUTOUT) {
      requestData.questions = values.questions;
    }

    if (popType === ServiceType.POPLIVE) {
      requestData.popLiveDateTime = values.dateTime;
    }

    try {
      const response = loggedIn
        ? await orderCreate(requestData)
        : await createOrderWithGuestUser(requestData);

      const { order, guestUser } = response;
      if (!loggedIn) dispatch({ type: SET_GUEST_USER, payload: guestUser });

      dispatch({
        type: SET_ORDER,
        payload: order,
      });

      if (popType === ServiceType.SHOUTOUT) {
        if (
          values.questions?.[0]?.responseValue === 'me' &&
          values.user?.name?.length &&
          !loggedIn
        ) {
          const name = values.user?.name?.replace(/\s{2,}/g, ' ').trim();
          const firstName = name.split(' ').slice(0, -1).join(' ');
          const lastName = name.split(' ').slice(-1).join(' ');

          await updateGuestUser({ firstName, lastName }).then((data) => {
            dispatch({
              type: SET_GUEST_USER,
              payload: { ...guestUser, ...data },
            });
          });
        }
      }

      const prams = stringify({ order: order._id, type: popType });
      return history.push(
        `/${username}/purchase/add-a-card-and-checkout?${prams}`,
      );
    } catch (e) {
      console.log(e);
    }
    setLoader(false);
  };

  useKeyPressListener(handleCreateOrder);

  const {
    title: vTitle,
    description: vDescription,
    _id: vId,
  } = selectedV?.value || {};
  return (
    <>
      <div className={className}>
        <div className="qa-wedget mb-30">
          <div className="title-box w-100">
            {icon && (
              <span className="icon">
                {skeleton ? <Skeleton circle width={40} height={40} /> : icon}
              </span>
            )}
            {skeleton ? (
              <div className="w-100">
                <Skeleton height={40} width="50%" />
              </div>
            ) : (
              <h2 className="text-capitalize">{vTitle || title}</h2>
            )}
            {selectedV?.value?.price > -1 && (
              <span className="price">${selectedV?.value?.price}</span>
            )}
          </div>
        </div>
        {additionalArt.length > 0 && <Slider additionalArt={additionalArt} />}
        {infoCard && (
          <InfoCard {...infoCard} skeleton={skeleton} className="mb-30" />
        )}

        {description?.length > 0 && (
          <InformationWidget icon={false} className="mb-30">
            {description}
          </InformationWidget>
        )}

        {variations.length > 1 && (
          <div className="select-wrap mb-30 pb-0">
            <Select
              options={variations}
              defaultValue={selectedV}
              onChange={(data) => {
                setSelectedV(data);
              }}
              size="large"
            />
          </div>
        )}

        {vDescription?.length > 0 && vId !== _id && (
          <InformationWidget className="mb-30">
            {vDescription}
          </InformationWidget>
        )}

        {popType === ServiceType.PAYMA && (
          <PaymaForm
            submitButton={
              skeleton ? (
                <ButtonSkeleton size="x-large" />
              ) : (
                <Button
                  type="primary"
                  size="x-large"
                  htmlType="submit"
                  block
                  isLoading={isloader}
                >
                  {buttonTitle}
                </Button>
              )
            }
            onSubmit={handleCreateOrder}
          />
        )}

        {popType === ServiceType.SHOUTOUT && (
          <ShoutoutForm
            submitButton={
              skeleton ? (
                <ButtonSkeleton size="x-large" />
              ) : (
                <Button
                  type="primary"
                  size="x-large"
                  htmlType="submit"
                  block
                  isLoading={isloader}
                >
                  {buttonTitle}
                </Button>
              )
            }
            pop={pop}
            onSubmit={handleCreateOrder}
          />
        )}

        {popType === ServiceType.POPLIVE && (
          <PopLiveForm
            submitButton={
              skeleton ? (
                <ButtonSkeleton size="x-large" />
              ) : (
                <Button
                  type="primary"
                  size="x-large"
                  htmlType="submit"
                  block
                  isLoading={isloader}
                >
                  {buttonTitle}
                </Button>
              )
            }
            pop={pop}
            onSubmit={handleCreateOrder}
          />
        )}
      </div>
    </>
  );
};

export default styled(Generic)`
  .icon {
    margin-right: 12px;
    color: #3b719e;
  }
`;

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { updateOrder } from 'api/Order';
import { getUser, updateGuestUser } from 'api/User';
import CardForm from 'components/AddCard/CardForm';
import { AppAlert } from 'components/Model';
import { ServiceType } from 'enums';
import useDispatch from 'hooks/useDispatch';
import useRequestLoader from 'hooks/useRequestLoader';
import useSelector from 'hooks/useSelector';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { SET_GUEST_USER, SET_ORDER } from 'reducer/purchaseStates';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Questions } from 'types';
import * as yup from 'yup';
import { chargeUser, fetchCards } from '../../api/billing';
import Button from '../../components/NButton';
import SignInPopUP from '../../components/SignInPopUp';
import { STRIPE_PUBLIC_KEY } from '../../config';
import useAuth from '../../hooks/useAuth';
import useOpenClose from '../../hooks/useOpenClose';
import { parseQuery } from '../../util';
import GuestContactForm from './component/GuestContactForm';
import PaymentWidget from './component/PaymentWidget';
import UserCards from './component/UserCards';
import DynamicQuestionFrom from './dynamic-flow/QuestionForm';

const apikey = STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(apikey === undefined ? '' : apikey);
type questiontype = Omit<Questions, 'title'> & {
  fieldName?: string;
  index?: number;
  title?: string | React.ReactElement;
  validationSchema?: any;
  validate?: any;
};
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Enter valid email address')
    .required('Enter valid email address'),
  name: yup.string().required('Name is required').trim(),
  phone: yup
    .string()
    .matches(
      /^$|^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      'Invalid Phone Number',
    ),
});

const AddCardAndCheckout = () => {
  const history = useHistory();
  const location = useLocation();
  const { username } = useParams<{ username: string }>();
  const { loggedIn, setUser } = useAuth();
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const { order: orderId } = parseQuery(location.search);
  const order = useSelector((state) => state.purchaseStates?.order);
  const guestUserContext = useSelector(
    (state) => state.purchaseStates?.guestUser,
  );
  const [isOpen, openSignPopUp, closeSignPopUp] = useOpenClose(false);
  const [disableEmail, setdisableEmail] = useState(false);
  const [card, setCard] = useState();
  const [userCards, setUserCards] = useState([]);
  const { withLoader } = useRequestLoader();
  const [guestUser, setGuestUser] = useState({
    name: `${guestUserContext?.data?.firstName || ''} ${
      guestUserContext?.data?.lastName || ''
    }`,
    phone: '',
    email: '',
  });
  const [questions, setQuestions] = useState<any[]>([]);
  const [isQuestionRequired, setIsQuestionRequired] = useState(false);
  const questionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 700);
  }, []);

  const onOpenSignInPop = () => {
    openSignPopUp();
  };

  const onNext = useCallback(
    async (user: any) => {
      if (isQuestionRequired) {
        AppAlert({
          title: 'Incomplete Fields',
          text: 'You must answer all the required questions before booking',
          onConfirm: () => {
            questionsRef?.current &&
              smoothScrollIntoView(questionsRef.current, {
                behavior: 'smooth',
                block: 'start',
                scrollMode: 'if-needed',
              });
          },
        });

        return;
      }
      setloading(true);
      try {
        await updateOrder(orderId as string, { questions });

        if (user?.stripe?.customerId) {
          const res = await chargeUser({ orderId });
          dispatch({
            type: SET_ORDER,
            payload: res?.updatedOrder,
          });
          if (!loggedIn) {
            return history.push(
              `/${username}/purchase/congratulations?order=${orderId}`,
            );
          }
          await getUser(user._id).then((res) => setUser(res));
          history.push(`/my-purchases?order=${orderId}`);
        }
      } catch (e) {
        console.error(e);
      }
      setloading(false);
    },
    [
      isQuestionRequired,
      questions,
      orderId,
      dispatch,
      loggedIn,
      history,
      username,
      setUser,
    ],
  );

  const requestPaymentButton = ({
    isLoading,
    isDisable = false,
    ...props
  }: any) => {
    const platformFee = (order?.orderPlatformFee / 100) * order?.price + 0.3;
    return (
      <div className="d-flex justify-content-center">
        <Button
          type="primary"
          size="x-large"
          block
          isLoading={isLoading || loading}
          disabled={isLoading || loading}
          className="mb-0"
          htmlType="submit"
          ref={ref}
          {...props}
        >
          Book for ${(order?.price + platformFee).toFixed(2)}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    fetchBillingCards();

    handleQuestionChanges(order?.questions || []);
  }, []);

  const fetchBillingCards = async () => {
    // FIXME: In case on guest user there no Stripe Customer ID

    if (loggedIn) {
      const response = await withLoader(fetchCards());
      setUserCards(response?.sources || []);
      setCard(response?.sources.find((item: any) => item.isPrimary));
      return response;
    }
  };

  const onSuccessLoginCB = async (user: any) => {
    setdisableEmail(true);
    ref.current?.click();
    closeSignPopUp();
  };

  const guestUserUpdate = async () => {
    try {
      await validationSchema.validate(guestUser);
      const name = guestUser.name?.replace(/\s{2,}/g, ' ').trim();
      const firstName = name.split(' ').slice(0, -1).join(' ');
      const lastName = name.split(' ').slice(-1).join(' ');
      const gu = await updateGuestUser({ ...guestUser, firstName, lastName });
      dispatch({
        type: SET_GUEST_USER,
        payload: { ...guestUserContext, ...gu },
      });
      return gu;
    } catch (e) {
      throw e;
    }
  };
  const handleQuestionChanges = (questions: questiontype[]) => {
    const isRequired =
      order?.popType !== ServiceType.SHOUTOUT &&
      questions?.some((q) => {
        if (q.isRequired) {
          if (q.responseType === 'file') {
            return q.attachements && q.attachements?.length < 1;
          }
          if (q.responseType === 'selectList') {
            return !(
              q.responseOptions &&
              q.responseOptions?.find((r) => r?.value)?.value
            );
          }
          if (q.responseValue?.trim() === '') {
            return true;
          }
        }

        return false;
      });
    setIsQuestionRequired(!!isRequired);
  };
  return (
    <>
      {!loggedIn && (
        <GuestContactForm
          onGuestInputChange={(prop: string, value: string) => {
            setGuestUser({ ...guestUser, [prop]: value });
          }}
        />
      )}

      {/* Custom Question Logic */}
      {order?.questions?.length > 0 && (
        <DynamicQuestionFrom
          questionsRef={questionsRef}
          questions={order.questions}
          popType={order.popType}
          onChange={(questions) => {
            setQuestions(questions);
            handleQuestionChanges(questions);
          }}
        />
      )}
      {/* Custom Question Logic */}

      <div className="mb-0">
        <PaymentWidget className="mb-30" order={order} seller={order?.seller} />
        <hr className="d-none" />

        {loggedIn && !!userCards?.length ? (
          <UserCards
            userCards={userCards}
            primaryCard={card}
            fetchBillingCards={fetchBillingCards}
            requestPaymentButton={requestPaymentButton}
            onSubmit={onNext}
          />
        ) : (
          <Elements stripe={stripePromise}>
            <CardForm
              totalCards={0}
              functions={{ onSave: onNext }}
              elementsProps={{ Save: requestPaymentButton }}
              visibility={true}
              showEmailField={false}
              disableEmail={disableEmail}
              onOpenSignInPop={onOpenSignInPop}
              updateGuestUser={guestUserUpdate}
              isQuestionRequired={isQuestionRequired}
            />
          </Elements>
        )}
      </div>
      <SignInPopUP
        isOpen={isOpen}
        onClose={closeSignPopUp}
        onSuccessCallback={onSuccessLoginCB}
        email={guestUser.email}
      />
    </>
  );
};

export default AddCardAndCheckout;

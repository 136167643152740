import { Empty } from 'assets/svgs';
import * as React from 'react';
import styled from 'styled-components';

interface IAppProps {
  className?: string;
}

const App: React.FunctionComponent<IAppProps> = ({ className }) => {
  return (
    <div className={className}>
      <div>
        <Empty />
      </div>
      <div>No Data</div>
    </div>
  );
};

export default styled(App)`
  text-align: center;
`;

import { updatePop } from 'api/Pop';
import classNames from 'classnames';
import {
  TimeComponents,
  TimeSlotInterface,
} from 'components/DateRangeSelector/interfaces';
import NewButton from 'components/NButton';
import { default as SwitchBox } from 'components/switchbox';
import { toast } from 'components/toaster';
import { ServiceType, SocialPlatforms } from 'enums';
import { useFormik } from 'formik';
import useDispatch from 'hooks/useDispatch';
import { ILinkEditorProps } from 'interfaces/ILinkEditorProps';
import { default as React, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';
import IPop from 'types/Pop.type';
import * as yup from 'yup';
import {
  Advertise,
  CustomServices,
  DigitalDownload,
  Dollar,
  Download,
  Payma,
  PlusFilled,
  PopLive,
  QuestionMark,
  RecycleBin,
  Shoutout,
  Star,
  UploadBold,
} from '../../assets/svgs';
import { LinkTypeName } from '../../constants';
import useAuth from '../../hooks/useAuth';
import According from '../according';
import Card from '../SPCards';
import UploadandEditor from '../UploadandEditor';
import AdditionalArt from './AdditionalArt';
import AdvertiseComponent from './Advertise';
import CustomQuestions from './CustomQuestionsDragableListing';
import PopLiveSection from './PopLiveSection';
import SimplePopFrom from './simplePopFrom';
import VariationDragableListing from './VariationDragableListing';

const platfromTypeArray: string[] = [
  SocialPlatforms.FACEBOOK,
  SocialPlatforms.INSTAGRAM,
  SocialPlatforms.ONLYFANS,
  SocialPlatforms.TIKTOK,
  SocialPlatforms.TWITTER,
  SocialPlatforms.YOUTUBE,
];
const validationSchema = yup.object().shape({
  title: yup.string().max(255).required('Title is required'),
  price: yup.number().when('popType', {
    is: (value) => {
      return value !== ServiceType.ADVERTISE;
    },
    then: yup
      .number()
      .required('Price should be from $5 to $2500')
      .max(2500, 'Price should be from $5 to $2500')
      .min(5, 'Price should be from $5 to $2500'),
  }),
  description: yup.string().max(500),
});

export const GetPopIcon = ({
  type,
  ...rest
}: {
  type?: ServiceType;
  primaryColor?: string;
  secondaryColor?: string;
}): any => {
  switch (type) {
    case ServiceType.SHOUTOUT:
      return <Shoutout {...rest} />;
    case ServiceType.PAYMA:
      return <Payma {...rest} />;
    case ServiceType.DIGITAL_DOWNLOADS:
      return <DigitalDownload {...rest} />;
    case ServiceType.ADVERTISE:
      return <Advertise {...rest} />;
    case ServiceType.POPLIVE:
      return <PopLive {...rest} />;
    case ServiceType.ADDITIONAL_SERVICES:
      return <CustomServices {...rest} />;
    default:
      return <Star />;
  }
};

const PriceVariationBlackList = [
  ServiceType.DIGITAL_DOWNLOADS,
  ServiceType.ADVERTISE,
  ServiceType.POPLIVE,
];

const getTimeComponents = (item: string) => {
  try {
    const time = item.split(' ');
    const hours = time[0].split(':')[0];
    const minutes = time[0].split(':')[1];
    const type = time[1];
    return {
      hours,
      minutes,
      ampm: type,
    };
  } catch (e) {
    console.error(e);
    return {
      hours: '00',
      minutes: '00',
      ampm: 'AM',
    };
  }
};
const getTimeComponentsString = (item: TimeComponents): string => {
  return `${item.hours}:${item.minutes} ${item.ampm}`;
};
const getMilliFromTimeComponents = (startTime: TimeComponents): Number => {
  return Date.parse(`1/1/1970 ${getTimeComponentsString(startTime)}`);
};
const compareTime = (
  startTime: TimeComponents,
  endTime: TimeComponents,
  slots: TimeSlotInterface[],
  id?: string,
): { isError: boolean; compareWith: object | null } => {
  const milliSecStartTime = getMilliFromTimeComponents(startTime);
  const milliSecEndtime = getMilliFromTimeComponents(endTime);
  if (milliSecStartTime >= milliSecEndtime && startTime.ampm === endTime.ampm) {
    toast.error(
      'Start time is later than end time, please make sure start time comes earlier than the selected end time',
    );
    return { isError: true, compareWith: null };
  }

  for (const slot of slots) {
    if (slot._id && id && slot._id === id) continue;

    const existingStartTime = Date.parse(`1/1/1970 ${slot.startTime}`);
    const existingEndTime = Date.parse(`1/1/1970 ${slot.endTime}`);

    if (
      milliSecStartTime === existingStartTime &&
      milliSecEndtime === existingEndTime
    ) {
      toast.error(
        'Two slots with same times. Please choose appropriate Options.',
      );
      return { isError: true, compareWith: slot };
    } else if (
      milliSecStartTime < existingStartTime &&
      milliSecEndtime > existingEndTime
    ) {
      toast.error(
        `This range is in conflict with ${slot.startTime} -  ${slot.endTime}. Please delete ${slot.startTime} -  ${slot.endTime} if you want to create this new range.`,
      );
      return { isError: true, compareWith: slot };
    } else if (
      (milliSecStartTime > existingStartTime &&
        milliSecStartTime < existingEndTime) ||
      (milliSecEndtime > existingStartTime && milliSecEndtime < existingEndTime)
    ) {
      toast.error(
        'You can not have a time range that overlaps with another time range',
      );
      return { isError: true, compareWith: slot };
    }
  }

  return { isError: false, compareWith: null };
};
const InlinePopForm: React.FC<
  { value: Partial<IPop>; className?: string } & Omit<
    ILinkEditorProps,
    'title' | 'value'
  >
> = ({
  onCancel,
  className,
  value,
  onSubmit,
  options = { status: true, delete: true, close: false },
  onDeleteClick,
  socialLinks: socialMediaLinks,
}) => {
  const { user = {}, setUser } = useAuth();
  const dispatch = useDispatch();

  const form = useFormik<IPop>({
    validationSchema,
    initialValues: {
      _id: '',
      title: '',
      popType: '',
      price: 0,
      description: '',
      popLiveAdditionalPrice: 0,
      popLiveAdditionalTime: 0,
      isActive: false,
      isThumbnailActive: true,
      popThumbnail: undefined,
      additionalArt: [],
      priceVariations: [],
      questions: [],
      digitalDownloads: [],
      socialMediaSorting: [],
      weeklyHours: [],
      timeIntervalBetweenEvents: 5,
      pricePerAdditionalFiveMinutes: 5,
      duration: 15,
      monEnabled: false,
      tueEnabled: false,
      thuEnabled: false,
      wedEnabled: false,
      friEnabled: false,
      satEnabled: false,
      sunEnabled: false,
    },
    // validate: (values) => {

    // },
    onSubmit: async (values) => {
      //Deleting some value before sending
      delete values.popName;

      //filter out the failed uploads
      const newAdditionalArts = values.additionalArt?.filter(
        ({ failed, showProgressBar }: any) => !failed && !showProgressBar,
      );
      const newdigitalDownloads = values.digitalDownloads?.filter(
        ({ failed, showProgressBar }: any) => !failed && !showProgressBar,
      );

      const inprocessAA = values.additionalArt?.filter(
        ({ showProgressBar }: any) => showProgressBar,
      ).length;
      const inprocessDD = values.digitalDownloads?.filter(
        ({ showProgressBar }: any) => showProgressBar,
      ).length;

      if (inprocessAA || inprocessDD) {
        const res = await swal({
          title: 'File are getting uploaded',
          text: 'Some of the files are still in progress do you want to continue without them?',
          icon: 'error',
          buttons: ['No', 'Yes'],
        });
        if (!res) return;
      }

      const sumbitValues = {
        ...values,
        additionalArt: newAdditionalArts,
        digitalDownloads: newdigitalDownloads,
      };

      onSubmit &&
        (await onSubmit(sumbitValues)
          .then(() => {
            onCancel && onCancel();
          })
          .catch(console.log));
    },
  });
  const checkOverLappingDates = useCallback((form: any) => {
    const { setFieldValue, setValues, values: formValues } = form;
    const { weeklyHours } = formValues;
    const daysObj: Record<string, any> = {
      sat: [],
      sun: [],
      mon: [],
      fri: [],
      thu: [],
      wed: [],
      tue: [],
    };
    weeklyHours.forEach((a: Record<string, any>) => {
      const day = a.dayOfTheWeek.toLowerCase();
      a.isError = false;
      daysObj[day].push(a);
    });

    let isError: {
      isError: boolean;
      compareWith: Record<string, any> | null;
    } = { isError: false, compareWith: null };
    let i = 0;
    const slotsArray = Object.keys(daysObj);
    while (i < slotsArray.length) {
      const comparingSlots = daysObj[slotsArray[i]];
      if (isError.isError) {
        return isError.isError;
      }
      if (comparingSlots?.length) {
        let j = 0;
        let comLength = [...comparingSlots].length;
        while (j < comLength) {
          const s = comparingSlots[j];
          isError = compareTime(
            getTimeComponents(s.startTime),
            getTimeComponents(s.endTime),
            comparingSlots.slice(j + 1),
            s?._id,
          );
          if (isError.isError) {
            const { compareWith } = isError;
            const st = s.dayOfTheWeek.toLowerCase();
            const stDaySlots = daysObj[st];
            stDaySlots.forEach((a: any) => {
              if (a.startTime === s.startTime && a.endTime === s.endTime) {
                a.isError = true;
              }
            });
            if (compareWith) {
              const { dayOfTheWeek, startTime, endTime } = compareWith;
              const se = dayOfTheWeek.toLowerCase();
              const seDaySlots = daysObj[se];
              seDaySlots.forEach((a: any) => {
                if (a.startTime === startTime && a.endTime === endTime) {
                  a.isError = true;
                }
              });
              setValues({
                ...formValues,
                [st]: stDaySlots,
                [se]: seDaySlots,
              } as any);
            } else {
              setFieldValue(st, stDaySlots);
            }

            return isError.isError;
          }
          j++;
        }
      }

      i++;
    }
    return isError.isError;
  }, []);

  const { values, setValues, setFieldValue } = form;
  useEffect(() => {
    const {
      _id,
      title,
      popType,
      price = 0,
      description,
      isActive = false,
      popThumbnail,
      isThumbnailActive = true,
      additionalArt = [],
      priceVariations = [],
      questions = [],
      digitalDownloads = [],
      socialMediaSorting = [],
      popLiveAdditionalPrice = 0,
      popLiveAdditionalTime = 0,
      duration = 15,
      timeIntervalBetweenEvents = 5,
      monEnabled = false,
      tueEnabled = false,
      thuEnabled = false,
      wedEnabled = false,
      friEnabled = false,
      satEnabled = false,
      sunEnabled = false,
      weeklyHours = [],
      timeZone,
      pricePerAdditionalFiveMinutes = 5,
    } = value;

    setValues((v) => {
      return {
        ...v,
        _id,
        title,
        popType,
        price,
        description,
        isActive,
        popThumbnail,
        isThumbnailActive,
        additionalArt,
        questions,
        digitalDownloads,
        priceVariations,
        popLiveAdditionalPrice,
        popLiveAdditionalTime,
        duration,
        timeIntervalBetweenEvents,
        monEnabled,
        tueEnabled,
        thuEnabled,
        wedEnabled,
        friEnabled,
        satEnabled,
        sunEnabled,
        weeklyHours,
        timeZone,
        pricePerAdditionalFiveMinutes,
        socialMediaSorting: Array.from(
          new Set([...socialMediaSorting, ...platfromTypeArray]),
        ),
      };
    });
  }, []);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const pushIntoValueArray = async (name: keyof typeof values, value: any) => {
    setValues((v) => {
      const oldarray: any = v[name];
      const newArray = [...oldarray, value];
      return { ...v, [name]: newArray };
    });
  };

  const updateNestedArrayElement = async (
    name: keyof typeof values,
    id: string,
    value: any,
  ) => {
    setValues((vls) => {
      const oldarray: any = vls[name];
      const indexof: any = oldarray.findIndex((el: any) => el.id === id);
      if (indexof === -1) return vls;
      oldarray[indexof] = { ...oldarray[indexof], ...value };
      return { ...vls, [name]: oldarray };
    });
  };

  const onChangeHander = (name: string, value: any) => {
    setFieldValue(name, value);
  };

  const onChangeHandlerandSave = (name: string, value: any) => {
    if (values._id) {
      let isActivePop = values.isActive;
      if (values.popType === ServiceType.ADVERTISE) {
        if (Array.isArray(value) && values.isActive) {
          isActivePop = !!value.find((v) => v.isActive);
          setFieldValue('isActive', isActivePop);
        } else if (name === 'isActive') {
          const priceV = values.priceVariations;
          const isAllowToactivate = !!priceV?.find((p) => p.isActive);
          if (!isAllowToactivate) {
            toast.info('Please activate atleast 1 variation');
            return;
          }
          isActivePop = value;
        }
      }
      let data = value;
      if (name === 'additionalArt') {
        data = value.filter(
          ({ failed, showProgressBar }: any) => !failed && !showProgressBar,
        );
      }
      saveValueRealTime({
        isActive: isActivePop,
        [name]: data,
        _id: values._id,
      });
      setUser({
        ...user,
        links: user?.links?.map((pop: any) => {
          return pop?.popLinksId && pop?.popLinksId?._id === values._id
            ? {
                ...pop,
                isActive: isActivePop,
                popLinksId: {
                  ...pop.popLinksId,
                  isActive: isActivePop,
                  [name]: value,
                },
              }
            : pop;
        }),
      });
    }
    setFieldValue(name, value);
  };

  const saveValueRealTime = useCallback(async (values) => {
    // return await debounce(async (values: any) => {
    return await updatePop(values, values._id).then((res) => console.log(res));
    // }, 500)();
  }, []);

  const additionalArtOnSuccess = useCallback(
    (file) => {
      new Promise((res, rej) => {
        setValues((prev) => {
          res(prev.additionalArt);
          return prev;
        });
      }).then((additionalArts) => {
        let oldarray: any = additionalArts;
        const indexof: any = oldarray.findIndex((el: any) => el.id === file.id);
        if (indexof === -1) {
          oldarray.push({
            artName: file.name,
            artPath: file.url,
            artType: file.type,
            showProgressBar: false,
            isActive: true,
            ...file,
          });
        } else {
          oldarray[indexof] = {
            ...oldarray[indexof],
            ...{
              artName: file.name,
              artPath: file.url,
              artType: file.type,
              showProgressBar: false,
              isActive: true,
              ...file,
            },
          };
        }
        onChangeHandlerandSave('additionalArt', oldarray);
      });
    },
    [values],
  );

  const {
    popType,
    priceVariations,
    additionalArt,
    questions,
    digitalDownloads,
    socialMediaSorting,
  } = values;

  if (!popType) return null;

  const { handleChange, handleSubmit, isSubmitting } = form;

  const { status: showStatus, close: showClose, delete: showDelete } = options;

  const preSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    const isErrorExist = checkOverLappingDates(form);
    if (!isErrorExist) {
      handleSubmit(e);
    }
  };
  const getSubtitle = (popType: string) => {
    switch (popType) {
      case 'shoutout':
        return LinkTypeName[popType as keyof typeof LinkTypeName];

      case ServiceType.ADVERTISE:
        return 'advertising';
      default:
        return popType;
    }
  };

  return (
    <div className={className}>
      <Card
        title={LinkTypeName[popType as keyof typeof LinkTypeName]}
        subtitle={getSubtitle(popType)}
        onCancel={handleCancel}
        icon={<GetPopIcon type={popType as ServiceType} />}
        classes={{
          card: classNames({ [`pop-${popType}`]: popType }),
        }}
        showClose={showClose}
        extra={
          <div>
            {showStatus && (
              <SwitchBox
                value={values.isActive}
                onChange={(e: any) => {
                  onChangeHandlerandSave('isActive', e.target.checked);
                }}
                name="isActive"
                status={false}
                size="small"
              />
            )}
            {showDelete && (
              <span>
                <NewButton
                  icon={<RecycleBin />}
                  outline
                  onClick={onDeleteClick}
                />
              </span>
            )}
          </div>
        }
        onSave={preSubmit}
        isloading={isSubmitting}
      >
        <div className="form">
          <SimplePopFrom
            form={form}
            cbonCancel={onCancel}
            placeholder={LinkTypeName[popType as keyof typeof LinkTypeName]}
          />
        </div>
        <div className="services_container_wrapper">
          {popType === ServiceType.ADDITIONAL_SERVICES ||
            (popType === ServiceType.POPLIVE && (
              <div className="price_variation_weidgt">
                <According
                  classes={{ according: 'simple-according' }}
                  title="Related Questions"
                  Svg={QuestionMark}
                >
                  <div className="subtitle_according">
                    Ask your customers the questions you need to complete the
                    custom service you are offering them.
                  </div>
                  <CustomQuestions
                    value={questions!}
                    onChange={onChangeHander.bind(undefined, 'questions')}
                  />
                </According>
              </div>
            ))}
          {popType === ServiceType.DIGITAL_DOWNLOADS && (
            <div className="addition__art">
              <According
                classes={{ according: ' simple-according' }}
                title="Digital Download Files"
                Svg={Download}
              >
                <div className="subtitle_according">
                  Add the files you’re providing for this service. .
                </div>
                <AdditionalArt
                  value={digitalDownloads!}
                  onChange={onChangeHander.bind(undefined, 'digitalDownloads')}
                />
                <UploadandEditor
                  accept={['image/*', 'video/*']}
                  onStartUploading={(file, handleCancel) => {
                    pushIntoValueArray('digitalDownloads', {
                      showProgressBar: true,
                      isActive: true,
                      cbOnCancel: handleCancel,
                      ...file,
                    });
                  }}
                  onProgress={(file) => {
                    updateNestedArrayElement('digitalDownloads', file.id, {
                      showProgressBar: true,
                      ...file,
                    });
                  }}
                  onSuccess={(file) => {
                    updateNestedArrayElement('digitalDownloads', file.id, {
                      showProgressBar: false,
                      path: file.url,
                      ...file,
                    });
                  }}
                  onFail={(file) => {
                    updateNestedArrayElement('digitalDownloads', file.id, {
                      failed: true,
                      ...file,
                    });
                  }}
                  // maxFiles={5}
                  validation={{ maxSize: 100 * 1024 * 1024 }} //100MB
                  url={'/image/upload'}
                  cropper={false}
                >
                  <NewButton type="primary" block>
                    <span className="custom-icon">
                      <UploadBold />
                    </span>
                    Add a Digital Download File
                  </NewButton>
                </UploadandEditor>
              </According>
            </div>
          )}
          {popType === ServiceType.POPLIVE && (
            <PopLiveSection
              value={values}
              onChange={(v) => {
                setValues((val) => {
                  return Object.assign(val, { ...v });
                });
              }}
            />
          )}
        </div>
      </Card>
      {popType === ServiceType.ADVERTISE && (
        <AdvertiseComponent
          onChange={(name: string, value: any) => {
            onChangeHandlerandSave(name, value);
          }}
          socialMediaLinks={socialMediaLinks}
          priceVariations={priceVariations || []}
          setField={onChangeHander}
          socialMediaSorting={socialMediaSorting}
        />
      )}
      <According
        classes={{ according: ' simple-according' }}
        title="Promotional Media"
        Svg={UploadBold}
      >
        <div className="subtitle_according">
          Upload images or videos to share more about your service. Drag and
          drop the list items to sort the order they will be displayed.
        </div>
        <UploadandEditor
          accept={['image/*', 'video/*']}
          onStartUploading={(file, handleCancel) => {
            pushIntoValueArray('additionalArt', {
              artName: file.name,
              artPath: file.url,
              artType: file.type,
              showProgressBar: true,
              isActive: true,
              cbOnCancel: handleCancel,
              ...file,
            });
          }}
          onProgress={(file) => {
            updateNestedArrayElement('additionalArt', file.id, {
              artName: file.name,
              artPath: file.url,
              artType: file.type,
              showProgressBar: true,
              ...file,
            });
          }}
          onSuccess={additionalArtOnSuccess}
          onFail={(file) => {
            updateNestedArrayElement('additionalArt', file.id, {
              failed: true,
              ...file,
            });
          }}
          url={'/image/upload'}
          cropper
        >
          <div>
            <NewButton
              type="primary"
              icon={<PlusFilled />}
              block
              className="mb-20"
            >
              <span className="pl-10">Add Promotional Media</span>
            </NewButton>
          </div>
        </UploadandEditor>
        <div className="addition__art">
          <AdditionalArt
            value={additionalArt!}
            onChange={onChangeHandlerandSave.bind(undefined, 'additionalArt')}
            binding={{ name: 'artName', path: 'artPath', type: 'artType' }}
          />
        </div>
      </According>
      {popType && !PriceVariationBlackList.includes(popType as ServiceType) && (
        <According
          classes={{ according: 'simple-according' }}
          title="Pricing Variations"
          Svg={Dollar}
        >
          <div>
            <div className="subtitle_according">
              Price variants are an advanced option that allow you to charge
              different amounts depending on what you are providing to your fan.
            </div>
            <div className="price--variation">
              <VariationDragableListing
                addVariationbtnTitle="New Price Variation"
                value={priceVariations || []}
                onChange={onChangeHandlerandSave.bind(
                  undefined,
                  'priceVariations',
                )}
                type="simple"
              />
            </div>
          </div>
        </According>
      )}
    </div>
  );
};

export default styled(InlinePopForm)`
  .simple-according {
    .indicator {
      @media (max-width: 767px) {
        right: 15px;
      }
    }

    .sp__card {
      &.paid-promotion {
        margin-left: -20px;
        margin-right: -20px;

        @media (max-width: 767px) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .__add {
      margin: 0 0 20px;
    }

    .rc-header {
      background: #f5f5f6;
      border-top: 1px solid #ededf1;
      border-bottom: 1px solid #ededf1;

      .title {
        // color: #005a5a;
      }
    }

    .subtitle_according {
      font-size: 14px;
      line-height: 18px;
      color: rgba(#000, 0.6);
      margin: 0 0 20px;
    }

    .extra {
      position: static;
    }

    .content {
      padding: 20px 40px;

      @media (max-width: 767px) {
        padding: 20px 15px;
      }
    }
  }

  .schedule-block {
    margin: 0;
    > .addition__art {
      &.shoutout-block {
        padding: 0;
      }
    }

    .shoutout-block {
      .rc-card-header {
        margin: 0;
        padding: 15px 42px;

        @media (max-width: 767px) {
          padding: 15px 17px;
        }
      }

      .shoutout-block__body-area {
        padding: 30px 42px;
        position: relative;
        background: #fafbfc;

        @media (max-width: 767px) {
          padding: 30px 17px;
        }
      }

      .dashed-line {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
      }
    }
  }
`;

import attrAccept from 'attr-accept';
import React from 'react';
import Slider, { Settings } from 'react-slick';
import { IAdditionalArt } from '../../types';

const PrevArrow = ({ onClick }: any) => (
  <button className="slick-arrow slick-prev" onClick={onClick}>
    <span className="icon-keyboard_arrow_left"></span>
  </button>
);
const NextArrow = ({ onClick }: any) => (
  <button className="slick-arrow slick-next" onClick={onClick}>
    <span className="icon-keyboard_arrow_right"></span>
  </button>
);

const defaultSettings: Settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const Item = ({ additionalArt }: { additionalArt: IAdditionalArt[] }) => {
  return additionalArt.map(({ artName, artType, artPath }, index) => {
    if (attrAccept({ name: artName, type: artType }, 'video/*')) {
      return (
        <div key={`${index}`} className="slide">
          <div className="video-holder">
            <video controls width="100%" height="100%">
              <source src={artPath} type="video/mp4" />
            </video>
            <img src="/assets/images/img-slide.jpg" alt="img description" />
          </div>
        </div>
      );
    }
    return (
      <div key={`${index}`} className="slide">
        <div className="video-holder">
          <img src={artPath} alt="img description" />
        </div>
      </div>
    );
  });
};
const index: React.FC<{ settings?: Settings; additionalArt?: [] }> = ({
  settings,
  additionalArt = [],
}) => {
  if (!additionalArt.length) return <></>;
  return (
    <Slider
      {...{ ...defaultSettings, ...settings }}
      className="slideshow mb-25"
    >
      {Item({ additionalArt })}
    </Slider>
  );
};

export default index;

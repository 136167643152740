import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../components/button';
import { IInviteSlideProps } from '../../../interfaces/IInvitation';

const Slide7: React.FC<IInviteSlideProps> = ({
  sliderNumber,
  next,
  className,
  user,
}) => {
  const history = useHistory();
  return (
    <div className={className}>
      <div className="profile--info mb-15">
        <h1>You are one click away..</h1>
        <h6>
          Your Pop Page has been set up and is ready to go. Simply click the
          link below to activate your profile and to start growing your social
          revenue today!
        </h6>

        <Button
          onClick={() =>
            history.push(`/${user?.username}?hash=${user?.invitationHash}`)
          }
          classNames={`my-btn-${sliderNumber}`}
        >
          SHOW ME TO MY PAGE!
        </Button>
      </div>
      <div className="image-holder">
        <figure className="profile-snap">
          <img
            src="/assets/images/invitation/Invite-Mobile-7.png"
            alt="profile"
            width="100%"
          />
        </figure>
      </div>
    </div>
  );
};

export default styled(Slide7)`
  .image-holder {
    margin: 0 -1.6vh !important;
    border-radius: 2vh;
    overflow: hidden;
  }

  .image-holder img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }

  ${({ sliderNumber }) => {
    return `
  .my-btn-${sliderNumber}:hover, .my-btn-${sliderNumber}:focus {
    background: #b81663 !important;
    border-color: #b81663 !important;
  }
  `;
  }}
`;

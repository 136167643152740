import * as React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button';
import { IInviteSlideProps } from '../../../interfaces/IInvitation';

const Slide1: React.FC<IInviteSlideProps> = ({
  sliderNumber,
  next,
  className,
  user,
}) => {
  return (
    <div className={className}>
      <div className="profile--info mb-20">
        <h1>
          <span className="font-light">Hi </span> {user?.firstName}{' '}
          {user?.lastName}!
        </h1>
        <h6>We would like to invite you to be one of the first skaterdudes</h6>
      </div>
      <div className="image-holder">
        <figure>
          <img
            src="/assets/images/invitation/Invite-PC1.png"
            alt="profile"
            width="100%"
          />
        </figure>
      </div>
      <Button onClick={next} classNames="my-btn">
        START THE TOUR
      </Button>
    </div>
  );
};

export default styled(Slide1)`
  .image-holder {
    max-width: 28.5vh;
  }

  .my-btn:hover,
  .my-btn:focus {
    background: #b81663 !important;
    border-color: #b81663 !important;
  }
`;

import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link, Redirect, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import IUser from 'types/user';
import { parseQuery } from 'util/index';
import Invitation from '../../layout/invitation';
import Slide1 from './components/slide1';
import Slide2 from './components/slide2';
import Slide3 from './components/slide3';
import Slide4 from './components/slide4';
import Slide5 from './components/slide5';
import Slide6 from './components/slide6';
import Slide7 from './components/slide7';

interface IArrowProps {
  onClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

const PrevArrow: React.FC<IArrowProps> = ({ onClick }) => (
  <button className="slick-arrow slick-prev" onClick={onClick}>
    <span className="icon-keyboard_arrow_left"></span>
  </button>
);
const NextArrow: React.FC<IArrowProps> = ({ onClick }) => (
  <button className="slick-arrow slick-next" onClick={onClick}>
    <span className="icon-keyboard_arrow_right"></span>
  </button>
);

const slides = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide6, Slide7];

const getStyles = (slideNumber: number) => {
  switch (slideNumber) {
    case 0:
    case 6:
      return { background: '#000' };
    case 1:
      return { background: '#54a9e2' };
    case 2:
      return { background: '#0289bb' };
    case 3:
      return { background: '#00658a' };
    case 4:
    case 5:
      return { color: '#000' };
    default:
      return {};
  }
};

const CSlider: React.FC<{ user: IUser }> = ({ user }) => {
  const slider = React.useRef<any>();
  const Next = () => {
    slider.current?.slickNext();
  };
  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 2000,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider className="mobile-slideshow" {...settings} ref={slider}>
      {slides.map((Slide, index) => {
        return (
          <div>
            <div
              className={`invitation-frame text-center bg-frame-${index}`}
              style={getStyles(index)}
            >
              <Slide next={Next} key={index} sliderNumber={index} user={user} />
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
const App: React.FC<{ user: IUser }> = ({ user }) => {
  const { hash } = parseQuery(useLocation().search);
  return isMobileOnly ? (
    <Redirect to={`/invite/welcome?hash=${hash}`} />
  ) : (
    <Invitation>
      <div className="invitation-area">
        <div className="invitation-holder">
          <div className="invitation-wrap">
            <span className="img-overlay">
              <img src="/assets/images/iphone-banner.png" alt="iPhone Banner" />
            </span>
            <img src="/assets/images/img-front-cover.png" alt="Front Cover" />
            <CSlider user={user} />
          </div>
          <div className="text-center">
            <Link
              to={`/${user?.username}?hash=${user?.invitationHash}`}
              className="btn btn-primary btn-page"
            >
              TAKE ME TO MY PAGE!
            </Link>
          </div>
        </div>
      </div>
    </Invitation>
  );
};

export default App;

import { LogoUpdated } from 'assets/svgs';
import * as React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button';
import { IInviteSlideProps } from '../../../../interfaces/IInvitation';

const Slide1: React.FC<IInviteSlideProps & { gotoSignUp: Function }> = ({
  sliderNumber,
  next,
  className,
  user,
}) => {
  return (
    <div className={className}>
      <div className="profile-image">
        {user?.profileImage && <img src={user?.profileImage} alt="" />}
      </div>
      <div className="profile--info mb-20">
        <h1>{user?.firstName}</h1>
        <h2>Has given you access to the</h2>
        <div className="logo-holder">
          <LogoUpdated />
        </div>
        <h6>Inner Circle</h6>
        <Button onClick={next} classNames="my-btn">
          START HERE
        </Button>
      </div>
      <div className="video-holder">
        <video
          style={{ width: '100%' }}
          poster="/assets/images/inner-slide1.jpg"
          autoPlay
          muted
          loop
          playsInline
          preload="none"
        >
          <source
            src="https://d1i1apkb1v314l.cloudfront.net/vid-pops/inner-circle/01_background.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default styled(Slide1)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  padding: 85px 0 0;

  .video-holder {
    position: absolute;
    left: -14px;
    right: -14px;
    top: 0;
    bottom: 0;
  }

  .profile-image {
    position: relative;
    z-index: 3;
    width: 130px;
    height: 130px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .profile--info {
    position: relative;
    z-index: 3;
  }

  h2 {
    font-size: 15px;
    margin: 0 0 10px;
  }

  .logo-holder {
    width: 150px;
    margin: 0 auto;

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  video {
    + img {
      opacity: 0;
      visibility: hidden;
    }
  }

  .my-btn:hover,
  .my-btn:focus {
    background: #1f295b !important;
    border-color: #1f295b !important;
  }
`;

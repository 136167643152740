import { Edit, PlusFilled } from 'assets/svgs';
import NewButton from 'components/NButton';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ProfileType } from 'types';
import OldProfile from './index';

interface Props {
  className?: string;
  type: ProfileType;
}

const getHeaderData = (type: ProfileType) => {
  switch (type) {
    case 'links':
      return {
        title: 'Manage Links',
        buttonText: 'New Link',
      };
    case 'services':
      return {
        title: 'Manage Services',
        buttonText: 'New Service',
      };
    default:
      return {
        title: '',
        buttonText: '',
      };
  }
};

const ProfileWrapper: React.FC<Props> = ({ className, type }) => {
  const { title, buttonText } = getHeaderData(type);

  return (
    <div className={className}>
      <div className="block-head">
        <div className="block-head__wrap">
          <h5>{title}</h5>
        </div>
        {type === 'links' && (
          <div className="caption">
            <p>
              Drag and drop the items to manage the order they appear on your
              page. Click
              <span className="btn-icon edit">
                <Edit />
              </span>
              button to modify each item individually.
            </p>
          </div>
        )}
        {type === 'services' && (
          <div className="caption">
            Manage the services that you offer your fans and followers. Drag and
            <p>
              drop the items to manage the order they appear on your page. Click
              the
              <span className="btn-icon edit">
                <Edit />
              </span>
              edit button to modify each service individually.
            </p>
          </div>
        )}
        {type !== 'services' && (
          <Link to={`/my-profile/pop-list?type=${type}`}>
            <NewButton
              type={type === 'links' ? 'primary' : 'secondary'}
              className="mt-10"
              size="middle"
              block
              icon={<PlusFilled />}
              id="add-new-pop"
            >
              {buttonText}
            </NewButton>
          </Link>
        )}
      </div>
      <OldProfile type={type} />
    </div>
  );
};

export default styled(ProfileWrapper)`
  padding: 24px 18px;

  .button.button-secondary {
    background: #255b87;

    &:hover {
      background: #9fa8da;
    }
  }
`;

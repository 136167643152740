import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../theme/logo';

const ComingSoon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={className}>
      <div className="wrap">
        <div className="box">
          <div className="logo-holder">
            <Logo primaryColor="255b87" secondaryColor="000000" />
          </div>
          <hr />
          <div className="text-holder">
            <Link to="/coming-soon/registration">Click Here</Link> to get on the
            list
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default styled(ComingSoon)`
  min-height: 100vh;
  display: flex;

  .wrap {
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .box {
    width: 100%;
    max-width: 550px;
    padding: 20px 15px;
  }

  .logo-holder {
    max-width: 441px;
    width: 100%;
    margin: 0 auto 41px;

    @media (max-width: 767px) {
      max-width: 280px;
      margin: 0 auto 20px;
    }

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  hr {
    display: block;
    margin: 0;
    height: 1px;
    background: #e7e6e6;
    border: none;
  }

  .text-holder {
    font-size: 30px;
    line-height: 1;
    color: #000;
    text-align: center;
    padding: 43px 0;
    font-weight: 500;

    @media (max-width: 767px) {
      padding: 20px 0;
      font-size: 20px;
      line-height: 1.2;
    }

    a {
      color: #3b4caa;
      text-decoration: underline;
      transition: all 0.4s ease;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

import * as React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button';
import { IInviteSlideProps } from '../../../interfaces/IInvitation';

const Slide6: React.FC<IInviteSlideProps> = ({
  sliderNumber,
  next,
  className,
}) => {
  return (
    <div className={className}>
      <div className="profile--info mb-5">
        <h1>Keep 90% of all sales from your shoutouts!</h1>
      </div>
      <div className="image-holder">
        <figure className="profile-snap mb-0">
          <img
            src="/assets/images/invitation/Invite-Mobile-6.png"
            alt="profile"
            width="100%"
          />
        </figure>
      </div>
      <div className="profile--info mb-10">
        <h6>
          Your Pop Page is always free for you to use, and you will keep 90% of
          the sales from your shout-outs and paid fan mail.
        </h6>
      </div>

      <Button onClick={next}>NEXT</Button>
    </div>
  );
};

export default styled(Slide6)`
  .image-holder {
    margin: 0 -1.6vh !important;
  }

  .image-holder img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`;

import { Question, Star } from 'assets/svgs';
import { ServiceType } from 'enums';
import useAuth from 'hooks/useAuth';
import UsePageView from 'hooks/UsePageView';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Questions } from 'types';
import { getPopByName } from '../../../api/Pop';
import Model from '../../../components/Model';
import useOpenClose from '../../../hooks/useOpenClose';
import Advertise from './components/advertise';
import Generic from './components/generic';

const shoutoutQuestion: Questions[] = [
  {
    title: 'Who is this Custom Video for?',
    text: 'Is this Custom Video for you or someone else?',
    responseType: 'radioOptions',
    responseOptions: [
      { key: 'someone_else', value: true, text: 'Someone Else' },
      { key: 'me', value: false, text: 'Myself' },
    ],
    responseValue: 'someone_else',
    isRequired: true,
    questionHint: 'Is this Custom Video for you?',
    isActive: true,
  },
  {
    title: 'Who is this Custom Video for?',
    text: '',
    responseType: 'text',
    responseValue: '',
    isRequired: true,
    questionHint: 'The person this Custom Video is for.',
    isActive: true,
  },
  {
    title: 'What do you want me to say to them ?',
    text: 'Shoutout Instructions',
    responseType: 'bigText',
    responseValue: '',
    isRequired: true,
    isActive: true,
    questionHint:
      'Try to be as specific as possible with your request such as your relationship to the recipient, numbers and details.',
  },
];

const PublicServices: React.FC<{ user?: any }> = ({ user: publicUser }) => {
  const history = useHistory();
  const { user, loggedIn } = useAuth();
  const { username, popslug } = useParams<{
    username: string;
    popslug: string;
  }>();
  const [stopPOPup, onStopPOPupOpen, onStopPOPupClose] = useOpenClose();

  const [pop, setPop] = useState<any>({});
  const [ownProfile, setOwnProfile] = useState<boolean>(false);

  UsePageView(publicUser, pop);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 700);
  }, []);

  useEffect(() => {
    if (!!username && !!popslug) {
      getPopData();
    }
  }, []);

  const getPopData = async () => {
    const popData = await getPopByName(username, popslug).catch((e: Error) =>
      console.log(e),
    );

    if (!popData) {
      return history.replace('/404');
    }

    if (!popData.owner?.isActiveProfile) {
      if (user?._id !== popData.owner?._id) {
        history.replace('/');
      }
    }

    // check if the user is need this own profile
    if (loggedIn && user?._id === popData.owner?._id) {
      setOwnProfile(true);
    }

    if (popData?.owner) {
      popData.owner.themeName = popData.owner.themeColor?.colorSetName
        .toLowerCase()
        .replace(' ', '-');
      popData.owner.themeNumber = 1;
      if (popData.owner.themeName === 'purple-haze')
        popData.owner.themeNumber = 2;
      else if (popData.owner.themeName === 'sedona')
        popData.owner.themeNumber = 3;
    }

    setPop(popData);
  };

  const GetPop = useMemo(() => {
    switch (pop.popType) {
      case ServiceType.PAYMA:
        return (
          <Generic
            pop={pop}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
            buttonTitle="Submit Question"
            // infoCard={{
            //   title: 'How does a PayMA Work?',
            //   items: [
            //     {
            //       icon: '/assets/images/svg/send.svg',
            //       label: 'Send your question',
            //     },
            //     {
            //       icon: '/assets/images/svg/message.svg',
            //       label: 'Get your reply',
            //     },
            //     {
            //       icon: '/assets/images/svg/thumbsup.svg',
            //       label: 'Pass through',
            //     },
            //   ],
            // }}
            icon={<Question />}
          />
        );
      case ServiceType.POPLIVE:
        return (
          <Generic
            pop={pop}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
            buttonTitle="Book it"
            infoCard={{
              title: 'How does it Work?',
              items: [
                {
                  icon: '/assets/images/svg/calendar-icon.svg',
                  label: 'Select a date',
                },
                {
                  icon: '/assets/images/svg/clock-icon.svg',
                  label: 'Choose the time',
                },
                {
                  icon: '/assets/images/svg/book-video-icon.svg',
                  label: 'Book Your Video Call',
                },
              ],
            }}
          />
        );
      case ServiceType.SHOUTOUT:
        return (
          <Generic
            pop={{ ...pop, questions: shoutoutQuestion }}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
            buttonTitle="Order Custom Video"
            icon={<Star />}
            infoCard={{
              title: 'How does Custom Video Work?',
              items: [
                {
                  icon: '/assets/images/svg/forward.svg',
                  label: 'Send your Request',
                },
                {
                  icon: '/assets/images/svg/video-clip.svg',
                  label: 'Get your video',
                },
                {
                  icon: '/assets/images/svg/thumbsup.svg',
                  label: 'Share and enjoy',
                },
              ],
            }}
          />
        );
      case ServiceType.DIGITAL_DOWNLOADS:
        return (
          <Generic
            pop={pop}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
            buttonTitle="Ask Anything"
            infoCard={{
              title: 'How does a Digital Download Work?',
              items: [
                {
                  icon: '/assets/images/svg/ok.svg',
                  label: 'Choose your options',
                },
                {
                  icon: '/assets/images/svg/card.svg',
                  label: 'Complete payment',
                },
                {
                  icon: '/assets/images/svg/book-video-icon.svg',
                  label: 'Download your file',
                },
              ],
            }}
          />
        );
      case ServiceType.ADDITIONAL_SERVICES:
        return (
          <Generic
            pop={pop}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
            buttonTitle="Order Service"
            infoCard={{
              title: 'How do Custom Service Work?',
              items: [
                {
                  icon: '/assets/images/svg/forward.svg',
                  label: 'Send your request',
                },
                {
                  icon: '/assets/images/svg/card.svg',
                  label: 'Complete payment',
                },
                {
                  icon: '/assets/images/svg/book-video-icon.svg',
                  label: 'Receive your order',
                },
              ],
            }}
          />
        );
      case ServiceType.ADVERTISE:
        return (
          <Advertise
            pop={pop}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
          />
        );
      default:
        return (
          <Generic
            pop={{}}
            ownProfile={false}
            onStopPOPupOpen={onStopPOPupOpen}
            infoCard={{}}
            skeleton
          />
        );
    }
  }, [onStopPOPupOpen, ownProfile, pop]);

  return (
    <div>
      {GetPop}
      <Model
        open={stopPOPup}
        icon="error"
        title="Can not perform this action"
        text="Sorry, but you can not purchase a service from yourself."
        onClose={onStopPOPupClose}
        onConfirm={onStopPOPupClose}
      />
    </div>
  );
};

export default PublicServices;

import appStatesReducer, { IAppState } from './appStates';
import purchaseStatesReducer, {
  IPurchaseStatesReducer,
} from './purchaseStates';
import themeReducer, { IThemeState } from './theme';

type IMainReducer = {
  appStates?: IAppState;
  purchaseStates?: IPurchaseStatesReducer;
  theme?: IThemeState;
};
const mainReducer = (
  { appStates, purchaseStates, theme }: IMainReducer,
  action: { type: string; payload: any },
): IMainReducer => ({
  appStates: appStatesReducer(appStates, action),
  purchaseStates: purchaseStatesReducer(purchaseStates, action),
  theme: themeReducer(theme, action),
});

export default mainReducer;

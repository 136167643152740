import {
  Advertise,
  CustomServices,
  DigitalDownload,
  Link as LinkIcon,
  Payma,
  PopLive,
  ProfileAvatar,
  Shoutout,
  Text,
  ThumbsUp,
  Youtube,
} from 'assets/svgs';
import classNames from 'classnames';
import {
  POP_BIOGRAPHY_DISABLE,
  POP_CONTENT_BLOCK_DISABLE,
  POP_CUSTOM_SERVICES_DISABLE,
  POP_CUSTOM_VIDEO_DISABLE,
  POP_DIGITAL_DOWNLOADS_DISABLE,
  POP_LINK_DISABLE,
  POP_LIVE_DISABLE,
  POP_PAID_FANMAIL_DISABLE,
  POP_PAID_PROMOTIONS_DISABLE,
  POP_SECTION_TITLE_DISABLE,
  POP_YOUTUBE_VIDEO_DISABLE,
} from 'config';
import useAuth from 'hooks/useAuth';
import useQuery from 'hooks/useQuery';
import { stringify } from 'querystring';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IUser from 'types/user';
import { arrayFind } from 'util/index';
import PopWidget from './components/PopWidget';

const getLinkWidgets = (user: IUser) => {
  const disableSocailIconWidget = !arrayFind(user?.links, {
    linkType: 'socialLinks',
  });
  return (
    <>
      {POP_LINK_DISABLE && (
        <Link to={`/my-profile/link?type=simpleLink&subType=link`}>
          <PopWidget
            className="pop-item"
            title="Link"
            subtitle="Add a new link to your Pop Page."
            icon={<LinkIcon color="#255B87" />}
          />
        </Link>
      )}
      {POP_BIOGRAPHY_DISABLE && (
        <Link to={`/my-profile/link?type=content&subType=biography`}>
          <PopWidget
            className="pop-item"
            title="Biography"
            subtitle="Share some information about yourself with your audience."
            icon={<ProfileAvatar />}
          />
        </Link>
      )}
      {POP_SECTION_TITLE_DISABLE && (
        <Link to={`/my-profile/link?type=content&subType=sectionTitle`}>
          <PopWidget
            className="pop-item"
            title="Section Title"
            subtitle="Add a title so you can organize the Pops on your page."
            icon={<Text />}
          />
        </Link>
      )}
      {POP_CONTENT_BLOCK_DISABLE && (
        <Link to={`/my-profile/link?type=content&subType=contentBlock`}>
          <PopWidget
            className="pop-item"
            title="Content Block"
            subtitle="Add a title so you can organize the Pops on your page."
            icon={<Text />}
          />
        </Link>
      )}
      {POP_YOUTUBE_VIDEO_DISABLE && (
        <Link to={`/my-profile/link?type=simpleLink&subType=youtubeLink`}>
          <PopWidget
            className="pop-item youtube-item"
            title="YouTube/Vimeo Video"
            subtitle="Share a You Tube video directly on your Pop Page."
            icon={<Youtube secondaryColor="#255B87" primaryColor="white" />}
          />
        </Link>
      )}
      {disableSocailIconWidget && (
        <Link to={`/my-profile/link?type=socialLinks`}>
          <PopWidget
            className="pop-item"
            title="Social Icons"
            subtitle="Social Icons linking to your profile."
            icon={<ThumbsUp />}
          />
        </Link>
      )}
    </>
  );
};

const getServiceWidgets = (user: IUser) => {
  return (
    <>
      {POP_CUSTOM_VIDEO_DISABLE && (
        <Link
          to={`/my-profile/link?${stringify({
            type: 'service',
            subType: 'shoutout',
          })}`}
        >
          <PopWidget
            className="pop-item"
            title="Custom Video"
            subtitle="Sell custom video training, greetings or any other video type  to your fans, followers and friends."
            icon={<Shoutout secondaryColor="#255B87" primaryColor="white" />}
          />
        </Link>
      )}
      {POP_PAID_FANMAIL_DISABLE && (
        <Link
          to={`/my-profile/link?${stringify({
            type: 'service',
            subType: 'payma',
          })}`}
        >
          <PopWidget
            className="pop-item"
            title="Paid Q&A"
            subtitle="Get paid to answer your fans and followers questions through text or emai."
            icon={<Payma secondaryColor="#255B87" primaryColor="white" />}
          />
        </Link>
      )}
      {POP_LIVE_DISABLE && (
        <Link
          to={`/my-profile/link?${stringify({
            type: 'service',
            subType: 'poplive',
          })}`}
        >
          <PopWidget
            className="pop-item"
            title="Pop Live"
            subtitle="Get paid to host virtual meetings in your private video chat room."
            icon={<PopLive secondaryColor="#255B87" primaryColor="white" />}
          />
        </Link>
      )}
      {POP_DIGITAL_DOWNLOADS_DISABLE && (
        <Link
          to={`/my-profile/link?${stringify({
            type: 'service',
            subType: 'digital-download',
          })}`}
        >
          <PopWidget
            className="pop-item"
            title="Digital Downloads"
            subtitle="Sell education, art or any other pre-made digital media as a digital download."
            icon={
              <DigitalDownload secondaryColor="#255B87" primaryColor="white" />
            }
          />
        </Link>
      )}
      {POP_PAID_PROMOTIONS_DISABLE && user.allowPromotions && (
        <Link
          to={`/my-profile/link?${stringify({
            type: 'service',
            subType: 'advertise',
          })}`}
        >
          <PopWidget
            className="pop-item"
            title="Promotional Shoutouts"
            subtitle="Provide Promotional Shoutouts to other brands through your social media channels."
            icon={<Advertise secondaryColor="#255B87" primaryColor="white" />}
          />
        </Link>
      )}
      {POP_CUSTOM_SERVICES_DISABLE && (
        <Link
          to={`/my-profile/link?${stringify({
            type: 'service',
            subType: 'additional-services',
          })}`}
        >
          <PopWidget
            className="pop-item"
            title="Custom Services"
            subtitle="Offer any custom service you can complete in five days or less and deliver the results through your Pop Page. "
            icon={
              <CustomServices secondaryColor="#255B87" primaryColor="white" />
            }
          />
        </Link>
      )}
    </>
  );
};

function PopTypeList({ className }: { className?: string }): ReactElement {
  const { type } = useQuery();
  const { user } = useAuth();
  return (
    <div className={classNames('pop_type_list', className)}>
      <div className="block-head">
        <div className="block-head__wrap">
          <h5>{type === 'services' ? 'Choose a Service Type' : ''}</h5>
        </div>
        <div className="caption">
          Choose the type of link or content you want to add to your Pop Page.
        </div>
      </div>
      {type === 'services' ? getServiceWidgets(user) : getLinkWidgets(user)}
    </div>
  );
}

export default styled(PopTypeList)`
  padding: 24px 18px;
  background: white;

  .block-head h5 {
    font-weight: 500;
  }

  .pop-item {
    border-top: 1px solid #ececec;
    position: relative;
    z-index: 2;

    &:hover {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    &:before {
      z-index: -1;
      top: 10px;
      bottom: 10px;
      left: -10px;
      right: -10px;
      content: '';
      border-radius: 10px;
      background: #f5f5f6;
      position: absolute;
      transition: all 0.4s ease;
      opacity: 0;
      visibility: hidden;
    }

    .icon {
      border-radius: 100%;

      svg {
        border-radius: 100%;
      }
    }
  }
`;

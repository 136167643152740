import FocusInput from 'components/focus-input';
import FormItem from 'components/Form/FromItem';
import { DashedLine } from 'components/Typography';
import { useFormik } from 'formik';
import useSelector from 'hooks/useSelector';
import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Enter valid email address')
    .required('Enter valid email address'),
  name: yup.string().required('Name is required'),
  phone: yup
    .string()
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      'Invalid Phone Number',
    ),
});

const GuestContactForm: React.FC<any> = ({ onGuestInputChange, className }) => {
  const guestUser = useSelector(
    (state) => state.purchaseStates?.guestUser?.data,
  );

  const { values, handleChange, handleBlur, errors, touched } = useFormik({
    validationSchema,
    initialValues: {
      phone: '',
      email: '',
      name: `${guestUser?.firstName || ''} ${guestUser?.lastName || ''}`.trim(),
    },
    onSubmit: () => {},
  });

  const getGuestUserFields = () => {
    return (
      <>
        <div className="input-wrap">
          <FormItem label="Name">
            <FocusInput
              placeholder="Name"
              id="guestName"
              name="name"
              type="text"
              error={errors.name}
              touched={touched.name}
              onChange={(e) => {
                onGuestInputChange('name', e.target.value);
                handleChange(e);
              }}
              onBlur={handleBlur}
              value={values.name}
            />
          </FormItem>
        </div>
        <div className="input-wrap">
          <FormItem label="Contact Email">
            <FocusInput
              placeholder="Email Address"
              id="guestEmail"
              name="email"
              type="email"
              error={errors.email}
              touched={touched.email}
              onChange={(e) => {
                onGuestInputChange('email', e.target.value);
                handleChange(e);
              }}
              onBlur={handleBlur}
              value={values.email}
            />
          </FormItem>
        </div>
        {/* <div className="input-wrap">
          <FormItem
            label="Text Order Updates (optional)"
            sublable="Add your number if you want us to send you notifications on your order and special offers via SMS. (Standard carrier fees will apply)"
          >
            <FocusInput
              placeholder="Phone Number (Optional)"
              id="guestPhone"
              name="phone"
              type="text"
              value={values.phone}
              touched={touched.phone}
              error={errors.phone}
              onChange={(e) => {
                onGuestInputChange('phone', e.target.value);
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
          </FormItem>
        </div> */}
      </>
    );
  };

  return (
    <div>
      <form className={className}>
        <div className="mb-30">{getGuestUserFields()}</div>
        <hr className="mb-30 d-none" />
      </form>
      <DashedLine className="my-20 my-md-50" />
    </div>
  );
};

export default styled(GuestContactForm)`
  .no-arrow {
    -moz-appearance: textfield;
  }
  .no-arrow::-webkit-inner-spin-button {
    display: none;
  }
  .no-arrow::-webkit-outer-spin-button,
  .no-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

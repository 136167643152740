import classNames from 'classnames';
import { ServiceType } from 'enums';
import useQuery from 'hooks/useQuery';
import useRequestLoader from 'hooks/useRequestLoader';
import useSelector from 'hooks/useSelector';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { getOrder } from '../api/Order';
import useDispatch from '../hooks/useDispatch';
import { SET_ORDER } from '../reducer/purchaseStates';
import { parseQuery } from '../util';
// import PublicUserLayout from './publicUser';

const Purchase: React.FC<{ className?: string }> = (props) => {
  const { children, className } = props;

  const dispatch = useDispatch();
  const { withLoader } = useRequestLoader();
  const location = useLocation();
  const order = useSelector((state) => state.purchaseStates?.order);
  const publicUser = useSelector((state) => state.appStates?.publicUser);

  const { type } = useQuery();

  const getOrderDetails = useCallback(
    async (orderId: string) => {
      const order = await getOrder(orderId).catch(console.log);
      if (order) {
        dispatch({
          type: SET_ORDER,
          payload: order,
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const { order } = parseQuery(location.search);
    !!order && withLoader(getOrderDetails(order as string));
  }, [location.search]);

  const GetTitle = () => {
    const name = `${publicUser?.firstName || ''} 
    ${publicUser?.lastName || ''}`;
    switch (type) {
      case ServiceType.SHOUTOUT:
        return (
          <h2>
            Custom Video Request from <span>{name}</span>
          </h2>
        );
      case ServiceType.PAYMA:
        return (
          <h2>
            Q&A Request from <span>{name}</span>
          </h2>
        );
      case ServiceType.POPLIVE:
        return (
          <h2>
            Video Chat Request from <span>{name}</span>
          </h2>
        );
      case ServiceType.DIGITAL_DOWNLOADS:
        return (
          <h2>
            Digital Download from <span>{name}</span>
          </h2>
        );
      case ServiceType.ADDITIONAL_SERVICES:
        return (
          <h2>
            Custom Service from
            <span>{name}</span>
          </h2>
        );
      case ServiceType.ADVERTISE:
        return (
          <h2>
            Advertise with <span>{name}</span>
          </h2>
        );
      default:
        return null;
    }
  };
  return (
    <div className={classNames('purchase__layout', className)}>
      {/* <GetTitle /> */}
      {children}
    </div>
  );
};

export default styled(Purchase)`
  h2 {
    text-align: center;
    margin: -10px 0 57px;
    font-size: 26px;
    line-height: ;

    @media (max-width: 767px) {
      margin: -10px 0 30px;
    }
  }
`;

import { createTransfer, getWalletHistory } from 'api/billing';
import FocusInput from 'components/focus-input';
import Button from 'components/NButton';
import { toast } from 'components/toaster';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Utils from 'utils';
import * as yup from 'yup';

const withdrawFee = 2.0;
const getFee = (value: number) => {
  return Number(
    (
      Number(((withdrawFee + (0.25 * value) / 100 + 0.25) * 1.015).toFixed(2)) -
      withdrawFee
    ).toFixed(2),
  );
};
export default function Withdraw(props: any) {
  const [availableAmount, setAvailableAmount] = useState(0);
  const disableField = Number(availableAmount) > 9;
  const history = useHistory();
  useEffect(() => {
    getWalletHistory()
      .then((res) => {
        if (res?.item) {
          setAvailableAmount(Number(res?.withdrawalAvailable));
        }
      })
      .catch((e: Error) => console.log(e));
  }, []);

  const validationSchema = yup.object().shape({
    money: yup
      .number()
      // .max(availableAmount, 'exceed withdrawal amount limit')
      .min(10)
      .required('Provide money to withdraw'),
  });
  const {
    values,
    setValues,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    isSubmitting,
    touched,
  } = useFormik({
    validationSchema,
    validate: (values) => {
      const error: any = {};
      if (Number(values.money)) {
        const fees = getFee(Number(values.money));
        const total = Number(
          (Number(fees) + 2.0 + Number(values.money)).toFixed(2),
        );

        if (total > availableAmount) {
          error.money = 'Exceed withdrawal amount limit';
        }
      }
      return error;
    },
    initialValues: {
      money: '',
    },
    onSubmit: async (values) => {
      let fees = 0;
      let total: string = '2.00';
      if (Number(values.money)) {
        fees = getFee(Number(values.money));
        total = (Number(fees) + 2.0 + Number(values.money)).toFixed(2);
      }
      const requestData = {
        amount: total,
        accountId: props.user?.stripeSellerAccountId,
        currency: 'usd',
        description: '',
      };
      await createTransfer(requestData)
        .then(async (data: any) => {
          if (data) {
            Utils.showAlert(
              'Withdraw',
              'success',
              'Payment Transfer Successfully',
            );
          }
        })
        .then(() => {
          history.push('/account/payments');
        })
        .catch((e) => {
          if (e.message) {
            toast.error(e.message);
          }
        });
    },
  });
  let fees = 0;
  let total: string = '0';
  if (Number(values.money)) {
    fees = getFee(Number(values.money));
    total = (Number(fees) + withdrawFee + Number(values.money)).toFixed(2);
  }
  const HanldeMaxAmountClick = () => {
    if (!disableField) return;
    const fees = getFee(Number(availableAmount));
    const total = (
      Number(availableAmount) -
      Number(fees) -
      withdrawFee
    ).toFixed(2);

    setValues({ money: total });
  };
  return (
    <div className="mb-80">
      <form onSubmit={handleSubmit}>
        <div className="bank-info mb-30">
          <div className="box available col-12">
            <span className="icon-dollar"></span>
            <div className="textbox">
              <strong className="amount">${availableAmount}</strong>
              <span className="status">Available for Withdrawal</span>
            </div>
          </div>
        </div>
        {!disableField && (
          <div className="text-center alert alert-danger">
            $10 is the minimum balance you can withdraw from your account.
          </div>
        )}
        {disableField && (
          <>
            {' '}
            <div className="input-wrap mb-35" style={{ position: 'relative' }}>
              <FocusInput
                hasIcon={true}
                disabled={!disableField}
                icon="dollar"
                label="Enter the money you want to withdraw"
                id="money"
                name="money"
                value={values.money}
                onChange={handleChange}
                onBlur={handleBlur}
                class={
                  touched.money
                    ? errors.money
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                }
                error={errors.money}
                touched={touched.money}
                validations={[{ type: 'number' }]}
              />
              <span
                className="input-tag input-icon"
                onClick={HanldeMaxAmountClick}
              >
                MAX AMOUNT
              </span>
            </div>
            <div className="mb-5 info-bar">
              <div className="user-box">
                <strong className="title">Withdrawal Fee</strong>
              </div>
              <div className="pr-20">$2.00</div>
            </div>
            <div className="info-bar mb-35">
              <div className="user-box">
                <strong className="title">Transaction Cost (0.25%)</strong>
              </div>
              <div className="pr-20">{fees}</div>
            </div>
            <div className="info-bar last mb-30">
              <div className="user-box">
                <strong className="title">Total Withdrawal</strong>
              </div>
              <div className="pr-20">
                <b>${total}</b>
              </div>
            </div>
            <div className="d-flex justify-content-center mb-100">
              <Link to={'/account/payments'}>
                <span className="btn btn-primary btn-light text-uppercase">
                  Cancel
                </span>
              </Link>
              <Button
                htmlType="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting}
                type="primary"
                className="ml-20"
              >
                SEND
              </Button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}

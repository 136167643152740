import { updatePop } from 'api/Pop';
import { createSocialAccount } from 'api/social-accounts';
import { update } from 'api/User';
import { upload } from 'api/Utils';
import {
  ArrowRightFilled,
  FacebookSquare,
  InstagramSquare,
  TicTocSquare,
  TwitterSquare,
  YoutubeSquare,
} from 'assets/svgs';
import FocusInput from 'components/focus-input';
import OutlineTagger from 'components/Tags/OutlineTagger';
import { toast } from 'components/toaster';
import { crop } from 'components/UploadandEditor/cropImage';
import { ServiceType, SocialPlatformschecks } from 'enums';
import { useFormik } from 'formik';
import useDispatch from 'hooks/useDispatch';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { SET_CURRENT_THEME } from 'reducer/theme';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import styled from 'styled-components';
import { IUserLink } from 'types';
import {
  analyticsReport,
  dataURLtoFile,
  isValidUrl,
  onboardingSequency,
} from 'util/index';
import Button from '../../components/NButton';
import { socialLinksPrefix } from '../../constants';
import useAuth from '../../hooks/useAuth';
import ProfileImageEditor from './components/profileEditor';
const PredefiendLinks = [
  {
    url: '',
    type: 'instagram',
    platfromUrl: 'https://www.instagram.com/',
  },
  {
    url: '',
    type: 'tiktok',
    platfromUrl: 'https://www.tiktok.com/',
  },
  {
    url: '',
    type: 'twitter',
    platfromUrl: 'https://www.twitter.com/',
  },
  {
    url: '',
    type: 'youtube',
    platfromUrl: 'https://www.youtube.com/c/',
  },
  {
    url: '',
    type: 'facebook',
    platfromUrl: 'https://www.facebook.com/',
  },
];

export function OnboardingProfilePhoto({ className }: any) {
  const { user, setUser }: any = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef<any>(null);
  const [socialErrors, setSocialErrors] = useState<Record<string, any>>({});
  const {
    values,
    isSubmitting,
    handleChange,
    setFieldValue,
    handleBlur,
    handleSubmit,
    setFieldError,
    errors,
    touched,
    // tslint:disable-next-line: react-hooks-nesting
  } = useFormik({
    initialValues: {
      profileImage: user?.profileImage,
      tagLine: user?.tagLine,
      description: user?.description,
      pageTitle: user?.pageTitle,
      tags: user?.tags,
      predefiendLinks: PredefiendLinks,
      Links: user.links,
      imageData: '',
      filename: '',
    },
    validateOnChange: true,
    onSubmit: async (values) => {
      const { predefiendLinks } = values;

      const altestOneIsFilled = predefiendLinks.findIndex(
        (item) => item.url.length,
      );
      const incorrectUrl = predefiendLinks.filter(
        (item) =>
          item.url.length &&
          isValidUrl(item.url) &&
          !item.url.includes(
            SocialPlatformschecks[
              item.type as keyof typeof SocialPlatformschecks
            ],
          ),
      );
      if (altestOneIsFilled === -1) {
        toast.error('Requires at least one social media link to continue.');
        scrollIntoView(ref.current, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
          block: 'center',
          inline: 'nearest',
        });
        setFieldError('predefiendLinks', 'Please provide at least one link.');
        return;
      } else if (incorrectUrl?.length) {
        const err: Record<string, any> = {};
        incorrectUrl.forEach(
          (inc) => (err[inc.type] = `Provide valid ${inc.type} url`),
        );
        setSocialErrors(err);
        return;
      }

      const pLinks = values.predefiendLinks
        .filter((i) => i?.url?.trim().length)
        .map((i) => {
          const platformUrl = socialLinksPrefix.find(
            (l) => l.type === i.type,
          )?.platformUrl;
          let url;
          if (isValidUrl(i.url)) {
            url = i.url;
          } else {
            if (i.type === 'tiktok') {
              url = i?.url?.trim()?.length
                ? `${platformUrl}@${i?.url.replace('@', '')}`
                : '';
            } else {
              url = i?.url?.trim()?.length
                ? `${platformUrl}${i?.url.replaceAll('@', '')}`
                : '';
            }
          }
          return { ...i, url };
        });

      const userLinks = values.Links.map((item: IUserLink) => {
        if (item.platfrom?.length) {
          return {
            ...item,
            url: pLinks.find((i) => i.type === item.platfrom)?.url,
          };
        }
        return item.linkType === 'service'
          ? {
              ...item,
              popLinksId: item?.popLinksId?._id,
            }
          : item;
      }).filter((lnk: IUserLink) => {
        if (lnk.platfrom && !lnk.url?.trim().length) {
          return false;
        }
        return true;
      });
      const advertisePop = user.links.find(
        (pop: any) => pop.popLinksId?.popType === ServiceType.ADVERTISE,
      );
      if (advertisePop?.popLinksId) {
        advertisePop.popLinksId.priceVariations = await Promise.all(
          pLinks.map(async (l) => {
            const account = await createSocialAccount({
              name: l.type,
              url: l.url,
              type: l.type,
            });
            return {
              isActive: false,
              question: [],
              price: 50,
              description: '',
              stats: {
                label: l.type,
                value: account._id,
                url: l.url,
                type: l.type,
              },
              title: l.type,
              type: l.type,
            };
          }),
        );
        const res = await updatePop(
          advertisePop.popLinksId,
          advertisePop.popLinksId._id,
        );
      }
      const u = await update({
        userSetupStatus: 1,
        ...values,
        links: [
          ...userLinks,
          {
            title: 'Social Links',
            isActive: true,
            linkType: 'socialLinks',
            sortOrder: 1000,
          },
        ],
        socialMediaLinks: pLinks,
      });
      setUser(u.data);
      if (values?.imageData) {
        const cropped = await crop(values.imageData, 16 / 9);
        const file = dataURLtoFile(cropped.toDataURL(), values.filename);
        const form = new FormData();
        form.append('file', file);
        form.append('folder', 'users/link-image');

        const data = await upload('/image/upload', form).catch((e) => {
          toast.error('Sorry, Please try again');
        });
        await Promise.all(
          values.Links.filter(
            (item: IUserLink) => item.linkType === 'service',
          ).map((item: IUserLink) => {
            return updatePop(
              {
                additionalArt: [
                  {
                    artName: file.name,
                    artPath: data.imageURL,
                    artType: file.type,
                  },
                ],
              },
              item.popLinksId?._id!,
            );
          }),
        ).catch(console.log);
      }
      history.push(onboardingSequency[1]);
      analyticsReport({
        category: 'onboarding/profile-photo',
        action: 'submit',
        label: 'Profile Information',
        data: {
          profileImage: Boolean(values?.profileImage),
          tagLine: Boolean(values?.tagLine),
          description: Boolean(user?.description),
          pageTitle: Boolean(user?.pageTitle),
          tags: Boolean(user?.tags),
          ...pLinks.reduce((obj: any, item) => {
            obj[`${item.type}_link`] = Boolean(item.url);
            return obj;
          }, {}),
        },
      });
    },
  });

  useEffect(() => {
    setUser({
      ...user,
      ...values,
      userThemeId: {
        ...user?.userThemeId,
        profile: { ...user?.userThemeId?.profile, image: values.profileImage },
      },
    });
  }, [values]);

  useEffect(() => {
    dispatch({
      type: SET_CURRENT_THEME,
      payload: user?.userThemeId,
    });
  }, [user]);

  const { profileImage, tagLine, pageTitle, tags, predefiendLinks } = values;
  return (
    <>
      <form className={className} onSubmit={handleSubmit}>
        <div className="profile--info">
          <div className="steps-detail">
            Step <span className="curr-step">1</span> of{' '}
            <span className="total-step">3</span>
          </div>
          <h3>Great! Now let’s set up your Pop Page...</h3>
        </div>
        <div className="mb-40">
          <ProfileImageEditor
            value={profileImage}
            onChange={(url: string, imageData: string, filename: string) => {
              setFieldValue('imageData', imageData);
              setFieldValue('profileImage', url);
              setFieldValue('filename', filename);
            }}
          />
        </div>
        <div>
          <div className="field-holder mb-45">
            <div className="label-area">
              <strong className="label">
                What would you like to call your Pop Page?
              </strong>
            </div>
            <FocusInput
              materialDesign
              label="Page Title"
              onChange={handleChange}
              name="pageTitle"
              onBlur={handleBlur}
              value={pageTitle}
              touched={touched.pageTitle}
              error={errors.pageTitle}
            />
          </div>
          <div className="field-holder mb-45">
            <div className="label-area">
              <strong className="label">
                Would you like to add an optional tag line?
              </strong>
            </div>
            <FocusInput
              materialDesign
              label="Tag line"
              onChange={handleChange}
              name="tagLine"
              onBlur={handleBlur}
              value={tagLine}
              touched={touched.tagLine}
              error={errors.tagLine}
            />
          </div>
        </div>
        <div className="mb-5 field-holder mb-md-5">
          <div className="label-area">
            <strong className="label">Tell us a bit about your Page.</strong>
            <span className="description-text">
              Select up to 3 tags that best describe your Pop Page. We'll
              customize your Selfie Pop experience based on your interests.
            </span>
          </div>
          <OutlineTagger
            selectedTags={tags}
            onChange={(tags) => setFieldValue('tags', tags)}
            selectionLimit={3}
          />
        </div>
        <div className="mb-10 content_component">
          <div className="label-area">
            <strong className="label">Social Links</strong>
            <span className="description-text">
              Input your social media links or handles in the fields below.
            </span>
          </div>
          {errors.predefiendLinks && (
            <strong className="mb-10 text-danger">
              {errors['predefiendLinks']}
            </strong>
          )}

          <div className="defualt_field mb-45" ref={ref}>
            <FocusInput
              className="mb-15"
              label="Instagram"
              materialDesign
              name="predefiendLinks[0].url"
              value={predefiendLinks[0].url}
              onChange={(e) => {
                setSocialErrors((er) => ({
                  ...er,
                  [predefiendLinks[0].type]: null,
                }));
                handleChange(e);
              }}
              onBlur={handleBlur}
              prefixElement={<InstagramSquare />}
              validations={[{ noSpace: true }]}
              touched={true}
              error={socialErrors.instagram}
            />
            <FocusInput
              label="TikTok"
              materialDesign
              name="predefiendLinks[1].url"
              value={predefiendLinks[1].url}
              onChange={(e) => {
                setSocialErrors((er) => ({
                  ...er,
                  [predefiendLinks[1].type]: null,
                }));
                handleChange(e);
              }}
              onBlur={handleBlur}
              prefixElement={<TicTocSquare />}
              validations={[{ noSpace: true }]}
              touched={true}
              error={socialErrors.tiktok}
            />
            <FocusInput
              label="Twitter"
              materialDesign
              name="predefiendLinks[2].url"
              value={predefiendLinks[2].url}
              onChange={(e) => {
                setSocialErrors((er) => ({
                  ...er,
                  [predefiendLinks[2].type]: null,
                }));
                handleChange(e);
              }}
              onBlur={handleBlur}
              prefixElement={<TwitterSquare />}
              validations={[{ noSpace: true }]}
              touched={true}
              error={socialErrors.twitter}
            />
            <FocusInput
              label="Youtube"
              materialDesign
              name="predefiendLinks[3].url"
              value={predefiendLinks[3].url}
              onChange={(e) => {
                setSocialErrors((er) => ({
                  ...er,
                  [predefiendLinks[3].type]: null,
                }));
                handleChange(e);
              }}
              onBlur={handleBlur}
              prefixElement={<YoutubeSquare />}
              validations={[{ noSpace: true }]}
              touched={true}
              error={socialErrors.youtube}
            />
            <FocusInput
              label="Facebook"
              materialDesign
              name="predefiendLinks[4].url"
              value={predefiendLinks[4].url}
              onChange={(e) => {
                setSocialErrors((er) => ({
                  ...er,
                  [predefiendLinks[4].type]: null,
                }));
                handleChange(e);
              }}
              onBlur={handleBlur}
              validations={[{ noSpace: true }]}
              prefixElement={<FacebookSquare />}
              touched={true}
              error={socialErrors.facebook}
            />
          </div>
        </div>
        <div className="text-center button-holder">
          <Button
            htmlType="submit"
            isLoading={isSubmitting}
            type="primary"
            size="large"
            block
          >
            Next Step{' '}
            <span className="img">
              <ArrowRightFilled />
            </span>
          </Button>
        </div>
      </form>
    </>
  );
}

export default styled(OnboardingProfilePhoto)`
  padding: 0 20px 110px;
  position: relative;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 0 0 80px;
  }

  .button-holder {
    border-top: 1px solid #dadee2;
    padding: 30px 30px 30px 40px;
    position: fixed;
    left: 0;
    width: 599px;
    bottom: 0;
    background: #fff;
    z-index: 9;

    @media (max-width: 1023px) {
      width: auto;
      right: 0;
    }

    @media (max-width: 767px) {
      padding: 15px 20px;
    }
  }

  .logo-holder {
    width: 152px;
    margin: 0 0 36px;

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .steps-detail {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    margin: 0 0 33px;
  }

  .profile--info {
    position: relative;
  }

  h3 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    margin: 0 0 30px;
  }

  h6 {
    font-size: 15px;
    line-height: 18px;
    margin: 0 0 20px;
    font-weight: 500;
  }

  .label-area {
    margin: 0 0 15px;
  }

  .label {
    display: block;
    margin: 0 0 8px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
  }

  .description-text {
    display: block;
    color: #67616d;
    font-size: 14px;
    line-height: 18px;
  }
`;

import { Advertise as AdIcon, Link, VerticalDots } from 'assets/svgs';
import { CardSection } from 'components/SPCards/SimpleCard';
import { DashedLine } from 'components/Typography';
import { SocialPlatforms } from 'enums';
import useDispatch from 'hooks/useDispatch';
import React, { ReactElement, useCallback } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import { PriceVariant } from 'types';
import { getLocation } from 'util/index';
import VariationDragableListing from './VariationDragableListing';

const Draghandle = SortableHandle(() => (
  <span className="drag-dots">
    <VerticalDots />
  </span>
));

const LinkIcon = () => <Link color="#255B87" />;
const EmptyShoutOut = styled.p`
  margin: 0 0 16px;
  font-weight: 400;
`;
interface Props {
  socialMediaLinks: any;
  socialMediaSorting?: string[];
  priceVariations: PriceVariant[];
  setField?: (name: string, value: any) => void;
  onChange: any;
}

export default function Advertise({
  socialMediaLinks,
  socialMediaSorting,
  priceVariations,
  setField,
  onChange,
}: Props): ReactElement {
  const dispatch = useDispatch();
  const getSubTitle = useCallback(
    (params: PriceVariant['stats']) => {
      const findState = socialMediaLinks?.find(
        ({ _id }: any) => _id === params?.value,
      );

      if (!findState) return;

      if (findState.type === SocialPlatforms.YOUTUBE) {
        return (
          <span className="items-detail">
            <a
              className="items-detail-info"
              href={getLocation(findState?.url).href}
              target="_blank"
              rel="noreferrer"
            >
              {findState?.stats?.name}
            </a>
            <span className="items-detail-info">
              Subscribers:{' '}
              <span className="num">{findState?.stats?.subscribers || 0}</span>
            </span>
          </span>
        );
      }
      if (findState.type === SocialPlatforms.FACEBOOK) {
        if (findState.url?.includes('facebook.com/groups')) {
          return (
            <span className="items-detail">
              <a
                className="items-detail-info"
                href={getLocation(findState?.url).href}
                target="_blank"
                rel="noreferrer"
              >
                {findState?.stats?.name}
              </a>
              <span className="items-detail-info">
                followers:{' '}
                <span className="num">{findState?.stats?.memebers || 0}</span>
              </span>
            </span>
          );
        }
        return (
          <span className="items-detail">
            <a
              className="items-detail-info"
              href={getLocation(findState?.url).href}
              target="_blank"
              rel="noreferrer"
            >
              {findState?.stats?.name}
            </a>
            <span className="items-detail-info">
              followers:{' '}
              <span className="num">{findState?.stats?.followers || 0}</span>
            </span>
          </span>
        );
      }
      return (
        <span className="items-detail">
          <a
            className="items-detail-info"
            href={getLocation(findState?.url).href}
            target="_blank"
            rel="noreferrer"
          >
            @
            {getLocation(findState.url)
              ?.pathname?.substring(1)
              ?.replace(/@|\//g, '')}
          </a>
          <span className="items-detail-info">
            followers:{' '}
            <span className="num">{findState?.stats?.followers || 0}</span>
          </span>
        </span>
      );
    },
    [socialMediaLinks],
  );
  const handleChange = (name: string, value: PriceVariant[]) => {
    onChange && onChange(name, value);
  };

  return (
    <div className="schedule-block">
      <DashedLine className="dashed" />
      <div className="addition__art mb-30 shoutout-block">
        <CardSection
          title="Social Offerings"
          subtitle="Add your social offerings here!"
          icon={<AdIcon />}
          className="rc-card-header-large"
        >
          <div className="shoutout-block__body-area">
            <DashedLine className="dashed-line" />
            {!priceVariations?.length && (
              <p>
                You do not currently have any promotional shoutouts defined.
                Please click the button below to create your first offering.
              </p>
            )}
            <div className="price--variation">
              <VariationDragableListing
                addVariationbtnTitle="Add Promotional Shoutout"
                value={priceVariations}
                onChange={(value: PriceVariant[]) => {
                  handleChange('priceVariations', value);
                }}
                socialMediaLinks={socialMediaLinks}
                title="Advertise"
                type="advertise"
                renderQuestionInForm={true}
                itemStyleType="compact"
                buttonPosition="bottom"
                showItemPriceTag={false}
                itemSubtitle={getSubTitle}
              />
            </div>
          </div>
        </CardSection>
      </div>
    </div>
  );
}

import { createTheme, updateTheme } from 'api/theme';
import { update } from 'api/User';
import useAuth from 'hooks/useAuth';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import useDispatch from 'hooks/useDispatch';
import useSelector from 'hooks/useSelector';
import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router';
import { SET_APPLY_THEME_MODAL } from 'reducer/appStates';
import { SET_CURRENT_THEME, SET_THEMES } from 'reducer/theme';
import ITheme from 'types/Theme';
import {
  getLocalStorage,
  getUserSetupUri,
  removeLocalStorage,
} from 'util/index';

const ThemeSelector: React.FC<{ isOnboarding?: boolean }> = (props) => {
  const { children, ...rest } = props;
  const { showLeftView } = useControllTwopanelLayoutView();

  const { user, setUser } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    allthemes: userThemes = [],
    current,
  }: { allthemes: ITheme[]; current: ITheme } = useSelector(
    (state) => state.theme,
  );

  const [isApplyModalOpen, setIsApplyModalOpen] = useState<boolean>(false);
  const [isApplying, setIsApplying] = useState<boolean>(false);

  const onApplyHandler = async () => {
    setIsApplying(true);
    if (rest.isOnboarding) {
      const isNextPasswordPage =
        getLocalStorage('setPasswordOnOnboarding', false) === 'yes';
      const requests = [];
      requests.push(
        update({
          userSetupStatus: isNextPasswordPage ? 4 : 10,
          isActiveProfile: isNextPasswordPage ? false : true,
        }),
      );
      if (current?._id) {
        requests.push(
          createTheme({
            ...current,
            isSystemTheme: false,
            isPublished: false,
            isDefault: false,
            isActive: true,
            baseURL: `${window.location.host}/${user.username}`,
            categoryId: (current?.categoryId as any)?._id || '',
          }),
        );
      }

      await Promise.all(requests)
        .then(([userRes, themeRes]) => {
          if (themeRes) {
            dispatch({
              type: SET_THEMES,
              payload: [...userThemes, themeRes?.theme],
            });
            setUser({ ...userRes.data, userThemeId: themeRes?.theme });
          } else {
            setUser({ ...userRes.data });
          }
          if (isNextPasswordPage) {
            removeLocalStorage('setPasswordOnOnboarding');
            history.push(getUserSetupUri(3));
          } else {
            history.push('/my-profile');
          }
        })
        .catch(console.log);
    } else {
      current?._id &&
        (await updateTheme(current._id, {
          ...current,
          isActive: true,
          baseURL: `${window.location.host}/${user.username}`,
        })
          .then((res) => {
            dispatch({
              type: SET_THEMES,
              payload: userThemes.map((item) =>
                item._id === res?.theme?._id
                  ? res.theme
                  : { ...item, isActive: false },
              ),
            });
            setSelectedTheme(res.theme);
            setUser({ ...user, userThemeId: res.theme });
            setIsApplyModalOpen(false);
          })
          .catch(console.log));
    }
    setIsApplying(false);
    showLeftView();
  };

  const onRevertHandler = () => {
    if (rest.isOnboarding && isMobileOnly) {
      showLeftView();
      return;
    }
    setSelectedTheme(userThemes.find((theme) => theme.isActive));
    setIsApplyModalOpen(false);
    showLeftView();
  };

  const openApplyModal = (value: boolean) => {
    if (isMobileOnly || rest.isOnboarding) {
      setIsApplyModalOpen(value);
    } else {
      dispatch({
        type: SET_APPLY_THEME_MODAL,
        payload: {
          active: value,
        },
      });
    }
  };

  const setSelectedTheme = (theme?: ITheme) => {
    dispatch({
      type: SET_CURRENT_THEME,
      payload: theme,
    });
  };

  return React.cloneElement(children as any, {
    ...rest,
    isApplyModalOpen,
    setIsApplyModalOpen: openApplyModal,
    selectedTheme: current,
    setSelectedTheme,
    isThemeApplying: isApplying,
    onApplyTheme: onApplyHandler,
    onRevertTheme: onRevertHandler,
  });
};

export default ThemeSelector;

import dayjs from 'dayjs';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { linkViewed } from '../api/analytic';
import useAuth from './useAuth';
import useLocalStorage from './useLocalStorage';
const UsePageView = (user: any = null, link: any = null) => {
  const [analytics, setAnalytics] = useLocalStorage('analytics');
  const { user: loggedUser } = useAuth();
  useEffect(() => {
    if (user) {
      if (analytics?._id) {
        if (dayjs().diff(analytics._createAt, 'day') > 0) {
          linkViewed({
            user: user._id,
            viewedById: analytics._id,
            _createAt: dayjs().format(),
          }).then(() => {
            setAnalytics({
              _id: analytics._id,
              _createAt: dayjs().format(),
              vi: [],
            });
          });
        }
      } else {
        const _id = loggedUser?._id || uuid();
        linkViewed({
          user: user._id,
          viewedById: _id,
        }).then(() => {
          setAnalytics({ _id, _createAt: dayjs().format(), vi: [] });
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (user?._id && link?._id) {
      const d: any = {
        linkTitle: null,
        linkID: null,
        linkURL: null,
        type: null,
      };

      d.linkTitle = link.title;
      d.linkID = link._id;
      d.linkURL = `/${user.username}/${link.popName}`;
      d.linkType = 'pop';

      if (analytics?._id) {
        const seen: any[] = analytics.vi;
        const alreadyViewThisLink = seen.find(
          (item) => item.linkID === d.linkID,
        );
        if (!alreadyViewThisLink) {
          linkViewed({
            user: user._id,
            viewedById: analytics._id,
            linkId: d.linkID,
            type: d.linkType,
          }).then(() => {
            setAnalytics({ ...analytics, vi: [...seen, d] });
          });
        }
      } else {
        const _id = loggedUser?._id || uuid();
        linkViewed({
          user: user._id,
          viewedById: _id,
          linksClicked: d,
          linkId: d.linkID,
          type: d.linkType,
        }).then(() => {
          setAnalytics({ _id, vi: [d] });
        });
      }
    }
  }, [user, link]);
};

export default UsePageView;

import classNames from 'classnames';
import EditBack from 'components/partials/components/profileBack';
import Scrollbar from 'components/Scrollbar';
import { AnimatePresence, Variants } from 'framer-motion';
import useAuth from 'hooks/useAuth';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import useSelector from 'hooks/useSelector';
import React, { ReactElement, useEffect, useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useLocation } from 'react-router';
import styled, { CSSProperties } from 'styled-components';
import ITheme from 'types/Theme';
import PageTransition from './page-transition';

const rtr = {
  pageInitial: {
    opacity: 0,
    left: '660px',
  },
  pageAnimate: {
    opacity: 1,
    left: '0',
  },
  pageExit: {
    opacity: 0,
    left: '660px',
    position: 'absolute',
  },
};
const ltl = {
  pageInitial: {
    opacity: 0,
    left: '-660px',
  },
  pageAnimate: {
    opacity: 1,
    left: '0',
  },
  pageExit: {
    opacity: 0,
    left: '-660px',
    position: 'absolute',
  },
};

interface Props {
  leftView?: React.ReactNode;
  rightView?: React.ReactNode;
  defaultRightSide?: boolean;
  classes?: { leftView?: string; rightView?: string; content?: string };
  styles?: { leftView?: CSSProperties; rightView?: CSSProperties };
  className?: string;
  children?: JSX.Element | JSX.Element[];
  defaultBackButton?: boolean;
}

export const setTwoPanelLayoutHeight = () => {
  setTimeout(() => {
    const ele = document.getElementById('twopanellayout');
    ele?.setAttribute(
      'style',
      `
      height: calc(100vh - ${ele?.getBoundingClientRect().y}px)
    `,
    );
  }, 0);
};

export function Layout({
  leftView,
  rightView,
  classes = {},
  styles = {},
  className = '',
  children,
  defaultBackButton = true,
  ...rest
}: Props): ReactElement {
  const { user } = useAuth();
  const { activeView, showLeftView } = useControllTwopanelLayoutView();
  const location = useLocation();
  useEffect(() => {
    showLeftView();
  }, [location.pathname, showLeftView]);

  const rendering = useRef(true);

  const {
    leftView: leftViewClass,
    rightView: rigthViewClass,
    content: contentClass,
  } = classes;
  const { leftView: leftViewStyle, rightView: rigthViewStyle } = styles;

  const { current: theme }: { current: ITheme } = useSelector(
    (state) => state.theme,
  );

  const Childrens = React.Children.toArray(children);
  let LeftPanelView = leftView;
  let RightPanelView = rightView;

  if (Childrens.length === 2) {
    LeftPanelView = Childrens[0];
    RightPanelView = Childrens[1];
  }

  useEffect(() => {
    setTwoPanelLayoutHeight();
    showLeftView();
    rendering.current = false;
  }, []);

  return (
    <div className={classNames(className)}>
      <div id="twopanellayout">
        <BrowserView
          viewClassName={classNames('desktop_view', 'two--panel-layout')}
          style={{ height: '100%' }}
        >
          <div
            className={classNames('col-left', leftViewClass)}
            style={{ position: 'relative', zIndex: 1, ...leftViewStyle }}
          >
            <Scrollbar
              className="custom-scroll-bar"
              style={{ overflowX: 'hidden' }}
            >
              <div className={classNames('col-inner-content', contentClass)}>
                {React.cloneElement(LeftPanelView as any, {
                  ...rest,
                  user,
                  theme: theme || user.userThemeId,
                })}
              </div>
            </Scrollbar>
          </div>
          <div
            className={classNames('col-right', rigthViewClass)}
            style={rigthViewStyle}
          >
            <Scrollbar className="custom-scroll-bar">
              <div className={classNames('col-inner-content', contentClass)}>
                {React.cloneElement(RightPanelView as any, {
                  ...rest,
                  user,
                  theme: theme || user.userThemeId,
                  twoPanelLayout: true,
                })}
              </div>
            </Scrollbar>
          </div>
        </BrowserView>
        <MobileView
          viewClassName={classNames('two--panel-layout-', 'mobile_view')}
        >
          <AnimatePresence initial={false}>
            {activeView === 'left' || rendering.current ? (
              <div key="1">
                <PageTransition variants={ltl as Variants}>
                  <div
                    className={classNames('col-left', leftViewClass)}
                    style={{
                      position: 'relative',
                      zIndex: 1,
                      ...leftViewStyle,
                    }}
                  >
                    <div
                      className={classNames('col-inner-content', contentClass)}
                    >
                      {React.cloneElement(LeftPanelView as any, {
                        ...rest,
                        user,
                        theme: theme || user.userThemeId,
                      })}
                    </div>
                  </div>
                </PageTransition>
              </div>
            ) : (
              <div key="2">
                <PageTransition variants={rtr as Variants}>
                  <div
                    className={classNames('col-right', rigthViewClass)}
                    style={rigthViewStyle}
                  >
                    <div
                      className={classNames('col-inner-content', contentClass)}
                    >
                      {defaultBackButton && (
                        <EditBack
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            showLeftView();
                          }}
                        />
                      )}
                      {React.cloneElement(RightPanelView as any, {
                        ...rest,
                        user,
                        theme: theme || user.userThemeId,
                        twoPanelLayout: true,
                      })}
                    </div>
                  </div>
                </PageTransition>
              </div>
            )}
          </AnimatePresence>
        </MobileView>
      </div>
    </div>
  );
}

export default styled(Layout)`
  .two--panel-layout {
    overflow: hidden;
    display: flex;
    flex-direction: row;

    .card-item-holder {
      @media (min-width: 1024px) {
        width: 33.333%;
      }
    }
  }
  .col-left {
    width: 600px;
    background-color: #f5f6f8;
    min-width: 600px;
    .custom-scroll-bar > div:nth-child(1) {
      overflow-x: hidden !important;
      margin-bottom: 0px !important;
    }

    @media (max-width: 1023px) {
      width: 100%;
      min-width: inherit;
    }

    .rc-scollbar {
      width: 600px;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }
  }
  .col-left::after {
    width: 1px;
    height: 100%;
    background: #d5dade !important;
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .rc-scollbar {
    height: 100%;
  }
  .col-left .col-inner-content {
    padding: 24px 18px;
    height: 100%;
    width: 100%;

    > div:only-child {
      height: 100%;

      > div:only-child {
        height: 100%;
      }
    }

    .pop__edit {
      min-height: 100%;
      background: #fff;
    }
  }
  .col-right {
    flex-grow: 1;
    flex-basis: 0;
  }

  .mobile_view {
    .col-left {
      width: 100%;
    }

    .col-inner-content {
      width: 600px;
      min-width: 600px;
      margin: 0 auto;

      @media (max-width: 1023px) {
        width: 100%;
        min-width: inherit;
      }
    }

    .card-item-holder {
      @media (min-width: 1024px) {
        width: 33.333%;
      }
    }

    .button-holder {
      width: 100%;
    }
  }

  ${({ defaultRightSide }) =>
    defaultRightSide && `.col-right .col-inner-content { padding:0px }`}
`;

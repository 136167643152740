import { addToMailingList } from 'api/Utils';
import { useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import swal from 'sweetalert';
import * as yup from 'yup';
import {
  InstagramSquare,
  Link,
  TicTocSquare,
  TwitterSquare,
  YoutubeSquare,
} from '../../assets/svgs';
import FocusInput from '../../components/focus-input';
import Button from '../../components/NButton';
import { socialLinksPrefix } from '../../constants';
import Logo from '../../theme/logo';

const validationSchema = yup.object().shape({
  name: yup.string().max(255, 'Too Long!').required('Enter your full name'),
  email: yup
    .string()
    .email('Enter valid email address')
    .required('Enter valid email address'),
  phone: yup
    .string()
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      'Invalid Phone Number',
    ),
});

const ComingSoonForm: React.FC<{ className: string }> = ({ className }) => {
  const history = useHistory();

  const {
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    validateOnChange: true,
    validationSchema,
    validate: (values) => {
      let errors: any = {};
      const { socialLinks } = values;

      const filledIndex = Object.entries(socialLinks).findIndex(
        ([key, value]) => value.length,
      );
      if (filledIndex < 0)
        errors.socialLinks = 'Please provide at least one link.';

      return errors;
    },
    initialValues: {
      name: '',
      email: '',
      phone: '',
      socialLinks: {
        instagram: '',
        tiktok: '',
        youtube: '',
        twitter: '',
      },
    },
    onSubmit: async (values) => {
      const payload = {
        fullName: values.name,
        phoneNumber: values.phone,
        email: values.email,
        socialMediaLinks: Object.entries(values.socialLinks)
          .map(([key, value]) => {
            const platformUrl = socialLinksPrefix.find(
              (l) => l.type === key,
            )?.platformUrl;
            let url;
            if (
              value.indexOf('https://') === 0 ||
              value.indexOf('http://') === 0
            ) {
              url = value;
            } else {
              url = value?.trim()?.length ? `${platformUrl}${value}` : '';
            }
            return {
              type: key,
              url,
            };
          })
          .filter((l: any) => l?.url?.length),
      };

      const res = await addToMailingList(payload).catch(console.log);
      if (res.success) {
        swal(
          `Congratulations`,
          `Request submitted successfully`,
          'success',
        ).then(() => {
          history.push('/coming-soon');
        });
      } else {
        swal('Error', 'Something went wrong. try again!', 'error');
      }
    },
  });

  return (
    <div className={className}>
      <div className="wrap">
        <div className="box">
          <div className="logo-area">
            <div className="logo-holder">
              <Logo primaryColor="255b87" secondaryColor="000000" />
            </div>
            <p>Fill out the form below to qualify for early access:</p>
          </div>
          <hr />
          <form onSubmit={handleSubmit}>
            <FocusInput
              error={errors.name}
              touched={touched.name}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              label="Full Name"
              validations={[{ noMultipeSpace: true }, { type: 'alpha' }]}
              id="name"
              materialDesign
              name="name"
            />
            <FocusInput
              error={errors.email}
              touched={touched.email}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              label="Email"
              materialDesign
              id="email"
              name="email"
            />
            <FocusInput
              error={errors.phone}
              touched={touched.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              materialDesign
              label="Phone Number (optional)"
              id="phone"
              name="phone"
            />
            <div className="social-area">
              <div className="heading-box">
                <div className="img">
                  <Link />
                </div>
                <div className="description">
                  <strong className="description-title">Social Links</strong>
                  <p>Input your default social links below.</p>
                </div>
              </div>
              {errors.socialLinks && (
                <strong className="text-danger mb-10">
                  {errors.socialLinks}
                </strong>
              )}
              <div className="defualt_field mb-45">
                <FocusInput
                  className="mb-15"
                  label="Instagram"
                  materialDesign
                  name="socialLinks.instagram"
                  value={values.socialLinks.instagram}
                  prefixElement={<InstagramSquare />}
                  onChange={handleChange}
                />
                <FocusInput
                  className="mb-15"
                  label="TikTok"
                  materialDesign
                  name="socialLinks.tiktok"
                  value={values.socialLinks.tiktok}
                  prefixElement={<TicTocSquare />}
                  onChange={handleChange}
                />
                <FocusInput
                  className="mb-15"
                  label="Twitter"
                  materialDesign
                  name="socialLinks.twitter"
                  value={values.socialLinks.twitter}
                  prefixElement={<TwitterSquare />}
                  onChange={handleChange}
                />
                <FocusInput
                  className="mb-15"
                  label="Youtube"
                  materialDesign
                  name="socialLinks.youtube"
                  value={values.socialLinks.youtube}
                  prefixElement={<YoutubeSquare />}
                  onChange={handleChange}
                />
                <hr />
                <div className="btn-holder">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default styled(ComingSoonForm)`
  min-height: 100vh;
  display: flex;

  .wrap {
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .box {
    width: 100%;
    max-width: 555px;
    padding: 20px 15px;
  }

  .logo-holder {
    max-width: 441px;
    width: 100%;
    margin: 0 auto 45px;

    @media (max-width: 767px) {
      max-width: 280px;
      margin: 0 auto 20px;
    }

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  hr {
    display: block;
    margin: 0;
    height: 1px;
    background: #e7e6e6;
    border: none;
  }

  .logo-area {
    font-size: 24px;
    line-height: 1;
    color: #000;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 0 0 17px;

    @media (max-width: 767px) {
      font-size: 18px;
      padding: 0;
    }
  }

  .heading-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 30px;

    .img {
      width: 40px;
    }

    .description {
      width: calc(100% - 40px);
      padding: 0 0 0 12px;
    }

    .description-title {
      display: block;
      font-size: 15px;
      line-height: 18px;
      color: #000;
      font-weight: 500;
      margin: 0 0 5px;
    }

    p {
      margin: 0;
    }
  }

  .social-area {
    padding: 28px 0 0;

    @media (max-width: 767px) {
      padding: 15px 0 0;
    }
  }

  form {
    padding: 37px 0 0;

    @media (max-width: 767px) {
      padding: 20px 0 0;
    }
  }

  .defualt_field {
    hr {
      margin-top: 43px;

      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }

    .mb-20 {
      margin-bottom: 15px !important;
    }
  }

  .btn-holder {
    padding: 50px 0 0;
    text-align: center;

    @media (max-width: 767px) {
      padding: 20px 0 0;
    }
  }
`;

import { useContext } from 'react';
import {
  AppGlobalStates,
  IAppGlobalStatesContext,
} from '../context/AppGlobalStates';

type ISelector = Omit<IAppGlobalStatesContext, 'dispatch'>;
type returnType =
  | Partial<ISelector>
  | ISelector[keyof ISelector]
  | ISelector[keyof ISelector][keyof ISelector[keyof ISelector]]
  | any;

const useSelector = (
  callback?: (state: ISelector) => returnType,
): returnType => {
  const state = useContext(AppGlobalStates);

  return callback ? callback(state) : state;
};

export default useSelector;

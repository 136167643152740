import { update } from 'api/User';
import { ProfileAvatarSquare } from 'assets/svgs';
import FocusInput from 'components/focus-input';
import Button from 'components/NButton';
import { DashedLine } from 'components/Typography';
import useAuth from 'hooks/useAuth';
import ProfileImageEditor from 'pages/onboarding/components/profileEditor';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';

interface Props {
  className?: string;
}

const BioEditor: React.FC<Props> = ({ className }) => {
  const { user, setUser } = useAuth();
  const [profileImage, setProfileImage] = useState<string>(user?.profileImage);
  const [tagLine, setTagLine] = useState<string>(user?.tagLine);
  const [pageTitle, setPageTitle] = useState<string>(user?.pageTitle);
  const [description, setDescription] = useState(user?.description);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (user?._id) {
      setProfileImage(user?.profileImage);
      setTagLine(user?.tagLine);
      setPageTitle(user?.pageTitle);
      setDescription(user?.description);
    }
  }, [user]);

  const handleSubmit = async () => {
    setIsUpdating(true);
    const res: any = await update({
      tagLine,
      pageTitle,
      description,
      profileImage,
    }).catch((e) => {
      swal('Error', e.message, 'error');
    });
    if (res) {
      setUser({ ...user, ...res.data });
      swal('', res.message, 'success');
    }
    setIsUpdating(false);
  };

  return (
    <div className={`${className} pt-30 px-30 pb-5`}>
      <div className="profile--info">
        <strong className="title">Profile Photo</strong>
        <p>
          Upload and manage your profile photo that is used for site
          communications.
        </p>
      </div>
      <div className="mb-40">
        <ProfileImageEditor
          value={profileImage}
          onChange={(url: string) => {
            setProfileImage(url);
          }}
        />
      </div>
      <div className="personal-info">
        <DashedLine />
        <div className="personal-info-wrap">
          <div className="heading-box">
            <div className="img">
              <ProfileAvatarSquare />
            </div>
            <div className="description">
              <strong className="description-title">Personal Info</strong>
              <p>Share some information about yourself and what you do.</p>
            </div>
          </div>
          <div className="field-holder mb-30">
            <div className="label-area">
              <strong className="label">Page Title:</strong>
            </div>
            <FocusInput
              materialDesign
              label="Page Title"
              name="pageTitle"
              value={pageTitle}
              onChange={(e) => setPageTitle(e.target.value)}
            />
          </div>
          <div className="field-holder mb-30">
            <div className="label-area">
              <strong className="label">Tag line:</strong>
            </div>
            <FocusInput
              materialDesign
              label="Tag line"
              name="tagLine"
              value={tagLine}
              onChange={(e) => setTagLine(e.target.value)}
            />
          </div>
          <div className="field-holder mb-30">
            <div className="label-area">
              <strong className="label">Pop Page Description</strong>
              <span className="description-text">
                This can be a biography, page description or just random words.
              </span>
            </div>
            <FocusInput
              materialDesign
              label="Bio / Description"
              type="textarea"
              name="description"
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <Button
        type="primary"
        block
        size="large"
        onClick={handleSubmit}
        isLoading={isUpdating}
        disabled={isUpdating}
      >
        Submit
      </Button>
    </div>
  );
};

export default styled(BioEditor)`
  background-color: #fff;

  .profile--info {
    position: relative;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 0 10px;

    .title {
      color: #000;
      display: block;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      margin: 0 0 15px;
    }

    p {
      margin: 0;
    }
  }

  .personal-info {
    margin: 0 -30px;
    background: #fafbfc;

    .personal-info-wrap {
      padding: 46px 30px 10px;
    }
  }

  .heading-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
    position: relative;
    overflow: hidden;
    margin: 0 0 30px;

    .img {
      width: 40px;
      height: 40px;
      margin: 0 10px 0 0;
    }

    .description {
      flex-grow: 1;
      flex-basis: 0;
    }

    p {
      margin: 0;
    }
  }

  .description-title {
    display: block;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    margin: 0 0 6px;
    color: #000;
  }

  .label-area {
    margin: 0 0 15px;
  }

  .label {
    display: block;
    margin: 0 0 8px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
  }

  .description-text {
    display: block;
    color: #67616d;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .social-area {
    padding: 30px 0 0;
  }

  .materialized-input.text-input .pre-fix {
    left: 6px;
  }
`;

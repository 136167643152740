import Image from 'components/Image';
import NewButton from 'components/NButton';
import Pagination from 'components/pagination';
import { PaginationProps } from 'rc-pagination';
import * as React from 'react';
import { ORDER_STATUS_FILTER_OPTIONS } from '../../constants';
import Header from './components/Header';
interface selectIProps {
  label: string;
  value: string;
}

export interface IOrderTable<T> {
  key: string;
  data: T[];
  paginationProps: PaginationProps;
  showHeader?: boolean;
  title: string | React.ReactElement;
  selectProps?: {
    selected?: selectIProps;
    options?: selectIProps[];
    handleChange?: any;
  };
  options?: { [key: string]: boolean };
  onRowClick?: (row?: T, key?: T[keyof T] | number) => void;
  icon?: React.ReactElement;
  emptyTitle?: string | React.ReactElement;
}

const OrderTable: React.FC<IOrderTable<any>> = ({
  key = '',
  title,
  data = [],
  selectProps = {},
  paginationProps,
  options: displayOptions = {},
  onRowClick = () => {},
  emptyTitle = 'You currently do not have any Purchases',
  icon,
}) => {
  const { date: Ddate = true, price: Dprice = true } = displayOptions;
  const {
    selected,
    options = ORDER_STATUS_FILTER_OPTIONS,
    handleChange,
  } = selectProps;
  return (
    <div>
      <Header
        icon={icon}
        title={title}
        options={{ select: true }}
        selectOptions={{
          placeholder: 'filter By:',
          defaultValue: { label: 'All', value: '' },
          value: selected,
          options: options,
          onChange: handleChange,
          isSearchable: false,
        }}
        classes={{ main: 'mb-35' }}
      />
      <div className="order-table pb-10">
        <div className="table-body">
          {data.length ? (
            <div className="table-content">
              <div id="list-area" className="mb-45">
                {data.map((row: any, index: number) => {
                  const {
                    image,
                    title,
                    subtitle,
                    status,
                    price,
                    date,
                    ...rest
                  } = row;

                  return (
                    <div
                      key={row[key] || index}
                      className="primary-shadow order-widget"
                      style={{ display: 'block' }}
                      onClick={() => {
                        onRowClick && onRowClick(row, row[key] || index);
                      }}
                    >
                      <div className="order-widget-wrap">
                        <div className="img-holder">
                          <Image
                            src={
                              image || '/assets/images/default-profile-img.svg'
                            }
                            alt="img description"
                          />
                        </div>
                        <div className="wrap">
                          <div className="title-wrap">
                            <strong className="title">{title}</strong>
                            {Dprice && (
                              <strong className="price">
                                ${(Number(price) || 0).toFixed(2)}
                              </strong>
                            )}
                          </div>
                          <div className="text-wrap">
                            <span className="subtext">{subtitle}</span>
                            {Ddate && (
                              <span className="date">
                                Date Ordered:&nbsp;
                                <time dateTime={date}>{date}</time>
                              </span>
                            )}
                            <span
                              className={`status text-capitalize ${status}`}
                            >
                              Status: <span>{status}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Pagination {...paginationProps} />
            </div>
          ) : (
            <div style={{ maxWidth: '402px', margin: '0 auto' }}>
              <NewButton
                block
                type="primary"
                shape="circle"
                className="btn-disabled"
              >
                {emptyTitle}
              </NewButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderTable;

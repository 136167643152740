import { ChevronRight, OutlinedLogo } from 'assets/svgs';
import ApplyThemeModal from 'components/ApplyThemeModal';
import Button from 'components/NButton';
import Footer from 'components/partials/footer';
import { AnimatePresence, motion } from 'framer-motion';
import useAuth from 'hooks/useAuth';
import useDispatch from 'hooks/useDispatch';
import useSelector from 'hooks/useSelector';
import React, { useEffect, useRef, useState } from 'react';
import { isBrowser, isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { matchPath } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { SET_CURRENT_THEME } from 'reducer/theme';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import styled, { css, DefaultTheme, ThemeProvider } from 'styled-components';
import { getCSSFontURL, getGradient } from 'util/index';
import { ImageLayoutOptions } from '../enums';
import ITheme from '../types/Theme';
import BackgroundVideo from './component/BackgroundVideo';
import PublicHeader from './component/public-header';
import ProfileVideoHeader from './component/PublicVideoHeader';

const PublicLayoutStyle = styled.div<{ theme: ITheme; applyTheme: boolean }>`
  ${(props) =>
    props.applyTheme &&
    css`
      font-family: '${props?.theme?.font?.family}',
        ${props.theme?.font?.category};
    `}
`;

const BackgroundColor = styled.div<{
  twoPanelLayout?: boolean;
  theme: ITheme;
}>`
  :before {
    position: fixed;
    ${({ twoPanelLayout }: any) =>
      twoPanelLayout ? ' left: 600px;' : ' left: 0;'}
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    z-index: -1;
    ${({ theme }) => {
      const gradient: string = getGradient(
        theme?.background?.subtype || 'solid',
        theme?.background?.gradient,
        theme?.background?.solidColor || '#fff',
      );
      return `background: ${gradient}`;
    }}
    }}
  }
`;

const StyledBackgroundImage = styled.div<{
  twoPanelLayout?: boolean;
  theme: ITheme;
}>`
  position: absolute;
  // z-index: -1;
  .top-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  ${({ theme }) => {
    if (theme?.background?.image) {
      switch (theme?.background?.layout) {
        case ImageLayoutOptions.MIDDLE:
          return `
          bottom: auto;
          right: auto;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          img {
            width: auto;
            height: auto;
            max-width: inherit;
          }
          `;
        case ImageLayoutOptions.TOP:
          return `
          bottom: auto;
          right: auto;
          width: auto;
          height: auto;
          left: 50%;
          transform: translate(-50%, 0);

          img {
            width: auto;
            height: auto;
            max-width: inherit;
          }
        `;
        case ImageLayoutOptions.BOTTOM:
          return `
          top: auto;
          right: auto;
          width: auto;
          height: auto;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);

          img {
            width: auto;
            height: auto;
            max-width: inherit;
          }
        `;
        case ImageLayoutOptions.TOP_COVER:
          return `
          bottom: auto;
          top: 0;
          left:0;
          right:0;`;
        case ImageLayoutOptions.BOTTOM_COVER:
          return `
          top: auto;
          bottom: 0;
          left:0;
          right:0;`;
        case ImageLayoutOptions.MIDDLE_COVER:
          return `
          top: 50%;
          left:0;
          right:0;
          bottom: auto;
          transform: translate(0, -50%);`;
        case ImageLayoutOptions.TITLE:
          return `
          background:url(${theme?.background?.image});
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;

            img{
              display:none;
            }

          `;
        default:
          return css`
            position: fixed;
            right: 0;
            top: 0;
            bottom: 0;
            ${({ twoPanelLayout }: any) =>
              twoPanelLayout ? ' left: 600px;' : ' left: 0;'}
          `;
      }
    }
    return 'display:none';
  }}
`;

const BackgroundImage = styled.img.attrs(
  ({ theme }: { twoPanelLayout?: boolean; theme: ITheme }) => ({
    src: theme?.background?.image,
  }),
)<{ theme: ITheme }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const defaultTheme: DefaultTheme = {
  button: { style: 'outline', buttonColor: 'blue' },
  cover: { size: 'full', isActive: true },
  profile: {
    style: 'outline-hard-shadow-hard-square',
    size: 'default',
    isActive: true,
  },
};

const TopNotification: React.FC<{
  open: boolean;
  user: any;
  onClose: () => void;
}> = ({ open, user, onClose }) => {
  const history = useHistory();
  return open ? (
    <div>
      <div className={`notification-bar info bordered`}>
        <div
          className={`d-flex justify-content-center justify-content-md-between align-items-center flex-wrap flex-md-nowrap`}
        >
          <div className="notification-bar__left-box text-center text-md-left">
            <span className="notification-bar__title">
              <div className="icon icon-close" onClick={onClose}></div>
            </span>
            You are viewing your Pop Page, using the theme{' '}
            {user?.userThemeId?.name}
          </div>
          <div
            onClick={() => {
              history.push('/my-profile');
            }}
            className="btn-bar"
          >
            EDIT MY POP PAGE
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const PurchaseHeader = styled.header`
  background: #000;
  color: #fff;
  margin-bottom: 50px;
  padding: 15px 0;

  .button {
    color: #fff;
    min-width: inherit;
    padding: 0 0 0 10px;

    svg {
      transform: rotate(180deg);
      margin: 0 20px 0 0;
    }
  }

  .user-name-container {
    cursor: pointer;
    display: none;
  }

  .user-name {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 0;
    font-size: 18px;
    line-height: 22px;
  }

  .user-img {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 0 0 10px;
    display: inline-block;
    vertical-align: middle;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const PublicLayout: React.FC<any> = ({
  children,
  showFooter = true,
  enableTheme = false,
  hideTopFooter,
  isApplyModalOpen,
  setIsApplyModalOpen,
  onRevertTheme,
  onApplyTheme,
  isThemeApplying,
  selectedTheme,
  ...props
}: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [topNotificationOpen, setTopNotificationOpen] =
    useState<boolean>(false);
  const publicUser = useSelector((state) => state.appStates?.publicUser);
  const order = useSelector((state) => state.purchaseStates?.order);
  const { user: loggedUser, Logout } = useAuth();

  const match = matchPath(history.location.pathname, {
    path: '/:username',
    exact: true,
    strict: false,
  });

  const purchaseMatch = matchPath(history.location.pathname, {
    path: '/:username/purchase',
    exact: false,
  });

  const congratulationsMatch = matchPath(history.location.pathname, {
    path: '/:username/purchase/congratulations',
    exact: true,
  });

  const themePreviewMatch = matchPath(history.location.pathname, {
    path: '/theme-library/preview',
    exact: true,
  });

  const popslugMatch = matchPath(history.location.pathname, {
    path: '/:username/:popslug',
    exact: true,
  });

  const applyFullTheme: boolean = enableTheme || match;

  const applyModalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      match &&
      !props.twoPanelLayout &&
      publicUser &&
      loggedUser &&
      publicUser?._id === loggedUser?._id
    ) {
      setTopNotificationOpen(true);
    } else {
      setTopNotificationOpen(false);
    }
  }, [match?.path, publicUser, loggedUser]);

  useEffect(() => {
    return () => {
      if (isMobileOnly && props.twoPanelLayout) {
        dispatch({ type: SET_CURRENT_THEME, payload: undefined });
      }
      setIsApplyModalOpen?.(false);
    };
  }, []);

  useEffect(() => {
    if (props.isOnboarding || isApplyModalOpen) {
      setTimeout(() => {
        scrollApplyModalIntoView();
      }, 0);
    }
  }, [isApplyModalOpen, selectedTheme]);

  const scrollApplyModalIntoView = () => {
    if (applyModalRef.current) {
      scrollIntoView(applyModalRef.current, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'start',
      });
    }
  };

  const purchase =
    !props.twoPanelLayout &&
    !congratulationsMatch &&
    !themePreviewMatch &&
    (purchaseMatch || popslugMatch);

  return (
    <div>
      {props.theme?.font && applyFullTheme && (
        <Helmet>
          <link href={getCSSFontURL(props.theme?.font)} rel="stylesheet" />
        </Helmet>
      )}
      <TopNotification
        open={topNotificationOpen}
        user={props.user}
        onClose={() => setTopNotificationOpen(false)}
      />
      {(props.isOnboarding && isMobileOnly && isApplyModalOpen) ||
      (!props.isOnboarding && isApplyModalOpen) ? (
        <ApplyThemeModal
          modalRef={applyModalRef}
          selectedTheme={selectedTheme}
          isThemeApplying={isThemeApplying}
          onRevertTheme={onRevertTheme}
          onApplyTheme={onApplyTheme}
        />
      ) : null}
      <div
        id="public-layout"
        style={{
          position: 'relative',
          zIndex: 0,
        }}
      >
        <ThemeProvider theme={props.theme || defaultTheme}>
          <PublicLayoutStyle applyTheme={applyFullTheme}>
            <div
              id="wrapper"
              className="d-flex flex-column min-vh-100 position-relative"
              style={{ zIndex: 0, overflow: 'hidden' }}
            >
              {/* start Background color*/}
              <AnimatePresence>
                {applyFullTheme && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.6 }}
                  >
                    <BackgroundColor
                      twoPanelLayout={isBrowser && props.twoPanelLayout}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              {/* End Background color*/}

              {/* start Background image*/}
              <AnimatePresence>
                {applyFullTheme && (
                  <motion.div
                    className="bg-holder"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.6 }}
                  >
                    {props.theme?.background?.type === 'image' && (
                      <StyledBackgroundImage
                        twoPanelLayout={isBrowser && props.twoPanelLayout}
                      >
                        <div className="top-overlay"></div>
                        <BackgroundImage />
                      </StyledBackgroundImage>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
              {/* End Background image*/}

              {/* start Background video*/}
              <AnimatePresence>
                {applyFullTheme && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.6 }}
                  >
                    <BackgroundVideo />
                  </motion.div>
                )}
              </AnimatePresence>
              {/* End Background video*/}

              {purchase ? (
                <PurchaseHeader className="purchase-header">
                  <div className="container-fluid d-flex align-items-center justify-content-between">
                    <Button
                      onClick={(e) =>
                        history.push(
                          purchaseMatch
                            ? `/${props.user?.username}/${order?.popId?.popName}`
                            : `/${props.user?.username}`,
                        )
                      }
                      icon={<ChevronRight />}
                      type="text"
                    >
                      Back
                    </Button>
                    <div
                      className="user-name-container"
                      onClick={() => history.push(`/${props.user?.username}`)}
                    >
                      <span className="user-name">
                        {props?.user?.firstName || ''}&nbsp;
                        {props?.user?.lastName || ''}
                      </span>
                      <span
                        className="user-img"
                        style={{
                          background: '#255b87',
                        }}
                      >
                        <img
                          width="50px"
                          height="50px"
                          src={
                            props?.user?.profileImage ||
                            '/assets/images/default-profile-pic.png'
                          }
                          alt="img description"
                        />
                      </span>
                    </div>
                  </div>
                </PurchaseHeader>
              ) : !applyFullTheme ? (
                <div className="mb-100" />
              ) : props.theme?.profile?.profileVideo?.active ? (
                <ProfileVideoHeader
                  theme={props.theme}
                  applyFullTheme={applyFullTheme}
                  user={props.user}
                />
              ) : (
                <PublicHeader
                  theme={props.theme}
                  applyFullTheme={applyFullTheme}
                  user={props.user}
                />
              )}

              <main id="main" className="flex-grow-1">
                <div className="container sm-container mb-80">
                  {React.Children.toArray(children).map((child) => {
                    return React.cloneElement(child as any, {
                      user: props.user,
                      ...props,
                    });
                  })}
                </div>
              </main>
              {showFooter ? (
                purchase ? (
                  <Footer hideTopFooter={hideTopFooter || !match} />
                ) : (
                  <Link to="/" className="public-logo">
                    <OutlinedLogo color={props.theme?.additional?.titleColor} />
                  </Link>
                )
              ) : null}
            </div>
          </PublicLayoutStyle>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default PublicLayout;

import SocialLink from 'types/SocialLink';

export const SET_LOADING = 'SET_LOADING';
export const SET_PUBLIC_USER = 'SET_PUBLIC_USER';
export const SET_EMAIL_NOTIFICATION_CLOSED_BY_USER =
  'SET_EMAIL_NOTIFICATION_CLOSED_BY_USER';
export const RESET_APP_STATE = 'RESET_APP_STATE';
export const SET_APPLY_THEME_MODAL = 'SET_APPLY_THEME_MODAL';
export const SET_SOCIAL_LINK = 'SET_SOCIAL_LINK';
export const SET_WELCOMEMODEL_STATE = 'SET_WELCOMEMODEL_STATE';

export interface IAppState {
  applyThemeModal?: { active: boolean; edit: boolean };
  loading?: boolean;
  publicUser?: any;
  emailNotificationClosedByUser?: boolean;
  socialLinks?: SocialLink[];
  showWelcomeModel?: false;
}

const appStateReducer = (
  state: IAppState = { socialLinks: [] },
  action: { type: string; payload: any },
): IAppState => {
  const { payload, type } = action;
  switch (type) {
    case SET_LOADING:
      return { ...state, loading: payload };
    case SET_PUBLIC_USER:
      return { ...state, publicUser: action.payload };
    case SET_EMAIL_NOTIFICATION_CLOSED_BY_USER:
      return { ...state, emailNotificationClosedByUser: action.payload };
    case SET_SOCIAL_LINK:
      return { ...state, socialLinks: action.payload };
    case SET_WELCOMEMODEL_STATE:
      return { ...state, showWelcomeModel: action.payload };
    case SET_APPLY_THEME_MODAL:
      return {
        ...state,
        applyThemeModal: {
          active: action.payload?.active,
          edit: action.payload?.edit || false,
        },
      };
    case RESET_APP_STATE:
      return {};
    default:
      return state;
  }
};

export default appStateReducer;

import { useIsPresent } from 'framer-motion';
import useDispatch from 'hooks/useDispatch';
import React, { ReactElement, useEffect, useRef } from 'react';
import { isBrowser } from 'react-device-detect';
import {
  SET_CURRENT_THEME,
  SET_SYSTEM_THEMES,
  SET_THEMES,
} from 'reducer/theme';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import styled from 'styled-components';
import ITheme from 'types/Theme';
import { startThemePolling, stopThemePolling } from 'util/index';
import ThemesListingComponent from './components/Editor/ThemesListing';

function ThemesListing({
  className,
  ...rest
}: {
  className?: string;
  setIsApplyModalOpen?: (isOpen: boolean) => void;
}): ReactElement {
  const dispatch = useDispatch();

  const ref: any = useRef();
  const isPresent = useIsPresent();

  useEffect(() => {
    if (!isPresent) {
      dispatch({ type: SET_CURRENT_THEME, payload: undefined });
      rest.setIsApplyModalOpen?.(false);
    }

    return () => {
      if (isPresent && isBrowser) {
        dispatch({ type: SET_CURRENT_THEME, payload: undefined });
        rest.setIsApplyModalOpen?.(false);
      }
    };
  }, [isPresent]);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        scrollIntoView(ref.current!, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
          block: 'start',
        });
      }
    }, 700);

    startThemePolling((userThemes: ITheme[], systemThemes: ITheme[]) => {
      dispatch({ type: SET_THEMES, payload: userThemes });
      dispatch({
        type: SET_SYSTEM_THEMES,
        payload: systemThemes,
      });
    });
    return () => {
      stopThemePolling();
    };
  }, []);

  return (
    <div className={className} ref={ref}>
      <ThemesListingComponent {...rest} />
    </div>
  );
}

export default styled(ThemesListing)`
  padding: 19px 16px 0;
`;

import { Dollar } from 'assets/svgs';
import OrderTable from 'components/Tables/Order';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import useRequestLoader from 'hooks/useRequestLoader';
import TwoPanelLayout from 'layout/TwoPanelLayout';
import { stringify } from 'querystring';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getOrder, getOrders } from '../../api/Order';
import { ORDER_STATUS_FILTER_OPTIONS } from '../../constants';
import { getSortbyParam, parseQuery } from '../../util';
import OrderDetail from './[id]';
const PAGE_LIMIT = 5;

export default function MyPurchases() {
  const history = useHistory();
  const { showLeftView, showRightView } = useControllTwopanelLayoutView();
  const [selected, setSelected] = useState<any>({
    label: 'All',
    value: '',
  });
  const [orders, setOrders] = useState<any>({});
  const [selectedOrder, setSelectedOrder] = useState<any>({});
  const {
    page: pageNumber,
    orderby,
    filterby,
    order: orderId,
  } = parseQuery(useLocation().search);

  const { withLoader } = useRequestLoader();
  useEffect(() => {
    const pageIndex = Number(pageNumber) || 1;
    const skip = (pageIndex - 1) * PAGE_LIMIT;

    const paramsList: any = {
      skip,
      orderId,
      limit: PAGE_LIMIT,
      sort: getSortbyParam(orderby),
      filter: filterby,
      order: 'desc',
      isActive: true,
      type: 'buyer',
    };
    const statusFilter = ORDER_STATUS_FILTER_OPTIONS.find(
      (v) => v.value === filterby,
    ) || { label: 'All', value: '' };
    setSelected(statusFilter);
    withLoader(getOrders(paramsList))
      .then((res) => {
        setOrders(res);
        if (!selectedOrder?._id) {
          const findOrderById = res?.items?.find(
            (order: any) => order._id === orderId,
          );
          findOrderById && setSelected({ label: 'Filter By:', value: '' });
          setSelectedOrder(findOrderById || res?.items?.[0] || {});
        }
      })
      .catch((e: Error) => console.log(e));
  }, [pageNumber, orderby, filterby, orderId]);

  const handleChange = (v: any) => {
    const Parsequery = { page: pageNumber, orderby, filterby };
    Parsequery.filterby = v.value;
    Parsequery.page = '1';
    const queryString = stringify(Parsequery);
    history.push(`?${queryString}`);
  };

  const handlePage = (page: number) => {
    const Parsequery = { page: pageNumber, orderby, filterby };
    Parsequery.page = page.toString();
    const queryString = stringify(Parsequery);
    history.push(`?${queryString}`);
  };

  const updateOrder = async (orderId: string) => {
    const order = await getOrder(orderId).catch(console.log);
    if (order) {
      setOrders((prev: any) => {
        const orders = [...prev.items];
        const orderIndex = orders?.findIndex((o: any) => o._id === orderId);
        if (orderIndex > -1) {
          orders.splice(orderIndex, 1, order);
        }

        return {
          ...prev,
          items: orders,
        };
      });
      setSelectedOrder((prev: any) => {
        if (order._id === prev._id) {
          return order;
        }

        return prev;
      });
    }
  };

  const { totalCount = 0, items = [] } = orders;
  useEffect(() => {
    showLeftView();
  }, []);

  return (
    <div>
      <TwoPanelLayout
        leftView={
          <OrderTable
            icon={<Dollar />}
            key={'_id'}
            title={
              <span>
                <span className="title-text">
                  <span className="title-text-holder">My Purchases :</span>
                  <span className="counter-text">{totalCount} Orders </span>
                </span>
                {/* {seleted.label !== 'All' ? seleted.label : ''} */}
              </span>
            }
            selectProps={{ selected, handleChange }}
            data={items.map((item: any) => {
              return {
                image: item?.seller?.profileImage,
                title: item.title,
                subtitle: (
                  <span className="text-capitalize">{`${
                    item?.seller?.firstName || ''
                  } 
                  ${item?.seller?.lastName || ''}`}</span>
                ),
                status: item.orderStatus,
                ...item,
              };
            })}
            paginationProps={{
              total: totalCount,
              current: parseInt(pageNumber as string) || 1,
              pageSize: PAGE_LIMIT,
              onChange: handlePage,
              showLessItems: window.innerWidth < 600,
              showPrevNextJumpers: window.innerWidth > 600,
            }}
            options={{ date: false, price: false }}
            onRowClick={(row, key) => {
              setSelectedOrder(row);
              showRightView();
            }}
          />
        }
        rightView={
          <div className="p-20">
            <OrderDetail order={selectedOrder} updateOrder={updateOrder} />
          </div>
        }
      />
    </div>
  );
}

import PageTransition from 'layout/page-transition';
import PublicUser from 'layout/publicUser';
import Purchase from 'layout/purchase';
import AddCardAndCheckout from 'pages/purchase/add-a-card-and-checkout';
import Congratulations from 'pages/purchase/congratulations';
import SetPassword from 'pages/purchase/set-password';
import PublicProfile from 'pages/[username]';
import PublicServices from 'pages/[username]/[popslug]';
import React from 'react';
import { Route } from 'react-router-dom';
import { RcAnimatePresence, RcRoute, RcSwitch } from 'routes/private';
const purchaseRoutes = [
  {
    path: '/:username/purchase/add-a-card-and-checkout',
    name: 'add-a-card-and-checkout',
    Component: AddCardAndCheckout,
    exact: true,
  },
  {
    path: '/:username/purchase/congratulations',
    name: 'pop',
    Component: Congratulations,
    exact: true,
  },
  {
    path: '/:username/purchase/set-password',
    name: 'pop',
    Component: SetPassword,
    exact: true,
  },
];

function App(props: any) {
  return (
    <Route path={`/:username`}>
      <PublicUser showFooter={true} hideTopFooter={true}>
        <RcAnimatePresence>
          <RcRoute path={`/:username`} exact>
            <PageTransition>
              <PublicProfile />
            </PageTransition>
          </RcRoute>
          <RcRoute path="/:username/purchase">
            <PageTransition>
              <RcSwitch>
                {purchaseRoutes.map((route) => (
                  <RcRoute key={route.path} path={route.path} exact>
                    <Purchase>
                      <route.Component />
                    </Purchase>
                  </RcRoute>
                ))}
              </RcSwitch>
            </PageTransition>
          </RcRoute>
          <RcRoute path={`/:username/:popslug`} exact>
            <PageTransition>
              <PublicServices />
            </PageTransition>
          </RcRoute>
        </RcAnimatePresence>
      </PublicUser>
    </Route>
  );
}

export default App;

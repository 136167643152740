import { updatePop } from 'api/Pop';
import { update } from 'api/User';
import { ArrowRightFilled } from 'assets/svgs';
import Button from 'components/NButton';
import { ServiceType } from 'enums';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import IPop from 'types/Pop.type';
import * as yup from 'yup';
import { defaultTags } from '../../constants';
import { analyticsReport, getUserSetupUri, setLocalStorage } from '../../util';
import PaymaFrom from './components/PaymaForm';
import PopLiveForm from './components/PopLiveForm';
import ShoutoutFrom from './components/ShoutoutForm';
const shoutoutTags = ['actor', 'influencer'];

const formvalidation = yup.object().shape({
  title: yup.string().max(255).required('This field is required'),
  price: yup
    .number()
    .required('Price should be from $5 to $2500')
    .max(2500, 'Price should be from $5 to $2500')
    .min(5, 'Price should be from $5 to $2500'),
  description: yup.string().max(500),
});
const validationSchema = yup.object().shape({
  payma: formvalidation,
  shoutout: formvalidation,
  poplive: formvalidation,
});

const InterestSetup = styled.div`
  padding: 0 20px 110px;

  @media (max-width: 767px) {
    padding: 0 0 80px;
  }
  .profile--info {
    .description-text {
      font-weight: 500;
    }
  }

  .interests-wrap {
    .header {
      position: relative;
      padding: 0;
      align-items: flex-start;
    }

    .body {
      padding: 15px 0 0;
      border-bottom: none;
    }

    .row-holder {
      align-items: center;

      @media (max-width: 480px) {
        flex-direction: column-reverse;
      }

      .col-75 {
        @media (max-width: 480px) {
          width: 100%;
          padding-top: 15px;
        }
      }
    }

    .checkbox {
      .label-text {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #000;
      }
    }

    .dashed {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .icon {
      overflow: hidden;
      border: none;
    }

    .title {
      font-size: 18px;
      line-height: 24px;
      margin: 0 0 5px;
    }

    .caption {
      color: #67616d;
      font-size: 14px;
      line-height: 19px;
    }

    .text-input.mb-25 {
      margin-bottom: 0 !important;

      @media (max-width: 480px) {
      }
    }

    .text-input.mb-20 {
      margin-bottom: 10px !important;
    }
  }

  .button-holder {
    border-top: 1px solid #dadee2;
    padding: 30px 30px 30px 40px;
    position: fixed;
    left: 0;
    width: 599px;
    bottom: 0;
    background: #fff;
    z-index: 9;

    @media (max-width: 1023px) {
      width: auto;
      right: 0;
    }

    @media (max-width: 767px) {
      padding: 15px 20px;
    }
  }

  .description {
    color: #2f1c43;
    font-size: 15px;
    margin: 0 0 25px;
  }

  .pop-top-content {
    position: relative;
  }

  .checkbox {
    label {
      padding: 0;
    }
  }

  .logo-holder {
    width: 152px;
    margin: 0 0 36px;

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .sp__card {
    position: relative;
    padding: 15px;
    background: none;
    margin: 0 0 20px;
    border-radius: 5px;
    border: 1px solid #d7b3e3;

    &.isActive {
      border-color: transparent;
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 2px solid #1da1f3;
      border-radius: 5px;
      content: '';
      opacity: 0;
      visibility: hidden;
    }

    .dashed {
      display: none;
    }
  }

  .steps-detail {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    margin: 0 0 33px;
  }

  .profile--info {
    position: relative;
  }

  h3 {
    font-weight: 500;
    margin: 0 0 24px;
  }

  h4 {
    font-size: 15px;
    margin: 0;
  }

  h6 {
    font-size: 15px;
    line-height: 18px;
    margin: 0 0 20px;
    font-weight: 500;
  }

  .label-area {
    margin: 0 0 24px;
  }

  .label {
    display: block;
    margin: 0 0 8px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
  }

  .description-text {
    display: block;
    color: #67616d;
    font-size: 14px;
    line-height: 18px;
  }

  .schedule-block {
    .dashed {
      display: none;
    }
  }
`;

const GetTile: React.FC<{ tags: string }> = ({ tags }) => {
  const defaultTagsArray = defaultTags.map((tag) => tag.value).join();
  const tagsArray = tags
    .split(',')
    .filter(
      (tag) =>
        tag.toLocaleLowerCase() !== 'other' && defaultTagsArray.includes(tag),
    );

  if (tagsArray.length)
    return (
      <span className="description-text">
        The following options are the most popular social revenue tools for
        {tagsArray.map((item, index, array) => {
          if (array.length > 1 && array.length - 1 === index)
            return (
              <>
                {' '}
                and <span className="text-capitalize">{pluralize(item)}</span>
              </>
            );
          return <span className="text-capitalize"> {pluralize(item)}</span>;
        })}
        . Please set your prices and other details below.
      </span>
    );

  return (
    <span className="description-text">
      The following options are the most popular social revenue tools. Please
      set your prices and other details below.
    </span>
  );
};
const InterestsSetup: React.FC = () => {
  const { user, setUser } = useAuth();
  const showShoutout =
    user?.tags?.includes(shoutoutTags[0]) ||
    user?.tags?.includes(shoutoutTags[1]);

  const history = useHistory();

  const form = useFormik<{ payma: IPop; shoutout: IPop; poplive: IPop }>({
    validationSchema,
    initialValues: {
      payma: {},
      shoutout: {},
      poplive: {},
    },
    onSubmit: async (values) => {
      setLocalStorage('onBoardingTour', 'true', false);
      // setLocalStorage('showWelcomeModal', 'true', false);

      const { payma, shoutout, poplive } = values;
      delete payma['popName'];
      delete shoutout['popName'];
      delete poplive['popName'];

      const requests: Promise<any>[] = [];

      requests.push(
        updatePop(
          { isActive: poplive.isActive, price: poplive.price },
          poplive?._id!,
        ),
      );

      requests.push(
        updatePop({ isActive: payma.isActive, price: payma.price }, payma._id!),
      );
      if (showShoutout) {
        requests.push(
          updatePop(
            {
              isActive: shoutout.isActive,
              isThumbnailActive: shoutout.isActive,
              price: shoutout.price,
            },
            shoutout._id!,
          ),
        );
      }

      requests.push(
        update({
          timeOffset: poplive.timeZone,
          userSetupStatus: 3,
        }),
      );

      await Promise.all(requests)
        .then((res) => {
          res.pop();
          setUser({
            ...user,
            userSetupStatus: 2,
            links: user.links.map((ln: any) => {
              const elemt = res.find(
                (i: IPop) => i._id === ln?.popLinksId?._id,
              );
              if (elemt) {
                return {
                  ...ln,
                  popLinksId: elemt,
                  isActive: elemt.isActive,
                };
              }
              return ln;
            }),
          });

          history.push(getUserSetupUri(2));
        })
        .catch(console.log);
      analyticsReport({
        category: 'onboarding/interests-setup',
        action: 'submit',
        label: 'Interests',
        data: {
          payma: Boolean(payma?.isActive),
          shoutout: Boolean(shoutout?.isActive),
          poplive: Boolean(poplive?.isActive),
          poplivePrice: Boolean(poplive?.price),
          shoutoutPrice: Boolean(shoutout?.price),
        },
      });
    },
  });

  const {
    handleChange,
    handleBlur,
    values,
    setValues,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = form;

  const { payma, shoutout, poplive } = values;

  const {
    payma: paymaErrors,
    shoutout: shoutoutErrors,
    poplive: popLiveError,
  } = errors;
  const {
    payma: paymaTouched,
    shoutout: shoutoutTouched,
    poplive: popliveTouched,
  } = touched;

  useEffect(() => {
    const links = user.links;
    if (links) {
      const payma = links?.find(
        (i: any) => i?.popLinksId?.popType === ServiceType.PAYMA,
      )?.popLinksId;
      const shoutout = links?.find(
        (i: any) => i?.popLinksId?.popType === ServiceType.SHOUTOUT,
      )?.popLinksId;
      const poplive = links?.find(
        (i: any) => i?.popLinksId?.popType === ServiceType.POPLIVE,
      )?.popLinksId;
      setValues({
        payma: { ...payma, isActive: true },
        shoutout: { ...shoutout, isActive: showShoutout },
        poplive: { ...poplive, timeZone: user.timeOffset, isActive: true },
      });
    }
  }, [showShoutout]);

  return (
    <InterestSetup>
      <div className="profile--info mb-30">
        <div className="steps-detail">
          Step <span className="curr-step">2</span> of{' '}
          <span className="total-step">3</span>
        </div>
        <h3>Set your Pricing.</h3>
        <GetTile tags={user?.tags || ''} />
      </div>
      <h6>Please set the price of each service you would like to offer:</h6>
      <div className="interests-wrap">
        <div>
          <PaymaFrom
            values={payma}
            baseName="payma"
            errors={paymaErrors}
            touched={paymaTouched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <PopLiveForm
            values={poplive}
            baseName="poplive"
            errors={popLiveError}
            touched={popliveTouched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setValues={setValues}
          />
          {showShoutout && (
            <ShoutoutFrom
              baseName="shoutout"
              values={shoutout}
              errors={shoutoutErrors}
              touched={shoutoutTouched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          )}
        </div>
        <div className="text-center button-holder">
          <Button
            onClick={handleSubmit as any}
            isLoading={isSubmitting}
            type="primary"
            size="large"
            block
          >
            Next Step{' '}
            <span className="img">
              <ArrowRightFilled />
            </span>
          </Button>
        </div>
      </div>
    </InterestSetup>
  );
};

export default InterestsSetup;

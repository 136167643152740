import React, { PropsWithChildren } from 'react';
import { components } from 'react-select';
import styled from 'styled-components/macro';
// import vars from 'src/styles/variables';

// ------------------------
// Two line select option
// ------------------------

type SelectCustomItemProps = {
  icon: any;
  label: string;
  type?: string;
};

const StyledItemOption = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  .label {
    flex-grow: 1;
  }
`;
const CustomOption = styled.div`
  &.not_selected
    > div.react-select__option--is-focused.react-select__option.custom_option {
    background-color: transparent;
    color: black;
  }
  &.not_selected:hover > div {
    background-color: #255b87 !important;
    color: white !important;
  }
`;

export const SelectCustomMenu = (
  props: PropsWithChildren<any>,
): JSX.Element => {
  return (
    <>
      <components.Menu className="custom_menu" {...props}>
        {props.children}
      </components.Menu>
    </>
  );
};

export const SelectCustomOption = (
  props: PropsWithChildren<any>,
): JSX.Element => {
  const { isSelected, ...rest } = props;
  return (
    <CustomOption className={`${isSelected ? 'selected' : 'not_selected'}`}>
      <components.Option
        isSelected={isSelected}
        className="custom_option"
        {...rest}
      >
        {props.children}
      </components.Option>
    </CustomOption>
  );
};

export const SelectCustomItem = (props: SelectCustomItemProps): JSX.Element => {
  const { icon, label, type, ...rest } = props;

  return (
    <StyledItemOption className={` ${type}`} {...rest}>
      <span className="label">{label}</span>
      <span className="icon">{icon}</span>
    </StyledItemOption>
  );
};

import { sendEmailVerificationLink } from 'api/User';
import Modal from 'components/modal';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../NButton';

interface Props {
  isOpen: boolean;
  user?: any;
  publicProfile?: boolean;
  onClose: () => void;
}

const PublicWelcomeModal: React.FC<Props> = ({ isOpen, user, onClose }) => {
  const [emailSent, setEmailSent] = useState(false);
  let publicMessage: any = emailSent ? (
    <span>Email has been sent.</span>
  ) : (
    <span>
      Please confirm your email address in order to allow the public to access
      your ecommerece features. If you need another copy of the email please{' '}
      <Link
        style={{ color: 'white', textDecoration: 'underline' }}
        to="#"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          sendEmailVerificationLink(user?.email).then(() => {
            setEmailSent(true);
          });
        }}
      >
        {'click here to resend it.'}
      </Link>
    </span>
  );

  if (!user?.isActiveProfile) {
    publicMessage = (
      <span>
        You are viewing your Pop Page, using the theme "
        {user?.userThemeId?.name}". Your profile is unavailable to the
        public.&nbsp;
        <Link
          to="/account"
          style={{ color: 'white', textDecoration: 'underline' }}
        >
          Click here
        </Link>
        &nbsp;to activate it
      </span>
    );
  }
  return (
    <Modal
      isOpen={isOpen}
      showHeader
      showFooter={false}
      onClose={onClose}
      className={`alert-modal`}
    >
      <div className="text-center">
        <div className="modal-head-area">
          <h2 className="text-capitalize">{user?.firstName}!</h2>
        </div>
        <div className="modal-body-area">
          <p>
            <AnimatePresence exitBeforeEnter initial={false}>
              <motion.div
                key={emailSent ? 'emailSent' : 'notSent'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {publicMessage}
              </motion.div>
            </AnimatePresence>
          </p>
        </div>
        <div className="modal-footer-area">
          <Button onClick={onClose} type="primary" shape="round" size="large">
            OK!
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default PublicWelcomeModal;

import { updatePop } from 'api/Pop';
import { getSocialAccounts } from 'api/social-accounts';
import { deleteLink, update, updateNestedAttribute } from 'api/User';
import classNames from 'classnames';
import { toast } from 'components/toaster';
import { ServiceType } from 'enums';
import useAuth from 'hooks/useAuth';
import useDispatch from 'hooks/useDispatch';
import useSelector from 'hooks/useSelector';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import { SET_SOCIAL_LINK } from 'reducer/appStates';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import styled from 'styled-components';
import swal from 'sweetalert';
import { IAlert, IUserLink } from 'types';
import EditableItem from './components/EditableItem';

interface Props {
  className?: string;
}

function PopEditor({ className }: Props): ReactElement<any> {
  const { user, setUser, isAuthenticated } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [link, setLink] = useState<any>(null);

  const dispatch = useDispatch();
  const socialLinks = useSelector((state) => state?.appStates?.socialLinks);
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      if (ref.current)
        scrollIntoView(ref.current, {
          behavior: 'smooth',
          block: 'start',
        });
    }, 700);
  }, []);

  const onSaveLink = async (requestData: any) => {
    await updateNestedAttribute(user._id, requestData)
      .then((data: any) => {
        const newLinks = user?.links?.map((item: any) => {
          if (item._id === requestData.id) {
            return { ...item, ...requestData.value };
          }
          return item;
        });
        setUser({ ...user, links: newLinks });
      })
      .catch((e: Error) => {
        throw e;
      });
  };

  const onSavePop = async (requestData: any) => {
    try {
      const res = await updatePop(requestData, requestData._id);
      const newLinks = user.links.map((item: any) => {
        if (item?.popLinksId?._id === res._id) {
          return { ...item, isActive: res.isActive, popLinksId: res };
        }
        return item;
      });
      if (requestData.type === ServiceType.POPLIVE) {
        const timeZone = requestData.timeZone;
        if (timeZone !== user.timeOffset) {
          await update({ timeOffset: timeZone });
        }
      }
      setUser({ ...user, links: newLinks, timeOffset: requestData.timeZone });
    } catch (e) {
      throw e;
    }
  };

  const UpdateUser = useCallback(
    async (value) => {
      delete value._id;
      await update(value);
      setUser((u: any) => ({ ...u, ...value }));
    },
    [setUser],
  );

  const updateSocialLinks = useCallback(
    async (value) => {
      const userData = await update({
        socialMediaLinks: value.socialMediaLinks,
      });
      delete value.socialMediaLinks;
      await onSaveLink?.(value);
      setUser((u: any) => ({ ...u, ...userData?.data }));
    },
    [setUser],
  );

  useEffect(() => {
    if (user && id && isAuthenticated) {
      const links = user.links;
      const selectedLink = links.find((l: any) => l._id === id);
      setLink(selectedLink);
    }
  }, [user, id, isAuthenticated]);

  useEffect(() => {
    if (link?.popLinksId?.popType === ServiceType.ADVERTISE) {
      getSocialAccounts().then((res) => {
        dispatch({ type: SET_SOCIAL_LINK, payload: res?.socialMedia || [] });
      });
    }
  }, [link]);

  const handleSubmit = async (values: any) => {
    try {
      if (link.linkType === 'service') {
        await onSavePop(values);
        return;
      }
      if (link.linkType === 'biography') {
        UpdateUser && (await UpdateUser(values));
        return;
      }
      if (link.linkType === 'socialLinks') {
        updateSocialLinks?.(values);
      }
      await onSaveLink(values);
      handleCancel();
    } catch (e) {
      toast.error('Please try again..');
      throw e;
    }
  };

  const handleCancel = () => {
    history.push(
      `/my-profile${link.linkType === 'service' ? '/services' : ''}`,
    );
  };

  const handleDelete = () => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete? ',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    } as IAlert).then(async (willDelete) => {
      if (willDelete) {
        const newLinks =
          user.links?.filter((l: any) => l._id !== link._id) || [];
        try {
          if (link?._id) {
            try {
              await deleteLink(link._id);
              setUser({ ...user, links: newLinks });
            } catch (error) {
              console.log(error);
            }
            history.push(
              `/my-profile/${link.type === 'service' ? 'services' : ''}`,
            );
          }
        } catch (e) {}
      }
    });
  };

  return (
    <div ref={ref} className={classNames('pop__edit', className)}>
      {link && (
        <EditableItem
          type={link?.linkType}
          socialLinks={socialLinks}
          value={
            link.popLinksId
              ? { ...link.popLinksId, timeZone: user?.timeOffset }
              : link
          }
          options={{
            close: false,
            status: true,
            delete:
              link.linkType === 'service'
                ? user?.links.filter(
                    (v: IUserLink) =>
                      v.popLinksId?.popType === link.popLinksId.popType,
                  )?.length > 1
                : true,
          }}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
}

export default styled(PopEditor)``;

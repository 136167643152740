import { createTheme, updateTheme } from 'api/theme';
import { update } from 'api/User';
import { Copy, LogoUpdated } from 'assets/svgs';
import classNames from 'classnames';
import ApplyThemeModal from 'components/ApplyThemeModal';
import NewButton from 'components/NButton';
import HeaderWithOption from 'components/partials/components/profileBack';
import { toast } from 'components/toaster';
import useAuth from 'hooks/useAuth';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import useCopyToClipBoard from 'hooks/useCopyToClipBoard';
import useDispatch from 'hooks/useDispatch';
import useQuery from 'hooks/useQuery';
import useSelector from 'hooks/useSelector';
import React, { useCallback, useEffect, useState } from 'react';
import { UnmountClosed } from 'react-collapse';
import { isMobileOnly } from 'react-device-detect';
import {
  Link,
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { SET_APPLY_THEME_MODAL } from 'reducer/appStates';
import { SET_CURRENT_THEME, SET_THEMES } from 'reducer/theme';
import styled from 'styled-components';
import swal from 'sweetalert';
import { IAlert } from 'types';
import ITheme from 'types/Theme';
import { parseQuery } from 'util/index';
import AvatarDropDown from './components/AvatarDropDown';

const noSubHeader = [
  '/my-sales',
  '/my-purchases',
  '/support',
  '/theme-library',
  '/theme-library/preview',
];

export const Header = styled.header<any>`
  width: 100%;
  padding: 15px 3px;
  background: #000;
  position: relative;
  margin: 0 0 51px;
  z-index: 3;
  border-bottom: 1px solid #d5dade;

  .counter {
    display: inline-block;
    vertical-align: middle;
    min-width: 21px;
    text-align: center;
    color: #fff;
    background: #7ccf78;
    border-radius: 4px;
    font-size: 14px;
    line-height: 17px;
    padding: 2px 3px;
    margin: 0 0 0 5px;
    display: none;

    &.bg-blue {
      background: #255b87;
    }
  }

  .dropdown-menu {
    right: 0px;
    left: auto;
  }

  @media (max-width: 767px) {
    padding: 15px 0;
    z-index: 9;
    margin: 0;
  }

  .logo {
    width: 163px;

    a {
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 14px;
      font-weight: 400;
      color: #255b87;

      svg {
        width: 163px;
      }
    }

    .text-beta {
      margin: 2px 0 0 -2px;
    }
  }
`;

const HeaderLinkArea = styled.div`
  background: #fff;
  // border-top: 1px solid #d5dade;
  // border-bottom: 1px solid #d5dade;
  padding: 8px 15px;
  margin-bottom: 0;
  position: absolute;
  left: 599px;
  right: 0;
  top: calc(100% + 1px);

  &.mobile-menu {
    display: block;
    position: static;
  }

  @media (max-width: 1023px) {
    display: none;

    // left: 0;
    // top: auto;
    // bottom: -52px;
    // z-index: 9;

    // &.is-active {
    //   display: block;
    // }
  }

  @media (max-width: 767px) {
    bottom: 0;
  }

  .header-link-area .btn-round {
    border-radius: 4px;
    font-size: 12px;
    line-height: 15px;
    min-width: 58px;
    padding: 4px 6px 3px;
  }
  .header-link-area__link-holder {
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    color: #303030;
    position: relative;
    padding: 0 0 0 30px;
  }
  .header-link-area__link-holder .icon-star1 {
    position: absolute;
    left: 0;
    top: -2px;
    font-size: 20px;
  }
  .header-link-area__link {
    color: #255b87;
  }
  @media (max-width: 991px) {
    .header-link-area__link-holder {
      margin: 0 -10px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 15px;
    padding-bottom: 15px;
    .header-link-area__link-holder {
      font-size: 14px;
      margin: 0 -5px;
      padding: 0 0 0 20px;
    }
    .header-link-area__link-holder .icon-star1 {
      font-size: 14px;
      top: 1px;
    }
    .header-link-area .btn.btn-round {
      min-width: 58px;
      font-size: 12px;
      text-transform: capitalize;
      margin-right: -5px;
    }
  }
`;

const TwoPanelRightSideSubHeader: React.FC<{
  user: any;
  className?: string;
}> = ({ user, className }) => {
  const [isCopied, copy] = useCopyToClipBoard();
  const { showLeftView } = useControllTwopanelLayoutView();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { themeId, systemThemeCreate: isSystemThemeCreate } = parseQuery(
    location.search,
  );
  const { user: loggedUser, setUser } = useAuth();
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const applyThemeModal = useSelector(
    (state) => state.appStates?.applyThemeModal,
  );
  const {
    current,
    allthemes: userThemes,
  }: { current: ITheme; allthemes: ITheme[] } = useSelector(
    (state) => state.theme,
  );

  const applyTheme = async () => {
    setIsApplying(true);
    const { category, ...selectedTheme } = { ...current };
    const profileImage = selectedTheme?.profile?.profileImage;
    //Delete extra keys before sending
    delete selectedTheme?.profile?.profileImage;
    let res: any;
    if (selectedTheme?._id) {
      res = await updateTheme(selectedTheme._id, {
        ...selectedTheme,
        isActive: true,
        baseURL: `${window.location.host}/${loggedUser.username}`,
        categoryId: category?.value,
      }).catch(console.log);
    } else {
      res = await createTheme({
        ...selectedTheme,
        isSystemTheme:
          isSystemThemeCreate === 'true' &&
          !!loggedUser?.enableSystemThemeAccess,
        baseURL: `${window.location.host}/${loggedUser.username}`,
        isActive: true,
        categoryId: category?.value || '',
      });
    }
    dispatch({
      type: SET_THEMES,
      payload: userThemes.map((item) =>
        item._id === res?.theme?._id ? res.theme : { ...item, isActive: false },
      ),
    });
    let updatedUser = loggedUser;
    updatedUser = await update({ profileImage }).then(({ data }) => data);
    if (res?.theme) {
      setUser({ ...loggedUser, ...updatedUser, userThemeId: res.theme });
    }
    dispatch({
      type: SET_APPLY_THEME_MODAL,
      payload: {
        active: false,
        edit: false,
      },
    });
    if (applyThemeModal?.edit) {
      history.push(
        isSystemThemeCreate === 'true'
          ? '/theme-library'
          : '/my-profile/themes-listing',
      );
    }
    setIsApplying(false);
    showLeftView();
  };

  return (
    <HeaderLinkArea className={classNames('header-link-area', className)}>
      {applyThemeModal?.active ? (
        <ApplyThemeModal
          selectedTheme={current}
          isThemeApplying={isApplying}
          compact
          edit={applyThemeModal?.edit}
          onRevertTheme={() => {
            dispatch({
              type: SET_CURRENT_THEME,
              payload: userThemes.find((theme) => theme.isActive),
            });
            dispatch({
              type: SET_APPLY_THEME_MODAL,
              payload: {
                active: false,
                edit: false,
              },
            });
            if (applyThemeModal?.edit) {
              history.push(
                isSystemThemeCreate === 'true'
                  ? '/theme-library'
                  : '/my-profile/themes-listing',
              );
            }
            showLeftView();
          }}
          onApplyTheme={applyTheme}
        />
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="header-link-area__link-holder"
            id="my-public-profile-url"
          >
            <i className="icon-star1"></i>My Selfie Pop:{' '}
            <Link
              to={`/${user.username}`}
              className="header-link-area__link"
              target="_blank"
            >
              {`${window.location.host}/${user.username}`}
            </Link>
          </div>
          <NewButton
            outline
            size="small"
            icon={<Copy />}
            onClick={() => {
              copy(`${window.location.host}/${user.username}`);
              toast.info('Copied the url');
            }}
            shape="round"
          >
            Copy
          </NewButton>
        </div>
      )}
    </HeaderLinkArea>
  );
};

const SubNav: React.FC<{ items?: any[] }> = ({ items }) => {
  return (
    <div className="sub-items-holder">
      <ul className="navbar-sub-menu">
        {items?.map(({ url, id, name, isHidden, ...rest }, index: number) =>
          isHidden ? null : (
            <li key={index} id={id} className="navbar-sub-menu__item">
              <NavLink
                to={url}
                className="navbar-sub-menu__link"
                activeClassName="is-active"
                {...rest}
              >
                {name}
              </NavLink>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

const ProfileLinkHeader: React.FC<{ user: any }> = ({ user }) => {
  return (
    <HeaderLinkArea className="mobile-menu d-md-none">
      <div className="header-link-area__link-holder" id="my-public-profile-url">
        <i className="icon-star1"></i>My Selfie Pop:{' '}
        <Link
          to={`/${user.username}`}
          className="header-link-area__link"
          target="_blank"
        >
          {`${window.location.host}/${user.username}`}
        </Link>
      </div>
    </HeaderLinkArea>
  );
};

export default function Index(props: any) {
  const { showHeaderMenu = true } = props;
  const [show, setshow] = useState<boolean>(false);
  const { activeView } = useControllTwopanelLayoutView();
  const leftViewActive: boolean = 'left' === activeView;
  const { Logout, user } = useAuth();
  const { pathname } = useLocation();
  const { type, themeId } = useQuery();
  const handleLogout = () => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to log out?',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Logout'],
    } as IAlert).then(async (willLogout) => {
      if (willLogout) {
        Logout();
      }
    });
  };

  useEffect(() => {
    window.onresize = () => {
      if (window.screen.width < 767) {
        if (!show) return;
        return setshow(true);
      }
      return setshow(false);
    };
  }, []);

  const Menu = useCallback(
    () => (
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink
            activeClassName="is-active"
            to="/my-profile"
            className="nav-link"
          >
            My Pop Page
          </NavLink>
          <div className={classNames({ 'is-active': true })}>
            <Switch>
              <Route path="/my-profile/pop-list" exact>
                <HeaderWithOption
                  backUrl={`/my-profile${
                    type === 'services' ? '/services' : ''
                  }`}
                  title="Add A New Pop"
                />
              </Route>
              <Route path="/my-profile/link" exact>
                <HeaderWithOption
                  backUrl={`/my-profile/pop-list?type=${
                    type === 'service' ? 'services' : ''
                  }`}
                  title="Add A New Pop"
                />
              </Route>
              <Route path="/my-profile/link/:id" exact>
                <HeaderWithOption
                  backUrl={`/my-profile${
                    type === 'service' ? '/services' : ''
                  }`}
                  title="Edit Pop"
                />
              </Route>
              <Route path="/my-profile/theme-editor" exact>
                <HeaderWithOption
                  backUrl="/my-profile/themes-listing"
                  title={themeId ? 'Edit Theme' : 'Add Theme'}
                />
              </Route>
              <Route path="/my-profile">
                <SubNav
                  items={[
                    {
                      name: 'My Links',
                      url: '/my-profile',
                      exact: true,
                      id: 'links-nav',
                    },
                    {
                      name: 'My Services',
                      url: '/my-profile/services',
                      exact: true,
                      id: 'services-nav',
                    },
                    {
                      name: 'My Profile',
                      url: '/my-profile/profile',
                      exact: true,
                      id: 'bio-editor-nav',
                    },
                    {
                      name: 'Themes',
                      url: '/my-profile/themes-listing',
                      exact: true,
                      id: 'theme-listing-nav',
                    },
                    {
                      name: 'Analytics',
                      url: '/my-profile/analytics',
                      exact: true,
                    },
                  ]}
                />
              </Route>
            </Switch>
          </div>
          <TwoPanelRightSideSubHeader user={user} />
        </li>
        {user?.showSellerMenu && (
          <li className="nav-item">
            <NavLink
              activeClassName="is-active"
              to="/my-sales"
              className="nav-link"
            >
              My Sales <span className="counter">10</span>
            </NavLink>
          </li>
        )}
        {user.showPurchaseMenu && (
          <li className="nav-item">
            <NavLink
              activeClassName="is-active"
              to="/my-purchases"
              className="nav-link"
            >
              My Purchases <span className="counter bg-blue">4</span>
            </NavLink>
          </li>
        )}
        {user.isAffiliate && (
          <li className="nav-item">
            <NavLink
              activeClassName="is-active"
              to="/account/inner-circle"
              className="nav-link"
              exact
            >
              Inner Circle
            </NavLink>
          </li>
        )}
        <li className="nav-item">
          <NavLink
            activeClassName="is-active"
            to="/account"
            className="nav-link"
          >
            My Account
          </NavLink>
          {pathname !== '/account/inner-circle' && (
            <SubNav
              items={[
                {
                  name: 'Settings',
                  url: '/account',
                  exact: true,
                },
                {
                  name: 'Billing',
                  url: '/account/billing',
                  exact: true,
                  isHidden: !user.showPurchaseMenu,
                },
                {
                  name: 'Wallet',
                  url: '/account/payments',
                  exact: true,
                },
                {
                  name: 'Social Accounts',
                  url: '/account/social-accounts',
                  exact: true,
                },
              ]}
            />
          )}
        </li>
        <li className="nav-item">
          <NavLink
            activeClassName="is-active"
            to="/support"
            className="nav-link"
          >
            Support
          </NavLink>
        </li>
      </ul>
    ),
    [user, type, leftViewActive, themeId, pathname],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const rootelem: any = document.getElementById('root');
    if (noSubHeader.includes(pathname) || !showHeaderMenu) {
      if (!rootelem?.classList.contains('isactive-sub_menu')) {
        rootelem.classList.add('isactive-sub_menu');
      }
    } else {
      if (rootelem?.classList.contains('isactive-sub_menu')) {
        rootelem.classList.remove('isactive-sub_menu');
      }
    }
  }, [pathname]);

  if (!props.user) {
    return <div />;
  }

  return (
    <Header id="header">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <strong className="logo">
          <Link to={showHeaderMenu ? '/my-profile' : '#'}>
            <LogoUpdated />
            <span className="text-beta">beta</span>
          </Link>
        </strong>
        {showHeaderMenu && (
          <>
            <nav className="navbar navbar-expand-md flex-grow-1">
              <button
                className={classNames('navbar-toggler ml-auto', {
                  isActive: show,
                })}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setshow((state: Boolean) => !state)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                {isMobileOnly && <ProfileLinkHeader user={user} />}
                <Menu />
                <div className="user-login-box ml-md-auto user-loggedin">
                  <AvatarDropDown
                    user={props.user}
                    handleLogout={handleLogout}
                  />
                </div>
              </div>

              <UnmountClosed
                isOpened={show}
                theme={{
                  collapse: 'ReactCollapse--collapse  navbar-collapse',
                  content: 'ReactCollapse--content custom-collapse-nav-content',
                }}
                style={{ position: 'absolute' }}
              >
                {/* <Menu /> */}
                <div className="user-login-box ml-md-auto user-loggedin">
                  <AvatarDropDown
                    user={props.user}
                    handleLogout={handleLogout}
                    alwaysOpen={true}
                  />
                </div>
              </UnmountClosed>
            </nav>
          </>
        )}{' '}
      </div>
    </Header>
  );
}

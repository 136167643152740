import { Wallet } from 'assets/svgs';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IBankInfoProps {
  withdrawalPending: string | number;
  withdrawalAvailable: string | number;
  className?: string;
}

const BankInfo: React.FC<IBankInfoProps> = (props) => {
  const { withdrawalAvailable, withdrawalPending, className } = props;
  return (
    <div className={className}>
      <h3 className="section-title">
        <span className="title-icon">
          <Wallet />
        </span>
        Wallet
      </h3>
      <div className="bank-info mb-20">
        <div className="box pending">
          <span className="icon-time"></span>
          <div className="textbox">
            <strong className="amount">
              ${(Number(withdrawalPending) || 0).toFixed(2)}
            </strong>
            <span className="status">Pending</span>
          </div>
        </div>
        <Link to={'/account/payments/withdraw'} className="box available">
          <span className="icon-dollar"></span>
          <div className="textbox">
            <strong className="amount">
              ${(Number(withdrawalAvailable) || 0).toFixed(2)}
            </strong>
            <span className="status">Available for Withdrawal</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default styled(BankInfo)`
  .section-title {
    font-size: 16px;
    font-weight: 500;
  }

  .title-icon {
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    color: #255b87;
    margin: 0 15px 0 0;
  }
`;

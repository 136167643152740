import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IUser from 'types/user';
import Button from '../../components/button';
import Invitation from '../../layout/invitation';

const Welcome: React.FC<{ className: string; user: IUser }> = ({
  className,
  user,
}) => {
  return (
    <div className={className}>
      <Invitation>
        <div className="profile--info text-center">
          <h1>
            <span className="font-light">Hi </span> {user?.firstName}{' '}
            {user?.lastName}!
          </h1>
          <h6>
            We would like to invite you to be one of the first skaterdudes
          </h6>
        </div>
        <div className="image-holder">
          <video
            style={{ width: '100%' }}
            poster="/assets/images/9.png"
            autoPlay
            muted
            loop
            playsInline
          >
            <source
              src="https://t2343148.p.clickup-attachments.com/t2343148/3458164d-89df-408a-b947-4ea04c29d955/screen1background.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="text-center">
          <Link to={`/invite/welcome-1?hash=${user?.invitationHash}`}>
            <Button>START THE TOUR</Button>
          </Link>
        </div>
      </Invitation>
    </div>
  );
};

export default styled(Welcome)`
  .container {
    background: #000;
    color: #fff;
    padding: 3vh 0;
  }
  .profile--info {
    margin: 0 0 4vh;
  }
  .profile--info h1 {
    font-size: 3.8vh;
  }
  .profile--info h6 {
    font-size: 2vh;
  }
  .image-holder {
    max-width: 31.5vh;
    margin: 0 auto 4vh;
  }

  .image-holder img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
  .font-light {
    font-weight: 300;
  }
`;

import * as React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button';
import { IInviteSlideProps } from '../../../interfaces/IInvitation';

const Slide4: React.FC<IInviteSlideProps> = ({
  sliderNumber,
  next,
  className,
}) => {
  return (
    <div className={className}>
      <div className="profile--info mb-10">
        <h1>Share Links</h1>
        <h6>
          Put all your links in one place and make it easy for your followers to
          connect with you.
        </h6>
      </div>
      <div className="image-holder">
        <figure>
          <img src="/assets/images/invitation/Invite-PC4.png" alt="profile" />
        </figure>
      </div>
      <Button onClick={next} classNames={`my-btn-${sliderNumber}`}>
        NEXT
      </Button>
    </div>
  );
};

export default styled(Slide4)`
  .image-holder {
    max-width: 31vh;
  }

  ${({ sliderNumber }) => {
    return `
  .my-btn-${sliderNumber} {
    background: #00516e;
    border-color: #00516e;
  }

  .my-btn-${sliderNumber}:hover {
    background: #003142 !important;
    border-color: #003142 !important;
  }
  `;
  }}
`;

import { fetchCards } from 'api/billing';
import useRequestLoader from 'hooks/useRequestLoader';
import React, { useEffect, useState } from 'react';
import AddCard from '../../components/AddCard';

export default function Billing(props: any) {
  const [cards, setCards] = useState<any[]>([]);
  const { withLoader } = useRequestLoader();

  useEffect(() => {
    fetchUserCards();
  }, []);

  const fetchUserCards = () => {
    withLoader(fetchCards()).then((userCards) => {
      setCards(userCards.sources);
    });
  };

  return (
    <AddCard
      showEmailField={false}
      cards={cards}
      functions={{ onSave: fetchUserCards }}
    />
  );
}

import * as React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button';
import { IInviteSlideProps } from '../../../interfaces/IInvitation';

const Slide5: React.FC<IInviteSlideProps> = ({
  sliderNumber,
  next,
  className,
}) => {
  return (
    <div className={className}>
      <div className="profile--info-heading">
        <h1>Your Pop Page is all about You!</h1>
      </div>
      <div className="image-holder">
        <figure>
          <img
            src="/assets/images/invitation/Invite-Mobile-5.png"
            alt="profile"
            width="100%"
          />
        </figure>
      </div>
      <div className="profile--info">
        <h6>
          This is not a marketplace, but rather your own, private platform. This
          way, you can directly link from your social media without sharing your
          audience or diminishing your brand.
        </h6>
      </div>

      <Button onClick={next}>NEXT</Button>
    </div>
  );
};

export default styled(Slide5)`
  .profile--info-heading {
    margin: 0 0 4vh !important;
  }
  .image-holder {
    max-width: 31vh;
    margin-bottom: 5vh !important;
  }
`;

import React from 'react';
import { Collapse as ReactCollapse } from 'react-collapse';
import useOpenClose from '../hooks/useOpenClose';
interface Props {
  title: string;
  icon?: string;
  percent?: number | string;
  children?: React.ReactNode;
}

const According: React.FC<Props> = ({ title, icon, percent = 0, children }) => {
  // tslint:disable-next-line: react-hooks-nesting
  const [isOpen, onOpen, OnClose, onToggel] = useOpenClose();

  return (
    <div className={`rc-according-social  ${isOpen && 'open'}`}>
      <div className="rc-header" onClick={onToggel}>
        <span className="social--icon">
          {icon ? <img src={icon} /> : <span className="icon-star1"></span>}
        </span>
        <div className="wrap">
          <div className="title-wrap">
            <strong className="title">{title}</strong>
          </div>
        </div>
        <div className="left-part">{percent}%</div>
      </div>
      <ReactCollapse isOpened={isOpen}>
        <div className="content">{children}</div>
      </ReactCollapse>
    </div>
  );
};

export default According;

import { BrowseStack, Chromatic, Star } from 'assets/svgs';
import classNames from 'classnames';
import { AppAlert } from 'components/Model';
import SimpleCard from 'components/SPCards/SimpleCard';
import useControlledState from 'hooks/useControlledState';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import useDispatch from 'hooks/useDispatch';
import useSelector from 'hooks/useSelector';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { deleteTheme } from '../../../../api/theme';
import { SET_THEMES } from '../../../../reducer/theme';
import ITheme from '../../../../types/Theme';
import { arrayFind } from '../../../../util';
import ThemeCard from './ThemeCard';

interface Props {
  selectedTheme?: ITheme;
  setSelectedTheme?(theme: ITheme | undefined): void;
  setIsApplyModalOpen?(isOpen: boolean): void;
  className?: string;
}

const getThumbnail = (theme?: ITheme) => {
  if (theme?.previewThumbnailPath) {
    return theme.previewThumbnailPath;
  }

  return '/assets/images/theme-preview-default.png';
};

const ThemesListing = ({
  className,
  setIsApplyModalOpen,
  selectedTheme,
  setSelectedTheme = (theme) => {},
}: Props) => {
  const { allthemes: userThemes = [] }: { allthemes: ITheme[] } = useSelector(
    (state) => state.theme,
  );
  const { showRightView } = useControllTwopanelLayoutView();
  const [themes, setThemes] = useControlledState([], {
    defaultValue: userThemes,
  });

  const userActiveTheme = arrayFind(userThemes, { isActive: true });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setThemes(userThemes?.filter((theme) => !theme.isActive) || []);
  }, [userThemes]);

  useEffect(() => {
    setSelectedTheme(userActiveTheme);
  }, [userActiveTheme?._id]);

  const handleDelete = async (theme: ITheme) => {
    AppAlert({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this!',
      buttons: ['', { text: 'Delete', closeModal: false }],
      onConfirm: async () => {
        if (!theme?._id) return;
        await deleteTheme(theme._id).then((res: ITheme) => {
          const newThemes = userThemes.filter((t) => t._id !== theme._id);
          dispatch({
            type: SET_THEMES,
            payload: newThemes,
          });
          //  @ts-ignore-block
          swal?.close();
        });
      },
    });
  };

  const clickHandleSelectorTheme = (theme: ITheme) => {
    setSelectedTheme(theme);
    showRightView();

    if (theme._id !== userActiveTheme?._id) {
      setIsApplyModalOpen?.(true);
      return;
    }
    setIsApplyModalOpen?.(false);
  };

  return (
    <div className={classNames(className)}>
      <div className="listing-top">
        <h5 className="listing-title">
          <Chromatic />
          <span className="text"> Manage Your Themes</span>
        </h5>
        <p>
          Choose from one of the themes in your theme collection, browse the
          theme library or make your own new theme from scratch!
        </p>
        <div className="themes-listing-cards row">
          <div className="themes-listing-card col-4 mb-0">
            <ThemeCard
              rendering={userActiveTheme?.isRendering}
              image={getThumbnail(userActiveTheme)}
              title="Current Theme"
              footerIcons={{
                edit: 'Edit',
                add: false,
                delete: false,
              }}
              onClick={async () => {
                setSelectedTheme(userActiveTheme);
                setIsApplyModalOpen?.(false);
              }}
              onEditClick={() => {
                setSelectedTheme(userActiveTheme);
                setIsApplyModalOpen?.(false);
                history.push(
                  `/my-profile/theme-editor?themeId=${userActiveTheme?._id}`,
                );
              }}
            />
          </div>
          <div className="themes-listing-card col-4 mb-0">
            <ThemeCard
              create
              cardText="Create Your Own Theme!"
              cardIcon={<Star />}
              title="Custom Theme"
              footerIcons={{ edit: false, add: false, delete: false }}
              onClick={() => {
                setSelectedTheme(undefined);
                setIsApplyModalOpen?.(false);
                history.push('/my-profile/theme-editor');
              }}
            />
          </div>
          <div className="themes-listing-card col-4 mb-0">
            <ThemeCard
              create
              cardText="Themes"
              cardIcon={<BrowseStack />}
              title="Theme Library"
              footerIcons={{ edit: false, add: false, delete: false }}
              onClick={() => {
                setSelectedTheme(undefined);
                setIsApplyModalOpen?.(false);
                history.push('/theme-library');
              }}
            />
          </div>
        </div>
      </div>
      <hr className="two--panel__divider" />

      <div className="themes-listing-container">
        <h4 className="themes-listing-title mb-15">Your Theme Collection</h4>
        <SimpleCard showHeader={false} classes={{ card: 'px-20 pt-20 mb-40' }}>
          <div className="themes-listing-cards row">
            {themes?.map((theme, index) => (
              <div
                key={`${theme.name}-${index}`}
                className="themes-listing-card col-sm-4"
              >
                <ThemeCard
                  rendering={theme.isRendering}
                  image={getThumbnail(theme)}
                  title={theme.name}
                  footerIcons={{
                    edit: true,
                    add: false,
                    delete: true,
                  }}
                  selected={theme._id === selectedTheme?._id}
                  onClick={() => clickHandleSelectorTheme(theme)}
                  onEditClick={() => {
                    setSelectedTheme(theme);
                    setIsApplyModalOpen?.(false);
                    history.push(
                      `/my-profile/theme-editor?themeId=${theme._id}`,
                    );
                  }}
                  onDeleteClick={() => handleDelete(theme)}
                />
              </div>
            ))}
          </div>
        </SimpleCard>
      </div>
    </div>
  );
};

export default styled(ThemesListing)`
  .listing-top {
    padding-bottom: 14px;

    h5 {
      font-weight: 500;
      margin: 0 0 8px;

      .text {
        display: inline-block;
        vertical-align: middle;
      }
    }

    p {
      font-size: 15px;
    }

    .theme-card.create {
      background-color: #ffffff;
    }

    .listing-title svg {
      width: 28px;
      height: 28px;
      display: inline-block;
      vertical-align: top;
      margin: -4px 16px 0 4px;
    }

    .action {
      justify-content: space-between;
      display: flex;

      .button {
        width: calc(50% - 7px);

        + .button {
          margin: 0;
        }
      }
    }
  }

  .themes-listing-container {
    margin: 0 -16px;
    padding: 20px 16px;
    background: #fff;

    .themes-listing-content {
      font-size: 15px;
      line-height: 20px;
      color: #655f5f;
    }
  }

  .themes-listing-cards {
    margin: 0 -8px;

    @media (max-width: 1023px) {
      justify-content: center;
    }

    .card-rendering {
      > img {
        display: none;
      }
    }
  }

  .themes-listing-card .theme-card.create {
    padding: 13px;
    min-height: 353px;
  }

  .themes-listing-card .theme-card.create h6 {
    font-size: 22px;
    line-height: 26px;
  }

  .themes-listing-card .theme-card {
    min-height: 371px;
    border-color: #e9e9eb;

    &:hover {
      border-color: #9fa8da;
    }
  }

  .two--panel__divider {
    margin: 0 -16px;
    height: 1px;
  }

  .card {
    background: none;
    border: none;
    padding: 0 !important;
  }

  .themes-listing-card:not(.col-4) {
    @media (max-width: 1023px) {
      width: 249px;
      padding: 0 10px;
      margin: 0 0 20px;
      flex: inherit;
      max-width: inherit;
    }

    @media (max-width: 767px) {
      width: 176px;
      padding: 0 5px;
    }
  }

  .themes-listing-card.col-4 {
    .theme-card,
    .theme-card.create {
      @media (max-width: 767px) {
        height: 190px;
      }

      h6 {
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    .theme-card-footer .title {
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    .theme-card-footer button {
      @media (max-width: 767px) {
        width: 22px;
        height: 22px;
      }
    }

    .theme-card-text {
      @media (max-width: 767px) {
        padding: 10px 0 0;
      }
    }
  }

  .themes-listing-card {
    padding: 0 8px;
    margin: 0 0 20px;

    @media (max-width: 767px) {
      padding: 0 5px;
    }

    .theme-card {
      min-height: inherit;
      height: 273px;

      @media (max-width: 1023px) {
        height: 371px;
      }

      @media (max-width: 767px) {
        height: 257px;
      }

      &.create {
        height: 273px;
        min-height: inherit;

        @media (max-width: 1023px) {
          height: 376px;
        }

        @media (max-width: 767px) {
          height: 262px;
        }

        .theme-card-inner {
          &:before {
            display: none;
          }
        }

        h6 {
          font-size: 16px;
          line-height: 20px;
          color: #9fa8da;
        }
      }

      .theme-card-image {
        max-height: inherit;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

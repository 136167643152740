import { AnimatePresence } from 'framer-motion';
import GuestLayout from 'layout/guest';
import InnerInviteLayout from 'layout/InnerInviteLayout';
import InvitationWrapper from 'layout/InvitationWrapper';
import PageTransition from 'layout/page-transition';
// Layouts
import PrivateLayout from 'layout/PrivateLayout';
import PublicLayout from 'layout/public';
import PublicVideoLayout from 'layout/PublicVideoLayout';
import SelfiePopLayout from 'layout/selfie-pop';
import SinglePanelLayout from 'layout/SinglePanelLayout';
import ThemeSelector from 'layout/ThemeSelector';
import TwoPanelLayout from 'layout/TwoPanelLayout';
import Page404 from 'pages/404';
import AccountSetting from 'pages/account/account-info';
import AffliatedPage from 'pages/account/affiliate-program';
import Billing from 'pages/account/billing';
import Payments from 'pages/account/payments/index';
import Withdraw from 'pages/account/payments/withdraw';
import PublicVideo from 'pages/account/public-video';
import SocialAccounts from 'pages/account/social-accounts';
import Support from 'pages/account/support';
import ActivateAccount from 'pages/activate-account';
import Analytics from 'pages/analytics';
import ComingSoon from 'pages/coming-soon';
import ComingSoonForm from 'pages/coming-soon/form';
import Invite from 'pages/invite';
import InnerCircleInvite from 'pages/invite/inner-circle';
import Welcome from 'pages/invite/welcome';
import Welcome1 from 'pages/invite/welcome-1';
import Welcome2 from 'pages/invite/welcome-2';
import Welcome3 from 'pages/invite/welcome-3';
import Welcome4 from 'pages/invite/welcome-4';
import Welcome5 from 'pages/invite/welcome-5';
import Welcome6 from 'pages/invite/welcome-6';
// Pages
import Login from 'pages/login';
import Logout from 'pages/logout';
import Markups from 'pages/markups';
import BioEditor from 'pages/my-profile/bio-editor';
import PopCreate from 'pages/my-profile/createPop';
import NewProfilePage from 'pages/my-profile/new-profile';
import EditPop from 'pages/my-profile/pop-editor';
import PopTypesList from 'pages/my-profile/PopList';
import ThemeEditor from 'pages/my-profile/theme-editor';
import ThemeListing from 'pages/my-profile/themes-listing';
import MyPuchase from 'pages/my-purchases';
import MySales from 'pages/my-sales';
import OnBoardingInterest from 'pages/onboarding/interests-setup';
import OnBoardingProfilePhoto from 'pages/onboarding/profile-photo';
import OnBoardingSetPassword from 'pages/onboarding/set-password';
import OnBoardingThemeSelection from 'pages/onboarding/theme-selection';
import Policy from 'pages/policy';
import ProfileUnavailable from 'pages/profile-unavailable';
import RecoverPassword from 'pages/recover-password';
import ResetPassword from 'pages/reset-password';
import ResetPasswordRecoveryLink from 'pages/reset-password-recovery-link';
import ContestThisOrder from 'pages/review/contest-this-order';
import ContestThisOrderInfo from 'pages/review/contest-this-order-info';
import RateYourOrder from 'pages/review/rate-your-order';
import SignUp from 'pages/signup';
import Terms from 'pages/terms';
import ThemeLibrary from 'pages/theme-library';
import ThemePreview from 'pages/theme-library/preview';
import EmailVerify from 'pages/verify-email';
import PublicProfile from 'pages/[username]';
import React, { cloneElement, Fragment, ReactElement } from 'react';
import { Route, RouteProps, SwitchProps } from 'react-router';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import PublicRoutes from './public';

export const EmptyLayout: React.FC = ({ children, ...rest }) => {
  return (
    <Fragment>{React.cloneElement(children as ReactElement, rest)}</Fragment>
  );
};

export const RcRoute: React.FC<
  { privateFC?: boolean; layoutProps?: any } & RouteProps
> = ({ children, layoutProps, privateFC, exact, path, ...rest }: any) => {
  if (privateFC)
    return (
      <Route exact={exact} path={path} key={path} {...rest}>
        <PrivateLayout {...layoutProps}>
          <SelfiePopLayout {...layoutProps}>
            {cloneElement(children, { ...rest })}
          </SelfiePopLayout>
        </PrivateLayout>
      </Route>
    );

  return (
    <Route exact={exact} path={path} key={path} {...rest}>
      {cloneElement(children, { ...rest })}
    </Route>
  );
};

export const RcSwitch: React.FC<
  {
    privateFC?: boolean;
    layoutProps?: any;
    key?: string | number;
  } & SwitchProps
> = ({ privateFC, layoutProps, children, location, key, ...rest }) => {
  if (privateFC)
    return (
      <Switch>
        <PrivateLayout {...layoutProps}>
          <SelfiePopLayout {...layoutProps}>
            {React.Children.toArray(children).map((child) => {
              return cloneElement(child as any, { RcSwitch: 'yes', ...rest });
            })}
          </SelfiePopLayout>
        </PrivateLayout>
      </Switch>
    );

  return (
    <Switch>
      {React.Children.toArray(children).map((child) => {
        return cloneElement(child as any, { RcSwitch: 'yes', ...rest });
      })}
    </Switch>
  );
};

export const RcAnimatePresence: React.FC<any> = ({ children, ...rest }) => {
  const location = useLocation();

  return (
    <AnimatePresence initial={false}>
      <Switch location={location} key={location.pathname}>
        {React.Children.toArray(children).map((child) => {
          return cloneElement(child as any, {
            AnimatePresence: 'yes',
            ...rest,
          });
        })}
      </Switch>
    </AnimatePresence>
  );
};

export interface IRoutes extends Partial<RouteProps> {
  name?: string;
  Component: any;
  RouteComponent?: React.ElementType;
  Layout?: React.ElementType;
  layoutProps?: { [key: string]: any };
}

///Sign up protection
// const SignUpProtection = () => {
//   const history = useHistory();
//   const [pass, setPass] = useState<any>();

//   useEffect(() => {
//     const tpass = window.prompt("What's the sceret pass?", '');
//     if (tpass !== '@p9"zGFtQA&9-H2J') {
//       history.push('/login');
//     }
//     setPass(tpass);
//   }, []);
//   // console.log('hello');

//   if (pass === '@p9"zGFtQA&9-H2J') {
//     return <SignUp />;
//   } else {
//     return null;
//   }
// };

const invitationRoutes: IRoutes[] = [
  {
    path: '/invite',
    Component: Invite,
    exact: true,
  },
  {
    path: '/invite/welcome',
    Component: Welcome,
    exact: true,
  },
  {
    path: '/invite/welcome-1',
    Component: Welcome1,
    exact: true,
  },
  {
    path: '/invite/welcome-2',
    Component: Welcome2,
    exact: true,
  },
  {
    path: '/invite/welcome-3',
    Component: Welcome3,
    exact: true,
  },
  {
    path: '/invite/welcome-4',
    Component: Welcome4,
    exact: true,
  },
  {
    path: '/invite/welcome-5',
    Component: Welcome5,
    exact: true,
  },
  {
    path: '/invite/welcome-6',
    Component: Welcome6,
    exact: true,
  },
];

const innerCircleInviteRoutes: IRoutes[] = [
  {
    path: '/innercircle/:username',
    Component: InnerCircleInvite,
    exact: true,
  },
];

const routes: IRoutes[] = [
  {
    path: '/Markups',
    Component: Markups,
    exact: true,
  },
  {
    name: 'Coming Soon',
    path: '/coming-soon',
    Component: ComingSoon,
    Layout: EmptyLayout,
    exact: true,
  },
  {
    name: 'Coming Soon Form',
    path: '/coming-soon/registration',
    Component: ComingSoonForm,
    Layout: EmptyLayout,
    exact: true,
  },
  {
    name: 'Dashboard',
    path: '/',
    Component: () => <Redirect to="/my-profile" />,
    exact: true,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    Component: () => <Redirect to="/my-profile" />,
    exact: true,
  },
  {
    name: 'login',
    path: '/login',
    Component: Login,
    Layout: GuestLayout,
    exact: true,
  },
  {
    name: 'Logout',
    path: '/logout',
    Component: Logout,
    Layout: GuestLayout,
    exact: true,
  },
  {
    name: 'Verify Email',
    path: '/verify-email',
    Component: EmailVerify,
    exact: true,
  },
  {
    name: 'Reset Password',
    path: '/reset-password',
    Component: ResetPassword,
    exact: true,
  },
  {
    name: 'Activate Account',
    path: '/activate-account',
    Component: ActivateAccount,
    exact: true,
  },
  {
    name: 'Reover Password',
    path: '/reset-password-recovery-link',
    Component: ResetPasswordRecoveryLink,
    exact: true,
  },
  {
    name: 'Terms',
    path: '/terms',
    Component: Terms,
    exact: true,
  },
  {
    name: 'Policy',
    path: '/policy',
    Component: Policy,
    exact: true,
  },
  {
    name: 'Recover Password',
    path: '/recover-password',
    Component: RecoverPassword,
    exact: true,
  },
  {
    name: 'signup',
    path: '/signup',
    Component: SignUp,
    Layout: GuestLayout,
    exact: true,
  },
  // {
  //   name: 'signup',
  //   path: '/founder',
  //   Component: SignUp,
  //   Layout: GuestLayout,
  //   exact: true,
  // },
  // {
  //   name: 'signup',
  //   path: '/founder',
  //   Component: SignUp,
  //   Layout: GuestLayout,
  //   exact: true,
  // },
  {
    name: '404',
    path: '/404',
    Component: Page404,
    exact: true,
  },
  {
    name: 'profile-unavailable',
    path: '/profile-unavailable',
    Component: ProfileUnavailable,
    exact: true,
  },
];

export default function Routes(): ReactElement {
  return (
    <Switch>
      {routes.map((route, index: number) => {
        const {
          Component,
          Layout,
          RouteComponent,
          name,
          path,
          exact,
          layoutProps,
        } = route;

        const CLayout = Layout || EmptyLayout;
        const CRoute = RouteComponent || Route;

        return (
          <CRoute key={index} name={name} path={path} exact={exact}>
            <CLayout key={path} {...layoutProps}>
              <Component />
            </CLayout>
          </CRoute>
        );
      })}

      <RcRoute path="/review/contest-this-order" exact privateFC>
        <SinglePanelLayout>
          <ContestThisOrder />
        </SinglePanelLayout>
      </RcRoute>
      <RcRoute path="/review/contest-this-order-info" exact privateFC>
        <SinglePanelLayout>
          <ContestThisOrderInfo />
        </SinglePanelLayout>
      </RcRoute>

      <RcRoute path="/shoutout/view/:orderId" exact>
        <PublicVideoLayout>
          <SinglePanelLayout>
            <PublicVideo />
          </SinglePanelLayout>
        </PublicVideoLayout>
      </RcRoute>

      <RcRoute path="/review/rate-your-order" exact privateFC>
        <SinglePanelLayout>
          <RateYourOrder />
        </SinglePanelLayout>
      </RcRoute>

      <RcRoute path="/my-sales" exact privateFC>
        <MySales />
      </RcRoute>
      <RcRoute path="/my-purchases" exact privateFC>
        <MyPuchase />
      </RcRoute>
      <RcRoute path="/support" exact privateFC>
        <Support />
      </RcRoute>
      <RcRoute path="/theme-library" exact privateFC>
        <ThemeLibrary />
      </RcRoute>
      <RcRoute path="/theme-library/preview" exact privateFC>
        <ThemePreview />
      </RcRoute>

      <RcRoute path="/account" privateFC>
        <RcSwitch>
          <RcRoute path="/">
            <SinglePanelLayout>
              <RcSwitch>
                <RcRoute path="/account" exact>
                  <AccountSetting />
                </RcRoute>
                <RcRoute path="/account/social-accounts" exact>
                  <SocialAccounts />
                </RcRoute>
                <RcRoute path="/account/billing" exact>
                  <Billing />
                </RcRoute>
                <RcRoute path="/account/inner-circle" exact>
                  <AffliatedPage />
                </RcRoute>
                <RcRoute path="/account/payments/withdraw" exact>
                  <Withdraw />
                </RcRoute>
                <RcRoute path="/account/payments" exact>
                  <Payments />
                </RcRoute>
              </RcSwitch>
            </SinglePanelLayout>
          </RcRoute>
        </RcSwitch>
      </RcRoute>
      <RcRoute
        path="/onboarding"
        privateFC
        layoutProps={{ showHeader: false, showHeaderMenu: false }}
      >
        <ThemeSelector isOnboarding>
          <TwoPanelLayout
            classes={{ leftView: 'bg-white' }}
            defaultBackButton={false}
          >
            <RcAnimatePresence>
              {/* Left Panel */}
              <RcRoute path="/onboarding/profile-photo" exact>
                <PageTransition>
                  <OnBoardingProfilePhoto />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/onboarding/theme-selection" exact>
                <PageTransition>
                  <OnBoardingThemeSelection />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/onboarding/interests-setup" exact>
                <PageTransition>
                  <OnBoardingInterest />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/onboarding/set-password" exact>
                <PageTransition>
                  <OnBoardingSetPassword />
                </PageTransition>
              </RcRoute>
            </RcAnimatePresence>
            {/* Right */}
            <RcSwitch>
              <RcRoute path="/onboarding">
                <PublicLayout showFooter={false} enableTheme={true}>
                  <PublicProfile />
                </PublicLayout>
              </RcRoute>
            </RcSwitch>
          </TwoPanelLayout>
        </ThemeSelector>
      </RcRoute>

      <RcRoute path="/my-profile" privateFC>
        <ThemeSelector>
          <TwoPanelLayout
            classes={{ content: 'p-0' }}
            defaultBackButton={false}
          >
            <RcAnimatePresence>
              {/* Left Panel */}
              <RcRoute path="/my-profile/profile" exact>
                <PageTransition>
                  <BioEditor />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/my-profile/themes-listing" exact>
                <PageTransition>
                  <ThemeListing />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/my-profile/services" exact>
                <PageTransition>
                  <NewProfilePage type="services" />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/my-profile" exact>
                <PageTransition>
                  <NewProfilePage type="links" />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/my-profile/theme-editor" exact>
                <PageTransition>
                  <ThemeEditor />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/my-profile/analytics" exact>
                <PageTransition>
                  <Analytics />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/my-profile/link/:id" exact>
                <PageTransition>
                  <EditPop />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/my-profile/pop-list" exact>
                <PageTransition>
                  <PopTypesList />
                </PageTransition>
              </RcRoute>
              <RcRoute path="/my-profile/link" exact>
                <PageTransition>
                  <PopCreate />
                </PageTransition>
              </RcRoute>
            </RcAnimatePresence>
            {/* Right */}
            <RcSwitch>
              <RcRoute path="/my-profile">
                <PublicLayout showFooter={false} enableTheme={true}>
                  <PublicProfile />
                </PublicLayout>
              </RcRoute>
            </RcSwitch>
          </TwoPanelLayout>
        </ThemeSelector>
      </RcRoute>

      <Route path="/innercircle/:username">
        <InnerInviteLayout>
          <RcAnimatePresence>
            {innerCircleInviteRoutes.map((route, index: number) => {
              const { Component, Layout, path, exact, layoutProps } = route;

              const CLayout = Layout || EmptyLayout;
              return (
                <RcRoute key={index} path={path} exact={exact}>
                  <PageTransition>
                    <CLayout key={path} {...layoutProps}>
                      <Component />
                    </CLayout>
                  </PageTransition>
                </RcRoute>
              );
            })}
          </RcAnimatePresence>
        </InnerInviteLayout>
      </Route>

      <Route path="/invite">
        <InvitationWrapper>
          <RcAnimatePresence>
            {invitationRoutes.map((route, index: number) => {
              const { Component, Layout, path, exact, layoutProps } = route;

              const CLayout = Layout || EmptyLayout;
              return (
                <RcRoute key={index} path={path} exact={exact}>
                  <PageTransition>
                    <CLayout key={path} {...layoutProps}>
                      <Component />
                    </CLayout>
                  </PageTransition>
                </RcRoute>
              );
            })}
          </RcAnimatePresence>
        </InvitationWrapper>
      </Route>

      <PublicRoutes />
    </Switch>
  );
}

import { ArrowRightFilled } from 'assets/svgs';
import Button from 'components/NButton';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import ThemeLibrary from 'pages/theme-library';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { analyticsReport } from 'util/index';

const InterestSetup = styled.div`
  padding: 0 20px 110px;

  @media (max-width: 767px) {
    padding: 0 0 80px;
  }
  .profile--info {
    .description-text {
      font-weight: 500;
    }
  }

  .interests-wrap {
    .header {
      position: relative;
      padding: 0;
      align-items: flex-start;
    }

    .body {
      padding: 15px 0 0;
      border-bottom: none;
    }

    .row-holder {
      align-items: center;
    }

    .checkbox {
      .label-text {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #000;
      }
    }

    .dashed {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .icon {
      overflow: hidden;
      border: none;
    }

    .caption {
      color: #67616d;
      font-size: 14px;
      line-height: 19px;
    }

    .text-input.mb-25 {
      margin-bottom: 0 !important;
    }

    .text-input.mb-20 {
      margin-bottom: 10px !important;
    }
  }

  .button-holder {
    border-top: 1px solid #dadee2;
    padding: 30px 30px 30px 40px;
    position: fixed;
    left: 0;
    width: 599px;
    bottom: 0;
    background: #fff;
    z-index: 9;

    @media (max-width: 1023px) {
      width: auto;
      right: 0;
    }

    @media (max-width: 767px) {
      padding: 15px 20px;
    }
  }

  .description {
    color: #2f1c43;
    font-size: 15px;
    margin: 0 0 25px;
  }

  .pop-top-content {
    position: relative;
  }

  .checkbox {
    label {
      padding: 0;
    }
  }

  .logo-holder {
    width: 152px;
    margin: 0 0 36px;

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .sp__card {
    position: relative;
    padding: 15px;
    background: none;
    margin: 0 0 20px;
    border-radius: 5px;
    border: 1px solid #d7b3e3;

    &.isActive {
      border-color: transparent;
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 2px solid #1da1f3;
      border-radius: 5px;
      content: '';
      opacity: 0;
      visibility: hidden;
    }

    .dashed {
      display: none;
    }
  }

  .steps-detail {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    margin: 0 0 33px;
  }

  .profile--info {
    position: relative;
  }

  h3 {
    font-weight: 500;
    margin: 0 0 24px;
  }

  h4 {
    font-size: 15px;
    margin: 0;
  }

  h6 {
    font-size: 15px;
    line-height: 18px;
    margin: 0 0 20px;
    font-weight: 500;
  }

  .label-area {
    margin: 0 0 24px;
  }

  .label {
    display: block;
    margin: 0 0 8px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
  }

  .description-text {
    display: block;
    color: #67616d;
    font-size: 14px;
    line-height: 18px;
  }

  .schedule-block {
    .dashed {
      display: none;
    }
  }

  .subtext {
    font-weight: 600;
    margin-bottom: 15px;
  }
`;

const ThemeSelection: React.FC<any> = ({
  selectedTheme,
  setSelectedTheme,
  onApplyTheme,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showRightView, showLeftView } = useControllTwopanelLayoutView();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onApplyTheme?.();
    setIsSubmitting(false);
    analyticsReport({
      category: 'onboarding/theme-selection',
      action: `${selectedTheme?._id} theme selected`,
      label: 'Theme selection',
      data: {
        isDefault: selectedTheme?.isDefault,
      },
    });
  };

  useEffect(() => {
    showLeftView();
  }, []);

  return (
    <InterestSetup>
      <div className="profile--info mb-30">
        <div className="steps-detail">
          Step <span className="curr-step">3</span> of{' '}
          <span className="total-step">3</span>
        </div>
        <h3>Choose a Theme</h3>
        <span className="description-text">
          Pick a theme. You’ll have the option to edit, customize and create
          your own theme after set up is complete.
        </span>
      </div>
      <div className="interests-wrap">
        <ThemeLibrary
          isOnboarding
          onCardClick={(theme) => {
            setSelectedTheme?.(theme);
            showRightView();
            props.setIsApplyModalOpen?.(true);
          }}
        />
        <div className="text-center button-holder">
          <Button
            onClick={handleSubmit as any}
            isLoading={isSubmitting}
            type="primary"
            size="large"
            block
          >
            Next Step{' '}
            <span className="img">
              <ArrowRightFilled />
            </span>
          </Button>
        </div>
      </div>
    </InterestSetup>
  );
};

export default ThemeSelection;

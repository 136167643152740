import { AddImage, RecycleBin, TickStar, Upload } from 'assets/svgs';
import CardBox from 'components/AddCard/CardBox';
import { AttachmentBar } from 'components/Attachments';
import Checkbox from 'components/checkbox';
import ColorPicker from 'components/ColorPicker';
import DateSelector from 'components/DateSelector';
import DotIndicator from 'components/dot-indicator';
import FocusInput from 'components/focus-input';
import FileUpload from 'components/Fulfillment/FileUpload';
import FulfillmentButtons from 'components/Fulfillment/FulfillmentButtons';
import FulfillmentHeader from 'components/Fulfillment/FulfillmentHeader';
import FulfillmentPriceWidget from 'components/Fulfillment/FulfillmentPriceWidget';
import FulfillmentQuestions from 'components/Fulfillment/FulfillmentQuestions';
import OrderInfoWidget from 'components/Fulfillment/OrderInfoWidget';
import Image from 'components/Image';
import LinkWidget from 'components/LinkWidget';
import Loader from 'components/loader';
import Model from 'components/Model';
import NewButton from 'components/NButton';
import Pagination from 'components/pagination';
import ProfileBack from 'components/partials/components/profileBack';
import Header from 'components/partials/header';
import TabHeader from 'components/partials/TabHeader';
import PopLiveLinkWidget from 'components/PopLiveLinkWidget';
import { default as Radio, default as RadioGroup } from 'components/RadioGroup';
import CRate from 'components/rate';
import Select from 'components/Select';
import Slider from 'components/slider';
import SwitchBox from 'components/switchboxWidget';
import HeaderOption from 'components/Tables/components/Header';
import OrderTable from 'components/Tables/Order';
import InlineTagger from 'components/Tags/InlineTagger';
import OutlineTagger from 'components/Tags/OutlineTagger';
import { toast } from 'components/toaster';
import UploadThumbnail from 'components/UploadThumbnail';
import UploadWedigt from 'components/UploadWidget';
import dayjs from 'dayjs';
import useAuth from 'hooks/useAuth';
import useCopyToClipBoard from 'hooks/useCopyToClipBoard';
import { Orders, walletHistory } from 'mock-data';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BankInfo from './account/payments/components/BankInfo';
import TransactionsList from './account/payments/components/TransactionsList';
import BackgroundImageSelector from './my-profile/components/Editor/BackgroundImageSelector';
import ColorSelector from './my-profile/components/Editor/BackgroupEditor';
import ThemeCard from './my-profile/components/Editor/ThemeCard';
import PaymentWidget from './purchase/component/PaymentWidget';

const order = {
  orderStatus: 'Completed',
  orderNumber: '3243-815039-5320',
  popType: 'additional-services',
  isDeleted: false,
  videoLink: null,
  videoName: null,
  dueDate: '2021-05-23T07:30:04.000Z',
  paymaQuestion: null,
  paymaAnswer: null,
  popLiveEventStartDateTime: null,
  videoEncodeComplete: 'incomplete',
  paymentIntentId: 'pi_1Isv8oKJHvJLirylqoJxJCuw',
  processingStatus: 'Awaiting Buyer Review',
  expireEmailDelivered: false,
  orderPlatformFee: 0.88,
  rate: null,
  review: null,
  qualifiedAffiliateId: null,
  qualifiedSuperAffiliateId: null,
  disputeStartedAt: null,
  disputeCompletedAt: null,
  _id: '60a5673c5c53742ecd5d18dd',
  questions: [
    {
      responseValue: '',
      responseOptions: [
        {
          _id: '608319b205067430d4abafcd',
          key: '0',
          text: 'Ans1',
          value: false,
        },
        {
          _id: '608319b205067430d4abafce',
          key: '1',
          text: ' Ans2',
          value: true,
        },
        {
          _id: '608319b205067430d4abafcf',
          key: '2',
          text: ' Ans3',
          value: false,
        },
      ],
      attachements: [],
      _id: '606b62f19870eb380910fda2',
      title: 'MCQ question for AS',
      text: 'AS1, AS2, AS3',
      responseType: 'selectList',
    },
    {
      responseValue: 'Test AS Answer',
      responseOptions: [],
      attachements: [],
      _id: '606b62f19870eb380910fda3',
      title: 'Test AS question',
      responseType: 'text',
    },
    {
      responseValue: '',
      responseOptions: [],
      attachements: [],
      _id: '60a564ee5c53742ecd5d18c4',
      title: 'Upload a file',
      responseType: 'file',
    },
  ],
  popId: {
    duration: 15,
    isActive: false,
    popType: 'additional-services',
    isComplete: false,
    isDeleted: false,
    isUserDefault: false,
    popThumbnail:
      'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/975877a1-ec59-4f04-9d90-382e5c7515dd.jpg',
    isThumbnailActive: false,
    _id: '606b62f19870eb380910fd9f',
    title: 'AS Hello',
    price: 20,
    description: 'Test AS',
    additionalArt: [
      {
        isActive: true,
        _id: '606b62f19870eb380910fda0',
        artPath:
          'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/670e22a7-3bbe-4501-a40b-245310d10aa5.png',
        artName: 'test.png',
        artType: 'image',
      },
      {
        isActive: true,
        _id: '606b62f19870eb380910fda1',
        artPath:
          'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/cb3f275c-a7f4-4350-baaa-666b68d52686.jpg',
        artName: 'image2.jpg',
        artType: 'image',
      },
    ],
    questions: [
      {
        isActive: true,
        responseOptions: [
          {
            _id: '608319b205067430d4abafcd',
            key: '0',
            text: 'Ans1',
            value: false,
          },
          {
            _id: '608319b205067430d4abafce',
            key: '1',
            text: ' Ans2',
            value: false,
          },
          {
            _id: '608319b205067430d4abafcf',
            key: '2',
            text: ' Ans3',
            value: false,
          },
        ],
        _id: '606b62f19870eb380910fda2',
        title: 'MCQ question for AS',
        text: 'AS1, AS2, AS3',
        responseType: 'selectList',
      },
      {
        isActive: true,
        responseOptions: [],
        _id: '606b62f19870eb380910fda3',
        title: 'Test AS question',
        responseType: 'text',
      },
      {
        isActive: true,
        responseOptions: [],
        _id: '60a564ee5c53742ecd5d18c4',
        title: 'Upload a file',
        responseType: 'file',
      },
    ],
    digitalDownloads: [],
    priceVariations: [
      {
        questions: [],
        _id: '606b62f19870eb380910fda4',
        title: 'VAR 1',
        price: 10,
        description: 'This is VAR 1',
        isActive: true,
        type: 'simple',
      },
      {
        questions: [],
        _id: '606b62f19870eb380910fda5',
        title: 'VAR 2',
        price: 15,
        description: 'This is VAR 2',
        isActive: true,
        type: 'simple',
      },
    ],
    popName: 'asf8b1',
    sortOrder: 11,
    owner: '605500a349057f0f3f51623e',
    altOptions: [],
    createdAt: '2021-04-05T19:20:17.931Z',
    updatedAt: '2021-05-19T20:45:32.217Z',
    __v: 0,
  },
  buyer: {
    socialLinks: [],
    tagLine: null,
    userSetupStatus: 10,
    verifiedPath: false,
    isDeleted: false,
    isInfluencer: false,
    isCreative: false,
    isEducator: false,
    isActiveProfile: true,
    isEmailVerified: true,
    isActiveSeller: false,
    skipOnBoarding: false,
    sellerComissionRate: 90,
    signupSource: 'checkout',
    sellerPlatformFee: 2.9,
    sellerProcessingFee: 10,
    servicesTitleEnabled: true,
    servicesTitleValue: 'My Services',
    linksTitleEnabled: true,
    linksTitleValue: 'My Links',
    sundayPopLiveActive: false,
    mondayPopLiveActive: false,
    tuesdayPopLiveActive: false,
    wednesdayPopLiveActive: false,
    thursdayPopLiveActive: false,
    fridayPopLiveActive: false,
    saturdayPopLiveActive: false,
    stripeSellerAccountId: '',
    isGuest: false,
    shoutoutHoursToDeliver: 48,
    fanmailHoursToDeliver: 48,
    popLiveOrderBuffer: 48,
    betaStatus: 0,
    invitationActive: false,
    inviationStatus: 'fresh',
    userStatus: 'invitationReviewPending',
    isAffiliate: false,
    isSuperAffiliate: false,
    allowPopLive: false,
    referralId: null,
    userThemeId: null,
    allowSelling: true,
    isInvitedUser: false,
    library: [],
    enableSystemThemeAccess: false,
    _id: '605bb5eed3a6213113d99a39',
    firstName: 'test',
    lastName: 'event',
    username: 'testefd',
    email: 'test@test12345.com',
    invitationHash: 'iuIBxq',
    themeColor: {
      isActive: true,
      _id: '5fa960bd19db9f677650d40d',
      colorSetName: 'Pop Default',
      backgroundColor: '#FFFFFF',
      textColor: '#000000',
      primaryColor: '#e51075',
      secondaryColor: '#02b0f0',
    },
    links: [
      {
        popLinksId: null,
        linkType: 'sectionTitle',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: true,
        _id: '605bb5eed3a6213113d99a3a',
        sortOrder: 1,
        url: null,
        title: 'My Services',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: null,
        linkType: 'sectionTitle',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: true,
        _id: '605bb5eed3a6213113d99a3b',
        sortOrder: 100,
        url: null,
        title: 'My Links',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: true,
        _id: '605bb5eed3a6213113d99a3c',
        sortOrder: 200,
        url: null,
        title: 'About Me',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: '605bb5eed3a6213113d99a3d',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: true,
        _id: '605bb5eed3a6213113d99a40',
        sortOrder: 2,
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: '605bb5eed3a6213113d99a3e',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: true,
        _id: '605bb5eed3a6213113d99a41',
        sortOrder: 3,
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: '605bb5eed3a6213113d99a3f',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: true,
        _id: '605bb5eed3a6213113d99a42',
        sortOrder: 4,
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: '60a2e5e38705fb062249a212',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: true,
        _id: '60a2e5e38705fb062249a213',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 7,
      },
    ],
    sundayPopLiveSlots: [],
    mondayPopLiveSlots: [],
    tuesdayPopLiveSlots: [],
    wednesdayPopLiveSlots: [],
    thursdayPopLiveSlots: [],
    fridayPopLiveSlots: [],
    saturdayPopLiveSlots: [],
    createdAt: '2021-03-24T21:58:06.128Z',
    updatedAt: '2021-05-19T20:38:43.212Z',
    __v: 0,
    timeOffset: 'Asia/Karachi',
  },
  seller: {
    socialLinks: [],
    tagLine: 'kjsadnlkasdas',
    userSetupStatus: 10,
    verifiedPath: false,
    isDeleted: false,
    isInfluencer: false,
    isCreative: false,
    isEducator: false,
    isActiveProfile: true,
    isEmailVerified: false,
    isActiveSeller: true,
    skipOnBoarding: false,
    sellerComissionRate: 90,
    signupSource: null,
    sellerPlatformFee: 2.9,
    sellerProcessingFee: 10,
    servicesTitleEnabled: true,
    servicesTitleValue: 'My Services',
    linksTitleEnabled: true,
    linksTitleValue: 'My Links',
    sundayPopLiveActive: false,
    mondayPopLiveActive: false,
    tuesdayPopLiveActive: false,
    wednesdayPopLiveActive: false,
    thursdayPopLiveActive: false,
    fridayPopLiveActive: false,
    saturdayPopLiveActive: false,
    stripeSellerAccountId: '',
    isGuest: false,
    shoutoutHoursToDeliver: 48,
    fanmailHoursToDeliver: 48,
    popLiveOrderBuffer: 48,
    betaStatus: 0,
    invitationActive: false,
    inviationStatus: 'fresh',
    userStatus: 'invitationReviewPending',
    isAffiliate: false,
    isSuperAffiliate: false,
    allowPopLive: false,
    referralId: null,
    userThemeId: '607d1dcb3b86101264674856',
    allowSelling: true,
    isInvitedUser: false,
    library: [
      '6075e1606194a106312e5f41',
      '6075e1736194a106312e5f42',
      '6075e1856194a106312e5f43',
      '6075e1a36194a106312e5f44',
      '6075e61b6194a106312e5f57',
      '6075ed326194a106312e5f59',
      '6075eeaf6194a106312e5f5a',
      '6075eee26194a106312e5f5b',
      '607cdae33b86101264674824',
      '607cdda03b86101264674825',
      '607ce2033b86101264674826',
      '607ce2473b86101264674827',
      '607ce8aa3b86101264674828',
      '607d15c03b86101264674851',
      '607d1eb73b86101264674857',
      '607d77813b8610126467485e',
    ],
    enableSystemThemeAccess: false,
    _id: '605500a349057f0f3f51623e',
    firstName: 'Test',
    lastName: 'User',
    email: 'testuser123@gmail.com',
    username: 'testuser56',
    themeColor: {
      isActive: true,
      _id: '5fa960bd19db9f677650d40e',
      colorSetName: 'Purple Haze',
      backgroundColor: '#FFFFFF',
      textColor: '#000000',
      primaryColor: '#a755c2',
      secondaryColor: '#6622cc',
    },
    links: [
      {
        popLinksId: null,
        linkType: 'sectionTitle',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '605500a349057f0f3f51623f',
        sortOrder: 7,
        url: null,
        title: 'My Services',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: null,
        linkType: 'sectionTitle',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '605500a349057f0f3f516240',
        title: '  ',
        sortOrder: 7,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: false,
        _id: '605500a349057f0f3f516241',
        sortOrder: 17,
        url: null,
        title: 'test',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: '605500a349057f0f3f51624a',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '605500a349057f0f3f51624d',
        sortOrder: 11,
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: '605500a349057f0f3f51624b',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '605500a349057f0f3f51624e',
        sortOrder: 4,
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: '605500a349057f0f3f51624c',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '605500a349057f0f3f51624f',
        sortOrder: 3,
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '605b9c7ad3a6213113d99996',
        url: 'instagram.com/saiban_a',
        title: 'My Instagram Feed',
        imageURL:
          'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/e7781a55-77c2-4e47-b384-ff7ca2647fcf.png',
        sortOrder: 8,
      },
      {
        popLinksId: '606b607d9870eb380910fd91',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '606b607d9870eb380910fd92',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 10,
      },
      {
        popLinksId: '606b62f19870eb380910fd9f',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '606b62f19870eb380910fda6',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 14,
      },
      {
        popLinksId: '606df7009ee89312fdc33329',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '606df7009ee89312fdc3332d',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 15,
      },
      {
        popLinksId: null,
        linkType: 'youtubeLink',
        autoPlay: true,
        mute: true,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '607ac5d7ee06777d131d0c6a',
        title: 'Videos are content',
        url: 'https://www.youtube.com/watch?v=NOwsM4XmBQM',
        sortOrder: 5,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '607d048f3b86101264674837',
        url: 'https://3.bp.blogspot.com/_DCpbCAE2Y7Y/TQfBV4UGaII/AAAAAAAAAg0/0RVMNLaI8lA/s1600/alodiag.jpg',
        title: 'Check out my Onlyfans!',
        imageURL:
          'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/70c7610b-eddc-47de-9878-9f8447ebb686.png',
        sortOrder: 9,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '607d063d3b8610126467483d',
        url: 'https://www.facebook.com/harrykaneofficial',
        title: 'Find me on Facebook',
        imageURL:
          'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/c15de841-fae3-4188-bc90-ac5d4d58aa41.png',
        sortOrder: 0,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '607d08543b86101264674850',
        url: 'gatlin.design',
        title: 'Visit my Website',
        imageURL:
          'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/b0277a46-bea0-4e1f-9b4f-78d9fa4b09fc.jpg',
        sortOrder: 1,
      },
      {
        popLinksId: '607d88983b86101264674864',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '607d88983b86101264674865',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 2,
      },
      {
        popLinksId: '607dd2f505067430d4abacdd',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '607dd2f505067430d4abacde',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 16,
      },
      {
        popLinksId: '607dd6e205067430d4abacdf',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '607dd6e205067430d4abace0',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 16,
      },
      {
        popLinksId: '607dd8a505067430d4abace1',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '607dd8a505067430d4abace2',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 17,
      },
      {
        popLinksId: null,
        linkType: 'sectionTitle',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '607df39505067430d4abad08',
        title: 'My Links',
        sortOrder: 6,
      },
      {
        popLinksId: '607e007d05067430d4abad11',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '607e007d05067430d4abad12',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 12,
      },
      {
        popLinksId: null,
        linkType: 'youtubeLink',
        autoPlay: true,
        mute: true,
        loop: true,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '607e00d105067430d4abad13',
        title: 'My Video',
        url: 'https://www.youtube.com/watch?v=qBOdfdFD5O4',
        sortOrder: 14,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ac92eac98e57d3353c9e0',
        url: 'www.youtube.com',
        title: 'link',
        imageURL:
          'https://www.google.com/s2/favicons?sz=64&domain_url=www.youtube.com',
        sortOrder: 22,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ac959ac98e57d3353c9e1',
        url: 'www.google.com',
        title: 'asldkas',
        imageURL:
          'https://www.google.com/s2/favicons?sz=64&domain_url=www.google.com',
        sortOrder: 23,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ac9a6ac98e57d3353c9e2',
        url: 'dsjn.com',
        title: 'daskbjn',
        imageURL:
          'https://www.google.com/s2/favicons?sz=64&domain_url=dsjn.com',
        sortOrder: 24,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609acb27ac98e57d3353c9e3',
        url: 'jsnd.com',
        title: 'kdjsn',
        imageURL:
          'https://www.google.com/s2/favicons?sz=64&domain_url=jsnd.com',
        sortOrder: 25,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609accfeac98e57d3353c9e4',
        url: 'dsjn.com',
        title: 'djasbjnasj',
        imageURL:
          'https://www.google.com/s2/favicons?sz=64&domain_url=dsjn.com',
        sortOrder: 26,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609acd65ac98e57d3353c9e5',
        url: 'sjksnd.com',
        title: 'skjdjnandlkasnm',
        imageURL:
          'https://www.google.com/s2/favicons?sz=64&domain_url=sjksnd.com',
        sortOrder: 27,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad039e71ac3134975b6aa',
        url: 'sabdamns.com',
        title: 'jhbknjsakldsa',
        imageURL:
          'https://www.google.com/s2/favicons?sz=64&domain_url=sabdamns.com',
        sortOrder: 28,
      },
      {
        popLinksId: '609ad0bee71ac3134975b6ab',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad0bee71ac3134975b6ac',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 29,
      },
      {
        popLinksId: '609ad105e71ac3134975b6ad',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad105e71ac3134975b6ae',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 30,
      },
      {
        popLinksId: '609ad187e71ac3134975b6af',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad187e71ac3134975b6b0',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 31,
      },
      {
        popLinksId: '609ad238e71ac3134975b6cf',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad238e71ac3134975b6d0',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 32,
      },
      {
        popLinksId: '609ad27ee71ac3134975b6d1',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad27ee71ac3134975b6d2',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 33,
      },
      {
        popLinksId: '609ad332e71ac3134975b6d3',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad332e71ac3134975b6d4',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 34,
      },
      {
        popLinksId: '609ad3b7e71ac3134975b6d5',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad3b7e71ac3134975b6d6',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 35,
      },
      {
        popLinksId: '609ad414e71ac3134975b6d7',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad414e71ac3134975b6d8',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 36,
      },
      {
        popLinksId: '609ad433e71ac3134975b6d9',
        linkType: 'service',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad433e71ac3134975b6da',
        url: null,
        title: '',
        imageURL: null,
        associatedRecordID: null,
        sortOrder: 37,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad44fe71ac3134975b6db',
        url: 'dabskjands.com',
        title: 'laskdnklas',
        imageURL:
          'https://www.google.com/s2/favicons?sz=64&domain_url=dabskjands.com',
        sortOrder: 38,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad46de71ac3134975b6dc',
        url: 'com.com',
        title: 'aksjdn',
        imageURL: 'https://www.google.com/s2/favicons?sz=64&domain_url=com.com',
        sortOrder: 39,
      },
      {
        popLinksId: null,
        linkType: 'sectionTitle',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad5f6e71ac3134975b6dd',
        title: 'asdbjksaddas',
        sortOrder: 40,
      },
      {
        popLinksId: null,
        linkType: 'sectionTitle',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad662e71ac3134975b6de',
        title: 'dkjasndkas',
        sortOrder: 41,
      },
      {
        popLinksId: null,
        linkType: 'sectionTitle',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad677e71ac3134975b6df',
        title: 'dsandlkas',
        sortOrder: 42,
      },
      {
        popLinksId: null,
        linkType: 'sectionTitle',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad6f1e71ac3134975b6e0',
        title: 'askjdna',
        sortOrder: 43,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ad7e4e71ac3134975b6e2',
        title: 'This is a cheesy tag line',
        sortOrder: 44,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ada8ce71ac3134975b6e3',
        title: 'This is a cheesy tag line',
        sortOrder: 45,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609adb95e71ac3134975b6f3',
        title: 'This is a cheesy tag line',
        sortOrder: 46,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609add3ae71ac3134975b6f4',
        title: 'This is a cheesy tag line',
        sortOrder: 47,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609add9ae71ac3134975b6f5',
        title: 'This is a cheesy tag line',
        sortOrder: 48,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609addb8e71ac3134975b6f6',
        title: 'This is a cheesy tag line',
        sortOrder: 49,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ade3be71ac3134975b6f7',
        title: 'This is a cheesy tag line',
        sortOrder: 50,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ade76e71ac3134975b6f8',
        title: 'This is a cheesy tag line',
        sortOrder: 51,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609adf4be71ac3134975b6f9',
        title: 'This is a cheesy tag line',
        sortOrder: 52,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609adf75e71ac3134975b6fa',
        title: 'This is a cheesy tag line',
        sortOrder: 53,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ae038dcf61e178e5e875c',
        title: 'This is a cheesy tag line',
        sortOrder: 54,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '609ae0e8dcf61e178e5e875d',
        title: 'Test',
        sortOrder: 18,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ae10bdcf61e178e5e875e',
        title: 'This is a cheesy tag line',
        sortOrder: 56,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ae13fdcf61e178e5e875f',
        title: '',
        sortOrder: 57,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ae196dcf61e178e5e8760',
        title: '',
        sortOrder: 58,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: true,
        isThumbnailActive: false,
        isActive: true,
        _id: '609ae1ccdcf61e178e5e8761',
        title: 'kjsadnlkasdas',
        sortOrder: 59,
      },
      {
        popLinksId: null,
        linkType: 'simpleLink',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '60a2cc3b76ce1674f0f3319d',
        url: 'link2.com',
        title: 'link2',
        imageURL:
          'https://www.google.com/s2/favicons?sz=64&domain_url=link2.com',
        sortOrder: 19,
      },
      {
        popLinksId: null,
        linkType: 'biography',
        autoPlay: false,
        mute: false,
        loop: false,
        isDeleted: false,
        isThumbnailActive: false,
        isActive: false,
        _id: '60a2cd3076ce1674f0f3319e',
        title: 'Test',
        sortOrder: 13,
      },
    ],
    sundayPopLiveSlots: [
      {
        _id: '605500a349057f0f3f516242',
        startTime: '09:00 AM',
        endTime: '05:00 PM',
      },
    ],
    mondayPopLiveSlots: [
      {
        _id: '605500a349057f0f3f516243',
        startTime: '09:00 AM',
        endTime: '05:00 PM',
      },
    ],
    tuesdayPopLiveSlots: [
      {
        _id: '605500a349057f0f3f516244',
        startTime: '09:00 AM',
        endTime: '05:00 PM',
      },
    ],
    wednesdayPopLiveSlots: [
      {
        _id: '605500a349057f0f3f516245',
        startTime: '09:00 AM',
        endTime: '05:00 PM',
      },
    ],
    thursdayPopLiveSlots: [
      {
        _id: '605500a349057f0f3f516246',
        startTime: '09:00 AM',
        endTime: '05:00 PM',
      },
    ],
    fridayPopLiveSlots: [
      {
        _id: '605500a349057f0f3f516247',
        startTime: '09:00 AM',
        endTime: '05:00 PM',
      },
    ],
    saturdayPopLiveSlots: [
      {
        _id: '605500a349057f0f3f516248',
        startTime: '09:00 AM',
        endTime: '05:00 PM',
      },
    ],
    invitationHash: 'CHtwyI',
    createdAt: '2021-03-19T19:50:59.264Z',
    updatedAt: '2021-05-20T18:41:40.948Z',
    __v: 0,
    timeOffset: 'Africa/Addis_Ababa',
    profileBackground:
      'https://selfiepop-bucket.s3.amazonaws.com/users/profile-background/d41e35de-53b1-4677-a6d6-6d79bf3c743a.png',
    profileImage:
      'https://selfiepop-bucket.s3.amazonaws.com/users/profile-image/b0eaace4-b2e8-4afa-93ce-bd206c66e838.jpg',
    address1: 'Alibaba Cell Repair, Ashburn Road',
    address2: 'Custom Solar Address Sign, Ryan Road, Ashburn, VA 20148, USA',
    city: ' Ashburn',
    country: ' USA',
    phone: '03021401014',
    state: ' VA',
    zip: 54000,
    description: 'I am something',
    tags: 'author,actor,tag 1,tag2',
  },
  price: 20,
  dateOrderStarted: '2021-05-19T19:33:18.000Z',
  title: 'AS Hello',
  description: 'Test AS',
  attachments: [
    {
      _id: '60a56d746a4ac14ba66134f8',
      url: 'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/10cc0e57-a321-4ecb-b68b-cce1f6870fc3.svg',
      name: 'check.svg',
      type: 'image/svg+xml',
    },
    {
      _id: '60a56d746a4ac14ba66134f9',
      url: 'http://d1i1apkb1v314l.cloudfront.net/vid-pops/order-videos/56cfcf94-b721-46a6-8175-8f067b748ee9.mp4',
      name: '56cfcf94-b721-46a6-8175-8f067b748ee9.mp4',
      type: 'video/mp4',
    },
  ],
  popLiveAttendees: [],
  createdAt: '2021-05-19T19:30:04.620Z',
  updatedAt: '2021-05-19T19:56:36.371Z',
  __v: 0,
  deliveryDate: '2021-05-19T07:56:36.000Z',
};

export default function Markups(props: any) {
  const { user } = useAuth();

  const [copied, copy] = useCopyToClipBoard();
  const [modelStatus, setModelStatus] = useState(false);
  const [size, setSize] = useState<any>('middle');

  const showToast = () => {
    toast.info('🦄 Wow so easy!');
    toast.error('🦄 Wow so easy!');
    toast.success('🦄 Wow so easy!');
  };

  return (
    <div id="wrapper" className="d-flex flex-column min-vh-100">
      {/* <Theme /> */}
      <Loader loading={false} />
      <TabHeader />
      <Header user={user} />
      <ProfileBack />
      <Image src="https://images.unsplash.com/photo-1603993097397-89c963e325c7?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" />
      <main id="main" className="flex-grow-1">
        <div className="visual">
          <div className="visual--image">
            <img src="assets/images/img-cover.jpg" alt="img description" />
          </div>
        </div>
        <div className="container pb-25">
          <div className="profile text-center mb-30">
            <div className="profile--photo">
              <img
                src="assets/images/default-profile-img.svg"
                alt="img description"
              />
            </div>
            <div className="profile--info">
              <h1>Skotti Young</h1>
              <h2>
                <span className="secondary-text">@skottiyoung</span>
              </h2>
            </div>
          </div>
          <h1 className="text-center">
            H1 Text <span className="primary-text">Primaryspan</span>{' '}
            <span className="secondary-text">Secondaryspan</span>
          </h1>
          <h2 className="text-center">
            H2 Text <span className="primary-text">Primaryspan</span>{' '}
            <span className="secondary-text">Secondaryspan</span>
          </h2>
          <h3 className="text-center">
            H3 Text <span className="primary-text">Primary Span</span>{' '}
            <span className="secondary-text">Secondaryspan</span>
          </h3>
          <h4 className="text-center">
            H4 Text <span className="primary-text">Primary Span</span>{' '}
            <span className="secondary-text">Secondaryspan</span>
          </h4>
          <h5 className="text-center">
            H5 Text <span className="primary-text">Primary Span</span>{' '}
            <span className="secondary-text">Secondaryspan</span>
          </h5>
          <h6 className="text-center">
            H6 Text <span className="primary-text">Primary Span</span>{' '}
            <span className="secondary-text">Secondaryspan</span>
          </h6>
          <div className="text-center">
            Plain Text <span className="primary-text">Primary Span</span>{' '}
            <span className="secondary-text">Secondaryspan</span>
          </div>
          <div className="text-center caption">
            Caption Text <span className="primary-text">Primary Span</span>{' '}
            <span className="secondary-text">Secondaryspan</span>
          </div>
        </div>

        <div className="visual style-edit">
          <div className="visual--image">
            <img
              src="/assets/images/img-cover-plaveholder.jpg"
              alt="img description"
            />
          </div>
        </div>
        <div className="container pb-25">
          <div className="profile text-center mb-30">
            <div className="profile--photo style-edit">
              <img
                src="/assets/images/img-profile-placeholder.jpg"
                alt="img description"
              />
            </div>
            <div className="profile--info">
              <h1>Skotti Young</h1>
              <h2>
                <span className="secondary-text">@skottiyoung</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="visual">
          <div className="visual--image">
            <img
              src="/assets/images/img-cover-plaveholder.jpg"
              alt="img description"
            />
          </div>
        </div>
        <div className="container pb-25">
          <div className="profile text-center mb-10">
            <div className="profile--photo">
              <img
                src="/assets/images/img-profile-placeholder.jpg"
                alt="img description"
              />
            </div>
            <div className="container sm-container p-0">
              <div className="pager mb-30">
                <Link to="javascript:void(0)" className="link-previous">
                  <span className="icon-keyboard_arrow_left"></span> back to
                  dashboard
                </Link>
                <Link to="javascript:void(0)" className="link-next">
                  next to dashboard{' '}
                  <span className="icon-keyboard_arrow_right"></span>
                </Link>
              </div>
            </div>
            <div className="profile--info">
              <h1>
                Manage your <span className="text-secondary">Services</span>
              </h1>
            </div>
          </div>
          <ul className="breadcrumbs">
            <li>
              <Link to="javascript:void(0)">Dashboard</Link>
            </li>
            <li>
              <Link to="javascript:void(0)">Poppage Manager</Link>
            </li>
            <li>
              <Link to="javascript:void(0)">Services</Link>
            </li>
            <li>Shoutout</li>
          </ul>
        </div>

        <div className="container sm-container">
          <FulfillmentHeader
            image={order?.seller?.profileImage}
            status={order?.orderStatus}
            popType={order.popType as any}
            name={`${order?.buyer?.firstName} ${order?.buyer?.lastName}`}
          />

          <FulfillmentPriceWidget
            popType={order.popType as any}
            price={order.price}
          />

          <OrderInfoWidget order={order} />

          <FulfillmentButtons
            submitButtonText="SUBMIT AS COMPLETE"
            refuseButtonText="REFUSE SHOUTOUT"
            onSubmitClick={() => {}}
            onRefuseClick={() => {}}
            orderStatus={order?.orderStatus}
          />

          <FileUpload
            disableUpload={true}
            onFilesChange={() => {}}
            defaultFiles={order?.attachments as any}
          />

          <FulfillmentQuestions
            title="Custom Service Details"
            questions={order.questions}
          />
          <Pagination total={25} defaultPageSize={1} />
          <div className="mb-20" />
          <ColorSelector onChange={() => {}} />
          <BackgroundImageSelector />
          <div className="pb-20">
            <ThemeCard
              cardText="Upload an image"
              cardIcon={<AddImage />}
              create
              showFooter={false}
            />
          </div>
          <div className="pb-20">
            <ThemeCard
              title="My Theme 1"
              image="/assets/images/img-chat01.jpg"
            />
          </div>
          <div className="pb-20">
            <ThemeCard
              image="/assets/images/img-chat01.jpg"
              showFooter={false}
              showRemoveIcon
            />
          </div>
          <div className="mb-30 mb-md-50">
            <ColorPicker showDefaultColor />
          </div>
          {/* <InlinePopFrom
            value={{
              popTitle: 'Advertising',
              length: 0,
              description:
                'Ask me any question you want and I will be sure to respond right away!',
              isActive: true,
              isComplete: false,
              isDeleted: false,
              isUserDefault: true,
              popName: 'fanmail',
              popType: 'advertise',
              price: 5,
              title: 'Ask Me a Question1',
              _id: '5fe8c9fbb8d9c36a995b89a4',
            }}
          />
          <InlinePopFrom
            value={{
              popTitle: 'Digital Downloads',
              digitalDownloads: [
                {
                  artName: 'ff95290f-9abc-4f26-9ff4-e6e6a24955f2.jpg',
                  artPath:
                    'https://selfiepop-bucket.s3.amazonaws.com/pops/additional-art/ff95290f-9abc-4f26-9ff4-e6e6a24955f2.jpg',
                  artSize: 22750,
                  artType: 'image',
                  isActive: true,
                  _id: '5fecdce8a660b801b7239ff4',
                },
              ],
              additionalArt: [
                {
                  artName: 'ff95290f-9abc-4f26-9ff4-e6e6a24955f2.jpg',
                  artPath:
                    'https://selfiepop-bucket.s3.amazonaws.com/pops/additional-art/ff95290f-9abc-4f26-9ff4-e6e6a24955f2.jpg',
                  artSize: 22750,
                  artType: 'image',
                  isActive: true,
                  _id: '5fecdce8a660b801b7239ff4',
                },
              ],
              length: 0,
              description:
                'Ask me any question you want and I will be sure to respond right away!',
              isActive: true,
              isComplete: false,
              isDeleted: false,
              isUserDefault: true,
              popName: 'fanmail',
              popType: 'digital-download',
              price: 5,
              title: 'Ask Me a Question1',
              _id: '5fe8c9fbb8d9c36a995b89a4',
            }}
          />
          <InlinePopFrom
            value={{
              popTitle: 'Additional Services',
              questions: [
                {
                  id: '1',
                  title: 'Question 1',
                  description: 'Question 1 description',
                  isActive: true,
                },
                {
                  id: '2',
                  title: 'Question 2',
                  description: 'Question 2 description',
                  isActive: false,
                },
              ],
              additionalArt: [
                {
                  artName: 'ff95290f-9abc-4f26-9ff4-e6e6a24955f2.jpg',
                  artPath:
                    'https://selfiepop-bucket.s3.amazonaws.com/pops/additional-art/ff95290f-9abc-4f26-9ff4-e6e6a24955f2.jpg',
                  artSize: 22750,
                  artType: 'image',
                  isActive: true,
                  _id: '5fecdce8a660b801b7239ff4',
                },
              ],
              length: 0,
              description:
                'Ask me any question you want and I will be sure to respond right away!',
              isActive: true,
              isComplete: false,
              isDeleted: false,
              isUserDefault: true,
              popName: 'fanmail',
              popType: 'additional-services',
              price: 5,
              priceVariations: [
                {
                  _id: '60021882189d004a4c421850',
                  title: 'asdkfa',
                  price: 55,
                  description: 'asldkfmkld',
                  isActive: false,
                },
                {
                  _id: '60021882189d004a4c421450',
                  title: 'asdkfa',
                  price: 55,
                  description: 'asldkfmkld',
                  isActive: false,
                },
              ],
              title: 'Ask Me a Question1',
              _id: '5fe8c9fbb8d9c36a995b89a4',
            }}
          />
          <InlinePopFrom
            value={{
              popTitle: 'payma edit',
              additionalArt: [
                {
                  artName: 'ff95290f-9abc-4f26-9ff4-e6e6a24955f2.jpg',
                  artPath:
                    'https://selfiepop-bucket.s3.amazonaws.com/pops/additional-art/ff95290f-9abc-4f26-9ff4-e6e6a24955f2.jpg',
                  artSize: 22750,
                  artType: 'image',
                  isActive: true,
                  _id: '5fecdce8a660b801b7239ff4',
                },
              ],
              length: 0,
              description:
                'Ask me any question you want and I will be sure to respond right away!',
              isActive: true,
              isComplete: false,
              isDeleted: false,
              isUserDefault: true,
              popName: 'fanmail',
              popThumbnail:
                'https://selfiepop-bucket.s3.amazonaws.com/users/link-image/a2770e3e-e9d2-400a-b3b9-51f49583c99c.jpg',
              popType: 'payma',
              price: 5,
              priceVariations: [
                {
                  _id: '60021882189d004a4c421850',
                  title: 'asdkfa',
                  price: 55,
                  description: 'asldkfmkld',
                  isActive: false,
                },
                {
                  _id: '60021882189d004a4c421450',
                  title: 'asdkfa',
                  price: 55,
                  description: 'asldkfmkld',
                  isActive: false,
                },
              ],
              title: 'Ask Me a Question1',
              _id: '5fe8c9fbb8d9c36a995b89a4',
            }}
          /> */}
          <div className="mb-30">
            <LinkWidget />
          </div>
          <form method="post">
            <h3 className="section-title">Shoutout Title</h3>
            <FocusInput
              id="rel001"
              name="rel001"
              label="Shoutout Title"
              value="Get a custom Shoutout from me!"
              inputClasses="mb-20"
            />
            <h3 className="section-title">Shoutout Price</h3>
            <FocusInput
              id="rel002"
              name="rel002"
              label="Shoutout Price"
              value="Free.99"
              inputClasses="mb-20"
            />
            <h3 className="section-title">Shoutout Description (Optional)</h3>
            <div className="input-wrap md mb-70">
              <FocusInput
                id="rel02"
                name="rel02"
                label="Shoutout Instructions"
                type="textarea"
              />
            </div>
          </form>
          <PaymentWidget order={{}} seller={{}} className="mb-20" />
          <div className="status-widget mb-45">
            <div className="status-box">
              <strong className="title">Status:</strong>
              <span className="status">COMPLETED</span>
            </div>
            <div className="price-box">
              <strong className="subtitle">Price:</strong>
              <span className="price">$10.99</span>
            </div>
          </div>

          <div className="share-popup mb-30 mb-md-50">
            <span className="btn-close">
              <span className="icon-clearclose"></span>
            </span>
            <div>
              <div className="user-info">
                <div className="img">
                  <img src="/assets/images/img-bio.jpg" alt="img description" />
                </div>
                <h3>
                  Share this Shoutout from
                  <br /> Skottie Young
                </h3>
              </div>
              <div className="form-block">
                <strong className="title">Send to:</strong>
                <FocusInput id="name" name="name" label="Name" />
                <FocusInput
                  id="email"
                  name="email"
                  label="Email Address"
                  type="email"
                />
              </div>
              <div className="form-block black">
                <strong className="title">From:</strong>
                <FocusInput id="name01" name="name01" label="Your Name" />
                <FocusInput
                  id="email01"
                  name="email01"
                  label="Your Email Address"
                  type="email"
                  inputClasses="mb-25"
                />
                <button type="submit" className="btn btn-primary">
                  SEND
                </button>
              </div>
            </div>
          </div>

          <UploadThumbnail icon={<span className="icon-star1"></span>} />
          <div className="calendar-area mb-35">
            <h4 className="mb-35">Your Links</h4>
            <div className="visitors-details">
              <div className="visitors-info">
                Total Visits: <strong className="visitors-num">12,250</strong>
              </div>
              <div className="datepicker-area">
                <i className="icon icon-card"></i>
                <span className="datepicker-area__start"> Jan 01, 2021</span>
                <span className="datepicker-area__separator">-</span>
                <span className="datepicker-area__end">Feb 01, 2021</span>
              </div>
            </div>
          </div>
          <InlineTagger value={'Comicon,Comicon,Comicon'} />
          <div className="orders-detail-box">
            <div className="orders--text">
              You have <div className="orders-numbers">22</div>active orders to
              complete!
            </div>
            <span className="btn btn-primary">
              <span className="img">
                <img
                  src="/assets/images/svg/ico-tick.svg"
                  alt="image description"
                />
              </span>
              <span className="text">My Sales</span>
            </span>
          </div>
          <div className="primary-shadow question">
            <div className="question--text">
              <span className="question--icon">?</span>
              <span className="question--title">Ask me a Question!</span>
            </div>
          </div>
          <div className="primary-shadow question">
            <div className="question--text">
              <span className="question--icon">?</span>
              <span className="question--title">Ask me a Question!</span>
            </div>
            <div className="question--price">$99.99</div>
          </div>
          <div className="primary-shadow question">
            <div className="question--text">
              <span className="question--icon">?</span>
              <span className="question--title">Ask me a Question!</span>
            </div>
            <div className="question--arrow">
              <span className="icon-keyboard_arrow_right"></span>
            </div>
          </div>
          <div className="question inverse">
            <div className="question--text">
              <span className="question--icon">?</span>
              <span className="question--title">Ask me a Question!</span>
            </div>
            <div className="question--arrow">
              <span className="icon-keyboard_arrow_right"></span>
            </div>
          </div>
          <h3 className="section-title">Section Title</h3>
          <div className="social">
            <span className="social--icon" style={{ background: '#fff' }}>
              <img src="/assets/images/img-user01.jpg" alt="img description" />
            </span>
            <span className="social--text">SkottieYoung.com</span>
          </div>
          <div className="social">
            <span className="social--icon" style={{ background: '#fff' }}>
              <img src="/assets/images/instagram.jpg" alt="img description" />
            </span>
            <span className="social--text">Instagram</span>
          </div>
          <div className="social twitter">
            <span className="social--icon" style={{ background: '#fff' }}>
              <img src="/assets/images/twitter.jpg" alt="img description" />
            </span>
            <span className="social--text">Follow me on Twitter</span>
          </div>
          <h3 className="section-title">My Services</h3>
          <div className="promo">
            <div className="promo--img">
              <img src="/assets/images/img-promo.jpg" alt="img description" />
            </div>
            <div className="promo--textbox">
              <div className="promo--wrap">
                <h5 className="promo--title">
                  Comission a Skottie Young Custom Print
                </h5>
                <span className="promo--price">$25.99</span>
              </div>
              <p>
                Skottie Young got his start at Marvel on titles like Spider-Man:
                Legend of the Spider Clan, Human Torch, and Venom. He soon began
                adapting the L. Frank Baum OZ novels at Marvel with Eric
                Shanower.
              </p>
            </div>
          </div>
          <span className="btn btn-primary btn-lg d-block mb-15">
            Order Shoutout for $25.99
          </span>
          <div className="d-flex justify-content-center mb-15">
            <span className="btn btn-primary text-uppercase">PREV</span>
            <span className="btn btn-primary">NEXT</span>
          </div>
          <div className="d-flex justify-content-center">
            <span className="btn btn-primary disabled">NEXT</span>
          </div>
          <hr />
          <hr className="divider-primary" />
          <hr className="divider-secondary" />
          <hr className="dashed" />
          <hr className="dashed divider-primary" />
          <hr className="dashed divider-secondary" />
          <div className="bio">
            <div className="bio--head">
              <div className="bio--img">
                <img src="/assets/images/img-bio.jpg" alt="img description" />
              </div>
              <div className="bio--title">
                <h2>Skottie Young</h2>
                <h5 className="bio--subtitle">
                  Comic &amp; Illustration Artist - Crazy Dude
                </h5>
              </div>
            </div>
            <p>
              Skottie Young got his start at Marvel on titles like Spider-Man:
              Legend of the Spider Clan, Human Torch, and Venom. He soon began
              adapting the L. Frank Baum OZ novels at Marvel with Eric Shanower.
            </p>
            <p>
              After five years and six OZ graphic novels, he began writing and
              drawing the hit series Rocket Raccoon, illustrating the children's
              book Fortunately, The Milk by Neil Gaiman, and the Young Marvel
              variant covers.
            </p>
          </div>
          <h3 className="section-title">Tags</h3>
          <div className="tags">
            <span>oz</span>
            <span>Human torch</span>
            <span>Human torch</span>
            <span>rocket raccoon</span>
            <span>i hate fairyland</span>
          </div>

          <div className="shoutout">
            <h3>How does a Shoutout work?</h3>
            <ul className="shoutout--list list-unstyled mb-0">
              <li>
                <span className="shoutout--icon">
                  <img
                    src="/assets/images/ico-send.png"
                    alt="img description"
                  />
                </span>
                <span className="shoutout--text">Send your request</span>
              </li>
              <li>
                <span className="shoutout--icon">
                  <img
                    src="/assets/images/ico-video.png"
                    alt="img description"
                  />
                </span>
                <span className="shoutout--text">Get your video</span>
              </li>
              <li>
                <span className="shoutout--icon">
                  <img
                    src="/assets/images/ico-thumb.png"
                    alt="img description"
                  />
                </span>
                <span className="shoutout--text">Share and enjoy</span>
              </li>
            </ul>
          </div>
          <div className=" mb-65">
            <Slider />
          </div>
          <div className="select-wrap mb-45">
            <Select
              placeholder="Ask Skottie a General Question - $9.95"
              options={[
                { label: 'Drop Down Text', value: '0' },
                { label: 'Drop Down List - Item 1', value: '1' },
              ]}
              onChange={() => {}}
            />
          </div>
          <div className="select-wrap sm">
            <Select
              placeholder="Drop Down Text"
              options={[
                { label: 'Drop Down Text', value: '0' },
                { label: 'Drop Down List - Item 1', value: '1' },
              ]}
              onChange={() => {}}
            />
          </div>
          <div className="input-wrap sm mb-30 mb-md-50">
            <FocusInput
              hasIcon={true}
              icon="person"
              id="rel01"
              name="rel01"
              label="What is your name?"
            />
          </div>

          <Radio
            name="pick-small"
            items={[
              { label: "Yes, I'm picking this one", value: 'yes' },
              { label: 'No, I want to pick this one', value: 'no' },
            ]}
            value="yes"
            onChange={(val: any) => {
              console.log(val);
            }}
            border
          />
          <Radio
            name="pick-full-width"
            items={[
              { label: "Yes, I'm picking this one", value: 'yes' },
              { label: 'No, I want to pick this one', value: 'no' },
            ]}
            value="yes"
            onChange={(val: any) => {
              console.log(val);
            }}
            border
            small
          />
          <Radio
            name="pick-full-width-alt"
            items={[
              { label: "Yes, I'm picking this one", value: 'yes' },
              { label: 'No, I want to pick this one', value: 'no' },
            ]}
            value="yes"
            onChange={(val: any) => {
              console.log(val);
            }}
            alt
            small
          />
          <Radio
            name="pick-dafasfdfsda-width-alt"
            items={[
              { value: 'yes', icon: <RecycleBin /> },
              {
                label: 'No, I want to pick this one',
                value: 'no',
                icon: <TickStar />,
              },
            ]}
            value="yes"
            onChange={(val: any) => {
              console.log(val);
            }}
            type="primary"
            inlineBlock
          />
          <div className="input-wrap md mb-30 mb-md-50">
            <FocusInput
              id="rel02"
              name="rel02"
              label="Shoutout Instructions"
              type="textarea"
            />
          </div>
          <UploadWedigt value={[{ name: 'File Name' }]} />
          <div className="widget-fileupload mb-40 mb-md-70">
            <label className="custom-fileupload">
              <input
                onChange={(e) => {
                  console.log(e);
                }}
                type="file"
              />
              <span className="img">
                <Upload />
              </span>
              <h5 className="subtitle">Drop Background or Cover Photo Here</h5>
              <span className="text">
                File format: JPEG, PNG, GIF (recommneded 1200x480, max 10MB)
              </span>
            </label>
            <div className="fileupload-toolbar">
              <div className="buttons">
                <span className="btn btn-round">
                  SKIP <span className="icon-arrow-thin-up"></span>
                </span>
              </div>
            </div>
          </div>
          <div className="widget-fileupload mb-70">
            <label className="custom-fileupload">
              <input
                onChange={(e) => {
                  console.log(e);
                }}
                type="file"
              />
              <span className="uploaded-img">
                <img
                  src="/assets/images/thumb-cover.jpg"
                  alt="img description"
                />
              </span>
            </label>
            <AttachmentBar title="File Name" />
          </div>
          <div>
            <Checkbox
              label="Yes, I would love to visit Japan"
              name="yes"
              classes="mb-15"
            />
          </div>
          <div>
            <Checkbox
              label="Yes, I would love to visit Japan"
              name="yes"
              classes="mb-15"
              checked={true}
            />
          </div>
          <div>
            <Checkbox
              border={true}
              label="Yes, I would love to visit Japan"
              name="yes"
              classes="mb-15"
              checked={true}
            />
          </div>

          <div>
            <Checkbox
              border={true}
              label="Maybe sometime next year"
              name="maybe"
              classes="mb-15"
            />
          </div>
          <div>
            <Checkbox
              border={true}
              small={true}
              label="Yes, I would love to visit Japan"
              name="yes"
              classes="mb-15"
            />
          </div>
          <div>
            <Checkbox
              border={true}
              small={true}
              label="Maybe sometime next year"
              name="maybe"
              classes="mb-15"
            />
          </div>
          <Checkbox
            label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
           do eiusmod tempor incididunt ut labore et dolore magna aliqua.
           Ut enim ad minim veniam"
            alt={true}
            classes="mb-15"
            small={true}
          />
          <Checkbox
            checked={true}
            label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
           do eiusmod tempor incididunt ut labore et dolore magna aliqua.
           Ut enim ad minim veniam"
            alt={true}
            classes="mb-15"
            small={true}
          />

          <Checkbox
            label="Yes, I would love to visit Japan"
            name="yes"
            icon={<RecycleBin />}
            type="primary"
          />
          <Checkbox name="yes" icon={<RecycleBin />} type="primary" />
          <Checkbox
            label="Yes, I would love to visit Japan"
            name="yes"
            type="primary"
          />

          <div className="profile-photo mb-65">
            <div className="photo-wrap">
              <div className="photo-box">
                <img
                  src="/assets/images/profile-avatar.png"
                  alt="img description"
                />
              </div>
              <span className="btn-add">
                <span className="icon-plus"></span>
              </span>
            </div>
            <label className="button-fileupload">
              <input
                onChange={(e) => {
                  console.log(e);
                }}
                type="file"
              />
              <span className="img">
                <img
                  src="/assets/images/svg/icon-upload-gray.svg"
                  alt="image description"
                />
                <img
                  className="img-white"
                  src="/assets/images/svg/icon-upload-white.svg"
                  alt="image description"
                />
              </span>
              <span className="h6">Upload Profile Image</span>
            </label>
            <span className="btn btn-primary">NEXT</span>
            <div className="widget-buttons-wrap">
              <span className="btn btn-round">
                Skip <span className="icon-arrow-thin-up"></span>
              </span>
              <label className="replace-img btn btn-round">
                <input
                  onChange={(e) => {
                    console.log(e);
                  }}
                  type="file"
                />
                Replace
              </label>
            </div>
          </div>
          <div className="profile-photo uploaded mb-65">
            <div className="photo-wrap">
              <div className="photo-box">
                <img
                  src="/assets/images/default-profile-img.svg"
                  alt="img description"
                />
              </div>
              <span className="btn-add">
                <span className="icon-plus"></span>
              </span>
            </div>
            <label className="button-fileupload">
              <input
                onChange={(e) => {
                  console.log(e);
                }}
                type="file"
              />
              <span className="img">
                <img
                  src="/assets/images/svg/icon-upload-gray.svg"
                  alt="image description"
                />
                <img
                  className="img-white"
                  src="/assets/images/svg/icon-upload-white.svg"
                  alt="image description"
                />
              </span>
              <span className="text">Upload Profile Image</span>
            </label>
            <span className="btn btn-primary">NEXT</span>
            <div className="widget-buttons-wrap">
              <span className="btn btn-round">
                Skip <span className="icon-arrow-thin-up"></span>
              </span>
              <label className="replace-img btn btn-round">
                <input
                  onChange={(e) => {
                    console.log(e);
                  }}
                  type="file"
                />
                Replace
              </label>
            </div>
          </div>

          <DateSelector
            availability={[]}
            onSelect={(data: Date) => console.log(Date)}
          />

          <div className="mb-75">
            <OutlineTagger />
          </div>
          <OrderTable
            key={'_id'}
            title={
              <span>
                <span>{Orders.totalCount}</span> Orders
              </span>
            }
            data={Orders.items.map((item: any) => {
              return {
                image: item?.buyer?.profileImage,
                subtitle: (
                  <span className="text-uppercase">{item.popType}</span>
                ),
                title: `${item?.buyer?.firstName || ''} 
                    ${item?.buyer?.lastName || ''}`,
                status: item.orderStatus,
                price: item.price,
                date: new Date(item.dateOrderStarted).toLocaleDateString(),
                ...item,
              };
            })}
            paginationProps={{
              total: Orders.totalCount,
              current: 1,
              pageSize: 5,
              onChange: () => {},
            }}
          />
          <SwitchBox label="Shoutout" />
          <ul className="breadcrumbs mb-35">
            <li>
              <Link to="javascript:void(0)">Dashboard</Link>
            </li>
            <li>
              <Link to="javascript:void(0)">Poppage Manager</Link>
            </li>
            <li>
              <Link to="javascript:void(0)">Services</Link>
            </li>
            <li>Shoutout</li>
          </ul>
          <div className="widget-fileupload sm mb-70">
            <label className="button-fileupload white">
              <input
                onChange={(e) => {
                  console.log(e);
                }}
                type="file"
              />
              <span className="img">
                <img
                  src="/assets/images/svg/icon-upload.svg"
                  alt="image description"
                />
                <img
                  className="img-white"
                  src="/assets/images/svg/icon-upload-w.svg"
                  alt="image description"
                />
              </span>
              <span className="text">
                Add Promotional Media to this Shoutout
              </span>
            </label>
          </div>
          <div className="widget-fileupload mb-70">
            <label className="custom-fileupload round-style">
              <input
                onChange={(e) => {
                  console.log(e);
                }}
                type="file"
              />
              <span className="uploaded-img">
                <img
                  src="/assets/images/img-cover01.jpg"
                  alt="img description"
                />
              </span>
            </label>
          </div>
          <div className="widget-fileupload mb-30">
            <label className="custom-fileupload round-style">
              <input
                onChange={(e) => {
                  console.log(e);
                }}
                type="file"
              />
              <span className="img">
                <img
                  src="/assets/images/img-upload.png"
                  alt="image description"
                />
              </span>
              <h6 className="subtitle">Drop Shoutout Here</h6>
              <span className="text">
                File format: AVI, MP4, MOV, WMV
                <br /> (recommneded 1920x1080, max 100MB)
              </span>
              <span className="or">- or -</span>
              <span className="button-fileupload">
                <span className="img">
                  <img
                    src="/assets/images/svg/icon-upload.svg"
                    alt=" description"
                  />
                  <img
                    className="img-white"
                    src="/assets/images/svg/icon-upload-w.svg"
                    alt=" description"
                  />
                </span>
                <span className="text">Upload Shotout</span>
              </span>
            </label>
          </div>
          <div className="fileupload-toolbar white-bar">
            <div className="status">
              <span className="icon-tick"></span> Tobin_shoutout.mp4
            </div>
            <div className="buttons">
              <span className="btn btn-round">Replace</span>
              <span className="btn btn-round">DELETE</span>
            </div>
          </div>
          <div className="question-box mb-40">
            <div className="image">
              <img
                src="/assets/images/img-user01.jpg"
                alt="image description"
              />
            </div>
            <div className="text">
              <p>
                I love the episode when Blanche is dating 2 men at the same time
                and they both show up at her house for a date. Then they realize
                what’s going on and end up fighting each other to the death.
              </p>
            </div>
          </div>
          <PopLiveLinkWidget link="http://www.selfiepop.com/20002163" />
          <div className="addatendee-widget mb-40">
            <div className="title-box">
              <strong className="title">
                <span>22</span> CONFIRMED ATTENDEES
              </strong>
            </div>
            <div className="add-box">
              <span className="btn btn-secondary">
                <span className="icon-plus"></span> Add Attendee
              </span>
              <span className="btn-search">
                <img
                  src="/assets/images/svg/icon-search.svg"
                  alt="img description"
                />
              </span>
            </div>
          </div>
          <div className="attendee-wedget mb-40">
            <div className="attendee-info">
              <div className="info-box">
                <div className="img">
                  <img
                    src="/assets/images/img-user02.jpg"
                    alt="img description"
                  />
                </div>
                <div className="text">
                  <strong className="title">Katie Plum</strong>
                  <span className="info-text">
                    Confirmed: <strong>07/03/20</strong>
                  </span>
                </div>
              </div>
              <div className="info-box">
                <div className="text">
                  <span className="info-text">
                    Email: <strong>katieplum@gmail.com</strong>
                  </span>
                  <span className="info-text">
                    Phone: <strong>707.902.3005</strong>
                  </span>
                </div>
              </div>
              <span className="btn-edit">
                <img
                  src="/assets/images/svg/icon-edit.svg"
                  alt="img description"
                />
                <img
                  className="white"
                  src="/assets/images/svg/icon-edit-w.svg"
                  alt="img description"
                />
              </span>
            </div>
            <div className="live-link-bar small mb-0">
              <div className="info-frame d-flex align-items-center">
                <div className="icon">
                  <span className="icon-live"></span>
                </div>
                <div className="link-link-info">
                  <p>
                    <strong>POP liVE lINK:</strong>{' '}
                    <Link to="http://www.selfiepop.com/20002163">
                      http://www.selfiepop.com/20002163
                    </Link>
                  </p>
                </div>
              </div>
              <span className="btn btn-secondary">
                <span className="icon-url"></span>Copy
              </span>
            </div>
          </div>

          <BankInfo
            withdrawalAvailable={Number(
              walletHistory.withdrawalAvailable,
            ).toFixed(2)}
            withdrawalPending={Number(walletHistory.withdrawalPending).toFixed(
              2,
            )}
          />
          <hr className="mb-30" />
          <HeaderOption
            options={{ calender: true, select: true, search: true }}
            classes={{ main: 'mb-35' }}
          />
          <TransactionsList
            transactions={walletHistory.item.walletEvents}
            paginationProps={{
              total: walletHistory?.total,
              current: 5,
              pageSize: 5,
            }}
          />
          <div className="dragabe-widget mb-20">
            <div className="question dragable title-box">
              <span className="drag-dots"></span>
              <div className="question--text">
                <div className="dots-area">
                  <span
                    className="dots-area__dot"
                    style={{ background: '#86a3e7' }}
                  ></span>
                  <span
                    className="dots-area__dot"
                    style={{ background: '#a51d75' }}
                  ></span>
                </div>
                <span className="question--title">Fanpage Offerings</span>
              </div>
              <div className="price-wrap">
                <span className="btn-edit">
                  <img
                    src="/assets/images/svg/icon-edit.svg"
                    alt="img description"
                  />
                  <img
                    className="white"
                    src="/assets/images/svg/icon-edit-w.svg"
                    alt="img description"
                  />
                </span>
              </div>
            </div>
            <div className="question dragable">
              <span className="drag-dots"></span>
              <div className="question--text">
                <span className="inactive-txt">
                  <span className="icon-close"></span> INACTIVE
                </span>
                <span className="icon">
                  <span className="icon-star1"></span>
                </span>
                <span className="question--title">Order a Shoutout</span>
              </div>
              <div className="price-wrap">
                <div className="question--price">$25.99</div>
                <span className="btn-edit">
                  <img
                    src="/assets/images/svg/icon-edit.svg"
                    alt="img description"
                  />
                  <img
                    className="white"
                    src="/assets/images/svg/icon-edit-w.svg"
                    alt="img description"
                  />
                </span>
              </div>
            </div>
            <div className="question dragable">
              <span className="drag-dots"></span>
              <div className="question--text">
                <span className="inactive-txt">
                  <span className="icon-close"></span> INACTIVE
                </span>
                <span className="icon question--icon">?</span>
                <span className="question--title">Ask me a Question!</span>
              </div>
              <div className="price-wrap">
                <div className="question--price">$2.99</div>
                <span className="btn-edit">
                  <img
                    src="/assets/images/svg/icon-edit.svg"
                    alt="img description"
                  />
                  <img
                    className="white"
                    src="/assets/images/svg/icon-edit-w.svg"
                    alt="img description"
                  />
                </span>
              </div>
            </div>
            <div className="question dragable   ">
              <span className="drag-dots"></span>
              <div className="question--text">
                <span className="inactive-txt">
                  <span className="icon-close"></span> INACTIVE
                </span>
                <span className="icon">
                  <span className="icon-star1"></span>
                </span>
                <span className="question--title">Video Chat with my Mom</span>
              </div>
              <div className="price-wrap">
                <div className="question--price">$1.99</div>
                <span className="btn-edit">
                  <img
                    src="/assets/images/svg/icon-edit.svg"
                    alt="img description"
                  />
                  <img
                    className="white"
                    src="/assets/images/svg/icon-edit-w.svg"
                    alt="img description"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="section-head">
            <h3 className="section-title">Biography</h3>
            <span className="edit-btn">
              <span className="icon">
                <img
                  src="/assets/images/svg/icon-edit.svg"
                  alt="img description"
                />
                <img
                  className="white"
                  src="/assets/images/svg/icon-edit-w.svg"
                  alt="img description"
                />
              </span>
              <span className="txt">Edit</span>
            </span>
          </div>
          <div className="bio gray-style">
            <div className="bio--head">
              <div className="bio--img">
                <img
                  src="/assets/images/img-bio-avatar.jpg"
                  alt="img description"
                />
              </div>
              <div className="bio--title">
                <h2>Skottie Young</h2>
                <h5 className="bio--subtitle">
                  Comic &amp; Illustration Artist - Crazy Dude
                </h5>
              </div>
            </div>
            <p>
              Skottie Young got his start at Marvel on titles like Spider-Man:
              Legend of the Spider Clan, Human Torch, and Venom. He soon began
              adapting the L. Frank Baum OZ novels at Marvel with Eric Shanower.
            </p>
            <p>
              After five years and six OZ graphic novels, he began writing and
              drawing the hit series Rocket Raccoon, illustrating the children's
              book Fortunately, The Milk by Neil Gaiman, and the Young Marvel
              variant covers.
            </p>
          </div>
          <div className="section-head">
            <h3 className="section-title">Tags</h3>
            <span className="edit-btn">
              <span className="icon">
                <img
                  src="/assets/images/svg/icon-edit.svg"
                  alt="img description"
                />
                <img
                  className="white"
                  src="/assets/images/svg/icon-edit-w.svg"
                  alt="img description"
                />
              </span>
              <span className="txt">Edit</span>
            </span>
          </div>
          <div className="tags text-color">
            <span>oz</span>
            <span>Human torch</span>
            <span>Human torch</span>
            <span>rocket raccoon</span>
            <span>i hate fairyland</span>
          </div>
          <InlineTagger value={'Comicon,Comicon,Comicon'} />

          <div className="live-link-bar pink-theme mb-40">
            <div className="info-frame d-flex align-items-center">
              <div className="icon">
                <span className="icon-star1"></span>
              </div>
              <div className="link-link-info">
                <p>
                  <strong>YOUR SHOUTOUT IS COMPLETE.</strong>
                </p>
                <p>
                  <Link to="http://www.selfiepop.com/125698">
                    http://www.selfiepop.com/125698
                  </Link>
                </p>
              </div>
            </div>
            <span className="btn btn-primary">
              <span className="ico-eye">
                <img src="/assets/images/svg/eye.svg" alt="img description" />
              </span>
              <span className="text">View</span>
            </span>
          </div>
          <div className="chat-header mb-35">
            <div className="user-box">
              <div className="img">
                <img
                  src="/assets/images/img-user01.jpg"
                  alt="img description"
                />
              </div>
              <div className="chat-header-text">
                <strong className="title">
                  Chat with <Link to="javascript:void(0)">Skottie Young</Link>
                </strong>
              </div>
            </div>
            <div className="time">
              <span>7:45</span> AM - PDT
            </div>
          </div>
          <div className="chat-box right mb-30">
            <div className="user-box">
              <div className="img">
                <img
                  src="/assets/images/img-user02.jpg"
                  alt="image description"
                />
              </div>
              <strong className="title">Katie Plum</strong>
            </div>
            <div className="chat-text">
              <p>
                Hi Skottie, I got your Shoutout and relly love it. Can I make a
                small request please? Nothing major.. a little update??
              </p>
              <div className="chat-time">
                <span className="icon-time"></span>
                <span className="time">8:20 - July 7, 2002</span>
              </div>
            </div>
          </div>
          <div className="chat-box mb-30">
            <div className="user-box">
              <div className="img">
                <img
                  src="/assets/images/img-user01.jpg"
                  alt="image description"
                />
              </div>
              <strong className="title">Skottie Young</strong>
            </div>
            <div className="chat-text">
              <p>Sure!! What would you like?</p>
              <div className="chat-time">
                <span className="icon-time"></span>
                <span className="time">8:20 - July 7, 2002</span>
              </div>
            </div>
          </div>
          <div className="chat-box right mb-30">
            <div className="user-box">
              <div className="img">
                <img
                  src="/assets/images/img-user02.jpg"
                  alt="image description"
                />
              </div>
              <strong className="title">Katie Plum</strong>
            </div>
            <div className="chat-text">
              <div className="media">
                <img
                  src="/assets/images/img-chat01.jpg"
                  alt="img description"
                />
              </div>
              <div className="chat-time">
                <span className="icon-time"></span>
                <span className="time">8:20 - July 7, 2002</span>
              </div>
            </div>
          </div>
          <div className="chat-box mb-30">
            <div className="user-box">
              <div className="img">
                <img
                  src="/assets/images/img-user01.jpg"
                  alt="image description"
                />
              </div>
              <strong className="title">Skottie Young</strong>
            </div>
            <div className="chat-text">
              <div className="media">
                <img
                  src="/assets/images/img-chat02.jpg"
                  alt="img description"
                />
              </div>
              <div className="chat-time">
                <span className="icon-time"></span>
                <span className="time">8:20 - July 7, 2002</span>
              </div>
            </div>
          </div>
          <div className="message-widget mb-30 mb-md-50">
            <textarea
              className="form-control"
              placeholder="Type your message here."
              cols={30}
              rows={10}
            ></textarea>
            <div className="message-bottom">
              <label
                htmlFor="att-file"
                className="upload-file d-flex align-items-center"
              >
                <input
                  onChange={(e) => {
                    console.log(e);
                  }}
                  id="att-file"
                  type="file"
                  className="d-none"
                />
                <span className="icon-url"></span>
                <em className="txt">Attach File</em>
              </label>
              <input
                onChange={(e) => {
                  console.log(e);
                }}
                type="submit"
                className="btn btn-success"
                value="send"
              />
            </div>
          </div>

          <CardBox
            title="Saved Card"
            card={{
              name: 'Awais',
              exp_month: '10',
              exp_year: '39',
              last4: '3988',
              brand: 'master',
              isPrimary: true,
            }}
          />
          <div className="text-center mb-35">
            <button
              className="btn btn-primary btn-add"
              style={{ width: 272, height: 50, fontSize: 18 }}
              type="submit"
            >
              <span className="icon-plus"></span> ADD PAYMENT METHOD
            </button>
          </div>
          <ul className="color-chooser mb-30 mb-md-50">
            <li>
              <label>
                <input
                  onChange={(e) => {
                    console.log(e);
                  }}
                  name="group-color"
                  type="radio"
                  checked
                />
                <span className="custom-input"></span>
                <span className="color-box">
                  <span className="buttons-wrap">
                    <span className="button">
                      <span
                        className="bullet"
                        style={{ background: '#02b0f0' }}
                      ></span>
                      <span
                        className="bullet"
                        style={{ background: '#e51075' }}
                      ></span>
                      <span className="label-text">Button Text</span>
                    </span>
                    <span className="avatar">
                      <img
                        src="/assets/images/img-bio-avatar.jpg"
                        alt="img description"
                      />
                    </span>
                  </span>
                </span>
              </label>
            </li>
            <li>
              <label>
                <input
                  onChange={(e) => {
                    console.log(e);
                  }}
                  name="group-color"
                  type="radio"
                />
                <span className="custom-input"></span>
                <span className="color-box">
                  <span className="buttons-wrap">
                    <span className="button">
                      <span
                        className="bullet"
                        style={{ background: '#02b0f0' }}
                      ></span>
                      <span
                        className="bullet"
                        style={{ background: '#e51075' }}
                      ></span>
                      <span className="label-text">Button Text</span>
                    </span>
                    <span className="avatar">
                      <img
                        src="/assets/images/img-bio-avatar.jpg"
                        alt="img description"
                      />
                    </span>
                  </span>
                </span>
              </label>
            </li>
          </ul>
          <button
            type="button"
            onClick={() => {
              setModelStatus(!modelStatus);
            }}
            className="btn btn-primary mb-30"
          >
            payment Show Model
          </button>
          <Model
            open={modelStatus}
            icon="error"
            title="Payment Required"
            text="Skottie Young got his start at Marvel on titles like Spider-Man: Legend of the Spider Clan, Human Torch, and Venom."
            onClose={() => {
              console.log('Close');
              setModelStatus(!modelStatus);
            }}
            onConfirm={() => {
              console.log('Confirm');
              setModelStatus(!modelStatus);
            }}
          />
          <button
            type="button"
            onClick={showToast}
            className="btn btn-primary mb-30 ml-30"
          >
            Show Toasts
          </button>
          <div className="input-wrap sm mt-25 mb-55">
            <FocusInput
              id="rel08"
              name="rel08"
              hasIcon={true}
              icon="person"
              labelClasses="label-alt"
              label=" Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do
                eius mod and tempor incididunt ut labore et dolore magna aliqua."
            />
          </div>
          <div className="input-wrap sm mb-30 mb-md-50">
            <FocusInput
              id="rel08"
              name="rel08"
              hasIcon={true}
              icon="person"
              labelClasses="label-alt"
              label=" Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do
                eius mod and tempor incididunt ut labore et dolore magna aliqua."
              value="Jessica_Long"
            />
          </div>
          <CRate />
          <DotIndicator total={10} active={5} />
          {/* Simple Button */}

          <NewButton type="primary">Primary Button</NewButton>
          <NewButton>Default Button</NewButton>
          <NewButton type="secondary">Secondary Button</NewButton>
          <NewButton type="info">Secondary Button</NewButton>
          <br />
          <NewButton type="primary" shape="circle">
            Primary Button
          </NewButton>
          <NewButton type="default" shape="circle">
            Default Button
          </NewButton>
          <NewButton type="secondary" shape="circle">
            Secondary Button
          </NewButton>
          <NewButton type="info" shape="circle">
            Secondary Button
          </NewButton>
          <br />
          <NewButton type="primary" block>
            Primary Button
          </NewButton>
          <NewButton type="default" block>
            Default Button
          </NewButton>
          <NewButton type="secondary" block>
            Secondary Button
          </NewButton>
          <NewButton type="info" block>
            Secondary Button
          </NewButton>
          <br />
          <NewButton type="primary" shape="circle" block>
            Primary Button
          </NewButton>
          <NewButton type="default" shape="circle" block>
            Default Button
          </NewButton>
          <NewButton type="secondary" shape="circle" block>
            Secondary Button
          </NewButton>
          <NewButton type="info" shape="circle" block>
            Secondary Button
          </NewButton>
          <br />
          <NewButton type="primary" shape="circle" dashed>
            Primary Button
          </NewButton>
          <NewButton type="default" shape="circle" dashed>
            Default Button
          </NewButton>
          <NewButton type="secondary" shape="circle" dashed>
            Secondary Button
          </NewButton>
          <NewButton type="info" shape="circle" dashed>
            Secondary Button
          </NewButton>
          <br />
          <NewButton type="text">Text Button</NewButton>
          <NewButton type="link">Link Button</NewButton>

          {/* Icon Button */}
          <NewButton type="primary" shape="circle" icon={<RecycleBin />} />

          <NewButton type="primary" shape="circle">
            A
          </NewButton>
          <NewButton type="primary" icon={<RecycleBin />}>
            Delete
          </NewButton>

          <NewButton shape="circle" icon={<RecycleBin />} />

          <NewButton icon={<RecycleBin />}>Delete</NewButton>
          <br />

          <NewButton type="dashed" shape="circle" icon={<RecycleBin />} />

          <NewButton type="dashed" icon={<RecycleBin />}>
            Search
          </NewButton>

          {/* Size */}

          <RadioGroup
            value={size}
            onChange={(e: any) => setSize(e?.target?.value)}
            name="size"
            items={[
              { label: 'Extra Small', value: 'x-small' },
              { label: 'Small', value: 'small' },
              { label: 'Default', value: 'middle' },
              { label: 'Large', value: 'large' },
              { label: 'Exrta Large', value: 'x-large' },
            ]}
          />

          <NewButton type="primary" size={size}>
            Primary
          </NewButton>
          <NewButton type="secondary" size={size}>
            Default
          </NewButton>
          <NewButton type="default" size={size}>
            Dashed
          </NewButton>
          <NewButton type="text" size={size}>
            Link
          </NewButton>
          <br />
          <NewButton
            type="primary"
            icon={<RecycleBin />}
            size={size}
            isLoading={true}
          />
          <NewButton
            type="primary"
            shape="circle"
            icon={<RecycleBin />}
            size={size}
          />
          <NewButton
            type="primary"
            shape="round"
            icon={<RecycleBin />}
            size={size}
          />
          <NewButton
            type="primary"
            shape="round"
            icon={<RecycleBin />}
            size={size}
          >
            Delete
          </NewButton>
          <NewButton type="primary" icon={<RecycleBin />} size={size}>
            Delete
          </NewButton>

          {/* Disabled */}

          <NewButton type="primary">Primary</NewButton>
          <NewButton type="primary" disabled>
            Primary(disabled)
          </NewButton>
          <br />
          <NewButton type="default">Default</NewButton>
          <NewButton type="default" disabled>
            Default(disabled)
          </NewButton>
          <br />
          <NewButton type="dashed">Dashed</NewButton>
          <NewButton type="dashed" disabled>
            Dashed(disabled)
          </NewButton>
          <br />
          <NewButton type="text">Text</NewButton>
          <NewButton type="text" disabled>
            Text(disabled)
          </NewButton>
          <br />
          <NewButton type="link">Link</NewButton>
          <NewButton type="link" disabled>
            Link(disabled)
          </NewButton>
          <br />
          <NewButton danger>Danger Default</NewButton>
          <NewButton danger disabled>
            Danger Default(disabled)
          </NewButton>
          <br />
          <NewButton danger type="text">
            Danger Text
          </NewButton>
          <NewButton danger type="text" disabled>
            Danger Text(disabled)
          </NewButton>
          <br />
          <NewButton type="link" danger>
            Danger Link
          </NewButton>
          <NewButton type="link" danger disabled>
            Danger Link(disabled)
          </NewButton>

          {/* Outline Buttons */}
          <NewButton type="primary" outline>
            Primary
          </NewButton>
          <NewButton outline>Default</NewButton>
          <NewButton outline block>
            Dashed
          </NewButton>

          {/* Blocked Size Button */}
          <NewButton type="primary" size={size} block>
            Primary
          </NewButton>
          <NewButton block>Default</NewButton>
          <NewButton type="dashed" block>
            Dashed
          </NewButton>
          <NewButton type="link" block isLoading={true}>
            Link
          </NewButton>

          <NewButton type="primary" isLoading={true} danger>
            Primary
          </NewButton>
          <NewButton danger isLoading={true}>
            Default
          </NewButton>
          <NewButton type="dashed" danger isLoading={true}>
            Dashed
          </NewButton>
          <NewButton type="text" danger isLoading={true}>
            Text
          </NewButton>
          <Link to="/">
            <NewButton type="link" danger isLoading={true}>
              Link
            </NewButton>
          </Link>

          <div className="mb-30 mb-md-60" />

          <Select options={[{ label: 'Select 1', value: '' }]} size="large" />
          <Select options={[{ label: 'Select 1', value: '' }]} />
          <Select options={[{ label: 'Select 1', value: '' }]} size="small" />
          <Select options={[{ label: 'Select 1', value: '' }]} size="x-small" />

          <div className="mb-30 mb-md-60" />

          <Select
            options={[{ label: 'Select 1', value: '' }]}
            type="seprated"
            size="large"
          />
          <Select
            options={[{ label: 'Select 1', value: '' }]}
            type="seprated"
          />
          <Select
            options={[{ label: 'Select 1', value: '' }]}
            type="seprated"
            size="small"
          />
          <Select
            options={[{ label: 'Select 1', value: '' }]}
            type="seprated"
            size="x-small"
          />
        </div>
      </main>
      <footer id="footer">
        <div className="container py-30">
          <div className="row justify-content-between">
            <div className="col-12 col-md-6 col-lg-5 col-xl-4 order-2 order-md-1">
              <ul className="social-networks list-inline d-flex">
                <li>
                  <Link to="javascript:void(0)">
                    <span className="icon-twitter"></span>
                  </Link>
                </li>
                <li>
                  <Link to="javascript:void(0)">
                    <span className="icon-instagram"></span>
                  </Link>
                </li>
                <li>
                  <Link to="javascript:void(0)">
                    <span className="icon-facebook"></span>
                  </Link>
                </li>
                <li>
                  <Link to="javascript:void(0)">
                    <span className="icon-envelope"></span>
                  </Link>
                </li>
              </ul>
              <div className="subscribe">
                <strong className="subscribe--title">
                  Join our mailing list
                </strong>
                <p>Subscribe for updates on new talent &amp; features</p>
                <div className="subscribe--form">
                  <div className="subscribe--field">
                    <input
                      onChange={(e) => {
                        console.log(e);
                      }}
                      className="form-control"
                      type="email"
                      placeholder="yourname@website.com"
                    />
                  </div>
                  <button type="submit" className="subscribe--button">
                    <span className="icon-arrow-right2"></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-4 order-1 order-md-2">
              <div className="page-info">
                <strong className="h3">
                  <span className="icon-star1"></span> Start{' '}
                  <span className="primary-text">your</span> own Pop Page &amp;
                  tap into the power of{' '}
                  <span className="primary-text">social revenue!</span>
                </strong>
                <span className="btn btn-primary">
                  Get Your Selfiepop Page!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bar">
          <div className="container d-flex flex-wrap justify-content-between">
            <p className="copyrights">©{dayjs().year()}. All rights reserved</p>
            <ul className="footer-links d-flex flex-wrap list-inline mb-0">
              <li>
                <Link to="javascript:void(0)">FAQ</Link>
              </li>
              <li>
                <Link to="javascript:void(0)">Privacy</Link>
              </li>
              <li>
                <Link to="javascript:void(0)">Terms</Link>
              </li>
              <li>
                <Link to="javascript:void(0)">Jobs</Link>
              </li>
              <li>
                <Link to="javascript:void(0)">Merch</Link>
              </li>
              <li>
                <Link to="javascript:void(0)">Press</Link>
              </li>
              <li>
                <Link to="javascript:void(0)">Help</Link>
              </li>
              <li>
                <Link to="javascript:void(0)">Email Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

import { AxiosRequestConfig } from 'axios';
import { COUNTRY_API_EMAIL, COUNTRY_API_KEY, GOOGLE_FONT_KEY } from '../config';
import { getLocalStorage } from '../util';
import request, { cancellableRequest } from '../util/request';
import { orderCreate } from './Order';
import { createGuestUser } from './User';
export const fetchCountries = async (universalToken: string) => {
  return await request('/countries', {
    baseURL: 'https://www.universal-tutorial.com/api',
    headers: { Authorization: `Bearer ${universalToken}` },
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const getUniversalToken = async () => {
  const apiToken = COUNTRY_API_KEY;
  const userEmail = COUNTRY_API_EMAIL;

  return await request('/getaccesstoken', {
    baseURL: 'https://www.universal-tutorial.com/api',
    headers: { 'api-token': apiToken, 'user-email': userEmail },
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const fetchStates = async (country: string, universalToken: string) => {
  return await request(`/states/${country}`, {
    baseURL: 'https://www.universal-tutorial.com/api',
    headers: { Authorization: `Bearer ${universalToken}` },
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const fetchCities = async (state: string, universalToken: string) => {
  return await request(`/cities/${state}`, {
    baseURL: 'https://www.universal-tutorial.com/api',
    headers: { Authorization: `Bearer ${universalToken}` },
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const placeSearch = async (place: string) => {
  return await cancellableRequest(
    'placeSearch',
    `/user/placeSearch/${place}`,
  ).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const getIcon = async (url: string) => {
  return await cancellableRequest('getIcon', `/image/social-icon`, {
    params: { platform: url },
  });
};

export const upload = async (
  url: string,
  data: any,
  config: AxiosRequestConfig = {},
  includeAuthHeader: boolean = true,
) => {
  const headers: any = {};
  const gu: any = getLocalStorage('guestUser');

  if (gu.token) {
    headers['Authorization'] = `Bearer ${gu.token}`;
  }

  return await request(
    url,
    {
      headers,
      data,
      method: 'POST',
      ...config,
    },
    null,
    includeAuthHeader,
  ).then((res) => {
    if (!res || !res?.success) {
      if (res?.reason !== 'cancelled')
        throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const getThemeColors = async (cookies: any) => {
  return request('/theme-color?limit=3', {}, cookies).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const getFontList = async () => {
  return await request('/webfonts/v1/webfonts', {
    baseURL: 'https://www.googleapis.com',
    params: { key: GOOGLE_FONT_KEY },
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const createOrderWithGuestUser = async (data: any) => {
  return await orderCreate(data)
    .then(async ({ order }) => {
      const guestUser = await createGuestUser({
        orderId: order._id,
        signupSource: 'checkout',
      });
      return { order, guestUser };
    })
    .catch(console.log);
};

export const addToMailingList = async (data: any) => {
  return await request('/mailing-list', { method: 'POST', data }, null, false);
};

import React from 'react';
import CardBox from './CardBox';

const CardList = ({ cards, title, makePrimary, deleteCard }: any) => {
  const listItems = cards.map((card: any, index: any) => (
    <CardBox
      card={card}
      key={card.id}
      index={index}
      title={title}
      makePrimary={makePrimary}
      deleteCard={deleteCard}
    />
  ));
  return <>{listItems}</>;
};

export default CardList;

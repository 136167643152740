import { useContext } from 'react';
import { AppGlobalStates } from '../context/AppGlobalStates';

const useDispatch = (
  callback?: (dp: React.Dispatch<any>) => React.Dispatch<any>,
): React.Dispatch<any> => {
  const { dispatch } = useContext(AppGlobalStates);

  return callback ? callback(dispatch!) : dispatch!;
};

export default useDispatch;

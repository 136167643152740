import useAuth from 'hooks/useAuth';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { SocialLink } from 'types';
import InlinePopFrom from '../../../components/InlinePopForm';
import LinkWidget from '../../../components/LinkWidget';
import BiographyCard from './BiographyCard';
import ContentBlockCard from './ContentBlockCard';
import SectionTitleCard from './SectionTitleCard';
import SocialLinksWidget from './SocialLinksWidget';
import YoutubeCard from './YoutubeCard';

interface Props {
  type?: string;
  value?: any;
  onCancel?: any;
  onSubmit?: any;
  className?: string;
  title?: string;
  options?: { status?: boolean; delete?: boolean; close?: boolean };
  socialLinks?: SocialLink[];
  onDelete?(): void;
}

function EditableItem({
  type,
  value,
  onCancel,
  onSubmit,
  className,
  onDelete,
  ...rest
}: Props): ReactElement {
  const { user } = useAuth();

  if (type === 'service') {
    return (
      <div className={className}>
        <InlinePopFrom
          onCancel={onCancel}
          value={value}
          onSubmit={onSubmit}
          onDeleteClick={onDelete}
          {...rest}
        />
      </div>
    );
  }
  if (type === 'socialLinks') {
    return (
      <div className={className}>
        <SocialLinksWidget
          onCancel={onCancel}
          user={user}
          value={value}
          onSubmit={onSubmit}
          {...rest}
        />
      </div>
    );
  }
  if (type === 'sectionTitle') {
    return (
      <div className={className}>
        <SectionTitleCard
          onCancel={onCancel}
          value={value}
          onSubmit={onSubmit}
          onDeleteClick={onDelete}
          {...rest}
        />
      </div>
    );
  }

  if (type === 'contentBlock') {
    return (
      <div className={className}>
        <ContentBlockCard
          onCancel={onCancel}
          value={value}
          onSubmit={onSubmit}
          onDeleteClick={onDelete}
          {...rest}
        />
      </div>
    );
  }

  if (type === 'youtubeLink') {
    return (
      <div className={className}>
        <YoutubeCard
          onCancel={onCancel}
          value={value}
          onSubmit={onSubmit}
          onDeleteClick={onDelete}
          {...rest}
        />
      </div>
    );
  }
  if (type === 'biography') {
    return (
      <div className={className}>
        <BiographyCard
          onCancel={onCancel}
          value={{ ...user, ...value }}
          onSubmit={onSubmit}
          onDeleteClick={onDelete}
          {...rest}
        />
      </div>
    );
  }
  return (
    <div className={className}>
      <LinkWidget
        onCancel={onCancel}
        onSave={onSubmit}
        Link={value}
        onDeleteClick={onDelete}
        {...rest}
      />
    </div>
  );
}

export default styled(EditableItem)`
  .header .icon {
    border-radius: 100%;
    overflow: hidden;
  }
  .custom-thumb-pop .social--icon svg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
`;

import dayjs from 'dayjs';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import swal from 'sweetalert';
import { onboardingSequency } from 'util/index';
import * as yup from 'yup';
import Checkbox from '../../../components/checkbox';
import FocusInput from '../../../components/focus-input';
import Button from '../../../components/NButton';
import Invitation from '../../../layout/invitation';
import IUser from '../../../types/user';

const PreAddedSocialLinks = [
  { type: 'instagram', url: '' },
  {
    type: 'tiktok',
    url: '',
  },
  {
    type: 'twitter',
    url: '',
  },
  {
    type: 'youtube',
    url: '',
  },
  {
    type: 'facebook',
    url: '',
  },
];

const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required('Enter your full name')
    .matches(/^(\D+\s+\D+)(\s*\D*)*$/, 'Enter your full name'),
  email: yup
    .string()
    .email('Enter valid email address')
    .required('Enter valid email address'),
  password: yup
    .string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must be the combination of upercase, lowercase, number, and special character',
    )
    .max(50)
    .min(8)
    .required(),
  sign: yup
    .bool()
    .oneOf([true], 'Accept Terms & Conditions is required')
    .required(),
});

const Welcome7: React.FC<{
  className: string;
  user: IUser & { _id?: string };
  next: () => void;
  gotoSignUp: () => void;
  sliderNumber: number;
}> = ({ className, user, next }) => {
  const history = useHistory();
  const { setUser, SignUp, loggedIn } = useAuth();
  const {
    values,
    handleChange,
    handleBlur,
    resetForm,
    isValid,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    setValues,
    // tslint:disable-next-line: react-hooks-nesting
  } = useFormik({
    validationSchema,
    initialValues: {
      fullName: '',
      email: '',
      password: '',
      sign: true,
      refId: undefined,
      isInvitedUser: false,
      isAffiliate: false,
    },
    onSubmit: async (formData: any) => {
      if (loggedIn) {
        swal({
          title: 'Already logged in',
          icon: 'success',
        }).then((data) => {
          // data && history.push('/my-profile');
        });

        return;
      }
      const name = formData.fullName.replace(/\s{2,}/g, ' ').trim();
      const firstName = name.split(' ').slice(0, -1).join(' ');
      const lastName = name.split(' ').slice(-1).join(' ');

      await SignUp({
        ...formData,
        firstName,
        lastName,
        refId: user?._id,
        isActiveProfile: true,
        isInvitedUser: true,
        isAffiliate: true,
        socialMediaLinks: PreAddedSocialLinks,
        frontendURL: `${window.location.protocol}//${window.location.host}/verify-email`,
        timeOffset: dayjs.tz.guess(),
      })
        .then((res: any) => {
          history.push(onboardingSequency[0]);
        })
        .catch((e: Error) => {
          if (e?.message.toLocaleLowerCase() === 'email already exists')
            swal({
              title: 'You already have an account!',
              text: 'Please login below or use a different email address for registration',
              icon: 'error',
              buttons: ['Cancel', 'Login'],
            });
        });
    },
  });

  const beforeSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    if (Object.keys(errors).length === 1 && errors.sign) {
      toast.info(
        'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
      );
    }
    handleSubmit(e);
  };
  return (
    <div className={className}>
      <Invitation>
        <div className="profile--info mb-15">
          <p>
            Simply fill out the form below to start growing your social revenue
            today!
          </p>
          <form
            action="post"
            onSubmit={(e) => {
              beforeSubmit(e);
            }}
            className="form"
          >
            <div className="field">
              <FocusInput
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.fullName}
                touched={touched.fullName}
                value={values.fullName}
                label="Full Name"
                name="fullName"
                validations={[{ noMultipeSpace: true }, { type: 'alpha' }]}
              />
            </div>
            <div className="field">
              <FocusInput
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
                value={values.email}
                label="Email"
                name="email"
                validations={[{ noSpace: true }]}
              />
            </div>
            <div className="field">
              <FocusInput
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                touched={touched.password}
                value={values.password}
                label="Password"
                type="password"
                limit={50}
                name="password"
              />
            </div>
            <div>
              <Checkbox
                name="sign"
                defaultChecked={values.sign}
                onChange={handleChange}
                onBlur={handleBlur}
                label={
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      position: 'relative',
                      zIndex: 10,
                    }}
                  >
                    By creating and account you are agreeing to our{' '}
                    <Link to="/terms" target="_blank">
                      Terms and Conditions
                    </Link>{' '}
                    and{' '}
                    <Link to="/policy" target="_blank">
                      Privacy Policy
                    </Link>
                  </span>
                }
              />
            </div>
            <Button
              type="primary"
              htmlType="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              SIGN UP
            </Button>
          </form>
        </div>
        <div className="video-holder">
          <video
            style={{ width: '100%' }}
            poster="/assets/images/inner-slide8.jpg"
            autoPlay
            muted
            loop
            playsInline
            preload="none"
          >
            <source
              src="https://d1i1apkb1v314l.cloudfront.net/vid-pops/inner-circle/08_background.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </Invitation>
    </div>
  );
};

export default styled(Welcome7)`
  height: 100%;
  position: relative;
  overflow: hidden;
  color: #fff;
  background: #000;

  .video-holder {
    position: absolute;
    left: -2vh;
    right: -2vh;
    top: 0;
    bottom: 0;
  }

  .logo-holder {
    max-width: 22vh;
    margin: 0 auto 1.2vh;

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .profile--info {
    position: relative;
    z-index: 3;
    font-size: 1.8vh;
    line-height: 1.2;
    padding: 0 0 90px;

    @media (max-height: 580px) {
      padding-bottom: 30px;
    }
  }

  .btns-holder {
    position: relative;
    color: #fff;
  }

  p {
    margin: 0 0 4vh;
    font-size: 2vh;
    line-height: 1.23;
  }

  .button {
    padding: 1.2vh 3vh;
    font-size: 2.6vh;
  }

  .checkbox {
    margin: 0 0 2vh;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    label {
      padding: 0 0 0 4vh;
      text-align: left;
      position: relative;
      color: #fff;
    }

    .custom-input-holder {
      position: absolute;
      left: 0;
      top: 0;
    }

    .custom-input {
      width: 2.6vh;
      height: 2.6vh;
      border-radius: 0;
      font-size: 1.1vh;
      margin-top: 0;
      border: none !important;

      &:after {
        border: none !important;
      }
    }

    .label-text {
      color: #fff;
      font-size: 1.1vh;
      line-height: 1.2;
      display: block;
    }
  }

  .check-holder {
    padding-top: 1vh;
  }

  .text-input {
    overflow: hidden;
    &.mb-20 {
      margin-bottom: 1.5vh !important;
    }

    &.input-active {
      label {
        font-size: 1vh;
        top: 0.65vh;
      }
    }

    label {
      top: 1.35vh;
      font-size: 1.565vh;
      line-height: 1.3;
    }
  }

  .form-control {
    height: 4.4vh;
    padding: 1.5vh 17px 0.5vh;
    border: none !important;
    font-size: 1.565vh;
  }

  .is-invalid {
    font-size: 1.3vh !important;
  }

  .content-parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    text-align: center;
  }
`;

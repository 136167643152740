import React from 'react';
import { useHistory } from 'react-router';
import SetPasswordForm from '../../components/PasswordFrom';
import useAuth from '../../hooks/useAuth';
import { getUserSetupUri, setLocalStorage } from '../../util';

export default function OnboardingBio(props: any) {
  const history = useHistory();
  const { user, setUser } = useAuth();
  const title =
    'Ok, you are all set. Before we send you to your pop page, please set your password so you can login to manage your orders and your pop page later.';

  const onSuccessCallback = () => {
    setUser({ ...user, userSetupStatus: 10, isActiveProfile: true });
    const url = getUserSetupUri(10);
    setLocalStorage('onBoardingTour', 'true', false);
    history.push(url);
  };

  return (
    <>
      <SetPasswordForm
        onSuccessCallback={onSuccessCallback}
        requestProps={{ userSetupStatus: 10, isActiveProfile: true }}
        title={title}
      />
    </>
  );
}

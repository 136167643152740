import useDispatch from 'hooks/useDispatch';
import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, LIFECYCLE, Step } from 'react-joyride';
import { SET_WELCOMEMODEL_STATE } from 'reducer/appStates';
import { removeLocalStorage, setLocalStorage } from 'util/index';

const stps: Step[] = [
  {
    target: '#add-new-pop',
    content: 'Add new content and services to your Pop Page.',
    spotlightClicks: true,
    disableBeacon: true,
    disableScrolling: true,
    // disableScrollParentFix: true,
  },
  {
    target: '#profile-area',
    disableScrolling: false,
    content: 'Drag and drop this list to sort your content',
  },
  {
    target: '#theme-listing-nav',
    disableScrolling: false,
    content: 'Easily change your Pop Page style and design',
  },
  {
    target: '#my-public-profile-url',
    disableScrolling: false,
    content: 'Congratulations, your Pop Page is ready to use!',
  },
];

export const Index = (props: {}) => {
  const [state, setState] = useState({
    run: false,
    sidebarOpen: false,
    stepIndex: 0,
    steps: [],
  });
  const dispatch = useDispatch();
  useEffect(() => {
    setState((s: any) => {
      return { ...s, run: true, steps: stps };
    });
  }, []);
  const setShowWelcomeModal = () => {
    removeLocalStorage('onBoardingTour');
    dispatch({
      type: SET_WELCOMEMODEL_STATE,
      payload: true,
    });
    setLocalStorage('showWelcomeModal', 'true', false);
  };
  const handleJoyrideCallback = (data: any) => {
    const { index, action, lifecycle, type } = data;
    if (action === ACTIONS.CLOSE) {
      setState({ ...state, stepIndex: state.steps.length });
      setShowWelcomeModal();
      return;
    }
    let el: any;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (index === 1 && action === ACTIONS.NEXT) {
        el = document.querySelector(`#theme-listing-nav > a`);
      }
      // Update state to advance the tour
      if (index === 2) {
        el = document.querySelector(`#links-nav > a`);
      }
      if (index === 3 && action === ACTIONS.PREV) {
        el = document.querySelector(
          `${(state.steps[index - 1] as any).target} > a`,
        );
      }
      el && el.click();
      if (index === 2 && action === ACTIONS.PREV) {
        setTimeout(() => {
          setState({
            ...state,
            stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
          });
        }, 1300);
      } else {
        setTimeout(() => {
          setState({
            ...state,
            stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
          });
        }, 500);
      }
    }
    if (
      action !== ACTIONS.PREV &&
      index === state.steps.length - 1 &&
      lifecycle === LIFECYCLE.COMPLETE
    ) {
      setShowWelcomeModal();
    }
    if (ACTIONS.SKIP === action) {
      setShowWelcomeModal();
    }
  };

  const { steps, run, stepIndex } = state;
  return (
    <div className="tout">
      <Joyride
        // debug
        steps={steps}
        stepIndex={stepIndex}
        continuous
        run={run}
        showProgress={true}
        showSkipButton={true}
        scrollToFirstStep={false}
        disableScrolling
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#255b87',
          },
        }}
      />
    </div>
  );
};

export default Index;

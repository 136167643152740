import { actions } from 'context/Auth';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useDispatch from 'hooks/useDispatch';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { SET_GUEST_USER } from 'reducer/purchaseStates';
import { createLinks, updateGuestUser } from '../../api/User';
import Button from '../../components/NButton';
import RadioGroup from '../../components/RadioGroup';
import useAuth from '../../hooks/useAuth';
import useSelector from '../../hooks/useSelector';
import { onboardingSequency, parseQuery, setLocalStorage } from '../../util';
dayjs.extend(utc);
dayjs.extend(timezone);
export default function MyProfile() {
  const order = useSelector((state) => state.purchaseStates?.order);
  const guestUser = useSelector((state) => state.purchaseStates?.guestUser);
  const { setUser, setToken, dispatch: authDispatch } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { username } = useParams<{ username: string }>();
  const { order: orderId } = parseQuery(location.search);
  const [confirmation, setConfirmation] = useState('yes');
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const onNoClickHandler = async () => {
    try {
      const resp = await updateGuestUser({
        skipOnBoarding: true,
        allowSelling: true,
        timeOffset: dayjs.tz.guess(),
      });
      delete resp?.data['themeColor'];

      setToken(guestUser.token);
      setUser({
        ...guestUser.data,
        ...resp.data,
        skipOnBoarding: true,
        allowSelling: true,
      });

      dispatch({ type: SET_GUEST_USER, payload: {} });
      history.push(`/${username}/purchase/set-password?order=${orderId}`);
    } catch (e) {
      console.error(e);
    }
  };

  const onTakeMeBoarding = async () => {
    try {
      const updateUserRequest = updateGuestUser({
        allowSelling: true,
        timeOffset: dayjs.tz.guess(),
      });
      const createUserLinksRequest = createLinks(guestUser.token);
      await Promise.all([createUserLinksRequest, updateUserRequest])
        .then(([linksRes, resp]) => {
          delete resp?.data['themeColor'];

          setUser({
            ...guestUser.data,
            ...resp.data,
            ...linksRes.data,
          });
          setToken(guestUser.token);
          authDispatch({ type: actions.isAuthenticated, payload: true });

          dispatch({ type: SET_GUEST_USER, payload: {} });
          setLocalStorage('setPasswordOnOnboarding', 'yes', false);
          history.push(onboardingSequency[0]);
        })
        .catch(console.log);
    } catch (e) {
      console.error(e);
    }
  };

  const checkConfirmation = async () => {
    setloading(true);
    await (confirmation === 'yes' ? onTakeMeBoarding : onNoClickHandler)();
    setloading(false);
  };

  return (
    <>
      <div className="profile--info mb-30">
        <h2 className="text-center">Congratulations, your order is all set!</h2>
        <h5 className="text-center">
          Would you like to setup your own free pop page just like{' '}
          <span className="text-primary">{order?.seller?.firstName}'s</span> so
          you can store your social media links all in one place?
        </h5>
      </div>
      <div className="mb-30">
        {/* FIXME: Radio buttons not working */}
        <RadioGroup
          name="confirmation"
          border
          items={[
            { label: 'No thank you, I am good for now.', value: 'no' },
            {
              label: 'YES, I do want a better place to keep my links!',
              value: 'yes',
            },
          ]}
          onChange={(e: any) => {
            setConfirmation(e.target.value);
          }}
        />
        <div className="d-flex justify-content-center ">
          <Button
            htmlType="button"
            onClick={checkConfirmation}
            type="primary"
            size="large"
            isLoading={loading}
          >
            NEXT
          </Button>
        </div>
      </div>
    </>
  );
}

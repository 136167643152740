import { LogoUpdated } from 'assets/svgs';
import Button from 'components/NButton';
import * as React from 'react';
import styled from 'styled-components';
import { IInviteSlideProps } from '../../../../interfaces/IInvitation';

const Slide5: React.FC<IInviteSlideProps & { gotoSignUp: Function }> = ({
  sliderNumber,
  next,
  className,
  gotoSignUp,
}) => {
  return (
    <div className={className}>
      <div className="profile--info">
        <div className="logo-holder">
          <LogoUpdated />
        </div>
      </div>
      <div className="video-holder">
        <video
          style={{ width: '100%' }}
          poster="/assets/images/inner-slide5.jpg"
          autoPlay
          muted
          loop
          playsInline
          preload="none"
        >
          <source
            src="https://d1i1apkb1v314l.cloudfront.net/vid-pops/inner-circle/05_background.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="btns-holder">
        <p>Also rewards you when you help spread the love..</p>
        <Button type="primary" onClick={next}>
          NEXT
        </Button>
        <Button
          block
          type="link"
          onClick={() => gotoSignUp()}
          className={`my-btn-${sliderNumber}`}
        >
          SIGN UP NOW
        </Button>
      </div>
    </div>
  );
};

export default styled(Slide5)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;

  .video-holder {
    position: absolute;
    left: -14px;
    right: -14px;
    top: -30px;
    bottom: 0;
  }

  .logo-holder {
    max-width: 200px;
    margin: 0 auto 25px;

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .profile--info {
    position: relative;
    z-index: 3;
    font-size: 16px;
    line-height: 1.2;
  }

  .btns-holder {
    position: relative;
    color: #fff;
  }

  p {
    margin: 0 0 20px;
  }

  .button {
    min-width: inherit;

    &.button-primary {
      padding: 15px;
      min-width: 140px;
    }

    + .button {
      margin: 12px 0 0;
    }
  }

  .button-link {
    color: #14d7f0;

    &:hover {
      color: #ff5400;
    }
  }
`;

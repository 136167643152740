import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import ITheme from 'types/Theme';
import IUser from 'types/user';

interface Props {
  applyFullTheme?: boolean;
  theme?: ITheme;
  user?: IUser;
  className?: string;
}

const PublicVideoHeader: React.FC<Props> = (props) => {
  const { className, theme } = props;
  const video = isMobileOnly
    ? theme?.profile?.profileVideo?.mobile
    : theme?.profile?.profileVideo?.desktop;
  return theme?.profile?.isActive ? (
    <div className={`visual-frame ${className}`} key={video}>
      <div className="container">
        <video autoPlay muted loop playsInline preload="metadata">
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  ) : null;
};

export default styled(PublicVideoHeader)`
  width: 594px;
  height: 334px;
  margin: 0 auto 30px;

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }

  video {
    width: 100%;
    height: 100%;

    @media (max-width: 600px) {
      width: 100%;
      height: auto;
    }
  }
`;

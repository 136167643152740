import React, { forwardRef, MouseEventHandler } from 'react';

interface Button extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: MouseEventHandler;
  children?: any;
  classNames?: string;
  isLoading?: boolean;
  style?: any;
  renderLoader?: Function;
  isIcon?: boolean;
  loadingStyle?: any;
  ref?: any;
  disabled?: boolean;
}
const Button: React.FC<Button> = forwardRef(
  (
    {
      onClick,
      classNames,
      children,
      isIcon,
      isLoading,
      style,
      type,
      renderLoader,
      disabled,
      loadingStyle,
      ...rest
    },
    ref,
  ) => {
    const onClickHandler = (e: any) => {
      if (onClick && !isLoading) onClick(e);
    };
    const loader = () => {
      if (isLoading) {
        if (renderLoader) {
          return renderLoader();
        }
        return <div className="loader-circule" style={loadingStyle}></div>;
      }
    };

    const icon = () => {
      if (isIcon) {
        return (
          <span className="icon--round">
            <span className="icon-arrow-right2"></span>
          </span>
        );
      }
    };

    console.warn('this component will be deprecated soon');
    return (
      <button
        ref={ref}
        type={type}
        disabled={disabled || isLoading}
        style={{ position: 'relative', ...style }}
        onClick={onClickHandler}
        className={`btn btn-primary ${classNames}`}
        {...rest}
      >
        {children}
        {loader()}
        {icon()}
      </button>
    );
  },
);

export default Button;

import Empty from 'components/Empty';
import dayjs from 'dayjs';
import RatingCard from 'pages/components/RatingCard';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getOrder } from '../../api/Order';
import { ServiceType } from '../../enums';
import AdvertisementSale from './components/AdvertisementSale';
import DigitalDownloadsSale from './components/DigitalDownloadsSale';
import PaymaSale from './components/PaymaSale';
import PopLiveSale from './components/PopLiveSale';
import ShoutoutSale from './components/ShoutoutSale';

const getComponent = (order: any, props: any) => {
  switch (order.popType) {
    case ServiceType.POPLIVE:
      return <PopLiveSale {...props} order={order} />;
    case ServiceType.PAYMA:
      return <PaymaSale {...props} order={order} />;
    case ServiceType.ADVERTISE:
      return <AdvertisementSale {...props} order={order} />;
    case ServiceType.DIGITAL_DOWNLOADS:
      return <DigitalDownloadsSale {...props} order={order} />;
    case ServiceType.SHOUTOUT:
      return <ShoutoutSale {...props} order={order} />;
    case ServiceType.ADDITIONAL_SERVICES:
    default:
      return null;
  }
};

function SalesDetail({ order: Order, className, ...props }: any) {
  const { id } = useParams<{ id: string }>();
  const [order, setOrder] = useState<any>(Order || {});

  useEffect(() => {
    Order && setOrder(Order);
  }, [Order]);

  useEffect(() => {
    if (Order) return;
    getOrder(id)
      .then((res) => {
        setOrder(res);
      })
      .catch((e: Error) => console.log(e));
  }, [id]);

  if (!order?._id) return <Empty />;

  const AWAITING_BUYER_REVIEW =
    order.processingStatus === 'Awaiting Buyer Review';
  const RatingView = () => {
    if (AWAITING_BUYER_REVIEW) return null;
    if (
      order.orderStatus.toLowerCase() === 'completed' &&
      order.disputeCompletedAt == null &&
      order.rate != null
    ) {
      return (
        <RatingCard
          name={`${order?.seller?.firstName || ''} ${
            order?.seller?.lastName || ''
          }`}
          rating={order?.rate}
          saleReview
        />
      );
    }
    return null;
  };
  const DisputeView = () => {
    if (AWAITING_BUYER_REVIEW && order.disputeStartedAt != null) return null;
    if (
      order.orderStatus.toLowerCase() === 'dispute' &&
      order.disputeStartedAt
    ) {
      return (
        <div className="caption primary-text text-center">
          Buyer contested this order. You will have the results of the review no
          later than{' '}
          {dayjs(order.dateOrderStarted).add(7, 'day').format('MM/DD/YYYY')}
        </div>
      );
    }
    if (
      order.orderStatus.toLowerCase() === 'declined' &&
      order.disputeCompletedAt
    ) {
      return (
        <div className="caption primary-text text-center">
          This order has been completed and the dispute has been resolved.
        </div>
      );
    }
    if (
      order.orderStatus.toLowerCase() === 'completed' &&
      order.disputeCompletedAt
    ) {
      return (
        <div className="caption primary-text text-center">
          Buy has contested this order. We have reviewed the dispute and have
          ruled in buyer favous.
        </div>
      );
    }
    return null;
  };

  return (
    <div className={className}>
      {getComponent(order, props)}
      <div className="mb-30">
        <RatingView />
        <DisputeView />
      </div>
    </div>
  );
}

export default styled(SalesDetail)`
  max-width: 555px;
  margin: 0 auto;
  padding: 0 15px;

  .button.button-lg {
    min-width: 245px;
    padding: 15px 10px;
  }

  .label-text {
    font-size: 16px;
    line-height: 22px;
    color: #67616d;

    .text-black {
      color: #000;
    }

    .font-bold {
      font-weight: 500;
    }
  }

  .heading-box {
    margin: 0 0 15px;
    font-size: 15px;

    strong {
      font-weight: 700;
    }
  }
`;

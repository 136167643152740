import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IUser from 'types/user';
import Button from '../../components/button';
import Invitation from '../../layout/invitation';

const Welcome6: React.FC<{ className: string; user: IUser }> = ({
  className,
  user,
}) => {
  return (
    <div className={className}>
      <Invitation>
        <div className="profile--info text-center">
          <h1>You are one click away..</h1>
          <h5>
            Your Pop Page has been set up and is ready to go. Simply click the
            link below to activate your profile and to start growing your social
            revenue today!
          </h5>
          <Link to={`/${user?.username}?hash=${user?.invitationHash}`}>
            <Button>SHOW ME TO MY PAGE!</Button>
          </Link>
        </div>
        <div className="image-holder">
          <figure className="profile-snap">
            <img
              src="/assets/images/invitation/Invite-Mobile-7.png"
              alt="profile"
              width="100%"
            />
          </figure>
        </div>
      </Invitation>
    </div>
  );
};

export default styled(Welcome6)`
  .container {
    background: #000;
    color: #fff;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  .font-light {
    font-weight: 300;
  }
  .my-btn {
    background: #00516e;
    border-color: #00516e;
  }
  .profile-snap {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -20px;
  }
  .profile-snap {
    margin-right: -1.2vh;
    margin-left: -1.2vh;
  }
  .profile--info {
    margin-top: 3vh;
  }
  .profile--info h1 {
    font-size: 4.4vh;
    margin: 0 0 3vh;
  }
  .profile--info h5 {
    font-size: 2.2vh;
    line-height: 1.3;
    margin: 0 0 3vh;
  }
  .image-holder {
    max-width: 48vh;
    margin: 0 auto;
  }
  .image-holder img {
    width: 100%;
    height: auto;
    vertical-align: top;
    margin-top: 18px;
  }
`;

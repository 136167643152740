// export const API_URL = 'http://localhost:3001';
export const API_URL = process.env.REACT_APP_SERVER_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const CLOUD_FRONT_URL = process.env.REACT_APP_CLOUD_FRONTEND_SERVER_URL;
export const VERSION = process.env.REACT_APP_VERSION;
export const S3_URL = process.env.REACT_APP_S3_URL;
export const CLOUD_FRONT_S3 = process.env.REACT_APP_CLOUDFRONT_S3_BUCKET;
export const CLOUD_FRONT_THUMBNAI =
  process.env.REACT_APP_CLOUDFRONT_S3_BUCKET_THUMBNAIL;

export const COUNTRY_API_KEY = process.env.REACT_APP_COUNTRIES_API_KEY;
export const COUNTRY_API_EMAIL = process.env.REACT_APP_COUNTRIES_API_EMAIL;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const GOOGLE_FONT_KEY = process.env.REACT_APP_GOOGLE_FONT_KEY;
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DSN;
export const CHIME_URL = process.env.REACT_APP_CHIME_URL;
export const GOOGLE_TRACK = process.env.REACT_APP_GOOGLE_TRACKING_ID as string;
export const POP_LINK_DISABLE = !(
  process.env.REACT_APP_POP_LINK_DISABLE === 'true'
);
export const POP_YOUTUBE_VIDEO_DISABLE = !(
  process.env.REACT_APP_POP_YOUTUBE_VIDEO_DISABLE === 'true'
);
export const POP_CUSTOM_VIDEO_DISABLE = !(
  process.env.REACT_APP_POP_CUSTOM_VIDEO_DISABLE === 'true'
);
export const POP_PAID_FANMAIL_DISABLE = !(
  process.env.REACT_APP_POP_PAID_FANMAIL_DISABLE === 'true'
);
export const POP_BIOGRAPHY_DISABLE = !(
  process.env.REACT_APP_POP_BIOGRAPHY_DISABLE === 'true'
);
export const POP_LIVE_DISABLE = !(
  process.env.REACT_APP_POP_LIVE_DISABLE === 'true'
);
export const POP_PAID_PROMOTIONS_DISABLE = !(
  process.env.REACT_APP_POP_PAID_PROMOTIONS_DISABLE === 'true'
);
export const POP_CUSTOM_SERVICES_DISABLE = !(
  process.env.REACT_APP_POP_CUSTOM_SERVICES_DISABLE === 'true'
);
export const POP_DIGITAL_DOWNLOADS_DISABLE = !(
  process.env.REACT_APP_POP_DIGITAL_DOWNLOADS_DISABLE === 'true'
);
export const POP_SECTION_TITLE_DISABLE = !(
  process.env.REACT_APP_POP_SECTION_TITLE_DISABLE === 'true'
);

export const POP_CONTENT_BLOCK_DISABLE = !(
  process.env.REACT_APP_POP_CONTENT_BLOCK_DISABLE === 'true'
);

export const LOG_ROCKET_APP_ID = process.env.REACT_APP_LOG_ROCKET_APP_ID;

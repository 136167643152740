import React from 'react';

const logo = ({ primaryColor = 'e51075', secondaryColor = '02b0f0' }) => {
  const primay = primaryColor || 'e51075';
  const secondary = secondaryColor || '02b0f0';
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlBase="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="387.333px"
      height="112px"
      viewBox="0 0 387.333 112"
      enableBackground="new 0 0 387.333 112"
      xmlSpace="preserve"
    >
      <path
        fill={`#${secondary}`}
        d="M13.31,76.84c1.197,4.702,4.702,7.523,10.602,7.523c6.327,0,8.806-2.736,8.806-6.669
    c0-4.104-1.966-5.984-10.259-7.951C10.062,66.751,7.497,62.905,7.497,57.005c0-6.07,4.531-12.141,15.646-12.141
    c11.2,0,15.816,6.498,16.33,12.482H32.29c-0.684-2.735-2.821-6.669-9.404-6.669c-6.07,0-7.951,2.907-7.951,5.729
    c0,3.249,1.795,4.873,9.747,6.754c13.166,3.163,15.816,7.523,15.816,13.936c0,7.609-6.07,13.167-16.843,13.167
    c-11.2,0-16.757-5.644-17.868-13.423H13.31z"
      />
      <path
        fill={`#${secondary}`}
        d="M54.776,68.889c0.085,8.806,4.531,15.133,11.884,15.133c6.839,0,9.063-3.591,10.43-6.755H84.7
    c-1.71,5.558-6.412,12.996-18.296,12.996c-13.594,0-19.151-11.115-19.151-22.229c0-12.909,6.498-23.169,19.664-23.169
    c14.021,0,18.553,11.2,18.553,20.434c0,1.368,0,2.479-0.086,3.591H54.776z M77.86,63.673c-0.085-7.181-3.676-12.909-11.029-12.909
    c-7.694,0-11.029,5.301-11.798,12.909H77.86z"
      />
      <path
        fill={`#${secondary}`}
        d="M95.302,89.237V26.483h7.523v62.753H95.302z"
      />
      <path
        fill={`#${secondary}`}
        d="M117.703,89.237V51.961h-6.925v-6.071h6.925v-5.215c0-7.951,3.078-15.047,12.653-15.047
    c1.625,0,3.762,0.171,4.788,0.513v6.412c-1.026-0.256-2.65-0.428-3.762-0.428c-4.445,0-6.155,2.736-6.155,9.063v4.702h8.72v6.071
    h-8.72v37.275H117.703z"
      />
      <path
        fill={`#${secondary}`}
        d="M141.213,35.802v-9.319h7.523v9.319H141.213z M141.213,45.891h7.523v43.346h-7.523V45.891z"
      />
      <path
        fill={`#${secondary}`}
        d="M165.923,68.889c0.085,8.806,4.531,15.133,11.884,15.133c6.839,0,9.063-3.591,10.43-6.755h7.609
    c-1.71,5.558-6.412,12.996-18.296,12.996c-13.594,0-19.151-11.115-19.151-22.229c0-12.909,6.498-23.169,19.664-23.169
    c14.021,0,18.553,11.2,18.553,20.434c0,1.368,0,2.479-0.086,3.591H165.923z M189.006,63.673
    c-0.085-7.181-3.676-12.909-11.029-12.909c-7.694,0-11.029,5.301-11.798,12.909H189.006z"
      />
      <path
        fill={`#${primay}`}
        d="M206.193,106.25V56.493c0-3.591,0-7.268-0.085-10.602h7.267c0.171,1.454,0.256,4.018,0.256,6.839
    c2.394-4.445,6.754-7.865,14.192-7.865c10.003,0,17.27,8.379,17.27,21.545c0,15.561-8.293,23.854-18.809,23.854
    c-7.011,0-10.687-2.907-12.653-6.412v22.399H206.193z M225.857,51.277c-8.977,0-12.568,5.643-12.568,16.244
    c0,10.174,2.907,16.415,11.969,16.415c7.951,0,12.14-6.497,12.14-17.185C237.399,57.518,233.21,51.277,225.857,51.277z"
      />
      <path
        fill={`#${primay}`}
        d="M292.716,67.436c0,12.567-7.182,22.827-20.433,22.827c-12.397,0-19.92-9.576-19.92-22.656
    c0-12.824,7.353-22.742,20.433-22.742C284.851,44.865,292.716,53.842,292.716,67.436z M260.057,67.521
    c0,9.575,4.788,16.501,12.568,16.501c7.865,0,12.396-6.583,12.396-16.415c0-9.747-4.445-16.501-12.567-16.501
    S260.057,57.689,260.057,67.521z"
      />
      <path
        fill={`#${primay}`}
        d="M302.464,106.25V56.493c0-3.591,0-7.268-0.085-10.602h7.267c0.171,1.454,0.256,4.018,0.256,6.839
    c2.394-4.445,6.754-7.865,14.192-7.865c10.003,0,17.27,8.379,17.27,21.545c0,15.561-8.293,23.854-18.809,23.854
    c-7.011,0-10.687-2.907-12.653-6.412v22.399H302.464z M322.128,51.277c-8.977,0-12.568,5.643-12.568,16.244
    c0,10.174,2.907,16.415,11.969,16.415c7.951,0,12.14-6.497,12.14-17.185C333.67,57.518,329.481,51.277,322.128,51.277z"
      />
      <path
        fill={`#${primay}`}
        style={{ opacity: 0.4 }}
        d="M383.316,30.43c-14.191,5.191-18.407,9.403-23.598,23.594c-5.191-14.191-9.403-18.403-23.594-23.594
    c14.191-5.194,18.403-9.405,23.594-23.598C364.909,21.024,369.125,25.235,383.316,30.43"
      />
    </svg>
  );
};

export default logo;

import { toast } from 'components/toaster';
import { ServiceType } from 'enums';
import React, { useCallback } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import styled from 'styled-components';
import swal from 'sweetalert';
import IUser from 'types/user';
import { updatePop } from '../../../api/Pop';
import {
  deleteLink,
  update,
  updateNestedAttribute,
  updateSorting,
} from '../../../api/User';
import useAuth from '../../../hooks/useAuth';
import { IAlert, Ilink, IUserLink, ProfileType } from '../../../types';
import SortableList from './SortableList';
interface Props {
  links: any[];
  user?: any;
  setLinks: any;
  className?: string;
  displayFilter?: (ls: IUserLink[]) => IUserLink[];
  inlineForm?: boolean;
  type?: ProfileType;
}

const LinkListing = ({
  links,
  setLinks,
  className,
  inlineForm = true,
  displayFilter,
  type,
}: Props) => {
  const { user, setUser }: any = useAuth();
  const filteredLinks = (displayFilter && displayFilter(links)) || links;

  const onDeleteHandler = async (index: number) => {
    const link = filteredLinks[index];
    let message = 'Are you sure you want to delete?';
    if (link.linkType === 'innerCircleLink') {
      message =
        'Deleting this link, will remove you from the inner circle program where you earn 25% of all platform fees from people who signup from your link. Are you sure you want to be removed from this program?';
    }
    swal({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      dangerMode: true,
      buttons: ['No', 'Yes'],
    } as IAlert).then(async (willDelete) => {
      if (willDelete) {
        const link = filteredLinks[index];
        const newArray = links.filter((item) => item._id !== link._id);
        try {
          await deleteLink(link?._id as string);
          setLinks(newArray);
          setUser((prev: IUser) => ({
            ...prev,
            isAffiliate: false,
            links: newArray,
          }));
        } catch (e) {}
      }
    });
  };
  const onStatusToggel = async (index: number) => {
    if (index < 0 || index > filteredLinks.length) return;
    const link = filteredLinks[index];
    let isAllowToToggle = true;
    if (
      link.linkType === 'service' &&
      link?.popLinksId &&
      link?.popLinksId?.popType === ServiceType.ADVERTISE
    ) {
      const pVariations = link?.popLinksId?.priceVariations;
      isAllowToToggle = !!pVariations.find((p: any) => p.isActive);
      if (!isAllowToToggle) {
        toast.info('Please activate atleast 1 variation');
        return;
      }
    }

    const requestData = {
      id: link._id,
      name: 'links',
      value: { ...link, isActive: !link.isActive },
    };
    const newArray = links.slice().map((item) => {
      if (item._id === link._id) {
        return {
          ...item,
          isActive: !item?.isActive,
          popLinksId: item.popLinksId && {
            ...item.popLinksId,
            isActive: !item?.isActive,
          },
        };
      }
      return item;
    });

    setLinks(newArray);
    await updateNestedAttribute(user._id, requestData)
      .then(() => {})
      .catch((e: Error) => {
        setLinks(links);
      });
  };
  const onSaveLink = async (requestData: any) => {
    await updateNestedAttribute(user._id, requestData)
      .then((data: any) => {
        const newLinks = links.map((item) => {
          if (item._id === requestData.id) {
            return { ...item, ...requestData.value };
          }
          return item;
        });

        setLinks(newLinks);
      })
      .catch((e: Error) => {});
  };

  const onSavePop = async (requestData: any) => {
    await updatePop(requestData, requestData._id)
      .then((res: any) => {
        const newLinks = links.map((item) => {
          if (item?.popLinksId?._id === res._id) {
            return { ...item, popLinksId: res };
          }
          return item;
        });
        setLinks(newLinks);
      })
      .catch(console.log);
  };

  const onLinkSortEnd = async (
    {
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    },
    e: any,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (oldIndex === newIndex) return;
    const oldLinks = links;
    const sortedArray = arrayMove<Ilink>(oldLinks, oldIndex, newIndex).map(
      (item, index) => ({ ...item, sortOrder: index }),
    );

    const requestData: string[] = sortedArray.map(({ _id }: any) => _id);
    setLinks(sortedArray);
    await updateSorting({ ids: requestData }).catch((e: Error) => {
      setLinks(oldLinks);
      console.log(e);
    });
  };

  const UpdateUser = useCallback(async (value) => {
    delete value._id;
    await update(value);
    setUser((u: any) => ({ ...u, ...value }));
  }, []);

  return (
    <div className="className">
      <div id="links-area">
        <SortableList
          useDragHandle
          lockAxis="y"
          useWindowAsScrollContainer
          items={filteredLinks}
          Actions={{
            onSaveLink,
            onSavePop,
            UpdateUser,
            onStatusToggel,
            onDelete: onDeleteHandler,
          }}
          user={user}
          onDelete={onDeleteHandler}
          onSortEnd={onLinkSortEnd}
          helperClass="isdragging"
          inlineForm={inlineForm}
          type={type}
        />
      </div>
    </div>
  );
};

export default styled(LinkListing)``;

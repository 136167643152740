import { ServiceType } from 'enums';
import React from 'react';
import styled from 'styled-components';

interface Props {
  isSeller?: boolean;
  image: string;
  name: string;
  status: string;
  popType: ServiceType;
  className?: string;
  order?: any;
}

const getTitle = (isSeller: boolean, name: string, popType: ServiceType) => {
  switch (popType) {
    case ServiceType.SHOUTOUT:
      return (
        <h3>
          Custom Video {isSeller ? 'from' : 'for'}{' '}
          <span className="name">{name}</span>
        </h3>
      );
    case ServiceType.ADDITIONAL_SERVICES:
      return (
        <h3>
          Custom Service {isSeller ? 'from' : 'for'}{' '}
          <span className="name">{name}</span>
        </h3>
      );
    case ServiceType.PAYMA:
      return (
        <h3>
          {isSeller ? 'Q&A from' : 'Paid Q&A for'}{' '}
          <span className="name">{name}</span>
        </h3>
      );
    case ServiceType.DIGITAL_DOWNLOADS:
      return (
        <h3>
          Digital Download {isSeller ? 'from' : 'for'}{' '}
          <span className="name">{name}</span>
        </h3>
      );
    case ServiceType.ADVERTISE:
      return (
        <h3>
          {isSeller ? 'Promotion from' : 'Promote with'}{' '}
          <span className="name">{name}</span>
        </h3>
      );
    case ServiceType.POPLIVE:
      return (
        <h3>
          Pop Live {isSeller ? 'from' : 'for'}{' '}
          <span className="name">{name}</span>
        </h3>
      );
  }
};

const FulfillmentHeader: React.FC<Props> = (props) => {
  const {
    order,
    isSeller = false,
    image,
    name,
    status,
    popType,
    className,
  } = props;
  return (
    <div className={`${className} qa-widget mb-40`}>
      <div className="image-holder">
        <img
          src={image || '/assets/images/default-profile-img.svg'}
          alt="profile"
        />
      </div>
      {getTitle(isSeller, name, popType)}
      <span
        className="status"
        style={status === 'Completed' ? { backgroundColor: '#7ccf78' } : {}}
      >
        {status}
      </span>
      <div className="ff-price">
        <strong className="title">Order Number</strong>
        <span className="price">{order?._id}</span>
      </div>
    </div>
  );
};

export default styled(FulfillmentHeader)`
  text-align: center;
  margin: 0 0 20px;

  .ff-price {
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
    color: #2f1c43;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 0 0 0;
  }

  .image-holder {
    width: 102px;
    height: 102px;
    margin: 0 auto 16px;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: covre;
    }
  }

  h3 {
    margin: 0 0 14px;
  }

  .name {
    color: #1da1f3;
  }

  .status {
    display: inline-block;
    vertical-align: top;
    background: #c1c1c1;
    border-radius: 16px;
    font-size: 14px;
    line-height: 18px;
    padding: 6px 18px 4px;
    border-radius: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
  }
`;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../components/NButton';
import Invitation from '../../../layout/invitation';
import IUser from '../../../types/user';

const Welcome6: React.FC<{
  className: string;
  user: IUser;
  next: () => void;
  gotoSignUp: () => void;
  sliderNumber: number;
}> = ({ className, user, next, gotoSignUp, sliderNumber }) => {
  return (
    <div className={className}>
      <Invitation>
        <div className="video-holder">
          <video
            style={{ width: '100%' }}
            poster="/assets/images/inner-slide7.jpg"
            autoPlay
            muted
            loop
            playsInline
            preload="none"
          >
            <source
              src="https://d1i1apkb1v314l.cloudfront.net/vid-pops/inner-circle/07_background.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="profile--info mb-15">
          <p>
            Best of all as part of the inner circle, you will also receive free
            access to our celebrity hosted VIP events!
          </p>
          <Link to="#">
            <Button type="primary" onClick={next}>
              NEXT
            </Button>
          </Link>
          <Button
            type="link"
            block
            onClick={() => gotoSignUp()}
            className={`my-btn-${sliderNumber}`}
          >
            SIGN UP NOW
          </Button>
        </div>
      </Invitation>
    </div>
  );
};

export default styled(Welcome6)`
  overflow: hidden;
  height: 100%;
  position: relative;
  color: #fff;
  background: #000;

  .video-holder {
    position: absolute;
    left: -2vh;
    right: -2vh;
    top: 0;
    bottom: 0;
  }

  .logo-holder {
    max-width: 25vh;
    margin: 0 auto 1.2vh;
    position: relative;
    z-index: 3;

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .profile--info {
    position: relative;
    z-index: 2;
    font-size: 2.4vh;
    line-height: 1.3;
    text-align: center;
    color: #fff;
    padding: 0 0 90px;
  }

  .btns-holder {
    color: #fff;
    font-size: 2.4vh;
    line-height: 1.4;
    position: relative;
    z-index: 3;
  }

  p {
    margin: 0 0 3vh;
  }

  .button {
    padding: 1.2vh 3vh;
    font-size: 2.6vh;
  }

  .content-parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    text-align: center;
  }
`;

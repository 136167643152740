import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

// Only loads the YouTube player

const VideoPlay = ({ url, mute, loop, playing, ...rest }: ReactPlayerProps) => {
  return (
    <div className="rc-vidoe-player">
      <ReactPlayer
        url={url}
        muted={mute}
        controls={true}
        className={'react-player'}
        width="100%"
        height="100%"
        playing={playing}
        loop={loop}
        {...rest}
      />
    </div>
  );
};

export default VideoPlay;

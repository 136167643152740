// import 'cropperjs/dist/cropper.css';
import classNames from 'classnames';
import 'rc-slider/assets/index.css';
import React, { CSSProperties } from 'react';
import Modal from 'react-modal';
import Button from './NButton';
Modal.setAppElement('#root');

interface IStyle {
  model?: CSSProperties;
  header?: CSSProperties;
  body?: CSSProperties;
  content?: CSSProperties;
  overlayStyle?: CSSProperties;
  footer?: CSSProperties;
  dialog?: CSSProperties;
}

const Model = ({
  isOpen,
  onClose,
  title,
  children,
  onOk,
  confirmLoading,
  showHeader = true,
  showFooter = true,
  styles = {},
  className,
}: {
  isOpen: boolean;
  onClose?: any;
  title?: string | React.ReactNode;
  onOk?: Function;
  confirmLoading?: boolean;
  children?: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  styles?: IStyle;
  className?: string;
}) => {
  const {
    model: modelStyle,
    content: contentStyle,
    overlayStyle,
    body: bodyStyle,
    dialog: dialogStyle,
  } = styles;

  const onOkHanlder = () => {
    onOk && onOk();
  };
  const onCloseHanlder = () => {
    onClose && onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
      className={classNames(className, 'modal-dialog')}
      style={{
        content: { ...modelStyle },
        overlay: {
          zIndex: 10,
          backgroundColor: 'rgba(0,0,0,0.6)',
          ...overlayStyle,
        },
      }}
    >
      <div className="modal-content" style={contentStyle}>
        {showHeader && (
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCloseHanlder}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        <div className="modal-body" style={bodyStyle}>
          {children}
        </div>
        {showFooter && (
          <div className="modal-footer">
            <Button
              onClick={onCloseHanlder}
              isLoading={false}
              disabled={false}
              style={{ marginRight: 12 }}
            >
              Cancel
            </Button>
            <Button
              isLoading={confirmLoading}
              onClick={onOkHanlder}
              type="primary"
              htmlType="submit"
              disabled={confirmLoading}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Model;

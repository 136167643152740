import { ServiceType } from 'enums';
import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  seller: any;
  order: any;
};

const getTitle = (type: ServiceType) => {
  switch (type) {
    case ServiceType.SHOUTOUT:
      return 'Custom Video Request from';
    case ServiceType.PAYMA:
      return 'Q&A Request from';
    case ServiceType.POPLIVE:
      return 'Video Chat Request from';
    case ServiceType.DIGITAL_DOWNLOADS:
      return 'Digital Download from';
    case ServiceType.ADDITIONAL_SERVICES:
      return 'Custom Service from';
    case ServiceType.ADVERTISE:
      return 'Advertise with';
    default:
      return null;
  }
};

export const PaymentWidget: React.FC<Props> = ({
  className,
  seller,
  order,
}) => {
  const platformFee = (order?.orderPlatformFee / 100) * order?.price + 0.3;
  return (
    <div className={className}>
      <h3 className="mb-35">Payment Info</h3>
      <div className="widget-payment">
        <ul className="payment-list">
          <li>
            <strong className="title">
              {getTitle(order?.popId?.popType) || ''}{' '}
              {`${seller?.firstName || ''} ${seller?.lastName || ''}`}
            </strong>
            <span className="price">${order?.price || 0}</span>
          </li>
          <li className="service-fee">
            <strong className="title">Service Fee</strong>
            <span className="price">${(platformFee || 0).toFixed(2)}</span>
          </li>
          <li>
            <strong className="title">Total</strong>
            <span className="price">
              ${(order?.price + platformFee || 0).toFixed(2)}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default styled(PaymentWidget)`
  .widget-payment {
    overflow: hidden;
    position: relative;
    padding: 23px 10px 3px;
  }

  .widget-payment:after,
  .widget-payment:before {
    position: absolute;
    left: 0;
    right: 0;
    top: -3px;
    border-top: 4px dashed rgba(103, 97, 109, 0.37);
    content: '';
    height: 4px;
  }

  .widget-payment:after {
    top: auto;
    bottom: -3px;
  }

  .payment-list {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    max-width: 430px;
    color: #2f1c43;
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
  }

  .payment-list li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 16px;
  }

  .payment-list .service-fee {
    color: #a3a3a3;
    font-size: 13px;
    line-height: 20px;
    margin: 0 0 26px;
  }
`;

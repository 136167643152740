import { getTicket } from 'api/Support';
import { LockIcon, Share } from 'assets/svgs';
import FocusInput from 'components/focus-input';
import Button from 'components/NButton';
import { DashedLine } from 'components/Typography';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SupportTicketStatus } from 'enums';
import useAuth from 'hooks/useAuth';
import useSocket from 'hooks/useSocket';
import { ITicket, ITicketMessage } from 'interfaces/Ticket';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

dayjs.extend(utc);

interface Props {
  ticket: ITicket;
  sendMessage(message: string, ticket: ITicket): Promise<void>;
  className?: string;
  updateTicket(ticketId: string, newTicket: ITicket): void;
  closeTicket?(ticket: ITicket): Promise<void>;
}

const TicketDetails: React.FC<Props> = (props) => {
  const {
    className,
    sendMessage,
    closeTicket,
    ticket: propTicket,
    updateTicket,
  } = props;
  const { user } = useAuth();
  const [ticket, setTicket] = useState<ITicket>({});
  const [commentMessage, setCommentMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { socket } = useSocket();

  useEffect(() => {
    if (propTicket._id) {
      setCommentMessage('');
      socket?.off(ticket?._id);
      setTicket(propTicket);
      getTicket(propTicket._id)
        .then((res) => {
          setTicket(res);
          socket?.on(res._id, (data: ITicketMessage) => {
            setTicket((prev) => {
              const newTicket = {
                ...prev,
                issueComments: prev.issueComments
                  ? [...prev.issueComments, data]
                  : [data],
              };

              updateTicket(prev._id!, newTicket);

              return newTicket;
            });
          });
        })
        .catch(console.log);
    }
  }, [propTicket]);

  const { userId } = ticket;

  return (
    <div className={className}>
      <div className="ticket-detail">
        <div className="support-head">
          <h2>
            Support Ticket <span className="counter">#{ticket?.ticketNo}</span>
            <span className="ticket-id">{ticket?.userId?._id}</span>
          </h2>
          <span className="support-tag">{ticket?.issueStatus}</span>
        </div>
        <div className="support-body">
          <div className="user-detail">
            <p>
              <strong>Name:</strong>{' '}
              <span>{`${ticket?.userId?.firstName} ${ticket?.userId?.lastName}`}</span>
            </p>
            <p>
              <strong>E-mail:</strong> <span>{ticket?.userId?.email}</span>
            </p>
          </div>
          <p>
            <strong>Profile:</strong>{' '}
            <a
              href={`${window.location.protocol}//${window?.location.host}/${ticket?.userId?.username}`}
              target="_blank"
              rel="noreferrer"
              className="link-profile"
            >
              {ticket?.userId?.username}
              <Share />
            </a>
          </p>
          <span className="title-holder">
            Title: <strong className="title">{ticket?.issueTitle}</strong>
          </span>
          <p className="ticket-description">
            <strong>Description:</strong>{' '}
            <span>{ticket?.issueDescription}</span>
          </p>
          <ul className="list-timing">
            <li>
              Opened:{' '}
              <span className="date">
                {dayjs
                  .utc(ticket?.createdAt)
                  .local()
                  .format('MM/DD/YYYY - h:mm A')}
              </span>
            </li>
            {ticket?.issueLastUpdated && (
              <li>
                Updated:{' '}
                <span className="date">
                  {dayjs
                    .utc(ticket?.issueLastUpdated)
                    .local()
                    .format('MM/DD/YYYY - h:mm A')}
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
      <DashedLine />
      {ticket?.issueComments?.map((comment, index) => (
        <div className="message-box" key={index}>
          <h2>
            {comment.commentDirection === 'user'
              ? 'Buyer Message'
              : 'Selfiepop Message'}
          </h2>
          <ul className="list-timing">
            <li>
              Sent:{' '}
              <span className="date">
                {dayjs
                  .utc(comment.dateTimeAdded)
                  .local()
                  .format('MM/DD/YYYY - h:mm A')}
              </span>
            </li>
          </ul>
          <p>{comment.commentValue}</p>
        </div>
      ))}
      {ticket?.issueStatus !== 'closed' ? (
        <div className="mt-30">
          <FocusInput
            type="textarea"
            rows={6}
            value={commentMessage}
            onChange={(e) => setCommentMessage(e.target.value)}
            materialDesign
          />
          <div className="text-center">
            <Button
              type="primary"
              isLoading={isLoading}
              disabled={!commentMessage?.trim().length}
              onClick={() => {
                setIsLoading(true);
                sendMessage(commentMessage, ticket)
                  .then(() => {
                    setCommentMessage('');
                    setIsLoading(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    setIsLoading(false);
                  });
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-center pt-15">
          <span className="btn-detail">
            <LockIcon /> This ticket has been closed
          </span>
        </div>
      )}
      {ticket?.issueStatus !== SupportTicketStatus.CLOSE &&
        user?.isSupportAgent && (
          <div className="text-center mt-20">
            <Button
              type="primary"
              isLoading={isClosing}
              onClick={() => {
                setIsClosing(true);
                closeTicket?.(ticket)
                  .then(() => {
                    setCommentMessage('');
                    setIsClosing(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    setIsClosing(false);
                  });
              }}
            >
              Close Ticket
            </Button>
          </div>
        )}
    </div>
  );
};

export default styled(TicketDetails)`
  max-width: 528px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.5;
  color: #67616d;
  font-weight: 400;

  .ticket-detail {
    padding: 0 0 16px;
  }

  .support-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 33px;

    .ticket-id {
      display: block;
      color: #67616d;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 5px 0 0;
    }

    h2 {
      margin: 0;
    }
  }

  .link-profile {
    display: inline-block;
    vertical-align: top;
    position: relative;

    svg {
      width: 16px;
      height: 16px;
      margin: 3px 0 0 8px;
      display: inline-block;
      vertical-align: top;
    }

    &:hover {
      color: #3f51b5;
    }
  }

  .user-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    margin: 0 0 10px;
  }

  .support-tag {
    min-width: 70px;
    background: #255b87;
    color: #fff;
    border-radius: 20px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    padding: 6px 10px 4px;
    text-align: center;
  }

  .title-holder {
    margin: 0 0 20px;
    display: block;

    .title {
      font-size: 20px;
      line-height: 24px;
      color: #000;
      font-weight: 500;
    }
  }

  p {
    margin: 0 0 10px;

    &.ticket-description {
      margin: 0 0 30px;

      span {
        white-space: pre-line;
      }
    }

    strong {
      font-weight: 500;
      color: #000;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .list-timing {
    margin: 0 0 20px;
    padding: 0 4% 0 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .date {
      font-weight: 500;
      color: #000;
    }
  }

  .message-box {
    padding: 30px 0 0;

    p {
      white-space: pre-line;
    }
  }

  .btn-detail {
    display: inline-block;
    vertical-align: top;
    background: #000;
    color: #fff;
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    padding: 15px 35px;
    border-radius: 30px;

    svg {
      display: inline-block;
      vertical-align: middle;
      margin: 0 15px 0 0;
    }
  }
`;

import { activateAccountRequest, getUser } from 'api/User';
import { EypeClose, EypeOpen } from 'assets/svgs';
import Checkbox from 'components/checkbox';
import FocusInput from 'components/focus-input';
import NButton from 'components/NButton';
import { toast } from 'components/toaster';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import useQuery from 'hooks/useQuery';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import swal from 'sweetalert';
import { onboardingSequency } from 'util/index';
import * as yup from 'yup';
const PreAddedSocialLinks = [
  { type: 'instagram', url: '' },
  {
    type: 'tiktok',
    url: '',
  },
  {
    type: 'twitter',
    url: '',
  },
  {
    type: 'youtube',
    url: '',
  },
  {
    type: 'facebook',
    url: '',
  },
];
const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required('Enter your full name')
    .matches(/^(\D+\s+\D+)(\s*\D*)*$/, 'Enter your full name'),
  email: yup
    .string()
    .email('Enter valid email address')
    .required('Enter valid email address'),
  password: yup
    .string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must be the combination of upercase, lowercase, number, and special character',
    )
    .max(50)
    .min(8)
    .required('Password is required'),
  sign: yup
    .bool()
    .oneOf([true], 'Accept Terms & Conditions is required')
    .required(),
});
type SignUpProps = {
  className?: string;
};
function Signup({ className }: SignUpProps) {
  const { SignUp, loggedIn } = useAuth();
  const history = useHistory();
  const [refUser, setRefUser] = useState<any>(undefined);
  const ref = useRef<any>();
  const { r: refId } = useQuery();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState('');
  const [hint, setHint] = useState('');

  const {
    values,
    handleChange,
    handleBlur,
    isValid,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
    setValues,
    // tslint:disable-next-line: react-hooks-nesting
  } = useFormik({
    validationSchema,
    initialValues: {
      fullName: '',
      email: '',
      password: '',
      sign: true,
      refId: undefined,
      isInvitedUser: false,
      isAffiliate: false,
      isActiveProfile: true,
    },
    onSubmit: async (formData: any) => {
      if (loggedIn) {
        swal({
          title: 'Already logged in',
          icon: 'success',
        }).then((data) => {
          data && history.push('/my-profile');
        });

        return;
      }
      const name = formData.fullName.replace(/\s{2,}/g, ' ').trim();
      const firstName = name.split(' ').slice(0, -1).join(' ');
      const lastName = name.split(' ').slice(-1).join(' ');

      await SignUp({
        ...formData,
        firstName,
        lastName,
        isActiveProfile: false,
        socialMediaLinks: PreAddedSocialLinks,
        frontendURL: `${window.location.protocol}//${window.location.host}/verify-email`,
        timeOffset: dayjs.tz.guess(),
      })
        .then((res: any) => {
          history.push(onboardingSequency[0]);
        })
        .catch((e: Error) => {
          if (e?.message.toLocaleLowerCase() === 'email already exists')
            swal({
              title: 'You already have an account!',
              text: 'Please login below, use a different email address for registration or activate your account if deactivated',
              icon: 'error',
              // buttons: ['Cancel', 'Login'],
              buttons: {
                cancel: {
                  visible: true,
                  text: 'Cancel',
                  closeModal: true,
                },
                login: {
                  visible: true,
                  text: 'Login',
                  closeModal: true,
                },
                activate: {
                  visible: true,
                  text: 'Activate',
                  closeModal: true,
                },
              },
            }).then(async (value) => {
              switch (value) {
                case 'login':
                  history.push('/login');
                  break;

                case 'activate':
                  await activateAccountRequest({
                    email: values.email,
                  })
                    .then((res: any) => {
                      swal(
                        'Email Success!',
                        'Check your email to activate your account',
                        'success',
                      ).then((_) => {
                        history.push('/login');
                      });
                    })
                    .catch((err) => {
                      swal('', err.message, 'info');
                    });

                  break;
              }
            });
        });
    },
  });

  const beforeSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    if (Object.keys(errors).length === 1 && errors.sign) {
      toast.info(
        'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
      );
    }
    handleSubmit(e);
  };

  useEffect(() => {
    if (refId) {
      getUser(refId)
        .then((res) => {
          if (res._id && res.isAffiliate === true) {
            setRefUser(res);
            setValues((values) => ({
              ...values,
              refId: res?._id,
              isInvitedUser: true,
              isAffiliate: true,
            }));
          }
        })
        .catch(console.log);
    }
  }, [refId]);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        ref?.current?.click();
      }, 0);
    }
  }, [ref]);
  const handlePasswordChecker = (e: any) => {
    let strongPassword = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
    );
    let mediumPassword = new RegExp(
      '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{6,}))',
    );

    if (strongPassword.test(e.target.value)) {
      setPasswordCheck('Strong');
      setHint('');
    } else if (mediumPassword.test(e.target.value)) {
      setPasswordCheck('Medium');
      setHint(
        'The password should be atleast 8 characters long. To make it stronger, use numbers and symbels like ! $ % # ',
      );
    } else {
      setPasswordCheck('Weak');
      setHint(
        'The password should be atleast 8 characters long. To make it stronger, use upper and lower case letters, numbers and symbels like ! $ % # ',
      );
    }
    setFieldValue('password', e.target.value);
  };
  return (
    <div className={`landing-page-area ${className}`} ref={ref}>
      <div className="container justify-content-center">
        <div className="form-signup-area">
          <strong className="logo">
            <Link to="/">
              <div className="logo-image">
                <img src="assets/images/beta-logo.svg" alt="selfiepop" />
              </div>
            </Link>
          </strong>
          {refUser ? (
            <>
              <div className="mb-30">
                <div className="empty-cover"> </div>
                <div className="text-center landing-profile">
                  <div
                    className="profile--image"
                    style={{
                      background: '#255b87',
                    }}
                  >
                    <img
                      src={
                        refUser.profileImage ||
                        '/assets/images/default-profile-pic.png'
                      }
                      alt="img description"
                    />
                  </div>
                </div>
                <div className="profile--info mb-30">
                  <h2 className="text-center">Congratulations!</h2>
                  <h1 className="text-center">
                    <span className="primary-text">
                      {`${refUser.firstName || refUser.firstName} ${
                        refUser.lastName || refUser.lastName
                      }`}
                    </span>
                  </h1>
                  <h2 className="text-center">
                    has invited you to join the founders club of selfiepop.
                  </h2>
                </div>
                <hr />
                <h6 className="text-center">
                  Fill out the form below to get started.
                </h6>
              </div>
            </>
          ) : (
            <strong className="text-center title">
              Start growing your{' '}
              <span className="text-primary">social revenue</span> today!
            </strong>
          )}
          <form action="post" onSubmit={beforeSubmit} className="form">
            <div className="field">
              <FocusInput
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.fullName}
                touched={touched.fullName}
                label="Full Name *"
                inputClasses="mb-25"
                name="fullName"
                class={values.fullName && !errors.fullName ? 'is-valid' : ''}
                value={values.fullName}
                validations={[{ noMultipeSpace: true }, { type: 'alpha' }]}
              />
            </div>
            <div className="field">
              <FocusInput
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
                label="Email *"
                class={values.email && !errors.email ? 'is-valid' : ''}
                inputClasses={`mb-25`}
                name="email"
                value={values.email}
                validations={[{ noSpace: true }]}
              />
            </div>
            <div className="field">
              <FocusInput
                onChange={handlePasswordChecker}
                onBlur={handleBlur}
                error={!values.password && errors.password}
                touched={touched.password}
                label="Password *"
                type={showPassword ? 'text' : 'password'}
                limit={50}
                inputClasses={`mb-25 icon-right `}
                name="password"
                value={values.password}
                hasIcon={true}
                icon={
                  <span
                    onClick={() => {
                      values.password
                        ? setShowPassword(!showPassword)
                        : setShowPassword(false);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {showPassword ? <EypeClose /> : <EypeOpen />}
                  </span>
                }
              />
              {passwordCheck && values.password && (
                <>
                  <span className={`displayBadge ${passwordCheck}`}>
                    {passwordCheck}
                  </span>
                  {hint && (
                    <span className="hint-text">
                      <strong>Hint:</strong> {hint}
                    </span>
                  )}
                </>
              )}
            </div>
            <div>
              <Checkbox
                name="sign"
                defaultChecked={values.sign}
                onChange={handleChange}
                onBlur={handleBlur}
                label={
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      position: 'relative',
                      zIndex: 10,
                    }}
                  >
                    I agree to the <Link to="/terms">Terms of Services</Link>{' '}
                    and <Link to="/policy">Privacy Policy</Link> *
                  </span>
                }
              />
            </div>
            <div className="links-area">
              <NButton
                htmlType="submit"
                disabled={!isValid && isSubmitting}
                isLoading={isSubmitting}
                size="x-large"
                block
                type="primary"
              >
                {refUser?._id ? 'Get Started' : "Let's go !"}
              </NButton>
              <strong className="info-text text-primary">
                Already have an Account?
              </strong>
              <Link to="/login">
                <span className="link">Sign-in Here</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default styled(Signup)`
  .displayBadge {
    color: white;
    display: block;
    width: 100%;
    text-align: center;
    border: 1px solid transparent;
    font-weight: 500;
    padding: 3px;
  }
  .Weak {
    border-color: red;
    background: rgba(255, 0, 0, 0.6);
  }
  .Strong {
    border-color: green;
    background: rgba(0, 128, 0, 0.6);
  }
  .Medium {
    border-color: blue;
    background: rgba(0, 0, 255, 0.6);
  }

  .hint-text {
    display: block;
    padding: 10px 0 0;
    font-size: 14px;
    line-height: 18px;
  }
`;

import {
  PublicFacebook,
  PublicInstagram,
  PublicTwitter,
  PublicYoutube,
  Tiktok,
} from 'assets/svgs';
import VideoPlay from 'components/VideoPlay';
import WelcomeModal from 'components/WelcomeModals/WelcomeModal';
import useAuth from 'hooks/useAuth';
import useLocalStorage from 'hooks/useLocalStorage';
import UsePageView from 'hooks/UsePageView';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router';
import styled, { withTheme } from 'styled-components';
import { IUserLink } from 'types';
import ITheme from 'types/Theme';
import IUser from 'types/user';
import {
  arrayFilter,
  getLocalStorage,
  getLocation,
  parseQuery,
  setLocalStorage,
} from 'util/index';
import { v4 as uuid } from 'uuid';
import { linkViewed } from '../../api/analytic';
import ListItem from './components/ListItem';
import PageDescription from './components/PageDescription';
import PageTagline from './components/PageTagLine';
import PageTitle from './components/PageTitle';
import SectionTitle from './components/SectionTitle';
const SocialNetworks = styled.div`
  display: flex;
  justify-content: center;

  a {
    margin: 0 10px;
    transition: all 0.4s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const getTopSection = (theme: any, user: any) => {
  let className = '';
  if (
    theme?.profile?.isActive &&
    !user?.pageTitle &&
    !user?.tagLine &&
    !user?.description
  ) {
    className = 'mt-10 mt-md-30 no-margin';
  }

  if (
    !theme?.profile?.isActive &&
    user?.pageTitle &&
    user?.tagLine &&
    !user?.description
  ) {
    className = 'my-40 my-md-80';
  }

  if (
    !theme?.profile?.isActive &&
    user?.pageTitle &&
    !user?.tagLine &&
    !user?.description
  ) {
    className = 'mb-40 mt-50 mt-md-100 mb-md-80';
  }

  if (
    !theme?.profile?.isActive &&
    !user?.pageTitle &&
    !user?.tagLine &&
    !user?.description
  ) {
    className = 'mt-30 mt-md-100';
  }

  return (
    <div className={className}>
      {!theme?.profile?.profileVideo?.active && (
        <>
          <PageTitle>
            {user ? <>{user.pageTitle || ''}</> : <Skeleton width="40%" />}
          </PageTitle>
          <PageTagline>
            {user ? <>{user?.tagLine}</> : <Skeleton width="30%" />}
          </PageTagline>
          {user && <PageDescription>{user?.description}</PageDescription>}
        </>
      )}
    </div>
  );
};

const LinksSkeleton: React.FC = () => {
  return (
    <>
      <h3>
        <Skeleton width="30%" height={30} />
      </h3>
      <Skeleton className="mb-10" height={50} count={2} />
      <h3>
        <Skeleton width="30%" height={30} />
      </h3>
      <Skeleton className="mb-10" height={50} count={4} />
    </>
  );
};

const getSocialMediaIcon = (
  link: { type: string; url: string },
  theme: ITheme,
  onClick: () => void,
) => {
  let icon: any = null;
  switch (link.type) {
    case 'instagram':
      icon = <PublicInstagram />;
      break;
    case 'tiktok':
      icon = <Tiktok />;
      break;
    case 'twitter':
      icon = <PublicTwitter />;
      break;
    case 'youtube':
      icon = <PublicYoutube />;
      break;
    case 'facebook':
      icon = <PublicFacebook />;
      break;
  }

  return icon ? (
    <a
      href={getLocation(link.url).href}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
      style={{ color: theme.socialIcon?.iconColor || '#3C2A4F' }}
    >
      {icon}
    </a>
  ) : null;
};

const PublicProfile: React.FC<any> = ({
  user,
  theme,
  twoPanelLayout,
  preview,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { hash } = parseQuery(location.search);
  const { user: loggedUser, loggedIn } = useAuth();
  const [analytics, setAnalytics] = useLocalStorage('analytics');
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
  const [services, setServices] = useState<IUserLink[]>([]);
  const [links, setLinks] = useState<IUserLink[]>([]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 700);
  }, []);

  useEffect(() => {
    if (!!user) {
      if (!loggedIn || loggedUser._id !== user._id) {
        if (user.profileStatus !== 'active' || !user.isActiveProfile) {
          return history.push('/profile-unavailable');
        }
      } else if (
        loggedIn &&
        (!user.isActiveProfile || !user.isEmailVerified) &&
        !twoPanelLayout &&
        !preview &&
        getLocalStorage('welcomeModalClosedByUser', false) !== 'true'
      ) {
        setIsWelcomeModalOpen(true);
        setLocalStorage('welcomeModalClosedByUser', 'false', false);
      }

      const userLinks: IUserLink[] = arrayFilter(user.links as IUserLink[], {
        isActive: true,
      })?.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
      setLinks(userLinks.filter((link) => link.linkType !== 'service'));
      setServices(userLinks.filter((link) => link.linkType === 'service'));
    }
  }, [user]);

  UsePageView(user);

  const onLinkViewSendAnalytics = async (
    link: any,
    socialLink?: IUser['socialMediaLinks'][number],
  ) => {
    const d: any = {
      linkTitle: null,
      linkID: null,
      linkURL: null,
      type: null,
    };
    if (link.linkType) {
      d.linkTitle = link.title;
      if (link.linkType === 'socialLinks') {
        d.linkID = socialLink?._id;
        d.linkType = 'socialLink';
        d.linkURL = getLocation(socialLink?.url || '').href;
      } else {
        d.linkID = link._id;
        d.linkType = 'link';
        d.linkURL = getLocation(link.url).href;
      }

      if (analytics?._id) {
        const seen: any[] = analytics.vi;
        const alreadyViewThisLink = seen.find(
          (item) => item.linkID === d.linkID,
        );
        if (!alreadyViewThisLink) {
          await linkViewed({
            user: user?._id,
            viewedById: analytics._id,
            linkId: d.linkID,
            type: d.linkType,
            linkURL: link.linkUrl,
          }).then(() => {
            setAnalytics({ ...analytics, vi: [...seen, d] });
          });
        }
      } else {
        const _id = loggedUser?._id || uuid();
        await linkViewed({
          user: user?._id,
          viewedById: _id,
          linksClicked: d,
          linkId: d.linkID,
          type: d.linkType,
          linkURL: link.linkUrl,
        }).then(() => {
          setAnalytics({ _id, vi: [d] });
        });
      }
    } else {
      d.linkTitle = link.title;
      d.linkID = link._id;
      d.linkURL = `/${user?.username}/${link.popName}`;
      d.linkType = 'pop';
    }
    return d;
  };

  async function onClickHandler(this: any, e: any) {
    const link = await onLinkViewSendAnalytics(this);
    if (link.linkType === 'pop') {
      history.push(link.linkURL);
      return;
    }
    window.open(link.linkURL, '_blank');
  }

  const getService = (item: any, rest: any) => {
    if (!loggedIn || loggedUser._id !== user._id) {
      if (!user?.allowSelling) {
        return null;
      }
    }
    const { _id, title, popThumbnail, popType, isThumbnailActive } =
      item.popLinksId;
    return (
      <ListItem
        key={_id}
        title={title}
        icon={popThumbnail}
        type={popType}
        showIcon={isThumbnailActive}
        onClick={onClickHandler.bind(item.popLinksId)}
        {...rest}
      />
    );
  };

  const GetListItem: React.FC<{
    item: IUserLink;
    user: IUser & { subtitle?: string };
  }> = ({ item, user, ...rest }) => {
    switch (item?.linkType) {
      case 'socialLinks':
        return (
          <SocialNetworks className="my-20">
            {user?.socialMediaLinks?.map((link) =>
              getSocialMediaIcon(link, theme, () => {
                onLinkViewSendAnalytics(item, link);
              }),
            )}
          </SocialNetworks>
        );
      case 'sectionTitle':
        return <SectionTitle>{item.title}</SectionTitle>;
      case 'contentBlock':
        return <p>{item.content}</p>;

      case 'biography':
        return (
          <div className="mb-16" key={item._id} {...rest}>
            <div>
              {!!user?.subtitle && (
                <h4 className="text-center">{user?.subtitle}</h4>
              )}
              {!!user?.description && (
                <p className="text-center">{user?.description}</p>
              )}
            </div>
          </div>
        );
      case 'simpleLink':
      case 'innerCircleLink':
        return (
          <ListItem
            key={item._id}
            title={item.title}
            icon={item.imageURL}
            onClick={onClickHandler.bind(item)}
            showIcon={item.isThumbnailActive}
            {...rest}
          />
        );
      case 'youtubeLink':
        const { mute, autoPlay, loop, url } = item;
        return (
          <VideoPlay
            key={item._id}
            url={url}
            loop={loop}
            mute={mute}
            playing={autoPlay}
            {...rest}
          />
        );
      case 'service':
        if (!item?.popLinksId?._id) return null;
        return getService(item, rest);
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <div className="profile--info">{getTopSection(theme, user)}</div>
        <div id="public-landing">
          {user ? (
            <>
              <div id="service-listing" className="mb-15">
                {services
                  ?.filter((item: any) => item.isActive)
                  .map((item: any) => (
                    <GetListItem key={item._id} item={item} user={user} />
                  ))}
              </div>
              <div id="link-listing" className="mb-30">
                {links
                  ?.filter((item: any) => item.isActive)
                  .map((item: any) => (
                    <GetListItem key={item._id} item={item} user={user} />
                  ))}
              </div>
            </>
          ) : (
            <LinksSkeleton />
          )}
        </div>
      </div>
      <WelcomeModal
        isOpen={isWelcomeModalOpen}
        onClose={() => {
          setIsWelcomeModalOpen(false);
          setLocalStorage('welcomeModalClosedByUser', 'true', false);
        }}
        publicProfile
        user={user}
      />
    </>
  );
};

export default withTheme(styled(PublicProfile)``);

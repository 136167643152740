import classNames from 'classnames';
import dayjs from 'dayjs';
import { PaginationProps } from 'rc-pagination';
import React from 'react';
import styled from 'styled-components';
import Pagination from '../../../../../components/pagination';
import { ITransaction } from '../../../../../interfaces/ITransaction';

interface ITransactionsListProps {
  transactions: ITransaction[];
  paginationProps: PaginationProps;
  showHeader?: boolean;
  className?: string;
}

const TransactionsList: React.FC<ITransactionsListProps> = (props) => {
  const { transactions, paginationProps } = props;
  return (
    <div className={classNames('transaction-table mb-30', props.className)}>
      {/* {showHeader && (
        <div className="table-head">
          <div className="title-box">Type</div>
          <div className="title-box">Transaction ID</div>
          <div className="title-box">Date</div>
          <div className="title-box">Status</div>
          <div className="title-box">Price</div>
        </div>
      )} */}
      <div className="table-body">
        {transactions?.map(({ event }) => (
          <div className="table-row">
            <div className="icon">
              <img
                src={`/assets/images/${event.eventType
                  .toLowerCase()
                  .replace(' ', '-')}.png`}
                alt="img description"
              />
            </div>
            <div className="table-content">
              <div className="top-row">
                <div className="text text-capitalize text-title">
                  {event.eventType}
                </div>
                {event.orderId && (
                  <div className="text orderId">
                    <span>Order ID#: </span>
                    <span className="text-highlighted">
                      {(event.orderId as any)?._id}
                    </span>
                  </div>
                )}
                <div className="text date-txt">
                  <span>Date:</span>{' '}
                  <span className="text-highlighted">{` ${dayjs(
                    event.eventDate,
                  ).format('MM/DD/YYYY')}`}</span>
                </div>
                <div className="text price-txt">
                  <strong className="price">
                    ${(Number(event.eventPrice) || 0).toFixed(2)}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Pagination {...paginationProps} />
    </div>
  );
};

export default styled(TransactionsList)`
  .icon img {
    width: 100%;
    height: 100%;
  }
`;

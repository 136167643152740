import {
  getDashboardLink,
  getStripeConnectLink,
  getStripeId,
  getWalletHistory,
} from 'api/billing';
import { DollarAlt } from 'assets/svgs';
import { DateRangeType } from 'components/DatePicker';
import NewButton from 'components/NButton';
import HeaderOption from 'components/Tables/components/Header';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import useAuth from 'hooks/useAuth';
import useQuery from 'hooks/useQuery';
import useRequestLoader from 'hooks/useRequestLoader';
import { ITransaction } from 'interfaces/ITransaction';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import Utils from 'utils';
import BankInfo from './components/BankInfo';
import TransactionsList from './components/TransactionsList';

const PAGE_LIMIT = 5;
dayjs.extend(isBetween);

const EVENT_STATUSES = [
  'All',
  'Commission',
  'Withdrawal',
  'Sale',
  'Sale Delivered',
  'Refund',
  'Platform Fee',
  'Time Extend',
];

const PageSizeOptions = [
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

const CustomSelect = (props: any) => {
  return (
    <>
      <Select
        {...props}
        isSearchable={false}
        options={PageSizeOptions}
        onChange={(value) =>
          props.onChange(value?.value || PageSizeOptions[0].value)
        }
        defaultValue={PageSizeOptions[0]}
        placeholder="Page Size"
        menuPlacement="top"
        styles={{ container: (base) => ({ ...base, width: '90px' }) }}
        value={{ label: props.value, value: props.value }}
      />
    </>
  );
};
CustomSelect.Option = 'option';

const Payments: React.FC = () => {
  const { user, setUser } = useAuth();
  const { code, page, pageSize = 5 } = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [walletHistory, setWalletHistory] = useState<any>({});
  const [walletEvents, setWalletEvents] = useState<ITransaction[]>([]);
  const [filters, setFilters] = useState<{
    status: string;
    dateRange: DateRangeType | null;
  }>({ status: '', dateRange: null });
  const { withLoader } = useRequestLoader();
  const connectBankAccount = () => {
    getStripeConnectLink(user?.email)
      .then((response) => {
        window.open(response.url, '_self');
      })
      .catch((e) => console.log(e));
  };

  const connectedBankAccount = () => {
    getDashboardLink()
      .then((response) => {
        window.open(response.url, '_self');
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (code) {
      getStripeId(code as string)
        .then((res) => {
          if (res?.user) {
            const stripeSellerAccountId = res.user.stripeSellerAccountId;
            setUser({ ...user, stripeSellerAccountId: stripeSellerAccountId });
          } else {
          }
        })
        .catch((e: Error) => {
          Utils.showAlert(e);
        });
    }
  }, [code]);

  useEffect(() => {
    getTransactions(
      parseInt(page as string),
      filters,
      parseInt(pageSize as string),
    );
  }, [page, pageSize]);

  useEffect(() => {
    if (filters.status || filters.dateRange) {
      console.log({ filters });
      getTransactions(1, filters, 5);
      history.push({
        pathname: location.pathname,
        search: 'page=1&pageSize=5',
      });
    }
  }, [filters]);

  const getTransactions = (
    pageNumber: number,
    currentFilters: typeof filters,
    size: number,
  ) => {
    const pageIndex = pageNumber || 1;
    const skip = (pageIndex - 1) * (size || PAGE_LIMIT);
    const { status, dateRange } = currentFilters;

    const params: Parameters<typeof getWalletHistory>[0] = {
      skip,
      limit: size || PAGE_LIMIT,
    };
    if (status) {
      params.status = status;
    }
    if (filters.status === 'All') {
      params.status = '';
    }
    if (dateRange) {
      params.startDate = dayjs(dateRange[0]).format('YYYY-MM-DD');
      params.endDate = dayjs(dateRange[1]).format('YYYY-MM-DD');
    }

    withLoader(getWalletHistory(params))
      .then((res) => {
        setWalletHistory({
          ...res,
          walletEvents: res.item?.walletEvents || [],
        });
        setWalletEvents(res.item?.walletEvents || []);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const onConnectBankClick = () => {
    if (user.stripeSellerAccountId) return connectedBankAccount();
    connectBankAccount();
  };

  const GetHeader = () => {
    let message = 'Connect Your Bank Account';

    if (user.stripeSellerAccountId) {
      if (
        walletHistory.capabilities?.card_payments.toLowerCase() ===
          'inactive' ||
        walletHistory.capabilities?.transfers.toLowerCase() === 'inactive'
      ) {
        message = 'Additional Info Required for Withdrawal';
      } else {
        message = 'Bank Account Connected!!';
      }
    }
    return (
      <div id="connect-bank-account" onClick={onConnectBankClick}>
        <NewButton
          className="text-uppercase"
          type="default"
          outline
          block
          icon={<DollarAlt />}
          style={{ fontSize: 16 }}
        >
          <span className="social--text">{message}</span>
        </NewButton>
      </div>
    );
  };
  return (
    <div>
      <BankInfo
        withdrawalAvailable={walletHistory.withdrawalAvailable}
        withdrawalPending={walletHistory.withdrawalPending}
      />
      <GetHeader />
      <hr className="mb-30" />
      <HeaderOption
        options={{ calender: true, select: true, search: false }}
        classes={{ main: 'mb-30' }}
        selectOptions={{
          options: EVENT_STATUSES.map((status) => ({
            label: status,
            value: status,
          })),
          onChange: (option: { label: string; value: string }) => {
            setFilters((prev) => ({ ...prev, status: option.value }));
          },
        }}
        onDateRangeChange={(dateRange: DateRangeType) => {
          setFilters((prev) => ({ ...prev, dateRange }));
        }}
      />
      <TransactionsList
        showHeader
        transactions={walletEvents}
        paginationProps={{
          total: walletHistory?.total,
          current: parseInt(page as string) || 1,
          pageSize: parseInt(pageSize as string) || PAGE_LIMIT,
          onChange: (page, size) => {
            if (size === parseInt(pageSize as string)) {
              history.push({
                pathname: location.pathname,
                search: `page=${page}&pageSize=${size}`,
              });
            }
          },
          onShowSizeChange: (current, size) => {
            history.push({
              pathname: location.pathname,
              search: `page=1&pageSize=${size}`,
            });
          },
          showSizeChanger: true,
          selectComponentClass: CustomSelect,
        }}
      />
    </div>
  );
};

export default Payments;

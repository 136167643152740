import dayjs from 'dayjs';
import useAuth from 'hooks/useAuth';
import useRequestLoader from 'hooks/useRequestLoader';
import { stringify } from 'querystring';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { capitalizeFirstLetter, parseQuery } from 'util/index';
import { getAnalytics } from '../../api/analytic';
import According from '../../components/according-social';
import useCopyToClipBoard from '../../hooks/useCopyToClipBoard';
const Index: React.FC<any> = ({ className }) => {
  const { user } = useAuth();
  const [dateRange, setDateRange] = useState({
    endDate: new Date(),
    startDate: new Date(),
  });
  const [copied, copy] = useCopyToClipBoard();
  const [data, setdata] = useState<any>({});
  const history = useHistory();
  const { links, profileView, totalActiveOrder } = data;
  const query = useLocation().search;
  const { withLoader } = useRequestLoader();
  useEffect(() => {
    const pareseQuery = parseQuery(query);
    const endDate = dayjs(pareseQuery?.sd as any).format('ddd MMM D YYYY');
    const startDate = pareseQuery?.st
      ? dayjs(pareseQuery?.st as any).format('ddd MMM D YYYY')
      : dayjs().subtract(6, 'month').format('ddd MMM D YYYY');

    withLoader(getAnalytics({ start: startDate, end: endDate })).then((res) => {
      setdata(res);
      setDateRange({
        endDate: new Date(endDate),
        startDate: new Date(startDate),
      });
    });
  }, [query]);

  const CustomInputStart = ({ value, onClick }: any) => (
    <span className="datepicker-area__start" onClick={onClick}>
      {value}
    </span>
  );
  const CustomInputEnd = ({ value, onClick }: any) => (
    <span className="datepicker-area__end" onClick={onClick}>
      {value}
    </span>
  );
  const handleChange = (date: any, type: string) => {
    setDateRange((s: any) => {
      const n = { ...s, [type]: date };
      const start = dayjs(n.startDate);
      const end = dayjs(n.endDate);
      if (end.diff(start, 'dates') < 1) {
        return s;
      }
      const md: string = stringify({
        st: dayjs(n.startDate).format('MM/DD/YYYY'),
        sd: dayjs(n.endDate).format('MM/DD/YYYY'),
      });

      history.push(`?${md}`);
      return n;
    });
  };
  return (
    <div className={className}>
      {/* <div className="mb-40 live-link-bar">
        <div className="info-frame d-flex align-items-center">
          <div className="icon">
            <span className="icon-live"></span>
          </div>
          <div className="link-link-info">
            <p>
              <strong>YOUR PROFILE LINK:</strong>
            </p>
            <p>
              <Link to={`${window.location.host}/${user?.username}`}>
                {`${window.location.host}/${user?.username}`}
              </Link>
            </p>
          </div>
        </div>
        <NewButton
          className="pl-40 button-small"
          type="primary"
          shape="circle"
          onClick={() => copy(`${window.location.host}/${user?.username}`)}
        >
          <span className="icon-url"></span>
          {copied ? 'Copied' : 'Copy'}
        </NewButton>
        <NewButton
          className="pl-40 button-small"
          outline
          type="default"
          shape="circle"
          onClick={() => (window.location.href = '/my-profile')}
        >
          <span className="icon-edit">
            <Edit />
          </span>
          Edit
        </NewButton>
      </div>
      <div className="orders-detail-box mb-30 mb-md-50">
        <div className="orders--text">
          You have <div className="orders-numbers">{totalActiveOrder || 0}</div>
          active orders to complete!
        </div>
        <NewButton
          className="button-small"
          type="primary"
          shape="circle"
          icon={<TickStar />}
          onClick={() => (window.location.href = '/my-sales')}
        >
          <span className="text">My Sales</span>
        </NewButton>
      </div>*/}
      <div className="calendar-area mb-35">
        {/* <h4 className="mb-35">Your Links</h4> */}
        <div className="visitors-details">
          <div className="visitors-info">
            Total Visits:{' '}
            <strong className="visitors-num">{profileView}</strong>
          </div>
          <div className="datepicker-area">
            <i className="icon">
              <img
                src="/assets/images/svg/icon-calendar-b.svg"
                alt="img description"
              />
            </i>
            <span className="starting-date">
              <DatePicker
                selected={dateRange.startDate}
                dateFormat="MMM dd, yyy"
                onChange={(date) => handleChange(date, 'startDate')}
                customInput={<CustomInputStart />}
                className="rc-date__picker"
              />
            </span>
            <span className="datepicker-area__separator">-</span>
            <span className="ending-date">
              <DatePicker
                selected={dateRange.endDate}
                dateFormat="MMM dd, yyy"
                onChange={(date) => handleChange(date, 'endDate')}
                customInput={<CustomInputEnd />}
                className="rc-date__picker"
              />
            </span>
          </div>
        </div>
      </div>
      {links?.map((link: any) => {
        const url =
          link.url ||
          `${window.location.host}/${user.username}/${link.popName}`;
        const percetage = Math.ceil((link.click / profileView) * 100);
        const PS = !isNaN(percetage) && percetage !== Infinity ? percetage : 0;

        return (
          <According
            title={link.title || capitalizeFirstLetter(link.type)}
            percent={PS}
            icon={link.imageURL || link.popThumbnail}
          >
            <div>
              <h6>{url}</h6>
              <h6>
                # CLICK:
                <span className="click-count"> {link.click || 0}</span>
              </h6>
            </div>
          </According>
        );
      })}
    </div>
  );
};

export default styled(Index)`
  padding: 24px 18px 2px;
  .visitors-details .ending-date .react-datepicker-popper {
    margin-left: -161px;
  }
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const HeadingBlock = styled.div`
  .label-title {
    font-size: 15px;
    line-height: 18px;
    margin: 0 0 5px;
    color: #000;
    font-weight: 500;
  }

  .description-text {
    display: block;
    color: #67616d;
    font-size: 14px;
    line-height: 18px;
    color: #67616d;
    font-weight: 400;
  }
`;

const TagList = styled.div`
  background: #fff;
  border-radius: 6px;
  padding: 13px 10px;
  margin: 0 0 14px;
  border: 1px solid #d7b3e3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  align-items: center;

  .tag {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: #fff;
    background: #3b719e;
    border-radius: 5px;
    display: block;
    margin: 5px;
    padding: 5px 30px 5px 10px;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
  }

  .tag .icon-close {
    width: 14px;
    height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 100%;
    background: #fff;
    color: #3b719e;
    position: absolute;
    right: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 14px;
    line-height: 1;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    cursor: pointer;
  }

  .tag .icon-close:hover {
    background: #d7b3e3;
  }

  .form-control {
    border: 0;
    font-size: 13px;
    line-height: 20px;
    padding: 5px 10px;
    height: 30px;
    border-radius: 0;
    border-left: 2px solid #d5d5d5;
    margin: 5px !important;
    width: 200px !important;

    &:focus {
      border-width: 2px !important;
    }
  }

  .form-control::-webkit-input-placeholder {
    color: #6e6e6e;
  }

  .form-control:-ms-input-placeholder {
    color: #6e6e6e;
  }

  .form-control::placeholder {
    color: #6e6e6e;
  }

  @media (max-width: 767px) {
    .tag {
      font-size: 14px;
    }
  }
`;

const InlineTagger = ({ name, value, onChange, setMainTags }: any) => {
  const [tags, setTags] = useState('');
  const [input, setInput] = useState<string>('');

  useEffect(() => {
    if (value) setTags(value);
  }, [value]);

  // useEffect(() => {
  //   onChange && onChange({ target: { name, value: tags } });
  // }, [tags]);

  const onKeyPress = (e: React.KeyboardEvent<any>) => {
    const trimed = input.trim();
    if (e.key === 'Enter' || (e.key === ',' && trimed.length > 0)) {
      const found = tags
        .split(',')
        .find(
          (item: string) => item.trim().toLowerCase() === trimed.toLowerCase(),
        );
      if (found) return;

      const newtags = [...tags.split(','), trimed]
        .filter((tag: string) => tag.length !== 0)
        .join(',');
      setTags(newtags);
      onChange && onChange({ target: { name, value: newtags } });
      setInput('');
      e.stopPropagation();
      e.preventDefault();
    }
  };
  const onChangeHandler = (e: React.ChangeEvent<any>) => {
    const val = e.target.value.replaceAll(',', '');
    setInput(val);
  };
  const onDelete = (index: number) => {
    const tgs = tags
      .split(',')
      .filter((e: any, i: number) => i !== index)
      .join(',');

    setTags(tgs);
  };

  const tagsArray =
    tags
      ?.split(',')
      ?.map((tag: string) => tag.trim())
      .filter((tag: string) => tag.length !== 0) || [];
  return (
    <div>
      <HeadingBlock className="label-area mb-20">
        <div className="label-title pb-0">Edit Your Tags</div>
        <span className="description-text">
          Add your tags to the field below. Separate them by using commas ( , )
        </span>
      </HeadingBlock>
      <TagList className="tagslist mb-35">
        {tagsArray.map((item: string, index: number) => (
          <span key={index} className="tag">
            {item}{' '}
            <span className="icon-close" onClick={() => onDelete(index)}></span>
          </span>
        ))}

        {tagsArray.length < 10 && (
          <input
            className="form-control"
            type="text"
            placeholder="Add New Tag"
            value={input}
            name={name}
            onChange={onChangeHandler}
            onKeyPress={onKeyPress}
          />
        )}
      </TagList>
    </div>
  );
};

export default InlineTagger;

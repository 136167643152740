import { useLayoutEffect } from 'react';
import { SET_LOADING } from './../reducer/appStates';
import useDispatch from './useDispatch';

const useRequestLoader = (isLoading: boolean = false) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch({
      type: SET_LOADING,
      payload: isLoading,
    });
  }, [dispatch, isLoading]);

  const startLoading = () => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
  };

  const stopLoading = () => {
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  };

  const withLoader = async <T>(promise: Promise<T>): Promise<T> => {
    startLoading();
    return await promise
      .then((res) => {
        stopLoading();
        return res;
      })
      .catch((e) => {
        stopLoading();
        throw e;
      });
  };

  return { withLoader };
};

export default useRequestLoader;

import ITheme from '../types/Theme';

export const SET_THEMES = 'SET_THEMES';
export const SET_CURRENT_THEME = 'SET_CURRENT_THEME';
export const SET_SYSTEM_THEMES = 'SET_SYSTEM_THEMES';
export const RESET_THEME_STATE = 'RESET_THEME_STATE';
export const SET_CATEGORIES = 'SET_CATEGORIES';

export interface IThemeState {
  allthemes?: ITheme[];
  current?: ITheme;
  systemThemes?: ITheme[];
  reflectProfileImage?: boolean;
  categories?: [];
}

const themeReducer = (
  state: IThemeState = {
    allthemes: [],
    current: undefined,
    systemThemes: [],
    categories: [],
  },
  action: { type: string; payload: any },
) => {
  const { payload, type } = action;
  switch (type) {
    case SET_THEMES:
      return { ...state, allthemes: payload };
    case SET_CURRENT_THEME:
      return { ...state, current: payload };
    case SET_SYSTEM_THEMES:
      return { ...state, systemThemes: payload };
    case RESET_THEME_STATE:
      return { allthemes: [], current: undefined, systemThemes: [] };
    case SET_CATEGORIES:
      return { ...state, categories: payload };
    default:
      return state;
  }
};

export default themeReducer;

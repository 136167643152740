// import 'cropperjs/dist/cropper.css';
import { getPresignedUrl } from 'api/User';
import attrAccept from 'attr-accept';
import axios from 'axios';
import { CLOUD_FRONT_S3, CLOUD_FRONT_THUMBNAI } from 'config';
import useSocket from 'hooks/useSocket';
import React, { useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import { upload } from '../../api/Utils';
import useOpenClose from '../../hooks/useOpenClose';
import { dataURLtoFile, getImageDimension } from '../../util';
import { toast } from '../toaster';
import CroperModal from './Croper';

const hidden = { display: 'none' };

export function readFile(file: any) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export interface IRcFile {
  id: any;
  url?: string;
  name?: string;
  type?: string;
  size?: number;
  key?: string;
  loaded?: number | string;
  uploadingProgress?: number;
  status?: 'start' | 'uploading' | 'completed' | 'error';
  orignalFile: File;
  encoding?: 'completed' | 'inprogress';
  thumbnail?: string;
  imageString?: string;
}

export interface IUploadAndEditor extends Partial<DropzoneOptions> {
  children?: JSX.Element;
  customRequest?: (file: File | File[]) => void;
  url?: string;
  aspectRatio?: number;
  onCancel?: any;
  accept?: string | string[];
  loading?: boolean;
  disabled?: boolean;
  cropper?: boolean;
  onStartUploading?: (file: IRcFile, cancelRequest?: () => void) => void;
  onFail?: (file: IRcFile, e: ErrorEvent) => void;
  onSuccess?: (file: IRcFile) => void;
  validation?: {
    minHeight?: number;
    minWidth?: number;
    minSize?: number;
    maxSize?: number;
  };
  maxFiles?: number;
  onProgress?: (file: any) => void;
}

export const UploadandEditor = ({
  children,
  aspectRatio,
  url,
  customRequest,
  accept = 'image/*',
  onProgress,
  disabled = false,
  onCancel,
  onSuccess,
  onFail,
  onStartUploading,
  validation = {},
  cropper = false,
  maxFiles,
  ...rest
}: IUploadAndEditor) => {
  const { minHeight, minWidth, minSize, maxSize } = validation;

  const { acceptedFiles, getRootProps, getInputProps, rootRef } = useDropzone({
    disabled,
    maxFiles: cropper ? 1 : maxFiles,
    multiple: !cropper,
    minSize,
    maxSize,
    accept,
    noDragEventsBubbling: true,
    onDrop: async (acceptedFiles, fileRejections, e) => {
      const rejections = [...fileRejections];
      const promises: Promise<any>[] = [];
      for (let index = 0; index < acceptedFiles.length; index++) {
        const file = acceptedFiles[index];

        promises.push(getImageDimension(file));
      }
      const files: any[] = await Promise.all(promises);
      files.forEach((file) => {
        if (
          attrAccept({ name: file.name, type: file.type }, 'image/*') &&
          (minHeight || minWidth)
        ) {
          if (
            minHeight &&
            minWidth &&
            (minHeight > file.height || minWidth > file.width)
          ) {
            rejections.push({
              file,
              errors: [
                {
                  code: 'small-width',
                  message: `The image should be alteast ${minWidth}x${minHeight}`,
                },
              ],
            });
          }
        }
      });
      if (rejections.length > 0) {
        rejections.forEach((error) => {
          if (error.errors[0].code === 'file-too-large') {
            toast.error(
              `Max ${maxSize! / (1024 * 1024)}MB of file is allowed `,
            );
          } else if (error.errors[0].code === 'file-invalid-type') {
            toast.error(`Sorry invalid file type`);
          } else {
            toast.error(error.errors[0].message);
          }
        });
        return;
      }

      const { name, type } = files[0];
      if (cropper && attrAccept({ name, type }, 'image/*')) {
        const imageString = await readFile(files[0]);
        setImageString(imageString as string);
        onOpen();
      } else {
        handleUpload(files);
      }
    },
    ...rest,
  });

  const { socket } = useSocket();
  const [open, onOpen, onClose] = useOpenClose();

  const [imageString, setImageString] = useState('');

  const [loading, setloading] = useState(false);

  const closeHandler = () => {
    onClose();
    if (onCancel) onCancel();
  };

  const handleProgress = (progressEvent: any, file: IRcFile) => {
    file.uploadingProgress = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    file.status = 'uploading';
    if (onProgress)
      onProgress({
        ...file,
        loaded: progressEvent.loaded,
        progressEvent: progressEvent,
      });
  };

  const handleUpload = async (fls: File[]) => {
    if (customRequest) {
      customRequest(fls);
      return;
    }

    const files = [];

    for (let index = 0; index < fls.length; index++) {
      const file = fls[index];
      files.push({
        name: file.name,
        type: file.type,
        id: uuid(),
        size: file.size,
        orignalFile: file,
      });
    }

    setloading(true);

    if (!url) {
      throw new Error('Please Provide a Url to upload');
    }

    for (let file of files) {
      if (attrAccept({ name: file.name, type: file.type }, 'video/*')) {
        let extention: any = file.name.split('.');
        extention = extention[extention.length - 1];
        const key = `${file.id}.${extention}`;

        getPresignedUrl(key, file.type)
          .then((res) => {
            const ourRequest = axios.CancelToken.source();
            onStartUploading &&
              onStartUploading(
                { uploadingProgress: 0, loaded: 0, ...file },
                ourRequest.cancel,
              );

            upload(
              '',
              file.orignalFile,
              {
                method: 'PUT',
                cancelToken: ourRequest.token,
                headers: { 'Content-Type': file.type },
                baseURL: res.signedUrl,
                onUploadProgress: (PE) => handleProgress(PE, file),
              },
              false,
            )
              .then((res) => {
                const videoThumbnail = `${CLOUD_FRONT_THUMBNAI}/thumb-pops/order-videos/${file.id}-00001.png`;
                const videoPath = `${CLOUD_FRONT_S3}/vid-pops/order-videos/${file.id}.mp4`;

                socket?.on(key, ({ status }: any) => {
                  if (status === 'In Progress' && onProgress) {
                    onProgress({
                      url: videoPath,
                      encoding: 'inprogress',
                      loaded: file.orignalFile.size,
                      progressEvent: 100,
                      percentCompleted: 100,
                      thumbnail: videoThumbnail,
                      ...file,
                      ...res,
                    });
                  } else if (status === 'Completed' && onSuccess) {
                    onSuccess({
                      url: videoPath,
                      percentCompleted: 100,
                      encoding: 'completed',
                      loaded: file.size,
                      thumbnail: videoThumbnail,
                      ...file,
                      ...res,
                    });
                  } else if (status === 'fail') {
                    throw new Error('Error while encoding file');
                  }
                });
              })
              .catch((e) => {
                toast.error('Sorry, Please try again');
                if (onFail) onFail(file, e);
              });
          })
          .catch((e) => {
            toast.error('Sorry, Please try again');
            if (onFail) onFail(file, e);
          });
      } else {
        const from = new FormData();
        from.append('file', file.orignalFile);
        from.append('folder', 'users/link-image');

        const ourRequest = axios.CancelToken.source();
        onStartUploading &&
          onStartUploading({ uploadingProgress: 0, loaded: 0, ...file }, () =>
            ourRequest.cancel(),
          );

        upload(url, from, {
          onUploadProgress: (PE) => handleProgress(PE, file),
          cancelToken: ourRequest.token,
        })
          .then((data) => {
            if (onSuccess)
              onSuccess({
                percentCompleted: 100,
                loaded: file.size,
                url: data.imageURL,
                imageString,
                ...file,
                ...data,
              });
          })
          .catch((e) => {
            toast.error('Sorry, Please try again');
            if (onFail) onFail(file, e);
          });
      }
    }
    setloading(false);
  };

  const saveHandler = async (data: string) => {
    const files = [dataURLtoFile(data, acceptedFiles[0].name)];
    closeHandler();
    await handleUpload(files);
  };

  const handleReplace = () => {
    if (disabled) return;
    rootRef?.current?.click();
  };

  return (
    <>
      <span {...getRootProps()}>
        <input type="file" style={hidden} {...getInputProps()} />
        {React.cloneElement(children as any, {
          loading,
          isLoading: loading,
          files: acceptedFiles,
        })}
      </span>
      <CroperModal
        isOpen={open}
        onClose={closeHandler}
        onCancel={closeHandler}
        onReplace={handleReplace}
        showCancel={true}
        showReplace={true}
        image={imageString}
        aspectRatio={aspectRatio}
        handlerSave={saveHandler}
        loading={loading}
        crop
      />
    </>
  );
};

export default UploadandEditor;

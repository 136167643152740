import { ImageThumbnail, VideoThumbnail } from 'assets/svgs';
import attrAccept from 'attr-accept';
import Modal from 'components/modal';
import React, { useCallback, useState } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import swal from 'sweetalert';
import { IDigitalDownloads } from 'types/Pop.type';
import { IAdditionalArt, IAlert } from '../../types';
import DragableItem from './DragableItem';
import SortableList from './SortableList';

const getArtIcon = (type: string) => {
  if (attrAccept({ type }, 'image/*')) {
    return <ImageThumbnail />;
  }
  if (attrAccept({ type }, 'video/*')) {
    return <VideoThumbnail />;
  }

  return null;
};

const AdditionalArt = ({
  title,
  description,
  value,
  onChange,
  binding = { name: 'name', path: 'path', type: 'type' },
  showDelete = true,
}: {
  title?: string;
  description?: string;
  value: Array<
    (IAdditionalArt | IDigitalDownloads) & {
      cbOnCancel?: (ms?: string) => void;
    }
  >;
  onChange: Function;
  binding?: { name: string; path: string; type: string };
  showDelete?: boolean;
}) => {
  const [preview, setPreview] = useState<null | any>(null);

  const onPopSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const sortedArray = arrayMove<any>(value, oldIndex, newIndex).map(
        (item, index) => ({ ...item, sortOrder: index }),
      );

      onChange(sortedArray);
    },
    [value],
  );

  const onDelete = useCallback(
    (index: number) => {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete? ',
        icon: 'warning',
        dangerMode: true,
        buttons: ['Cancel', 'Delete'],
      } as IAlert).then(async (willDelete) => {
        if (willDelete) {
          value[index]?.cbOnCancel?.('Operation canceled by the user.');
          const newadditionalArt = [...value];
          newadditionalArt.splice(index, 1);
          onChange(newadditionalArt);
        }
      });
    },
    [value],
  );

  const onView = (v: any) => {
    setPreview(v);
  };

  const onCancel = (index: number) => {
    onDelete(index);
  };

  return (
    <div>
      <SortableList
        useDragHandle
        items={value}
        onSortEnd={onPopSortEnd}
        renderItem={(item: any, index: number) => {
          return (
            <DragableItem
              icon={getArtIcon(item[binding.type])}
              index={index}
              title={item[binding.name]}
              isActive={item.isActive}
              onView={() => onView(item)}
              onDeleteClick={() => onDelete(index)}
              options={{ view: true, delete: showDelete }}
              showProgress={item.showProgressBar}
              completed={item.uploadingProgress}
              onCancel={() => onCancel(index)}
              error={item.failed}
              {...item}
            />
          );
        }}
      />
      <Modal
        isOpen={Boolean(preview)}
        onClose={() => setPreview(false)}
        showFooter={false}
      >
        {preview && (
          <>
            {attrAccept(
              {
                name: preview[binding.name],
                type: preview[binding.type],
              },
              'video/*',
            ) && (
              <video width="100%" height="100%" controls>
                <source src={preview[binding.path]} type={'video/mp4'} />
              </video>
            )}

            {attrAccept(
              {
                name: preview[binding.name],
                type: preview[binding.type],
              },
              'image/*',
            ) && (
              <img
                src={preview[binding.path]}
                style={{ width: '100%', height: '100' }}
                alt="alt"
              />
            )}
          </>
        )}
      </Modal>
    </div>
  );
};
export default AdditionalArt;

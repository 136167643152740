import { getAffliatedData } from 'api/User';
import { Stars } from 'assets/svgs';
import NewButton from 'components/NButton';
import Pagination from 'components/pagination';
import dayjs from 'dayjs';
import useAuth from 'hooks/useAuth';
import useCopyToClipBoard from 'hooks/useCopyToClipBoard';
import useQuery from 'hooks/useQuery';
import useRequestLoader from 'hooks/useRequestLoader';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { LinkTypeName } from './../../constants';

const ItemRow: React.FC<any> = ({
  affiliateId,
  childId,
  user,
  createdAt,
  notificationType,
  notificationValue,
  notificationTransactionId,
}) => {
  const { firstName, lastName, profileImage } = childId;

  const GetTag = () => {
    if (notificationValue) {
      return (
        <strong className="price">
          <span className="icon icon-dollar"></span> New sale
        </strong>
      );
    }
    switch (notificationType) {
      case 'new_user':
        return (
          <>
            <strong className="price bg-sky-blue">
              <span className="icon icon-star"></span> User
            </strong>
          </>
        );
      case 'new_grandchild_user':
        return (
          <>
            <strong className="price bg-dark-blue">
              <span className="icon icon-star"></span> Sub-user
            </strong>
          </>
        );
    }
    return <></>;
  };

  const GetCaption = () => {
    let message: any;
    if (notificationValue) {
      message = (
        <span>
          Sold a {(LinkTypeName as any)[notificationTransactionId?.popType]}
        </span>
      );
    } else if (affiliateId._id === user?._id) {
      message = (
        <span>
          Joined with <strong>your partner</strong> code.
        </span>
      );
    } else {
      message = (
        <span>
          Joined with{' '}
          <strong>
            {affiliateId.firstName}{' '}
            {affiliateId.lastName || affiliateId.lastName}
          </strong>{' '}
          code.
        </span>
      );
    }

    return <span className="note-text">{message}</span>;
  };
  const style = { width: '100%', height: '100%', borderRadius: '100%' };
  return (
    <div className="table-row">
      <div className="icon blue">
        <img
          style={style}
          src={profileImage || '/assets/images/default-profile-img.svg'}
          alt="img description"
        />
      </div>
      <div className="table-content">
        <div className="top-row">
          <div className="text">
            {firstName} {lastName}
          </div>

          <div className="text">
            <GetTag />
          </div>
        </div>
        <div className="bottom-row">
          <span className="status">
            Date:<span> {dayjs(createdAt).format('MMM DD, YYYY')}</span>
          </span>
          <GetCaption />
        </div>
      </div>
    </div>
  );
};
const PAGE_LIMIT = 50;
export const Affiliate = (props: any) => {
  const { className } = props;
  const [copied, copy] = useCopyToClipBoard();
  const [paginatedItems, setPaginatedItems] = useState({
    items: [],
    totalCount: 0,
  });
  const { user } = useAuth();
  const router = useHistory();
  const { withLoader } = useRequestLoader();
  const { page, pageSize } = useQuery();
  const location = useLocation();
  useEffect(() => {
    if (user && !user?.isAffiliate) {
      router.push('/');
      return;
    }
    const pageIndex = parseInt(page as string) || 1;
    const skip = (pageIndex - 1) * (parseInt(pageSize as string) || PAGE_LIMIT);
    const params: Parameters<typeof getAffliatedData>[0] = {
      skip,
      limit: PAGE_LIMIT || 50,
    };
    withLoader(getAffliatedData(params))
      .then((res) => {
        if (!!res.totalCount) {
          setPaginatedItems({ items: res.items, totalCount: res.totalCount });
        }
      })
      .catch((e) => {
        console.log(e);
        return {};
      });
  }, [page]);

  const paginationProps = {
    total: paginatedItems.totalCount,
    current: parseInt(page as string) || 1,
    pageSize: parseInt(pageSize as string) || PAGE_LIMIT,
    onChange: (page: any, size: any) => {
      router.push({
        pathname: location.pathname,
        search: `page=${page}&pageSize=${size}`,
      });
    },
  };
  const link = `${window.location.host}/innercircle/${user?.username}`;
  return (
    <div className={className}>
      <div className="affiliate-header">
        <img src="/assets/images/innercircle-header.png" alt="banner" />
        <div className="header-text">
          <h2>Grow your inner circle</h2>
          <p>
            to grow your social revenue. Simply share your invitation code below
            and watch your social revenue grow!
          </p>
        </div>
      </div>
      <div className="live-link-bar mb-40">
        <div className="info-frame d-flex align-items-center">
          <div className="icon link-icon">
            <Stars />
          </div>
          <div className="link-link-info">
            <p>
              <strong>YOUR INVITATION LINK:</strong>
            </p>
            <p>
              <Link to="#">{link}</Link>
            </p>
          </div>
        </div>
        <span onClick={() => copy(`${link}`)} className="btn btn-secondary">
          <span className="icon-url"></span>
          {copied ? 'Copied' : 'Copy'}
        </span>
      </div>

      <h3 className="mb-30">My Partners</h3>
      {!!paginatedItems.items.length ? (
        <>
          <div className="transaction-table affiliated-program mb-30">
            <div className="table-body">
              {paginatedItems.items.map((item: any) => (
                <Link
                  to={`/${item?.childId?.username}`}
                  className="header-link-area__link"
                  target="_blank"
                  key={item._id}
                >
                  <ItemRow {...item} user={user} />
                </Link>
              ))}
            </div>
          </div>
          <Pagination {...paginationProps} />
        </>
      ) : (
        <div style={{ maxWidth: '402px', margin: '0 auto' }}>
          <NewButton
            block
            type="primary"
            shape="circle"
            className="btn-disabled"
          >
            You currently do not have any Partners
          </NewButton>
        </div>
      )}
    </div>
  );
};

export default styled(Affiliate)`
  .link-icon {
    color: white;
    background: #255b87;
    text-align: center;
    line-height: 53px;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .affiliate-header {
    position: relative;
    margin-bottom: 40px;

    img {
      width: 100%;
    }
    .header-text {
      position: absolute;
      color: white;
      bottom: 0;
      text-align: center;
      width: 100%;
      padding: 0 40px;

      p {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
`;

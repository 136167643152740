import { removeLocalStorage, setLocalStorage } from '../util';

export const SET_GUEST_USER = 'SET_GUEST_USER';
export const RESET_PURCHASE_STATES = 'RESET_PURCHASE_STATES';
export const SET_ORDER = 'SET_ORDER';

export interface IPurchaseStatesReducer {
  guestUser?: any;
  order?: any;
}

const purchaseStatesReducer = (
  state: IPurchaseStatesReducer = {},
  action: { type: string; payload: any },
) => {
  const { payload, type } = action;
  switch (type) {
    case SET_GUEST_USER:
      setLocalStorage('guestUser', payload);
      return { ...state, guestUser: payload };
    case RESET_PURCHASE_STATES:
      removeLocalStorage('guestUser');
      return { order: undefined, guestUser: undefined };
    case SET_ORDER:
      return { ...state, order: payload };
    default:
      return state;
  }
};

export default purchaseStatesReducer;

import { loadStripe } from '@stripe/stripe-js';
import { AppAlert } from '../components/Model';
import { STRIPE_PUBLIC_KEY } from '../config';
import { getLocalStorage } from '../util';
import request from '../util/request';

const apikey = STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(apikey === undefined ? '' : apikey);

export const fetchCards = async () => {
  const headers: any = {};
  const gu: any = getLocalStorage('guestUser');
  if (gu?.token) {
    headers['Authorization'] = `Bearer ${gu.token}`;
  }
  return await request('/billing', { headers });
};

export const createBillingIntend = async (orderId: string) => {
  const headers: any = {};
  const gu: any = getLocalStorage('guestUser');
  if (gu?.token) {
    headers['Authorization'] = `Bearer ${gu.token}`;
  }

  return await request('/billing/create-payment-intent', {
    headers,
    method: 'POST',
    data: {
      orderId,
    },
  });
};

export const chargeUser = async (
  { orderId, cardId }: any,
  cookies: any = null,
) => {
  const headers: any = {};
  const gu: any = getLocalStorage('guestUser');
  if (gu?.token) {
    headers['Authorization'] = `Bearer ${gu.token}`;
  }

  try {
    let card: any = { id: cardId };
    if (!cardId) {
      const cards = await fetchCards();
      card = cards.sources.find((item: any) => item.isPrimary);
      if (!card.id) throw new Error('Problem adding card');
    }
    const clientSceret = await createBillingIntend(orderId);

    if (!clientSceret.client_secret) {
      throw new Error(
        'Sorry, Due to some technical issue this action can not be performed',
      );
    }

    const stripe = await stripePromise;

    await stripe?.confirmCardPayment(clientSceret.client_secret).then((e) => {
      if (e.error) {
        throw new Error(e.error.message);
      }
      return e;
    });

    const response = await request('/billing/charge-customer', {
      headers,
      method: 'POST',
      data: {
        orderId,
        cardId: card.id,
      },
    });

    if (response?.error) {
      throw new Error(response.message);
    }
    return response;
  } catch (error: any) {
    AppAlert({
      title: 'Oops! We ran into some problems',
      text: error.message,
    });
    throw error;
  }
};
export const addStripeCustomer = async (billingMethodData: any) => {
  const headers: any = {};
  const gu: any = getLocalStorage('guestUser');
  if (gu?.token) {
    headers['Authorization'] = `Bearer ${gu.token}`;
  }

  return await request('/billing/customer', {
    headers,
    method: 'POST',
    data: billingMethodData,
  }).then((res: any) => {
    if (!res.success) {
      throw new Error(res.data.message);
    }
    return res;
  });
};

export const updateStripeCustomer = async (billingMethodData: any) => {
  return await request('/billing/card', {
    method: 'PUT',
    data: billingMethodData,
  }).then((res: any) => {
    if (!res.success) {
      throw new Error(res.data.message);
    }
    return res;
  });
};

export const updatePrimarySource = async (cardId: string) => {
  return request(`/billing/change-primary-source/${cardId}`, {
    method: 'POST',
  }).then((res: any) => {
    if (!res.success) {
      throw new Error(res.data.message);
    }
    return res;
  });
};

export const removeCard = async (cardId: string) => {
  return request(`/billing/${cardId}`, {
    method: 'DELETE',
  }).then((res: any) => {
    if (!res.success) {
      throw new Error(res.data.message);
    }
    return res;
  });
};

export const getStripeConnectLink = async (email: string) => {
  return request(`/stripeConnect/get-stripe-connect-link/${email}`).then(
    (res: any) => {
      if (!res.success) {
        throw new Error(res.data.message);
      }
      return res;
    },
  );
};

export const getDashboardLink = async () => {
  return request('/stripeConnect/get-dashboard-link').then((res: any) => {
    if (!res.success) {
      throw new Error(res.data.message);
    }
    return res;
  });
};

export const getStripeId = async (code: string) => {
  return request(`/stripeConnect/get-stripe-id/?code=${code}`).then(
    (res: any) => {
      if (!res.success) {
        throw new Error(res.data.message);
      }
      return res;
    },
  );
};

export const getWalletHistory = async (params?: {
  skip?: number;
  limit?: number;
  status?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return request('/wallet-history', { params }).then((res: any) => {
    if (!res.success) {
      throw new Error(res.data.message);
    }
    return res;
  });
};

export const createTransfer = async (data: any) => {
  return request('/stripeConnect/create-transfer', {
    data,
    method: 'POST',
  }).then((res: any) => {
    if (!res.success) {
      throw new Error(res.data.message);
    }
    return res;
  });
};

export enum ServiceType {
  SHOUTOUT = 'shoutout',
  ADVERTISE = 'advertise',
  PAYMA = 'payma',
  POPLIVE = 'poplive',
  ADDITIONAL_SERVICES = 'additional-services',
  DIGITAL_DOWNLOADS = 'digital-download',
}

export enum ArtType {
  VIDEO = 'video',
  IMAGE = 'image',
}
export enum ImageLayoutOptions {
  TITLE = 'Tile',
  FIT = 'fit',
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
  TOP_COVER = 'top-cover',
  MIDDLE_COVER = 'middle-cover',
  BOTTOM_COVER = 'bottom-cover',
}

export enum Time {
  SECONDS = 1000,
  MINUTES = 60 * SECONDS,
  HOURS = 60 * MINUTES,
  DAYS = 24 * HOURS,
  YEARS = 365 * DAYS,
}

export enum OrderStatus {
  COMPLETED = 'Completed',
  DISPUTE = 'Dispute',
  CANCELED = 'Canceled',
  IN_PROGRESS = 'In Progress',
  REFUNDED = 'Refunded',
  PRE_ORDER = 'pre-order',
  PENDING = 'Pending',
}
export enum SupportTicketStatus {
  OPEN = 'open',
  CLOSE = 'closed',
}

export enum SocialPlatforms {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  TWITTER = 'twitter',
  TIKTOK = 'tiktok',
  ONLYFANS = 'onlyfans',
}
export enum SocialPlatformschecks {
  facebook = 'facebook.com',
  instagram = 'instagram.com',
  youtube = 'youtube.com',
  twitter = 'twitter.com',
  tiktok = 'tiktok.com',
  onlyfans = 'onlyfans.com',
}

export enum DayOfWeek {
  Sun = 0,
  Mon = 1,
  Tue = 2,
  Wed = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6,
}

import { ContentIcon, ProfileAvatar, Text, Youtube } from 'assets/svgs';
import { GetPopIcon } from 'components/InlinePopForm';
import React from 'react';
import { useHistory } from 'react-router';
import { SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components';
import { ISortableItem } from 'types';
import IPop from 'types/Pop.type';
import { IUserLink } from 'types/user';
import { LinkTypeName } from '../../../constants';
import CardDragable from './card-dragable';
import EditableItem from './EditableItem';
const Li = styled.li`
  z-index: 10;
  margin-bottom: 15px;
  &.service {
    input:checked + .switcher {
      background: #255b87 !important;
    }

    .card-dragable::before {
      background: #255b87 !important;
    }
    .card-dragable.inactive::before {
      background: #d4d4d4 !important;
    }
  }
  &.isdragging .card-dragable {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #dcbde7;
    transform: scale(1.02);

    .drag-dots {
      color: #3b719e;
    }
  }
  & .sp__card {
    border-radius: 10px;
    border: 1px solid #d5dade;
    overflow: hidden;
  }
`;
const SortableItem = SortableElement(
  ({
    link,
    onDelete,
    Actions,
    edit,
    setEdit,
    sn,
    user,
    inlineForm,
    className,
    showDeleteIcon = true,
    showActiveToggle = true,
    showEditIcon = true,
  }: ISortableItem) => {
    const history = useHistory();
    const { onStatusToggel } = Actions;
    const { linkType, title, isActive, _id, popLinksId } = link;

    const onEditHandler = (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (inlineForm) {
        setEdit && setEdit(sn);
        return;
      }
      history.push(`/my-profile/link/${link._id}?type=${linkType}`);
    };

    if (edit !== null && edit === sn) {
      const getValues = () => {
        if (linkType === 'service') {
          return popLinksId;
        }
        if (linkType === 'sectionTitle') {
          return { title, isActive, _id };
        }
        if (linkType === 'biography') {
          return user;
        }
        return link;
      };
      const { onSavePop, onSaveLink, UpdateUser } = Actions;
      return (
        <Li key={sn} className={className}>
          <EditableItem
            type={linkType as string}
            onCancel={() => setEdit(undefined)}
            onSubmit={(values: IPop | IUserLink) => {
              if (linkType === 'service') {
                onSavePop && onSavePop(values);
                return;
              }
              if (linkType === 'biography') {
                UpdateUser && UpdateUser(values);
                return;
              }
              onSaveLink && onSaveLink(values);
            }}
            value={getValues()}
          />
        </Li>
      );
    }

    if (linkType === 'service' && popLinksId?._id) {
      const { _id, title, popThumbnail, price, popType } = popLinksId;
      return (
        <Li key={sn} className={`${className} service`}>
          <CardDragable
            icon={
              popThumbnail ? (
                <img src={popThumbnail} alt="icon" />
              ) : (
                <GetPopIcon type={popType} primaryColor="#255B87" />
              )
            }
            _id={_id}
            title={title}
            active={isActive}
            onEdit={onEditHandler}
            onToggel={onStatusToggel}
            index={sn}
            showDeleteIcon={showDeleteIcon}
            showActiveToggle={showActiveToggle}
            showEditIcon={showEditIcon}
            onDelete={onDelete}
            subTitle={LinkTypeName[popType as keyof typeof LinkTypeName]}
          />
        </Li>
      );
    }

    return (
      <Li key={sn} className={className}>
        <CardDragable
          _id={_id}
          title={title}
          active={isActive}
          onEdit={onEditHandler}
          onDelete={onDelete}
          onToggel={onStatusToggel}
          subTitle={LinkTypeName[linkType as keyof typeof LinkTypeName]}
          showDeleteIcon={showDeleteIcon}
          showActiveToggle={showActiveToggle}
          showEditIcon={showEditIcon}
          index={sn}
          icon={
            linkType === 'sectionTitle' ? (
              <Text />
            ) : linkType === 'contentBlock' ? (
              <ContentIcon />
            ) : linkType === 'biography' ? (
              <ProfileAvatar />
            ) : linkType === 'youtubeLink' ? (
              <Youtube />
            ) : (
              <span className="icon-url"></span>
            )
          }
        />
      </Li>
    );
  },
);
export default SortableItem;

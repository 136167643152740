import Pagination from 'rc-pagination';
// import 'rc-pagination/assets/index.css';
import styled from 'styled-components';
export default styled(Pagination)`
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 14px;

  li {
    padding: 0 3px;
    height: auto;
    outline: none;
    box-shadow: none;

    &:first-child {
      a,
      button {
        border-radius: 4px 0 0 4px;
      }
    }

    &:last-child {
      a,
      button {
        border-radius: 0 4px 4px 0;
      }
    }

    a,
    button {
      color: #747474;
      padding: 6px 13px;
      border: 1px solid #eaeaea;
      display: block;
      background: none;

      &:hover {
        background: #9fa8da;
        border-color: #9fa8da;
        color: #fff;
      }
    }

    .rc-pagination-item-active {
      a,
      button {
        background: #255b87;
        border-color: #255b87;
        color: #fff;
      }
    }
  }

  .rc-pagination-item-active {
    a {
      background: #255b87;
      border-color: #255b87;
      color: #fff;
    }
  }

  .rc-pagination-next {
    .rc-pagination-item-link {
      &:after {
        display: block;
        content: 'Next';
      }
    }
  }

  .rc-pagination-prev {
    .rc-pagination-item-link {
      &:after {
        display: block;
        content: 'Previous';
      }
    }
  }

  .rc-pagination-jump-next,
  .rc-pagination-jump-prev {
    .rc-pagination-item-link {
      background: none;
      border: none;
      outline: none;

      &:hover {
        color: #9fa8da;
      }

      &:after {
        display: block;
        content: '•••';
      }
    }
  }
`;

import NewButton from 'components/NButton';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';
import * as yup from 'yup';
import {
  fetchCities,
  fetchCountries,
  fetchStates,
  getUniversalToken,
  placeSearch,
} from '../../../api/Utils';
import AutoComplete from '../../../components/autoComplete';
import FocusInput from '../../../components/focus-input';
import Select from '../../../components/Select';
import { timezones } from '../../../constants';
const timeZOneOption = timezones.map((data: string) => ({
  value: data,
  label: data,
}));

const AddressCityContainer = styled.div`
  display: flex;

  .input-wrap {
    flex: 1;
    margin-right: 10px !important;
  }

  .input-wrap:last-child {
    margin-right: 0 !important;
  }

  .input-wrap:nth-child(1) {
    flex: 2;
  }

  input {
    height: 58px !important;
  }
`;

type Props = {
  authUser: any;
  onSubmit(values: any): void;
};
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Enter valid email address')
    .required('Enter valid email address'),
  phone: yup.string().max(15, 'Max 15 digits allowed'),
});
const AddressForm: React.FC<Props> = (props) => {
  const { authUser, onSubmit } = props;

  const [countries, setCountries] = useState<any>([]);
  const [copyCountries, setCopyCountries] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [copyStates, setCopyStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [copyCities, setCopyCities] = useState<any>([]);
  const [mailOne, setMailOne] = useState<any>(null);
  const [token, setToken] = useState(null);
  // useEffect(() => {
  //   getCountries();
  // }, []);

  const {
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    validateOnChange: true,
    validationSchema,
    initialValues: {
      phone: authUser?.phone || '',
      zip: authUser?.zip || '',
      timezone: authUser?.timeOffset || '',
      city: authUser?.city || '',
      state: authUser?.state || '',
      address2: authUser?.address2 || '',
      address1: authUser?.address1 || '',
      email: authUser?.email || '',
    },

    onSubmit: async (values) => {
      return onSubmit({ ...values, timeOffset: values.timezone });
    },
  });

  useEffect(() => {
    // if (!!authUser?.country) {
    //   getStates(authUser.country);
    // }
    // if (!!authUser?.state) {
    //   getCities(authUser.state);
    // }
    setValues({
      phone: authUser?.phone || '',
      zip: authUser?.zip || '',
      timezone: authUser?.timeOffset || '',
      city: authUser?.city || '',
      state: authUser?.state || '',
      address2: authUser?.address2 || '',
      address1: authUser?.address1 || '',
      email: authUser?.email || '',
    });
  }, [authUser]);
  const getToken = async () => {
    const res = await getUniversalToken().catch(console.log);
    setToken(res?.auth_token);
    return res?.auth_token;
  };

  const getCountries = async () => {
    let authToken;
    if (token != null) {
      authToken = token;
    } else {
      authToken = await getToken().catch(console.log);
    }
    try {
      fetchCountries(authToken)
        .then(({ data: countries1 }: any) => {
          const countries2: any = countries1.map((country: any) => ({
            label: country.country_name,
            description: country.country_name,
          }));
          setCountries([...countries2]);
          setCopyCountries([...countries2]);
        })
        .catch(console.log);
    } catch (err) {}
  };

  const getStates = async (country: any) => {
    let authToken;
    if (token != null) {
      authToken = token;
    } else {
      authToken = await getToken().catch(console.log);
    }
    try {
      fetchStates(country, authToken)
        .then(({ data: states1 }: any) => {
          const states2 = states1.map((state: any) => ({
            label: state.state_name,
            description: state.state_name,
          }));
          setStates([...states2]);
          setCopyStates([...states2]);
        })
        .catch(console.log);
    } catch (err) {}
  };

  const getCities = async (state: any) => {
    let authToken;
    if (token != null) {
      authToken = token;
    } else {
      authToken = await getToken().catch(console.log);
    }

    try {
      fetchCities(state, authToken)
        .then(({ data: cities1 }: any) => {
          const cities2 = cities1.map((city: any) => ({
            label: city.city_name,
            description: city.city_name,
          }));
          setCities([...cities2]);
          setCopyCities([...cities2]);
        })
        .catch(console.log);
    } catch (err) {}
  };

  // This method handles the search for area
  const mailHandler = (value: any, type: any) => {
    if (value) {
      setMailOne([]);
      if (type === 'addressTwo') {
        setFieldValue('address2', value);
      } else {
        setFieldValue('address1', value);
      }
      placeSearch(value)
        .then((res) => {
          setMailOne(res.data.predictions);
        })
        .catch(console.log);
    } else {
      setMailOne(null);
    }
  };
  // This method handles the values if selected from dropdown
  const valueHandler = (value: any, type: string) => {
    switch (type) {
      case 'addressOne':
        const parsed = value.split(',');
        const country = parsed[parsed.length - 1];
        const state = parsed[parsed.length - 2];
        const city = parsed[parsed.length - 3];
        const address = parsed.slice(0, -3).join(',');
        setFieldValue('country', country);
        setFieldValue('state', state);
        setFieldValue('city', city);
        setFieldValue('address1', address);

        setMailOne([]);
        break;
      case 'addressTwo':
        setFieldValue('address2', value);
        setMailOne([]);
        break;
    }
    setMailOne(null);
  };

  const areafilterHandler = (value: string, type: string) => {
    setFieldValue(type, value);
    // switch (type) {
    //   case 'country':
    //     if (value) {
    //       let _countries = [...countries];
    //       _countries = _countries.filter((sin) => {
    //         return (
    //           sin.description.toLowerCase().indexOf(value.toLowerCase()) !==
    //           -1
    //         );
    //       });
    //       setCopyCountries(_countries);
    //     } else {
    //       setCopyCountries([...countries]);
    //     }
    //     break;
    //   case 'state':
    //     let _state = [...states];
    //     if (value) {
    //       _state = _state.filter((sin) => {
    //         return (
    //           sin.description.toLowerCase().indexOf(value.toLowerCase()) !==
    //           -1
    //         );
    //       });
    //       setCopyStates(_state);
    //     } else {
    //       setCopyStates([...states]);
    //     }
    //     break;
    //   case 'city':
    //     let _city = [...cities];
    //     if (value) {
    //       _city = _city.filter((sin) => {
    //         return (
    //           sin.description.toLowerCase().indexOf(value.toLowerCase()) !==
    //           -1
    //         );
    //       });
    //       setCopyCities(_city);
    //     } else {
    //       setCopyCities([...cities]);
    //     }
    //     break;
    // }
  };

  const areaValuehandler = (value: string, type: string) => {
    setFieldValue(type, value);
  };

  return (
    <form
      id="profileSettingsForm"
      onSubmit={(e) => {
        e.preventDefault();
        const errorsT = JSON.parse(JSON.stringify(errors, null, 2));
        const errors1: any = { ...errorsT };
        const totalErrors = Object.keys(errors1).length;

        if (totalErrors > 0) {
          swal(
            Object.keys(errors1).reduce((result, key: string) => {
              return `${key}: ${errors1[key]}\n${result}`;
            }, ''),
          );
          return;
        }
        handleSubmit(e);
      }}
    >
      <h3 className="section-title mt-50">Contact Information</h3>
      <div className="input-wrap">
        <FocusInput
          label="Email Address"
          id="email"
          name="email"
          type="email"
          dataLpignore="true"
          autocomplete="off"
          error={errors.email}
          touched={true}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
        />
      </div>
      <div className="input-wrap">
        <FocusInput
          label="Phone Number (Optional)"
          id="phone"
          name="phone"
          value={values.phone}
          touched={touched.phone}
          error={errors.phone}
          onChange={handleChange}
          validations={[{ type: 'number' }]}
          limit={15}
          onBlur={handleBlur}
        />
      </div>
      <h3 className="section-title mt-50">Mailing Address (Optional)</h3>
      <div className="input-wrap position-relative customAutoComplete">
        <AutoComplete
          label="Address Line 1"
          id="address1"
          name="address1"
          value={values.address1}
          result={mailOne}
          onChange={mailHandler}
          onSelect={valueHandler}
          fieldType="addressOne"
        />
      </div>

      <div className="input-wrap position-relative customAutoComplete">
        <AutoComplete
          label="Address Line 2"
          id="address2"
          name="address2"
          value={values.address2}
          result={mailOne}
          onChange={mailHandler}
          onSelect={valueHandler}
          fieldType="addressTwo"
        />
      </div>
      <AddressCityContainer>
        <div className="input-wrap position-relative customAutoComplete">
          <AutoComplete
            label="City"
            id="city"
            name="city"
            value={values.city}
            result={copyCities}
            onChange={areafilterHandler}
            onSelect={areaValuehandler}
            fieldType="city"
          />
        </div>

        <div className="input-wrap position-relative customAutoComplete">
          <AutoComplete
            label="State"
            id="state"
            name="state"
            value={values.state}
            result={copyStates}
            onChange={areafilterHandler}
            onSelect={areaValuehandler}
            fieldType="state"
          />
        </div>

        <div className="input-wrap">
          <FocusInput
            label="Zip"
            id="zip"
            name="zip"
            value={values.zip}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.zip}
            error={errors.zip}
          />
        </div>
      </AddressCityContainer>
      <h3 className="section-title">My Timezone</h3>
      <div className="select-wrap mb-45">
        <Select
          name="timezone"
          placeholder="Select timezone"
          options={timeZOneOption}
          value={{
            label: values.timezone,
            value: values.timezone,
          }}
          size="large"
          onChange={(timezone: any) => {
            setFieldValue('timezone', timezone.value);
          }}
        />
      </div>
      <div className="d-flex justify-content-center mb-50">
        <NewButton
          type="primary"
          htmlType="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Save Details
        </NewButton>
      </div>
    </form>
  );
};

export default AddressForm;

import { useState } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { IUserLink, ProfileType } from '../../../types';
import SortableItem from './SortableItem';

const showWidget = (link: IUserLink, type?: ProfileType) => {
  if (!type) {
    return true;
  }
  return type === 'services'
    ? link.linkType === 'service'
    : link.linkType !== 'service';
};

const SortableList = SortableContainer(
  ({
    items,
    onDelete,
    Actions,
    type,
    ...rest
  }: {
    items: IUserLink[];
    onDelete?: (index: number) => void;
    Actions?: any;
    user: any;
    inlineForm?: boolean;
    type?: ProfileType;
  }) => {
    // tslint:disable-next-line: react-hooks-nesting
    const [edit, setEdit] = useState(undefined);
    return (
      <ul className="sortable">
        {items.map((item: any, index: number, arr: IUserLink[]) => {
          const showDeleteIcon =
            item.linkType === 'service'
              ? arr.filter(
                  (v) => v.popLinksId?.popType === item.popLinksId.popType,
                )?.length > 1
              : true;
          const showEditIcon = item.linkType !== 'innerCircleLink';
          const showActiveToggle = item.linkType !== 'innerCircleLink';
          return showWidget(item, type) ? (
            <SortableItem
              onDelete={onDelete}
              Actions={Actions}
              key={`item-${index}-${item._id}`}
              link={item}
              index={index}
              edit={edit}
              sn={index}
              setEdit={setEdit}
              disabled={edit === index}
              showDeleteIcon={showDeleteIcon}
              showEditIcon={showEditIcon}
              showActiveToggle={showActiveToggle}
              {...rest}
            />
          ) : null;
        })}
      </ul>
    );
  },
);
export default SortableList;

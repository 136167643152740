import * as React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button';
import { IInviteSlideProps } from '../../../interfaces/IInvitation';

const Slide2: React.FC<IInviteSlideProps> = ({
  sliderNumber,
  next,
  className,
}) => {
  return (
    <div className={className}>
      <div className="profile--info mb-10">
        <h1>Sell Shoutouts</h1>
        <h6>Let fans pay you to to create custom video greetings for them.</h6>
      </div>
      <div className="image-holder">
        <figure>
          <img src="/assets/images/invitation/Invite-PC2.png" alt="profile" />
        </figure>
      </div>
      <Button onClick={next} classNames={`my-btn-${sliderNumber}`}>
        NEXT
      </Button>
    </div>
  );
};

export default styled(Slide2)`
  .image-holder {
    max-width: 31vh;
  }

  ${({ sliderNumber }) => {
    return `
  .my-btn-${sliderNumber} {
    background: #018abd;
    border-color: #018abd;
  }
  .my-btn-${sliderNumber}:hover {
    background: #015371 !important;
    border-color: #015371 !important;
  }
  `;
  }}
`;

import classNames from 'classnames';
import React from 'react';
import Switchbox from './switchbox';

export default function SwitchboxWidget(props: any) {
  const classes = props.classes || 'mb-20';
  return (
    <div
      className={classNames(
        'toggle-widget',
        { inactive: !props.enabled },
        classes,
      )}
    >
      <strong className="title">
        {props.label ? `Activate this ${props.label}?` : props.title}
      </strong>
      <Switchbox size="small" value={props.enabled} {...props} />
      <span className="icon">
        {props.enabled ? (
          <span className={`show ${props.showicon || 'icon-tick'}`}></span>
        ) : (
          <span className={`hide ${props.hideicon || 'icon-close'}`}></span>
        )}
      </span>
    </div>
  );
}

import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import Invitation from '../../../layout/invitation';
import IUser from '../../../types/user';
import Slide1 from './components/slide1';
import Slide2 from './components/slide2';
import Slide3 from './components/slide3';
import Slide4 from './components/slide4';
import Slide5 from './components/slide5';
import Slide6 from './components/slide6';
import Slide7 from './components/slide7';
import Slide8 from './components/slide8';
import Welcome from './welcome';
import Welcome1 from './welcome-1';
import Welcome2 from './welcome-2';
import Welcome3 from './welcome-3';
import Welcome4 from './welcome-4';
import Welcome5 from './welcome-5';
import Welcome6 from './welcome-6';
import Welcome7 from './welcome-7';

const slides = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide6, Slide7, Slide8];

const getStyles = (slideNumber: number) => {
  switch (slideNumber) {
    case 0:
    case 6:
      return { background: '#000' };
    case 1:
      return { background: '#01101e' };
    case 2:
      return { background: '#01101e' };
    case 3:
      return { background: '#01101e' };
    case 4:
      return { background: '#000' };
    case 5:
      return { background: '#000' };
    case 7:
      return { background: '#000' };
    default:
      return {};
  }
};

const CSlider: React.FC<{ user: IUser }> = ({ user }) => {
  const slider = React.useRef<any>();
  const Next = () => {
    slider.current?.slickNext();
  };
  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 2000,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
  };

  return (
    <Slider
      className="mobile-slideshow"
      {...settings}
      ref={slider}
      lazyLoad="ondemand"
    >
      {slides.map((Slide, index) => {
        return (
          <div
            className="test"
            style={{ height: '100%', verticalAlign: 'top' }}
          >
            <div
              className={`invitation-frame text-center bg-frame-${index}`}
              style={getStyles(index)}
            >
              <Slide
                next={Next}
                key={index}
                sliderNumber={index}
                user={user}
                gotoSignUp={() => slider.current?.slickGoTo(7)}
              />
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
const App: React.FC<{ user: IUser }> = ({ user }) => {
  const { username } = useParams<{ username: string }>();
  const slider = React.useRef<any>();
  const Next = () => {
    slider.current?.slickNext();
  };
  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 2000,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
  };
  return isMobileOnly ? (
    <Slider {...settings} ref={slider} lazyLoad="ondemand">
      {[
        Welcome,
        Welcome1,
        Welcome2,
        Welcome3,
        Welcome4,
        Welcome5,
        Welcome6,
        Welcome7,
      ].map((Slide, index) => {
        return (
          <div
            className="test"
            style={{ height: '100%', verticalAlign: 'top' }}
          >
            <div
              className={`invitation-frame text-center bg-frame-${index}`}
              style={getStyles(index)}
            >
              <Slide
                className=""
                next={Next}
                key={index}
                sliderNumber={index}
                user={user}
                gotoSignUp={() => slider.current?.slickGoTo(7)}
              />
            </div>
          </div>
        );
      })}
    </Slider>
  ) : (
    <Invitation>
      <div className="invitation-area">
        <div className="invitation-holder">
          <div className="invitation-wrap">
            <span className="img-overlay">
              <img src="/assets/images/iphone-banner.png" alt="iPhone Banner" />
            </span>
            <img src="/assets/images/img-front-cover.png" alt="Front Cover" />
            <CSlider user={user} />
          </div>
        </div>
      </div>
    </Invitation>
  );
};

export default App;

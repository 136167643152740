import OnBoardingTour from 'components/OnboardingTour';
import Header from 'components/partials/header';
import SpecialNotification from 'components/specific-notification';
import WelcomeModal from 'components/WelcomeModals/WelcomeModal';
import useAuth from 'hooks/useAuth';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import useDispatch from 'hooks/useDispatch';
import useSelector from 'hooks/useSelector';
import React, { useEffect, useState } from 'react';
import { BrowserView, isMobileOnly } from 'react-device-detect';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import {
  SET_EMAIL_NOTIFICATION_CLOSED_BY_USER,
  SET_WELCOMEMODEL_STATE,
} from 'reducer/appStates';
import {
  getLocalStorage,
  onboardingSequency,
  removeLocalStorage,
} from 'util/index';
interface ISelfiePopProps {
  showHeaderMenu?: boolean;
}

const SelfiePopLayout: React.FC<ISelfiePopProps> = (props) => {
  const { showHeaderMenu = true } = props;
  const { user } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { loading, showWelcomeModel, emailNotificationClosedByUser } =
    useSelector((state) => state.appStates);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(
    getLocalStorage('showWelcomeModal', false) === 'true',
  );

  const { activeView } = useControllTwopanelLayoutView();
  // const dispatch = useDispatch();

  const [notification, setNotification] = useState<boolean>(false);

  // Expample how to use Dispatch just like request
  // useEffect(() => {
  //   dispatch({ type: SET_LOADING, payload: true });
  // }, []);

  const isOnboarding = matchPath(history.location.pathname, {
    path: '/onboarding',
    exact: false,
    strict: false,
  });

  useEffect(() => {
    if (user?._id) {
      if (!user.skipOnBoarding) {
        let redirect = '';
        if (onboardingSequency[user?.userSetupStatus]) {
          redirect = onboardingSequency[user?.userSetupStatus];
        } else if (isOnboarding) {
          redirect = '/';
        }

        if (redirect) {
          history.push(redirect);
          return;
        }
      } else if (isOnboarding) {
        history.push('/');
        return;
      }
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (user?._id) {
      if (
        user.userSetupStatus > 9 &&
        !user?.isEmailVerified &&
        !emailNotificationClosedByUser
      ) {
        setNotification(true);
      }
    }
  }, [user]);

  const OBT = getLocalStorage('onBoardingTour', false) === 'true';
  const isMyProfile = matchPath(history.location.pathname, {
    path: '/my-profile',
    strict: false,
  });
  const isThemeListing = matchPath(history.location.pathname, {
    path: '/my-profile/themes-listing',
    exact: true,
    strict: false,
  });

  const themePreviewMode =
    pathname.startsWith('/theme-library/preview') ||
    (isMobileOnly && isThemeListing && activeView === 'right');
  return (
    <>
      <BrowserView>{OBT && isMyProfile && <OnBoardingTour />}</BrowserView>
      {user && showHeaderMenu && notification && !themePreviewMode && (
        <SpecialNotification
          isOpen={notification}
          user={user}
          onClose={() => {
            dispatch({
              type: SET_EMAIL_NOTIFICATION_CLOSED_BY_USER,
              payload: true,
            });
            setNotification(false);
          }}
        />
      )}
      <div id="wrapper" className="d-flex flex-column">
        {!themePreviewMode && (
          <Header user={user} showHeaderMenu={showHeaderMenu} />
        )}
        <main id="main" className="flex-grow-1">
          {props.children}
        </main>
      </div>
      <WelcomeModal
        isOpen={isWelcomeModalOpen || showWelcomeModel}
        user={user}
        onClose={() => {
          setIsWelcomeModalOpen(false);
          removeLocalStorage('showWelcomeModal');
          dispatch({
            type: SET_WELCOMEMODEL_STATE,
            payload: false,
          });
        }}
      />
    </>
  );
};

export default SelfiePopLayout;

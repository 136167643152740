import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IUser from 'types/user';
import Button from '../../components/button';
import Invitation from '../../layout/invitation';

const Welcome5: React.FC<{ className: string; user: IUser }> = ({
  className,
  user,
}) => {
  return (
    <div className={className}>
      <Invitation>
        <div className="profile--info text-center">
          <h1>Keep 90% of all sales from your shoutouts!</h1>
        </div>
        <div className="image-holder">
          <figure className="profile-snap">
            <img
              src="/assets/images/invitation/Invite-Mobile-6.png"
              alt="profile"
              width="100%"
            />
          </figure>
        </div>
        <div className="profile--info">
          <h5 className="text-center">
            Your Pop Page is always free for you to use, and you will keep 90%
            of the sales from your shout-outs and paid fan mail.
          </h5>
        </div>
        <div className="text-center">
          <Link to={`/invite/welcome-6?hash=${user?.invitationHash}`}>
            <Button>NEXT</Button>
          </Link>
        </div>
      </Invitation>
    </div>
  );
};

export default styled(Welcome5)`
  .container {
    color: #000;
    padding: 3vh 0;
  }
  .profile-snap {
    margin-right: -3.2vh;
    margin-left: -3.2vh;
  }
  .profile--info {
    margin: 0 0 3vh;
  }
  .profile--info h1 {
    font-size: 3.8vh;
  }
  .profile--info h6 {
    font-size: 2vh;
  }
  .image-holder {
    max-width: 48vh;
    margin: 0 auto;
  }
  .image-holder img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`;

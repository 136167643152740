import React, { ReactElement, useEffect, useRef } from 'react';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars';
import Measure from 'react-measure';
import { useLocation } from 'react-router-dom';

interface Props extends ScrollbarProps {
  children?: React.ReactChild | React.ReactChildren | React.ReactNode;
}

export default function Scrollbar({ children, ...rest }: Props): ReactElement {
  const ref = useRef<any>();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => ref?.current?.forceUpdate(), 700);
  }, [location.pathname]);

  return (
    <Scrollbars {...rest} ref={ref}>
      <Measure
        bounds
        onResize={() => {
          ref.current && ref.current.forceUpdate();
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef} className="rc-scollbar">
            {children}
          </div>
        )}
      </Measure>
    </Scrollbars>
  );
}

import classNames from 'classnames';
import Button from 'components/NButton';
import * as React from 'react';
import styled from 'styled-components';
import Switchbox from './switchbox';
import UploadandEditor, { IUploadAndEditor } from './UploadandEditor';
interface IAppProps {
  className?: string;
  classes?: { main?: string };
  icon?: string | React.ReactElement;
  uploadProps?: Partial<IUploadAndEditor>;
  onDelete?: () => void;
  options?: {
    title?: boolean;
    delete?: boolean;
    upload?: boolean;
    replace?: boolean;
  };
  onToggel?: Function;
  isActive?: boolean;
}

const UploadThumbnail: React.FunctionComponent<IAppProps> = ({
  className,
  classes = {},
  icon,
  uploadProps,
  onDelete,
  options = {},
  onToggel,
  isActive,
}) => {
  const { main: mainClass } = classes;

  return (
    <div className={classNames(className, mainClass, 'custom-thumb-pop')}>
      <div className="thumb-title">
        <div className="thumb-box">
          <div className="social--icon img">
            {icon && (
              <>
                {typeof icon === 'string' ? (
                  <img src={icon} alt="img description" />
                ) : (
                  icon
                )}
              </>
            )}
          </div>
          <div className="description">
            <strong className="description-title">Custom Thumbnail</strong>
            <p>Add a custom Icon or Thumbnail to this link.</p>
          </div>
        </div>
        <Switchbox
          status={false}
          size="small"
          name="isThumbnailActive"
          onChange={onToggel}
          value={isActive}
        />
      </div>
      <div className="actions-btns">
        <UploadandEditor
          url={'/image/upload'}
          aspectRatio={1}
          accept={['image/png', 'image/jpg', 'image/jpeg']}
          cropper
          validation={{ minHeight: 25, minWidth: 25 }}
          {...uploadProps}
        >
          <Button type="primary" block>
            Upload Image
          </Button>
        </UploadandEditor>
        {typeof icon === 'string' && (
          <Button type="primary" onClick={onDelete} block>
            Remove Uploaded Image
          </Button>
        )}
      </div>
    </div>
  );
};

export default styled(UploadThumbnail)`
  .thumb-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: calc(100% - 50px);
  }

  .title {
    display: block;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 2px;
  }

  .img {
    width: 60px;
    height: 60px;
    padding: 4px;
    border-radius: 100%;
    border: 2px dashed #e1c5eb;
    margin: 0 0 10px;
  }

  .img img {
    width: 52px;
    height: 52px;
    display: block;
    border-radius: 100%;
    object-fit: cover;
  }

  .description {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 0 0 10px;
    color: #626263;
    font-size: 14px;
    line-height: 18px;

    .description-title {
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      color: #000;
    }

    p {
      margin: 0 0 10px;
    }
  }

  .toggle-switch {
    margin: 10px 0 0;
  }

  .thumb-title {
    margin: 0 0 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .actions-btns {
    display: flex;
    flex-direction: row;

    > span {
      width: calc(50% - 5px);
      padding: 0 10px 0 0;

      .button {
        margin: 0;
      }
    }

    .button {
      flex: 1;
      margin: 0 0 0 10px;
    }
  }
`;

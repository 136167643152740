import { Forward, LinkSimple, Star } from 'assets/svgs';
import useCopyToClipBoard from 'hooks/useCopyToClipBoard';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ShareShoutoutModal from './components/ShareShoutoutModal';

interface Props {
  className?: string;
  order?: any;
  orderDetails?: any;
}

const PublicVideo: React.FC<Props> = (props) => {
  const { className, order, orderDetails } = props;
  const { orderId } = useParams<{ orderId: string }>();
  const [isShareShoutoutModalOpen, setIsShareShoutoutModalOpen] =
    useState<boolean>(false);
  const [isCopied, copy] = useCopyToClipBoard();
  return (
    <div className={className}>
      <h2>
        <span className="img">
          <Star />
        </span>
        {order?.seletedPriceVariation?.title || order?.popTitle}
      </h2>
      <div className="video-holder">
        <video
          key={order?.videoLink}
          style={{ width: '100%' }}
          poster=""
          autoPlay
          muted
          loop
          playsInline
          controls
        >
          <source src={order?.videoLink} type="video/mp4" />
        </video>
      </div>
      <div className="video-creator">
        Created By:
        <img
          src={order?.profileImage}
          alt="seller"
          style={{ width: 60, height: 60, borderRadius: '100%' }}
        />
        <Link to={`/${order?.username}`}>
          {order ? `${order?.firstName} ${order?.lastName}` : ''}
        </Link>
      </div>
      {order?.sharedUser ? (
        <div>
          <p>
            From:{' '}
            <span>
              <b>
                {orderDetails?.buyerFirstName} {orderDetails?.buyerLastName}
              </b>
            </span>
          </p>
          <p>
            <b>Message:</b> <span>{order?.sharedUser?.message}</span>
          </p>
        </div>
      ) : (
        <>
          <ul className="list-links">
            <li>
              <Link
                to="#"
                onClick={() => {
                  copy(`${window.location.host}/shoutout/view/${orderId}`);
                  toast.info('Copied the url');
                }}
              >
                <span className="img">
                  <LinkSimple />
                </span>
                <span className="text">Copy Link</span>
              </Link>
            </li>
            <li>
              <Link to="#" onClick={() => setIsShareShoutoutModalOpen(true)}>
                <span className="img img-forward">
                  <Forward />
                </span>
                <span className="text">Share</span>
              </Link>
            </li>
          </ul>
          <hr className="m-0" />
        </>
      )}
      <ShareShoutoutModal
        isOpen={isShareShoutoutModalOpen}
        onClose={() => setIsShareShoutoutModalOpen(false)}
        order={order}
      />
    </div>
  );
};

export default styled(PublicVideo)`
  padding: 14px 0 0;

  h2 {
    font-size: 20px;
    line-height: 24px;
    color: #000;
    font-weight: 500;
    margin: 0 0 19px;

    a {
      color: #1da1f3;
    }

    .img {
      display: inline-block;
      vertical-align: top;
      line-height: 1;
      margin: 0 10px 0 0;
      width: 21px;

      svg {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }
  }

  .video-holder {
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    margin: 0 0 28px;

    video {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .list-links {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 36px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      padding: 0 25px;
    }

    a {
      display: block;

      &:hover {
        color: #1da1f3;
      }
    }

    .img {
      display: block;
      margin: 0 auto 10px;
      width: 30px;

      &.img-forward {
        width: 36px;
      }

      svg {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }

    .text {
      display: block;
    }
  }

  .video-creator {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 18px;
    color: #666;
    border-bottom: 1px solid rgba(221, 221, 221, 0.77);
    padding: 0 0 30px;
    margin: 0 0 30px;

    img {
      overflow: hidden;
      margin: 0 12px;
      border-radius: 100%;
    }

    a {
      color: #000;
      font-weight: 500;
    }
  }

  hr {
    border-color: rgba(221, 221, 221, 0.77);
  }
`;

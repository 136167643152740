import { Cancel, Check, Information } from 'assets/svgs';
import React from 'react';
import { toast as rtoast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

const TosterStyle = styled.div`
  .icon {
    height: 18px;
    width: 18px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const toaster: React.FC<any> = (props) => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      limit={3}
      {...props}
    />
  );
};

const Element: React.FC<any> = ({
  type,
  message,
}: {
  type: string;
  message: string;
}) => {
  let title = 'Success';
  let Icon = Check;
  switch (type) {
    case 'info':
      title = 'Information';
      Icon = Information;
      break;
    case 'error':
      title = 'Error';
      Icon = Cancel;
      break;
  }
  return (
    <TosterStyle>
      <div className="title">
        <span className="icon">
          <Icon />
        </span>
        {title}
      </div>
      <div className="description">{message}</div>
    </TosterStyle>
  );
};
const error = (message: string) => {
  rtoast.error(<Element type="error" message={message} />);
};
const info = (message: string) => {
  rtoast.info(<Element type="info" message={message} />);
};
const success = (message: string) => {
  rtoast.success(<Element type="success" message={message} />);
};

export const toast = { error, info, success };

export default toaster;

const InvitationStatus = (user: any, addition: any) => {
  let message = '';
  let button: any = undefined;
  let buttonText = '';
  let showCloseButton = true;
  let showLink = false;
  let type = 'default';
  if (user.userSetupStatus > 9) {
    // if (user?.profileStatus === 'pending') {
    //   message =
    //     'Your Pop Page is not available to the public as your application is still under review.';
    //   buttonText = 'OK';
    //   showCloseButton = false;
    //   button = addition.handleClose;
    //   type = 'corner';
    // } else
    if (!user.isEmailVerified) {
      message = `Please verify your email address ${user.email} to continue`;
      if (!!!user.allowSelling) {
        message =
          'Please verify your email address to use ecommerce and other advanced features.';
        if (addition.isPublicPage) {
          showLink = true;
        }
      }
      button = addition.button;
      buttonText = addition.emailSent;
      showCloseButton = true;
      type = 'corner';
    } else if (addition.isPublicPage && !user.isActiveProfile) {
      message =
        'Your pop page is currently inactive. Would you like to activate it? ';

      button = addition.ActivateProfile;
      buttonText = 'ACTIVATE IT';
      type = 'corner';
      showCloseButton = false;
    } else {
      message = 'Your are viewing your public profile page.';
      buttonText = 'EDIT MY POP PAGE';
      button = addition.goToEditProfile;
      showCloseButton = false;
      type = 'corner';
    }
  }
  return {
    message,
    showCloseButton,
    buttonText,
    type,
    showLink,
    onButtonClick: button,
  };
};

export default InvitationStatus;

import DatePicker, { DateRangeType } from 'components/DatePicker';
import Select from 'components/Select';
import dayjs from 'dayjs';
import * as React from 'react';
import styled from 'styled-components';

interface IAppProps {
  options?: { search?: boolean; calender?: boolean; select?: boolean };
  title?: string | React.ReactElement;
  selectOptions?: any;
  className?: string;
  classes?: { main?: string };
  icon?: React.ReactElement;
  onDateRangeChange?: (dateRange: DateRangeType) => void;
}

const App: React.FunctionComponent<IAppProps> = ({
  options = {},
  selectOptions,
  title,
  className,
  classes = {},
  icon,
  onDateRangeChange,
}) => {
  const { search, calender, select } = options;
  const { main: mainClass } = classes;
  const [dateRange, setDateRange] = React.useState<[Date, Date]>([
    dayjs().subtract(6, 'months').toDate(),
    new Date(),
  ]);

  return (
    <div className={`${className} ${mainClass}`}>
      {calender ? (
        <DatePicker
          dateRange={dateRange}
          onChange={(dateRange) => {
            const start = dayjs(dateRange[0]);
            const end = dayjs(dateRange[1]);
            if (end.diff(start, 'dates') >= 1) {
              setDateRange(dateRange);
              onDateRangeChange?.(dateRange);
            }
          }}
        />
      ) : (
        <strong className="title">
          <span>{icon}</span>
          {title}
        </strong>
      )}
      <div className="sort-box" style={{ width: 'auto' }}>
        {select && (
          <div className="select-box">
            <Select size="small" placeholder="Select" {...selectOptions} />
          </div>
        )}
        {search && (
          <span className="btn-search">
            <img
              src="/assets/images/svg/icon-search.svg"
              alt="img description"
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default styled(App)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 14px;

  .title {
    display: block;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: #303030;

    svg {
      display: inline-block;
      vertical-align: middle;
      fill: #255b87;
      stroke: #255b87;
      margin: -2px 15px 0 0;
    }

    .counter-text {
      color: #e41076;
      font-weight: 400;
      margin: 0 0 0 5px;
    }
  }

  .calendar-field {
    position: relative;
    width: 47%;
  }

  .datepicker-area {
    background: none;
    border: 1px solid #d5dade;
    border-radius: 24px;
    text-align: center;
    padding: 11px 25px 11px 45px;

    @media (max-width: 767px) {
      width: 100%;
      margin: 0 0 10px;
    }

    .icon {
      left: 24px;

      @media (max-width: 640px) {
        left: auto;
      }
    }
  }

  .calendar-field .icon {
    width: 18px;
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
  }

  .calendar-field .icon img {
    width: 100%;
    height: auto;
    display: block;
  }

  .calendar-field .text-input {
    background: #f7f7f7;
    border-radius: 30px;
    border: 1px solid #dfe0e0;
    text-align: center;
    color: #000;
    height: 44px;
    width: 100%;
    padding: 10px 15px 8px 45px;
    font-weight: 500;
  }

  .calendar-field .text-input:focus {
    outline: none;
  }

  .sort-box {
    width: 310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .select-box {
    width: 244px;
  }

  .btn-search {
    width: 44px;
    height: 44px;
    display: block;
    padding: 11px;
    border-radius: 5px;
    background: rgba(229, 16, 117, 0.4);
    margin-left: 10px;
  }

  .btn-search img {
    display: block;
    max-width: none;
    width: 100%;
    height: auto;
  }

  .btn-search:hover {
    background: #000;
  }

  @media (max-width: 767px) {
    .title {
      width: 100%;
      margin: 0 0 15px;
    }

    .calendar-field {
      width: calc(100% - 60px);
      margin: 0 0 20px;
    }

    .sort-box {
      width: 100% !important;
    }

    .select-box {
      width: 100%;
    }
  }
`;

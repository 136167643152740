import React, { useEffect, useState } from 'react';
import { ProfileType } from 'types';
import useAuth from '../../hooks/useAuth';
import Listing from './components/Listing';

export interface IProps {
  type: ProfileType;
}

const NewProfile: React.FC<IProps> = ({ type }) => {
  const { user, setUser }: any = useAuth();
  const [Links, setLinks] = useState<any[]>([]);

  useEffect(() => {
    setLinks(user?.links || []);
  }, [user]);

  return (
    <div id="profile-area">
      <Listing
        links={Links?.map((item: any) =>
          item.linkType === 'biography'
            ? { ...item, title: user.firstName }
            : item,
        )}
        type={type}
        user={user}
        setLinks={(links: any[]) => {
          setUser((prev: any) => ({ ...prev, links }));
        }}
        inlineForm={false}
      />
    </div>
  );
};

export default NewProfile;

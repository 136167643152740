import { createTicket } from 'api/Support';
import FocusInput from 'components/focus-input';
import NewButton from 'components/NButton';
import { useFormik } from 'formik';
import { ITicket } from 'interfaces/Ticket';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import Modal from '../../../../components/modal';
interface Props {
  onCreateTicketCallback: (ticket: ITicket) => void;
  className?: string;
}
const validationSchema = yup.object().shape({
  subject: yup.string().required('Subject field is required!'),
  description: yup.string().required('Description field is required!'),
});
function SupportFooter({
  onCreateTicketCallback,
  className,
}: Props): ReactElement {
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [isSubmitFormOpen, setIsSubmitFormOpen] = useState(false);
  const {
    isSubmitting,
    values,
    handleChange,
    errors,
    handleBlur,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      subject: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const res = await createTicket({
        issueTitle: values.subject,
        issueDescription: values.description,
      });

      if (res) {
        onCreateTicketCallback(res.ticket);
        resetForm();
      }
      setIsTicketModalOpen(false);
      setIsSubmitFormOpen(true);
    },
  });

  return (
    <div
      className={`${
        isTicketModalOpen
          ? 'ticket_modal'
          : isSubmitFormOpen
          ? 'thankyou-modal'
          : ''
      } ${className}`}
    >
      <div className="btn-holder">
        <NewButton
          block
          type="primary"
          shape="circle"
          onClick={() => setIsTicketModalOpen(true)}
        >
          Open a new Ticket
        </NewButton>
      </div>
      {(isTicketModalOpen || isSubmitFormOpen) && (
        <Modal
          className="support-modal"
          onClose={() => {
            setIsSubmitFormOpen(false);
            setIsTicketModalOpen(false);
          }}
          title={!isSubmitFormOpen ? 'Support Ticket' : ''}
          showFooter={false}
          isOpen={isTicketModalOpen || isSubmitFormOpen}
        >
          {isTicketModalOpen && (
            <form onSubmit={handleSubmit}>
              <div className="mb-10">
                <p>Please let us know how we can help!</p>
                <h3 className="primary_title">Description</h3>
                <p>
                  Please describe the issue you are having. Try to be as
                  detailed as possible.
                </p>
              </div>
              <div className="subject">
                <FocusInput
                  value={values.subject}
                  name="subject"
                  label={'Subject'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.subject}
                  touched={touched.subject}
                  materialDesign
                />
              </div>
              <div className="description">
                <FocusInput
                  value={values.description}
                  label={'Describe the issue'}
                  type="textarea"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  materialDesign
                  error={errors.description}
                  touched={touched.description}
                  name="description"
                />
              </div>
              <NewButton block htmlType="submit" isLoading={isSubmitting}>
                Submit
              </NewButton>
            </form>
          )}
          {isSubmitFormOpen && (
            <div className="text-center modal-submit">
              <h2>Thank You!</h2>
              <p>Your Support Ticket has been submitted</p>
              <p>We will reply as soon as possible.</p>
              <div className="btn-holder">
                <NewButton
                  block
                  size="large"
                  type="primary"
                  shape="circle"
                  onClick={() => setIsSubmitFormOpen(false)}
                >
                  Ok
                </NewButton>
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
}
export default styled(SupportFooter)`
  .btn-holder {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 599px;
    background: #fff;
    padding: 25px 15px;

    @media (max-width: 1023px) {
      width: 100%;
      padding: 10px;
    }

    .button {
      max-width: 202px;
      margin: 0 auto;
    }
  }
`;

import { useState } from 'react';
import { CopyToClipBoard } from '../util';

const useCopyToClipBoard = (): [boolean, Function] => {
  const [copied, setCopied] = useState(false);

  const copy = (text: string) => {
    setCopied(false);
    CopyToClipBoard(text);
    setCopied(true);
  };
  return [copied, copy];
};

export default useCopyToClipBoard;

import React, { cloneElement } from 'react';

interface Props {}

const SinglePanelLayout: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <div className="container sm-container page-container mt-30 mb-30">
      {React.Children.toArray(children).map((child: any) => {
        return cloneElement(child, { Layout1: 'yes', ...rest });
      })}
    </div>
  );
};

export default SinglePanelLayout;

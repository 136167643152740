import React, { createContext, useEffect, useReducer } from 'react';
import reducerCombainer from '../reducer';
import { IAppState } from '../reducer/appStates';
import {
  IPurchaseStatesReducer,
  SET_GUEST_USER,
} from '../reducer/purchaseStates';
import { IThemeState } from '../reducer/theme';
import { getLocalStorage } from '../util';

export interface IAppGlobalStatesContext {
  appStates?: IAppState;
  purchaseStates?: IPurchaseStatesReducer;
  theme?: IThemeState;
  dispatch?: React.Dispatch<any>;
}
export const AppGlobalStates = createContext<IAppGlobalStatesContext>({});

const AppGlobalStatesProvider: React.FC = (props) => {
  const [{ appStates, purchaseStates, theme }, dispatch] = useReducer(
    reducerCombainer,
    //InitialValues
    {
      appStates: {
        loading: false,
        emailNotificationClosedByUser: false,
        applyThemeModal: {
          active: false,
          edit: false,
        },
        socialLinks: [],
      },
      purchaseStates: {},
      theme: {
        allthemes: [],
        current: undefined,
        systemThemes: [],
        categories: [],
      },
    },
  );

  useEffect(() => {
    const guestUser = getLocalStorage('guestUser');
    dispatch({ type: SET_GUEST_USER, payload: guestUser });
  }, []);

  return (
    <AppGlobalStates.Provider
      value={{ appStates, purchaseStates, theme, dispatch }}
    >
      {props.children}
    </AppGlobalStates.Provider>
  );
};

export default AppGlobalStatesProvider;

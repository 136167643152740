import React from 'react';
import styled, { withTheme } from 'styled-components';

interface Props {
  className?: string;
}

export const PageDescription: React.FC<Props> = ({ className, children }) => {
  return <p className={className}>{children}</p>;
};

export default withTheme(styled(PageDescription)`
  text-align: center;
  color: black;
  margin: 0 0 30px;
  ${({ theme }: any) =>
    theme?.additional?.descriptionSize &&
    `font-size:${theme?.additional?.descriptionSize};`}
  ${({ theme }: any) =>
    theme?.additional?.descriptionColor &&
    `color:${theme.additional.descriptionColor};`}
`);

import { getUser, login, signup } from 'api/User';
import { ENVIRONMENT } from 'config';
import { Time } from 'enums';
import useDispatch from 'hooks/useDispatch';
import LogRocket from 'logrocket';
import React, { createContext, useCallback, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { RESET_APP_STATE } from 'reducer/appStates';
import { RESET_PURCHASE_STATES } from 'reducer/purchaseStates';
import { RESET_THEME_STATE } from 'reducer/theme';
import {
  GAUserTracking,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
  simpleEvent,
} from 'util/index';
const cookies = new Cookies();

export const actions = {
  setUser: 'SET_USER',
  toggleLoading: 'TOGGLE_LOADING',
  loggedIn: 'SET_LOGGED_IN',
  isAuthenticated: 'ISAUTHENTICATED',
};

export interface IAuthState {
  user: object | undefined;
  isAuthenticated?: boolean;
  loggedIn: boolean;
  isInitiallyLoading: boolean;
}
const reducer = (state: IAuthState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case actions.setUser:
      setLocalStorage('user', action.payload);
      return {
        ...state,
        user: action.payload,
        isInitiallyLoading: false,
      };
    case actions.toggleLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.loggedIn:
      return {
        ...state,
        loggedIn: action.payload,
      };
    case actions.isAuthenticated:
      return {
        ...state,
        isAuthenticated: action.payload,
        loggedIn: action.payload,
      };
    default:
      throw new Error(`No case for type ${action.type} found.`);
  }
};

interface IAuthContext extends Partial<IAuthState> {
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  options: { loggedIn?: boolean };
  Login: Function;
  SignUp: Function;
  Logout: Function;
  setToken: (token: string) => void;
  dispatch?: any;
}

export const Auth = createContext<IAuthContext>({
  user: undefined,
  setUser: () => {},
  Login: () => {},
  SignUp: () => {},
  Logout: () => {},
  setToken: () => {},
  options: {},
});

const AppAuthProvider: React.FC<any> = (props) => {
  const history = useHistory();
  const [{ user, isAuthenticated, ...rest }, dispatch] = React.useReducer(
    reducer,
    {
      isInitiallyLoading: true,
      isLoading: false,
      loggedIn:
        getLocalStorage('user')?._id && cookies.get('token') ? true : false,
      user: getLocalStorage('user'),
      isAuthenticated: false,
    },
  );
  const appDispatch = useDispatch();

  useEffect(() => {
    //check if authicated
    if (rest.loggedIn) {
      getUser(user._id)
        .then((res) => {
          dispatch({ type: actions.setUser, payload: res });
          dispatch({ type: actions.isAuthenticated, payload: true });
          GAUserTracking({
            name: res.firstName,
            userId: res._id,
            clientId: res._id,
          });
          if (ENVIRONMENT === 'development') {
            LogRocket.identify(res._id, {
              name: `${res.firstName} ${res.lastName}`,
              email: res.email,
            });
          }
        })
        .catch((e) => {
          dispatch({ type: actions.setUser, payload: undefined });
          dispatch({ type: actions.isAuthenticated, payload: false });
          cookies.remove('token', { path: '/' });
          removeLocalStorage('user');
        });
    }
  }, []);

  const Login = useCallback(
    (credientials) => {
      return login(credientials).then(async (res: any) => {
        const { data, token } = res;
        setToken(token);
        removeLocalStorage('guestUser');
        dispatch({ type: actions.setUser, payload: data });
        dispatch({ type: actions.isAuthenticated, payload: true });
        GAUserTracking({
          name: data.firstName,
          userId: data._id,
          clientId: data._id,
        });
        if (ENVIRONMENT === 'development') {
          LogRocket.identify(data._id, {
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
          });
        }
        simpleEvent({
          category: 'login',
          action: 'login',
          label: data._id,
        });
        return data;
      });
    },
    [dispatch],
  );

  const setToken = useCallback((token: string) => {
    cookies.set('token', token, {
      path: '/',
      maxAge: 68 * Time.YEARS,
      sameSite: false,
    });
  }, []);

  const SignUp = useCallback(
    (data) => {
      return signup(data)
        .then(async (res: any) => {
          const { data, token } = res;
          setToken(token);
          dispatch({ type: actions.setUser, payload: data });
          dispatch({ type: actions.isAuthenticated, payload: true });
          GAUserTracking({
            name: data.firstName,
            userId: data._id,
            clientId: data._id,
          });
          simpleEvent({
            category: 'signup',
            action: 'signup',
            label: data._id,
          });
          return user;
        })
        .catch((e: Error) => {
          //TODO: Handle Error case
          throw e;
        });
    },
    [dispatch],
  );

  const setUser = (value: any) => {
    const updatedUser = typeof value === 'function' ? value(user) : value;
    dispatch({ type: actions.setUser, payload: updatedUser });
  };

  const Logout = useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        dispatch({ type: actions.setUser, payload: undefined });
        dispatch({ type: actions.isAuthenticated, payload: false });
        appDispatch({ type: RESET_THEME_STATE });
        appDispatch({ type: RESET_APP_STATE });
        appDispatch({ type: RESET_PURCHASE_STATES });

        removeLocalStorage('user');
        cookies.remove('token', { path: '/' });

        history.push('/login');
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }, [dispatch]);
  return (
    <Auth.Provider
      value={{
        user,
        isAuthenticated,
        setUser,
        Login,
        SignUp,
        Logout,
        setToken,
        options: rest,
        dispatch,
        ...rest,
      }}
    >
      {props.children}
    </Auth.Provider>
  );
};

export default AppAuthProvider;

import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from 'config';
import { Offer } from 'types/Chat';
import request from 'util/request';

const apikey = STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(apikey === undefined ? '' : apikey);

export const createOfferIntent = async (orderId: string, offerId: string) => {
  return request(`/order/conversation/create-offer-intent`, {
    method: 'POST',
    data: {
      orderId,
      offerId,
    },
  }).then((res) => {
    if (!res || !res.success) {
      throw new Error(res.data?.message || '');
    }

    return res;
  });
};

export const createOffer = async (orderId: string, offer: Offer) => {
  const res = await request(`/order/conversation/${orderId}/offers`, {
    method: 'POST',
    data: offer,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

export const acceptOffer = async (orderId: string, offerId: string) => {
  const secret = await createOfferIntent(orderId, offerId);

  if (!secret.client_secret) {
    throw new Error(
      'Sorry, Due to some technical issue this action can not be performed',
    );
  }

  const stripe = await stripePromise;

  await stripe?.confirmCardPayment(secret.client_secret).then((res) => {
    if (res.error) {
      throw new Error(res.error.message);
    }
    return res;
  });

  const res = await request(
    `/order/conversation/${orderId}/offer/${offerId}/accept`,
    {
      method: 'GET',
    },
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

export const declineOffer = async (orderId: string, offerId: string) => {
  const res = await request(
    `/order/conversation/${orderId}/offer/${offerId}/decline`,
    {
      method: 'GET',
    },
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

export const withdrawOffer = async (orderId: string, offerId: string) => {
  const res = await request(
    `/order/conversation/${orderId}/offer/${offerId}/withdraw`,
    {
      method: 'GET',
    },
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

import useDispatch from 'hooks/useDispatch';
import useSelector from 'hooks/useSelector';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { matchPath } from 'react-router-dom';
import { SET_PUBLIC_USER } from 'reducer/appStates';
import 'styles/public.css';
import { fetchUserByName } from '../api/User';
import { parseQuery, removeLocalStorage } from '../util';
import PublicLayout from './public';

const PublicUser: React.FC<{
  showFooter?: boolean;
  hideTopFooter?: boolean;
}> = (props) => {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  const { username } = useParams<{ username: string }>();
  const { themeId, token } = parseQuery(location.search);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.appStates?.publicUser);

  const getUser = useCallback(async () => {
    const userData = await fetchUserByName(
      username,
      { themeId, token },
      false,
    ).catch((e: Error) => {
      console.log(e);
    });

    if (!userData) {
      return history.push('/404');
    }
    dispatch({ type: SET_PUBLIC_USER, payload: userData });
  }, [dispatch, history, themeId, token, username]);

  useEffect(() => {
    !!username && getUser();
    removeLocalStorage('welcomeModalClosedByUser');
  }, [username]);

  const popslugMatch = matchPath(history.location.pathname, {
    path: '/:username/:popslug',
    exact: true,
  });

  const purchaseMatch = matchPath(history.location.pathname, {
    path: '/:username/purchase',
    exact: false,
  });

  return (
    <div className={popslugMatch || purchaseMatch ? 'public__layout' : ''}>
      <PublicLayout theme={user?.userThemeId} user={user} {...props}>
        {children}
      </PublicLayout>
    </div>
  );
};

export default PublicUser;

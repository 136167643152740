import React from 'react';
import { useHistory, useLocation } from 'react-router';
import SetPasswordForm from '../../components/PasswordFrom';
import { parseQuery } from '../../util';

export default function MyProfile() {
  const history = useHistory();
  const location = useLocation();
  const { order } = parseQuery(location.search);
  const title =
    'Ok, you are all set. Before we send you to your order page, please set your password so you can easily login to view your orders later as well.';
  const onSuccessCallback = () => {
    history.push(`/my-purchases?order=${order}`);
  };
  return (
    <>
      <SetPasswordForm onSuccessCallback={onSuccessCallback} title={title} />
    </>
  );
}

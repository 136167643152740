import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IUser from 'types/user';
import Button from '../../components/button';
import Invitation from '../../layout/invitation';

const Welcome4: React.FC<{ className: string; user: IUser }> = ({
  className,
  user,
}) => {
  return (
    <div className={className}>
      <Invitation>
        <div className="profile--info text-center">
          <h1>Your Pop Page is all about You!</h1>
        </div>
        <div className="image-holder">
          <figure>
            <img
              src="/assets/images/invitation/Invite-Mobile-5.png"
              alt="profile"
              width="100%"
            />
          </figure>
        </div>
        <div className="profile--info">
          <h5 className="text-center">
            This is not a marketplace, but rather your own, private platform.
            This way, you can directly link from your social media without
            sharing your audience or diminishing your brand.
          </h5>
        </div>
        <div className="text-center">
          <Link to={`/invite/welcome-5?hash=${user?.invitationHash}`}>
            <Button>NEXT</Button>
          </Link>
        </div>
      </Invitation>
    </div>
  );
};

export default styled(Welcome4)`
  .container {
    color: #000;
    padding: 3vh 0;
  }
  .profile--info {
    margin: 0 0 4vh;
  }
  .profile--info h1 {
    font-size: 3.8vh;
  }
  .profile--info h5 {
    font-size: 2.5vh;
  }
  .image-holder {
    max-width: 48vh;
    margin: 0 auto 4vh;
  }
  .image-holder img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`;

import { motion, Variants } from 'framer-motion';
import React, { useRef } from 'react';
import { useHistory } from 'react-router';

const rtl = {
  pageInitial: {
    opacity: 0,
    left: '660px',
  },
  pageAnimate: {
    opacity: 1,
    left: '0',
  },
  pageExit: {
    opacity: 0,
    left: '-660px',
    position: 'absolute',
  },
};
const ltr = {
  pageInitial: {
    opacity: 0,
    left: '-660px',
  },
  pageAnimate: {
    opacity: 1,
    left: '0',
  },
  pageExit: {
    opacity: 0,
    left: '660px',
    position: 'absolute',
  },
};

export const Transition = {
  // type: 'spring',
  mass: 0.2,
  duration: 0.6,
};

const PageTransition: React.FC<{ variants?: Variants }> = ({
  children,
  variants,
  ...props
}) => {
  const { action } = useHistory();
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  const trasition = action === 'POP' ? ltr : rtl;
  return (
    <div style={{ position: 'relative' }}>
      <motion.div
        ref={ref}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={variants || (trasition as any)}
        style={{ width: '100%', position: 'relative' }}
        transition={Transition}
      >
        {React.cloneElement(children as any, { ...props })}
      </motion.div>
    </div>
  );
};

export default PageTransition;

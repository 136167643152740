import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IUser from 'types/user';
import Button from '../../components/button';
import Invitation from '../../layout/invitation';

const Welcome1: React.FC<{ className: string; user: IUser }> = ({
  className,
  user,
}) => {
  return (
    <div className={className}>
      <Invitation>
        <div className="profile--info text-center">
          <h1>Sell Shoutouts</h1>
          <h6>
            Let fans pay you to to create custom video greetings for them.
          </h6>
        </div>
        <div className="image-holder">
          <figure>
            <img src="/assets/images/invitation/Invite-PC2.png" alt="profile" />
          </figure>
        </div>
        <div className="text-center">
          <Link to={`/invite/welcome-2?hash=${user?.invitationHash}`}>
            <Button classNames="my-btn">NEXT</Button>
          </Link>
        </div>
      </Invitation>
    </div>
  );
};

export default styled(Welcome1)`
  .container {
    background: #54a9e2;
    color: #fff;
    padding: 3vh 0;
  }
  .profile--info {
    margin: 0 0 4vh;
  }
  .profile--info h1 {
    font-size: 3.8vh;
  }
  .profile--info h6 {
    font-size: 2vh;
  }
  .image-holder {
    max-width: 38.5vh;
    margin: 0 auto;
  }

  .image-holder img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
  .font-light {
    font-weight: 300;
  }
  .my-btn {
    background: #018abd;
    border-color: #018abd;
  }
`;

import FocusInput from 'components/focus-input';
import Button from 'components/NButton';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import useQuery from 'hooks/useQuery';
import useRequestLoader from 'hooks/useRequestLoader';
import useSelector from 'hooks/useSelector';
import GuestLayout from 'layout/guest';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { sendEmailVerificationLink, verifyAccount } from '../api/User';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Enter valid email address')
    .required('Email is required'),
});
export default function Login() {
  const { user, loggedIn, setUser } = useAuth();
  const [accountverified, setAccountverified] = useState(false);
  const [serverError, setserverError] = useState<string | null>(null);
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const { token } = useQuery();
  const { withLoader } = useRequestLoader();
  const loading = useSelector((state) => state.appStates?.loading);

  const {
    values,
    handleChange,
    isValid,
    handleBlur,
    handleSubmit,
    errors,
    setValues,
    touched,
    isSubmitting,
  } = useFormik({
    validationSchema,

    initialValues: {
      email: user?.email || '',
    },

    onSubmit: async (values) => {
      await sendEmailVerificationLink(values.email)
        .then(() => {
          setLinkSent(true);
        })
        .catch((e) => setserverError(e.message || e));

      setserverError(null);
    },
  });

  useEffect(() => {
    if (token) {
      withLoader(
        verifyAccount({ token })
          .then((res) => {
            setAccountverified(true);
            if (loggedIn) {
              setValues({ email: user.email });
            }
          })
          .catch((e) => {
            setAccountverified(false);
          }),
      );
    }
  }, [token]);

  const pushToDashboard = () => {
    if (loggedIn) {
      setUser({ ...user, isEmailVerified: true });
    }
  };
  return (
    <>
      {!loading && (
        <GuestLayout>
          <div className="landing-page-area">
            <div className="container justify-content-center">
              <div className="form-signup-area ">
                <strong className="logo">
                  <Link to="/">
                    <img src="assets/images/logo-large.png" alt="selfiepop" />
                  </Link>
                </strong>
                <strong className="text-center title">
                  {accountverified
                    ? 'Your email address was successfull verified. Thank you'
                    : ' Sorry! Your Link Has Been Expired!'}
                </strong>

                {serverError && (
                  <div className="alert alert-danger">{serverError}</div>
                )}
                <div style={{ position: 'relative' }}>
                  <div
                    className={`alert alert-success ${
                      linkSent ? 'fadeIn' : 'c-hide'
                    }`}
                  >
                    Verification link successful sent to your email address
                  </div>
                  {!accountverified && (
                    <div className={` t-field ${linkSent && 'hide-from'}`}>
                      <form
                        action="post"
                        className="form"
                        onSubmit={handleSubmit}
                      >
                        {!user && (
                          <div className="field">
                            <FocusInput
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.email}
                              touched={touched.email}
                              label="Email"
                              // inputClasses="mb-25"
                              name="email"
                              value={values.email}
                            />
                          </div>
                        )}

                        <div className="mt-20 links-area">
                          {!linkSent && (
                            <Button
                              htmlType="submit"
                              block
                              size="x-large"
                              type="primary"
                              disabled={!isValid || isSubmitting}
                              isLoading={isSubmitting}
                            >
                              Send Verification Email
                            </Button>
                          )}
                        </div>
                      </form>
                    </div>
                  )}
                </div>
                <div className="links-area">
                  {linkSent ||
                    (accountverified && (
                      <Link to="/login" onClick={pushToDashboard}>
                        <Button block type="primary" size="x-large">
                          {user ? 'Go to Dashboard' : 'Sign In Again'}
                        </Button>
                      </Link>
                    ))}
                  {!user && (
                    <>
                      <strong className="mt-10 info-text text-primary">
                        Don't have a Account?
                      </strong>
                      <Link to="/signup">
                        <span className="link">Sign-up Here</span>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </GuestLayout>
      )}{' '}
    </>
  );
}
